import { Lightning, Colors } from '@lightningjs/sdk'
import { COLORS } from '../constants/colors'

export default class ScrollBar extends Lightning.Component {
  static _template() {
    return {
      Background: {
        h: h => h,
        mountX: 0.5,
        w: 6,
        shader: {
          type: Lightning.shaders.RoundedRectangle,
          radius: 3,
        },
        rect: true,
        color: Colors(COLORS.LIGHTGREY)
          .alpha(0.2)
          .get(),
      },
      Scroller: {
        mountX: 0.5,
        h: h => h,
        w: w => w,
        shader: {
          type: Lightning.shaders.RoundedRectangle,
          radius: 8,
        },
        rect: true,
      },
    }
  }

  _construct() {
    this._direction = ScrollBar.DIRECTION.vertical
    this._colors = {
      background: Colors(COLORS.LIGHTGREY)
        .alpha(0.2)
        .get(),
      scrollerFocused: Colors(COLORS.LIGHTGREY).get(),
      scrollerUnfocused: Colors(COLORS.COBALT).get(),
    }
  }

  _init() {
    this._previousIndex = -1
    this._index = 0
    this.patch({
      Scroller: { color: this._colors.scrollerUnfocused },
    })
  }

  set changeColor(v) {
    v ? this._focus() : this._unfocus()
  }

  _focus() {
    this.patch({
      Scroller: { color: Colors(COLORS.WHITE).get() },
    })
  }

  _unfocus() {
    this._scrollerPosition = 0
    this._apply()
    this.patch({
      Scroller: { color: Colors(COLORS.COBALT).get() },
    })
  }

  /**
   * Set the scroll bar direction.
   * @param string vertical or horizontal
   */
  set direction(string) {
    this._direction = ScrollBar.DIRECTION[string] || ScrollBar.DIRECTION.vertical
  }

  get direction() {
    return this._direction
  }

  /**
   * Set the required colors for the scroll bar.
   * @param background scroll bar color
   * @param scrollerFocused scroller color when focused
   * @param scrollerUnfocused scroller color when it is out of focus
   */
  set colors({ background = null, scrollerFocused = null, scrollerUnfocused = null }) {
    this._colors.background = background || this._colors.background
    this._colors.scrollerFocused = scrollerFocused || this._colors.scrollerFocused
    this._colors.scrollerUnfocused = scrollerUnfocused || this._colors.scrollerUnfocused

    this.tag('Background').patch({ color: this._colors.background })
  }

  get colors() {
    return this._colors
  }

  /**
   * Set the possible number of scrolls.
   * @param val number of scrolls available
   */
  set totalNumOfScrolls(val) {
    //current scroller position
    this._scrollerPosition = 0
    this._totalScrolls = val

    //scroller height
    this._scrollerSize = this._scrollbarTotalSize / this._totalScrolls

    let scrollerSizeProperty = this.direction === 'vertical' ? 'h' : 'w'
    this.tag('Scroller').patch({
      smooth: { [scrollerSizeProperty]: this._scrollerSize },
    })
  }

  get totalNumOfScrolls() {
    return this._totalScrolls
  }

  get _scrollbarTotalSize() {
    return this.direction === 'vertical' ? this.h : this.w
  }

  get _scrollbarStart() {
    return this.tag('Background')[this.direction === 'vertical' ? 'finalY' : 'finalX']
  }

  get _scrollerStartPosition() {
    return this._scrollbarStart + this._scrollerPosition * this._scrollerSize
  }

  get _axis() {
    return this.direction === 'vertical' ? 'y' : 'x'
  }

  _handleNext() {
    let scrollerPosition = this._scrollerPosition
    if (this._scrollerPosition < this._totalScrolls - 1) {
      scrollerPosition++

      this._previousIndex = this._index
      this._index++
      if (scrollerPosition > this._totalScrolls - 1) {
        scrollerPosition = scrollerPosition - (scrollerPosition - (this._totalScrolls - 1))
      }
      this._scrollerPosition = scrollerPosition
      this._apply()
    } else {
      return false
    }
  }

  _handlePrevious() {
    if (this._scrollerPosition > 0) {
      this._previousIndex = this._index
      this._index--
      this._scrollerPosition--
      this._scrollerPosition = this._scrollerPosition < 0 ? 0 : this._scrollerPosition
      this._apply()
    } else {
      return false
    }
  }

  _handleDown() {
    if (this.direction === 'vertical') {
      return this._handleNext()
    } else {
      return false
    }
  }

  _handleUp() {
    if (this.direction === 'vertical') {
      return this._handlePrevious()
    } else {
      return false
    }
  }

  _handleRight() {
    if (this.direction === 'horizontal') {
      return this._handleNext()
    } else {
      return false
    }
  }

  _handleLeft() {
    if (this.direction === 'horizontal') {
      return this._handlePrevious()
    } else {
      return false
    }
  }

  _apply() {
    let scrollerStart = this._scrollerStartPosition
    let axis = this._axis
    this.tag('Scroller').patch({ smooth: { [axis]: scrollerStart } })
    this.signal('scrollTo', this._scrollerPosition, this._previousIndex, this._index)
  }

  reset() {
    this._scrollerPosition = 0
    this._previousIndex = -1
    this._index = 0
    this.tag('Scroller').patch({ smooth: { y: 0 } })
    this.patch({
      Scroller: { color: Colors(COLORS.COBALT).get() },
    })
  }
}

ScrollBar.DIRECTION = {
  vertical: 'vertical',
  horizontal: 'horizontal',
}
