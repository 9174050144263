import { Colors, Lightning } from '@lightningjs/sdk'
import { isBoolean, isNumber } from '../../utils/checks'
import { COLORS } from '../../constants/colors'

export default class CardCounter extends Lightning.Component {
  static _template() {
    return {
      mountX: 1,
      Counter: {
        mountX: 1,
        text: {
          text: '',
          fontSize: 28,
          fontFace: 'PoppinsSemiBold',
          lineHeight: 42,
          textAlign: 'right',
        },
      },
    }
  }

  set index(index) {
    this._index = index
    this.updateCounter(index)
  }

  get index() {
    return isNumber(this._index) ? this._index : 0
  }

  set colorFocus(colorFocus) {
    this._colorFocus = colorFocus
  }

  get colorFocus() {
    return this._colorFocus ? this._colorFocus : Colors(COLORS.LIGHTGREY).get()
  }

  set colorUnfocus(colorUnfocus) {
    this._colorUnfocus = colorUnfocus
  }

  get colorUnfocus() {
    return this._colorUnfocus ? this._colorUnfocus : Colors(COLORS.DARKGREY_60_FADED).get()
  }

  set highlight(highlight) {
    this._highlight = isBoolean(highlight) ? highlight : false
    this.tag('Counter').patch({
      color: this._highlight ? this.colorFocus : this.colorUnfocus,
    })
  }

  get highlight() {
    return isBoolean(this._highlight) ? this._highlight : false
  }

  set total(total) {
    this._total = total
    this.updateCounter(this._index)
  }

  get total() {
    return isNumber(this._total) ? this._total : 0
  }

  updateCounter(index = 1) {
    this.tag('Counter').text.text = `${index}/${this.total}`
  }
}
