import { Lightning, Colors } from '@lightningjs/sdk'
import { COLORS } from '@/constants/colors'
import { FONTS } from '@/constants/fonts'
import { voiceGuide } from '../../../services/voiceGuide'

export default class InputKey extends Lightning.Component {
  static _template() {
    return {
      BG: {
        alpha: 0,
        texture: Lightning.Tools.getRoundRect(
          50,
          50,
          10,
          3,
          Colors(COLORS.LIGHTGREY).get(),
          true,
          0x0000ffff
        ),
      },
      Label: {
        x: 27,
        y: 28,

        mount: 0.5,
        color: FONTS.PAGE.SEARCH.fontColor,
        text: {
          fontSize: FONTS.PAGE.SEARCH.fontSize,
          fontFace: 'PoppinsSemiBold',
        },
      },
    }
  }

  _init() {
    this.tag('Label').patch({ text: { text: this.buttonText } })
  }

  _focus() {
    const buttonText = this.buttonText || ''
    voiceGuide.utterThis(buttonText)
    this.tag('BG').patch({ alpha: 1 })
    this.tag('Label').color = FONTS.PAGE.SEARCH.focusColor
  }

  _unfocus() {
    this.tag('BG').patch({ alpha: 0 })
    this.tag('Label').color = FONTS.PAGE.SEARCH.fontColor
  }
}
