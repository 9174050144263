import { API } from '../../constants/api'
import { isArrayWithLength } from '../../utils/checks'
import Api_HomeCallAsset from './Api_HomeCallAsset'

export default class Api_HomeAsset {
  constructor(homeCalls) {
    this._callInfo = isArrayWithLength(homeCalls && homeCalls.carousels)
      ? this._sortArrayByWeight(this._makeCallAssets(homeCalls.carousels))
      : undefined
    this._callInfoLength = isArrayWithLength(this._callInfo) ? this._callInfo.length : 0
    this._hasLive = this._callInfo ? this._includesLive(this._callInfo) : false
    this._hasData = !!this._callInfo
  }

  get callInfo() {
    return this._callInfo
  }

  get callInfoLength() {
    return this._callInfoLength
  }

  get hasLive() {
    return this._hasLive
  }

  get hasData() {
    return this._hasData
  }

  _sortArrayByWeight(callAssetArray) {
    return callAssetArray.sort((a, b) => a.weight - b.weight)
  }

  _includesLive(callInfo) {
    for (let i = 0; i < callInfo.length; i++) {
      if (callInfo[i] && callInfo[i].id && callInfo[i].id === API.CALLID.LIVESTREAM) {
        return true
      }
    }
    return false
  }

  _makeCallAssets(homeCallCarousels) {
    return homeCallCarousels.map((callData, indexWeight) => {
      return new Api_HomeCallAsset(callData, indexWeight)
    })
  }
}
