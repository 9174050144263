import { Lightning, Colors, Language, Router, Utils } from '@lightningjs/sdk'
import { COLORS } from '../constants/colors'
import { getSearchResult } from '@/api/Api'
import SearchBar from '@/components/Search/SearchBar'
import Keyboard from '@/components/Search/Keyboard/Keyboard'
import SearchResult from '@/components/Search/SearchResult'
import { FONTS } from '@/constants/fonts'
import { STATES, RESULT_COLUMN } from '@/constants/search'
import { getWidget } from '@/utils/tools'
import { ROUTE } from '@/constants/routes'
import BackToTop from '@/components/Home/BackToTop'
import { sendMetrics } from '../services/metrics'
import { BUTTON, GA4, PAGENAME } from '../constants/analytics'
import { sortVideoByDate } from '../utils/cspan'

export default class Search extends Lightning.Component {
  static _template() {
    return {
      Logo: {
        x: 94,
        y: 65,
        src: Utils.asset('images/logo.png'),
      },
      SearchBar: {
        x: 104,
        y: 278,
        w: 360,
        h: 66,
        type: SearchBar,
      },
      Keyboard: {
        x: 99,
        y: 375,
        w: 360,
        type: Keyboard,
        signals: {
          onKeyboardInputUpdate: true,
        },
      },
      InstructionText: {
        w: 577,
        h: 48,
        x: 890,
        y: 516,
        text: {
          text: Language.translate('page_search').searchResultInstText,
          fontFace: 'PoppinsRegular',
          fontSize: FONTS.PAGE.SEARCH.fontSize,
          lineHeight: 48,
          textColor: Colors(COLORS.DARKGREY_60).get(),
        },
      },

      SearchResult: {
        x: 542,
        y: 210,
        w: 1274,
        type: SearchResult,
        signals: {
          onSearchGridIndexChanged: true,
        },
      },
      BackToTop: {
        type: BackToTop,
      },
    }
  }

  get Keyboard() {
    return this.tag('Keyboard')
  }

  get SearchBar() {
    return this.tag('SearchBar')
  }

  get SearchResult() {
    return this.tag('SearchResult')
  }

  get InstructionText() {
    return this.tag('InstructionText')
  }

  _active() {
    this._gridStartPosition = 320
    this._isInstActive = 1
    this._searchKeyword = this._searchKeyword ? this._searchKeyword : undefined
    this._focusIndex = this._focusIndex ? this._focusIndex : 0
    this._setState(STATES.KEYBOARD)
    const headerWidget = getWidget('Header')
    if (headerWidget && headerWidget.showhideLogo) {
      headerWidget.showhideLogo(0)
    }
    sendMetrics(GA4.SCREENVIEW, { page_title: PAGENAME.SEARCH })
  }

  _handleUp() {
    this.handleRight = false
    if (this._focusIndex > 2) {
      this.SearchResult.Result.setIndex(0)
      this.goToSearchResult()
    } else {
      this._setState('Inactive')
    }
  }

  _handleDown() {
    if (this._getState() === STATES.SEARCH_RESULT) {
      if (this.isLastButOne()) {
        this.SearchResult.Result.last()
      }
    }
  }

  _handleLeft() {
    this.handleRight = false
    if (this._getState() === STATES.SEARCH_RESULT) {
      this._setState(STATES.KEYBOARD)
    }
  }

  _handleRight() {
    this.goToSearchResult()
  }

  _handleBack() {
    sendMetrics(GA4.CLICK, { button: BUTTON.BACK, page_title: PAGENAME.SEARCH })
    const historyLength = Router.getHistory() ? Router.getHistory().length : 0
    if (this._focusIndex > 2) {
      this.SearchResult.Result.setIndex(0)
    } else if (historyLength > 0) {
      Router.back()
    } else {
      if (Router.getActiveRoute() === ROUTE.HOME) {
        Router.back()
      } else {
        Router.navigate(ROUTE.HOME)
      }
    }
  }

  historyState(params) {
    if (params) {
      this._resultData = params.data
      this._searchKeyword = params.searchKeyword
      if (this._resultData != undefined) {
        setTimeout(() => this._setState(params.lastActiveState), 1000)
      }
    } else {
      return {
        lastActiveState: this._getState(),
        data: this._resultData ? this._resultData : undefined,
        searchKeyword: this._searchKeyword ? this._searchKeyword : undefined,
        focusIndex: this._focusIndex ? this._focusIndex : 0,
      }
    }
  }

  goToSearchResult() {
    this.handleRight = true
    if (this._getState() === STATES.KEYBOARD && this._resultActive) {
      this._setState(STATES.SEARCH_RESULT)
    }
  }

  showHideInstText(alphaValue) {
    this._isInstActive = alphaValue
    this.InstructionText.setSmooth('alpha', alphaValue)
  }

  updateNoItemsFound() {
    this._setState(STATES.KEYBOARD)
    this.showHideInstText(1)
    this.InstructionText.patch({
      text: {
        text: Language.translate('page_search').noItemsFound,
      },
    })
  }

  showSearchResult() {
    if (this._resultData === undefined) {
      this.SearchResult.clearGrid()
      this._resultActive = false
      this.updateNoItemsFound()
      this.SearchResult.showHideTitle(0)
    } else {
      this._resultActive = true
      this.showHideInstText(0)
      this.SearchBar._updateLabel(this._searchKeyword, true)
      this.SearchResult.updateTitle(this._searchKeyword)
      this.SearchResult.data = this._resultData
    }
  }

  getData() {
    this._focusIndex = 0
    // Not using showHideInstText method here as it changes the flag _isInstActive
    if (getWidget('Background') && getWidget('Background').moveBackground)
      getWidget('Background').moveBackground(0) //Reset after deeplinking
    if (getWidget('Header') && getWidget('Header').trackHeader)
      getWidget('Header').trackHeader(undefined) //Reset after deeplinking
    this.Keyboard.value = this._searchKeyword //Update value after deeplinking
    this.SearchBar._updateLabel(this._searchKeyword, true) //Update value after deeplinking
    this.InstructionText.setSmooth('alpha', 0)
    this.SearchResult.showLoader()
    if (!this._resultData) {
      getSearchResult(this._searchKeyword + '~')
        .then(items => {
          if (items) this._resultData = sortVideoByDate(items, 'date', 'Descending')
          this.showSearchResult()
          this._prevSearchKeyword = this._searchKeyword
        })
        .catch(() => {})
    } else {
      this._isInstActive ? this.showSearchResult() : ''
    }
  }

  onSearchGridIndexChanged({ index, previousIndex }) {
    this._focusIndex = index

    const listIndex = Math.floor(index / 3)
    const listPreviousIndex = Math.floor(previousIndex / 3)

    getWidget('Background').moveBackground(listIndex)

    if (listIndex === 1 && listPreviousIndex < listIndex) {
      this.tag('BackToTop').show = true
    } else if (listIndex === 0) {
      this.tag('BackToTop').show = false
    }

    if (index <= 2) {
      getWidget('Header').trackHeader(undefined)
    } else {
      // eslint-disable-next-line prettier/prettier
			getWidget('Header').trackHeader(
				this.SearchResult.Result.wrapper,
				this._gridStartPosition
			)
    }
  }

  isLastButOne() {
    let currentIndex = 0
    let rowMatch = -1
    const rowLen = Math.floor(this._resultData.length / RESULT_COLUMN)
    for (let row = 0; row <= rowLen; row++) {
      for (let col = 0; col < RESULT_COLUMN; col++) {
        if (this._focusIndex === currentIndex) {
          rowMatch = row
          return rowMatch === rowLen - 1 ? true : false
        }
        currentIndex++
      }
    }
  }

  static _states() {
    return [
      class Keyboard extends this {
        $keyboardValueChanged({ value }) {
          this.SearchBar._updateLabel(value)
        }

        onKeyboardInputUpdate(searchInput) {
          this._searchKeyword = searchInput
          this._resultData = undefined
          this._setState(STATES.SEARCH_RESULT)
        }

        _getFocused() {
          return this.Keyboard
        }
      },
      class SearchResult extends this {
        $enter() {
          if (!this.handleRight) {
            // eslint-disable-next-line prettier/prettier
						if (
							this._searchKeyword.length > 0 &&
							this._prevSearchKeyword != this._searchKeyword
						) {
              this.getData()
            } else {
              this._setState(STATES.KEYBOARD)
            }
          }
        }

        _getFocused() {
          return this.SearchResult
        }
      },
      class Inactive extends this {
        $enter() {
          Router.focusWidget('Header')
        }

        _focus() {
          if (!Router.isNavigating()) {
            this._setState('Keyboard')
          }
        }
      },
    ]
  }
}
