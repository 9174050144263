import { isNumber, isStringWithLength } from '../../utils/checks'
import { adjustToItalic } from '../../utils/cspan'

export default class SeriesAsset {
  constructor(seriesItem) {
    this._bookImagePath = isStringWithLength(seriesItem.bookImagePath)
      ? seriesItem.bookImagePath
      : undefined
    this._id = isNumber(seriesItem.id) ? seriesItem.id : 0
    this._publicId = isStringWithLength(seriesItem.publicId) ? seriesItem.publicId : undefined
    this._title = isStringWithLength(seriesItem.title) ? adjustToItalic(seriesItem.title) : ''
    this._description = isStringWithLength(seriesItem.description)
      ? adjustToItalic(seriesItem.description)
      : ''
    this._imagePath = isStringWithLength(seriesItem.imagePath) ? seriesItem.imagePath : undefined
    this._date = isStringWithLength(seriesItem.date) ? seriesItem.date : undefined
    this._videoLink = isStringWithLength(seriesItem.videoLink) ? seriesItem.videoLink : undefined
    this._videoDuration = seriesItem.videoDuration
    this._itemType = isStringWithLength(seriesItem.itemType) ? seriesItem.itemType : ' '
  }

  get bookImagePath() {
    return this._bookImagePath
  }

  get itemType() {
    return this._itemType
  }

  get videoDuration() {
    return this._videoDuration
  }

  get videoLink() {
    return this._videoLink
  }

  get date() {
    return this._date
  }

  get imagePath() {
    return this._imagePath
  }

  get description() {
    return this._description
  }

  get title() {
    return this._title
  }

  get id() {
    return this._id
  }

  get publicId() {
    return this._publicId
  }

  get formatDate() {
    const formatedDate = new Date(this.date).toLocaleString('en-us', {
      month: 'long',
      day: '2-digit',
      year: 'numeric',
    })

    return formatedDate
  }
}
