import { Colors, Lightning, Log, Router, Utils } from '@lightningjs/sdk'
import { getFileExtension, isObjectWithLength, isStringWithLength } from '../../utils/checks'
import { getImage, getVideoLive, getVideoVodClip, getVideoVodProgram } from '../../api/Api'
import { ROUTE } from '../../constants/routes'
import { playerPolymorph } from '../../utils/player'
import { COLORS } from '../../constants/colors'
import { appInstance } from '../../utils/tools'
import LiveBadge from './LiveBadge'
import { getVideoLiveState, isCurrentTimeBetweenStartEnd } from '../../utils/cspan'
import { voiceGuide } from '../../services/voiceGuide'

export default class LiveFeaturedCard extends Lightning.Component {
  static _template() {
    return {
      w: 690,
      h: 388,
      Highlight: {
        x: -10,
        y: -10,
        w: 710,
        h: 408,
        rect: true,
        alpha: 0.0001,
        shader: {
          type: Lightning.shaders.RoundedRectangle,
          radius: 20,
          stroke: 5,
          fillColor: Colors(COLORS.WHITE)
            .alpha(0)
            .get(),
          strokeColor: Colors(COLORS.LIGHTGREY).get(),
        },
      },
      Background: {
        x: -10,
        y: -10,
        src: Utils.asset('images/thumbnails/default/live_max_bg.png'),
      },
      Image: {
        w: 690,
        h: 388,
        shader: {
          type: Lightning.shaders.RoundedRectangle,
          radius: 10,
        },
      },
      LiveBadge: {
        x: 20,
        y: 20,
        zIndex: 30,
        type: LiveBadge,
      },
    }
  }

  set highlight(highlight) {
    this._highlight = highlight
    this.tag('Highlight').patch({ alpha: highlight ? 1 : 0.0001 })
    if (this.tag('LiveBadge')) {
      this.tag('LiveBadge').activate = highlight
    }
  }

  get highlight() {
    return typeof this._highlight === 'boolean' ? this._highlight : false
  }

  get strokeColor() {
    return this._strokeColor ? this._strokeColor : Colors(COLORS.LIGHTGREY).get()
  }

  set data(data) {
    this._data = data
    this._loadImage(data.imagePath)
    this.liveBadge = data
  }

  get data() {
    return this._data
  }

  set liveBadge(data) {
    if (isObjectWithLength(data)) {
      this.tag('LiveBadge').item = data
    } else {
      this.patch({ LiveBadge: undefined })
    }
  }

  _init() {
    this.tag('Image').on('txError', () => {
      this.tag('Image').patch({ src: Utils.asset('images/thumbnails/missing/normal.png') })
    })
    this._stillWatchingOpen = false
    playerPolymorph.updateTag()
    this._initPlayerEvents()
  }

  _detach() {
    this.application.off('stillWatching_featureCard')
    this.application.off('global_playerEvent_liveFeaturedCard')
    this._clearVideoCountDown()
    // this.stopVideo()
  }

  _handleEnter() {
    const itemType = this._data.itemType
    const id = this._data.id
    const liveBeginTime = new Date(this._data.liveBeginTime)
    const liveEndTime = new Date(this._data.liveEndTime)

    if (itemType && id) {
      if (isCurrentTimeBetweenStartEnd(liveBeginTime, liveEndTime)) {
        this._clearVideoCountDown()
        Router.navigate(`${ROUTE.VIDEO}/${this._data.itemType}/${this._data.id}`)
      } else {
        Log.warn('Stream is not live yet')
      }
    } else {
      Log.warn('missing params ', { itemType, id })
    }
  }

  _focus() {
    this.highlight = true
    const title = (this._data && this._data.title) || ''
    voiceGuide.utterThis(title)

    const isPlaying =
      appInstance && typeof appInstance.isPlaying === 'boolean' ? appInstance.isPlaying : false
    if (!isPlaying) {
      this._startVideoCountDown()
    }
  }

  _unfocus() {
    this._stoppedByStillWatching = false
    this.highlight = false
    this._clearVideoCountDown()
    if (Router.getActiveRoute() === ROUTE.HOME) this.stopVideo()
  }

  _initPlayerEvents() {
    this.application.off('stillWatching_featureCard')
    this.application.on('stillWatching_featureCard', ({ open, buttonPressed }) => {
      this._stillWatchingOpen = open
      if (buttonPressed !== undefined) {
        switch (buttonPressed) {
          case 'yes':
            this._stoppedByStillWatching = false
            appInstance.continue()
            break
          case 'no':
            this._stoppedByStillWatching = true
            this._showPlayer(false)
            this.stopVideo()
            break
        }
      }
    })

    this.application.off('global_playerEvent_liveFeaturedCard')
    this.application.on('global_playerEvent_liveFeaturedCard', event => {
      switch (event) {
        case 'Playing':
          //show player
          this._showPlayer(true)
          break
        case 'Ended':
        case 'Error':
        case 'Clear':
        case 'Abort':
          //hide player
          this._showPlayer(false)
          break
      }
    })
  }

  _loadImage(imagePath) {
    getImage(imagePath, {
      resize: { width: 690, height: 388 },
    }).then(imageUrl => {
      if (isStringWithLength(imageUrl)) {
        this.tag('Image').patch({
          src: imageUrl,
        })
      } else {
        this.tag('Image').patch({
          src: Utils.asset('images/thumbnails/missing/normal.png'),
        })
      }
    })
  }

  _showPlayer(showPlayer) {
    if (showPlayer) {
      this._playerVisible = true
      appInstance.isHomePlayer(true, this)
    } else {
      this._playerVisible = false
      appInstance.isHomePlayer(false)
    }
  }

  _startVideoCountDown() {
    this._clearVideoCountDown()
    this._videoCountDownCleared = false
    const startTime = this._data.liveBeginTime
    const endTime = this._data.liveEndTime
    //Do not start video if not live yet
    if (startTime && endTime && getVideoLiveState(startTime, endTime) !== 'live') return

    if (this._stoppedByStillWatching || this._stillWatchingOpen) return
    this._videoCountDown = setTimeout(() => {
      if (this._stoppedByStillWatching || this._stillWatchingOpen) return
      if (!appInstance.isPlaying) {
        this._loadVideo(this._data.itemType, this._data.id).then(videoFile => {
          if (videoFile !== undefined) {
            this._playVideo(videoFile)
          } else {
            this.stopVideo()
          }
        })
      }
    }, 2000)
  }

  _clearVideoCountDown() {
    this._videoCountDownCleared = true
    if (this._videoCountDown) clearTimeout(this._videoCountDown)
  }

  _loadVideo(itemType, id) {
    switch (itemType) {
      case 'program':
        return getVideoVodProgram(id)
          .then(data => {
            return data.videoFileHD
          })
          .catch(() => {
            return undefined
          })
      case 'clip':
        return getVideoVodClip(id)
          .then(data => {
            return data.videoFileHD
          })
          .catch(() => {
            return undefined
          })
      case 'event':
        return getVideoLive(id)
          .then(data => {
            return data.videoFileHD
          })
          .catch(() => {
            return undefined
          })
      default:
        return getVideoVodProgram(id)
          .then(data => {
            return data.videoFileHD
          })
          .catch(() => {
            return undefined
          })
    }
  }

  _playVideo(videoFile) {
    if (this._videoCountDownCleared) return
    appInstance.clear()
    if (this._stoppedByStillWatching || this._stillWatchingOpen) return
    if (isStringWithLength(videoFile)) {
      let extension = getFileExtension(videoFile)
      if (extension === '.m3u8' || extension === '.mp4') {
        //Check extension before playing
        appInstance.playerOpen({ url: videoFile, islive: true, isHomePlayer: true, scope: this })
      }
    }
  }

  stopVideo() {
    appInstance.stop()
    this._clearVideoCountDown()
    this._showPlayer(false)
  }

  updateImageAndBadge() {
    this._loadImage(this.data.imagePath)
    this.liveBadge = this.data
  }
}
