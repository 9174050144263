import { Language, Lightning, Colors } from '@lightningjs/sdk'
import TextButton from '@/components/TextButton'
import { COLORS } from '@/constants/colors'
import { FONTS } from '@/constants/fonts'
import Details from '@/components/Settings/Details'
import { STATES } from '@/constants/settings'
import { getTVNetData, getTVProviderData, unLinkTVNetData } from '../../api/Api'
import { getWidget } from '../../utils/tools'
import Loader from '../Loader'
import { FONTSTYLE } from '../../constants/fonts'
import { PROVIDER } from '../../constants/settings'
import { voiceGuide } from '../../services/voiceGuide'

export default class ActivationLoggedin extends Lightning.Component {
  static _template() {
    return {
      Loader: { y: 250, x: 400, type: Loader, alpha: 0 },
      Activation: {
        alpha: 0,
        flex: { direction: 'column' },
        Instruction: {
          h: 150,
          text: {
            ...FONTSTYLE.H2,
            text: Language.translate('tvNet_link_message'),
            textColor: Colors(COLORS.LIGHTGREY).get(),
            verticalAlign: 'middle',
            wordWrapWidth: 690,
          },
        },
        Line1: {
          flexItem: { marginTop: 40 },
          flex: { direction: 'row' },
        },
        Line2: {
          h: 42,
          flexItem: { marginTop: 20 },
          text: {
            fontSize: 28,
            fontFace: FONTS.POPPINS.REGULAR,
            textColor: Colors(COLORS.DARKGREY_40).get(),
            verticalAlign: 'middle',
            text: Language.translate('activation_line2'),
            lineHeight: 42,
            wordWrapWidth: 690,
          },
        },
        CodeBackground: {
          flexItem: { marginTop: 52 },
          w: 690,
          h: 160,
          texture: Lightning.Tools.getRoundRect(690, 160, 20),
          color: Colors(COLORS.BLACK)
            .alpha(0.4)
            .get(),
          Code: {
            mount: 0.5,
            x: w => w / 2 + 6,
            y: h => h / 2 + 9,
            h: 120,
            w: 690,
            text: {
              textAlign: 'center',
              fontSize: 80,
              letterSpacing: 10,
              fontFace: 'PoppinsRegular',
              textColor: Colors(COLORS.WHITE).get(),
              verticalAlign: 'middle',
            },
          },
        },
      },

      LoggedIn: {
        alpha: 0,
        flex: { direction: 'column' },
        Content: {
          flexItem: { marginTop: 1 },
          type: Details,
        },
        Provider: {
          flexItem: { marginTop: 59 },
          w: 396,
          h: 112,
          texture: Lightning.Tools.getRoundRect(396, 112, 20, 0, 0, true),
          Image: {
            x: w => w / 2,
            y: h => h / 2,
            mount: 0.5,
          },
          Name: {
            mount: 0.5,
            x: w => w / 2 + 8,
            y: h => h / 2 + 3,
            text: {
              ...FONTSTYLE.H3,
              textColor: Colors(COLORS.LIGHTGREY).get(),
              verticalAlign: 'middle',
              wordWrap: true,
              wordWrapWidth: 395,
            },
          },
        },
        Unlink: {
          flexItem: { marginTop: 237 },
          w: 228,
          h: 61,
          type: TextButton,
          radius: 15,
          fontSize: 22,
          fontFace: FONTS.POPPINS.REGULAR,
          unfocusBackgroundColor: Colors(COLORS.BLACK)
            .alpha(0.4)
            .get(),
          label: Language.translate('page_settings').unlink,
          autoWidth: false,
          padding: 0,
        },
      },
    }
  }

  get Instruction() {
    return this.tag('Instruction')
  }

  get Line1() {
    return this.tag('Line1')
  }

  get Code() {
    return this.tag('Code')
  }

  get Loader() {
    return this.tag('Loader')
  }

  get Unlink() {
    return this.tag('Unlink')
  }

  get Provider() {
    return this.tag('Provider')
  }

  get Activation() {
    return this.tag('Activation')
  }

  get LoggedIn() {
    return this.tag('LoggedIn')
  }

  get Content() {
    return this.tag('Content')
  }

  _init() {
    this._activationUrl = ''
    this._url = ''
    this.Provider.tag('Image').on('txLoaded', () => {
      const providerWidth = 390
      const renderWidth = this.Provider.tag('Image').renderWidth
      const providerHeight = 72
      const renderHeight = this.Provider.tag('Image').renderHeight

      if (renderWidth > providerWidth) {
        this.Provider.tag('Image').scale = providerWidth / renderWidth
      }

      if (renderHeight > providerHeight) {
        this.Provider.tag('Image').scale = providerHeight / renderHeight
      }
    })
  }

  _active() {
    if (!this._loggedIn) {
      this.startPollTimer()
    }
  }

  _inactive() {
    this.stopPollTimer()
  }

  activationStatus(data) {
    this._activationCode = data && data.activationCode ? data.activationCode : undefined
    this._url = data && data.activationUrl ? data.activationUrl : undefined
    this._activationUrl = this._url.replace(/^https?:\/\//, '')
    this._loggedIn = data && data.loggedIn ? data.loggedIn : false
    if (!this._loggedIn) {
      this._setState(STATES.ACTIVATION)
    } else {
      this._mvpd = data && data.mvpd ? data.mvpd : undefined
      this._setState(STATES.LOGGEDIN)
    }
  }

  createFlexText(text) {
    return text.map(t => {
      return {
        w: t.w || 0,
        flexItem: { marginRight: 10 },
        text: {
          text: t.text || t,
          fontFace: t.fontFace ? t.fontFace : FONTS.POPPINS.REGULAR,
          fontSize: t.fontSize ? t.fontSize : 28,
          textColor: t.textColor ? t.textColor : Colors(COLORS.WHITE).get(),
        },
      }
    })
  }

  updateLine() {
    this.Line1.children = this.createFlexText([
      {
        text: Language.translate('activation_line1'),
        fontSize: 28,
        textColor: Colors(COLORS.DARKGREY_40).get(),
        fontFace: FONTS.POPPINS.REGULAR,
        verticalAlign: 'middle',
        lineHeight: 42,
      },
      {
        advancedRenderer: true,
        text: this._activationUrl,
        textColor: Colors(COLORS.LIGHTGREY).get(),
        fontFace: FONTS.POPPINS.BOLD,
        fontSize: 28,
        verticalAlign: 'middle',
        lineHeight: 42,
      },
    ])
    const code = this._activationCode || ''
    voiceGuide.utterThis(`Activation code: ${code}`)
    this.Code.text = code
  }

  startPollTimer() {
    this.stopPollTimer()
    this._pollTimer = setTimeout(() => {
      getTVNetData()
        .then(response => {
          this._loggedIn = response && response.loggedIn ? response.loggedIn : false
          if (!this._loggedIn) {
            this._activationCode =
              response && response.activationCode ? response.activationCode : undefined
            this._url = response && response.activationUrl ? response.activationUrl : undefined
            this._activationUrl = this._url.replace(/^https?:\/\//, '')
            this.application.emit('loggedIn', response)
            this._setState(STATES.ACTIVATION)
            this.startPollTimer()
          } else {
            this._mvpd = response && response.mvpd ? response.mvpd : undefined
            this.application.emit('loggedIn', response)
            this.stopPollTimer()
            this._setState(STATES.LOGGEDIN)
          }
        })
        .catch(() => {
          // Catch needs to be here
        })
    }, 5000)
  }

  stopPollTimer() {
    if (this._pollTimer) {
      clearTimeout(this._pollTimer)
      this._pollTimer = undefined
    }
  }

  updateImage(image) {
    this.Provider.patch({
      texture: Lightning.Tools.getRoundRect(396, 112, 20, 5, 0, true),
      Image: {
        alpha: 1,
        src: image,
      },
      Name: {
        alpha: 0,
      },
    })
  }

  updateText(name) {
    this.Provider.patch({
      texture: Lightning.Tools.getRoundRect(396, 112, 20, 3, Colors(COLORS.LIGHTGREY).get(), false),
      Image: {
        alpha: 0,
      },
      Name: {
        text: name,
      },
    })
  }

  updateProvider() {
    let providerData = []
    return (
      getTVProviderData(this._mvpd)
        .then(items => {
          providerData = items
          if (providerData && providerData.logoURL) {
            this.updateImage(providerData.logoURL)
          } else if (providerData && providerData.displayName) {
            this.updateText(providerData.displayName)
          }
          this.LoggedIn.setSmooth('alpha', 1)
        })
        // eslint-disable-next-line no-unused-vars
        .catch(err => {
          this.updateText(PROVIDER)
          this.LoggedIn.setSmooth('alpha', 1)
        })
    )
  }

  showAreYouSure() {
    getWidget('Prompt').content = {
      message: Language.translate('page_settings').unlink_Confirmation,
      buttons: [
        {
          label: 'Yes',
          action: () => {
            return unLinkTVNetData()
              .then(() => {
                this._setState('Loader')
              })
              .catch(() => {
                // Catch needs to be here
              })
          },
        },
        {
          label: 'No',
        },
      ],
    }
  }

  static _states() {
    return [
      class Activation extends this {
        $enter() {
          this.LoggedIn.setSmooth('alpha', 0)
          this.Activation.setSmooth('alpha', 1)
          this.updateLine()
          this.startPollTimer()
        }

        $exit() {
          this.Activation.setSmooth('alpha', 0)
          this.stopPollTimer()
        }
      },
      class Loader extends this {
        $enter() {
          this.Loader.alpha = 1
          this.startPollTimer()
        }

        $exit() {
          this.Loader.alpha = 0
        }
      },
      class LoggedIn extends this {
        _focus() {
          /* istanbul ignore next */
          voiceGuide.utterThis(Language.translate('page_settings').unlink || '')
        }

        $enter() {
          this.Activation.setSmooth('alpha', 0)
          this.updateProvider()
          this.Content.updateText({
            title: Language.translate('page_settings').loggedin_title,
            text: Language.translate('page_settings').loggedin_text,
            url: '',
          })
          this.Content.hideUrl()
        }

        _getFocused() {
          return this.Unlink
        }

        _handleEnter() {
          this.showAreYouSure()
        }

        $exit() {
          this.LoggedIn.setSmooth('alpha', 0)
        }
      },
    ]
  }
}
