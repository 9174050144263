import { Log } from '@lightningjs/sdk'
import VideoList from '../VideoList'
import SeriesList from '../SeriesList'
import { isArrayWithLength } from '../../../utils/checks'
import HouseAndSenateList from '../HouseAndSenateList'
import LiveContainer from '../LiveContainer'

export class ContentRenderer {
  constructor(scope) {
    this._scope = scope
  }

  // Find both rendered and unrendered rows
  findRenderedAndUnrendered() {
    const rendered = this.getRenderedCallAssets()
    const unrendered = this._scope._data.callInfo.filter(({ weight, id, type }) => {
      return !rendered.some(e => {
        return e.weight === weight && e.id === id && e.type === type
      })
    })
    return { rendered, unrendered }
  }

  // Returns unrendered rows past the rendered rows
  findUnrenderedForward({ rendered, unrendered }) {
    const lastRenderedWeight = Math.max(...rendered.map(obj => obj.weight))
    return unrendered.filter(({ weight }) => weight > lastRenderedWeight)
  }

  // Returns unrendered rows before the rendered rows
  findUnrenderedBackward({ rendered, unrendered }) {
    const firstRenderedWeight = Math.min(...rendered.map(obj => obj.weight))
    return unrendered.filter(({ weight }) => weight < firstRenderedWeight)
  }

  // Extract callAssets from rendered lists
  getRenderedCallAssets() {
    return this._scope.tag('List').childList._items.map(list => {
      return list.callAsset
    })
  }

  getRenderedComponents() {
    return this._scope.tag('List').childList._items.map(list => {
      return list
    })
  }

  // Check if callAsset is already rendered or not
  isCallAssetRendered(callAsset) {
    const rendered = this.getRenderedCallAssets()
    return rendered.some(item => {
      return (
        item.weight === callAsset.weight && item.id === callAsset.id && item.type === callAsset.type
      )
    })
  }

  //Render list component on screen, placement top or bottom
  renderComponent(homelist, position = 'bottom', callAsset = {}) {
    let newList = undefined

    if (homelist && homelist.displayType === 'live video') {
      this._scope.updateListYPosition(true)
      newList = this.makeLiveList(homelist, callAsset)
      this._scope.tag('List').addAt(newList, this._scope.tag('List').items.length)
    } else {
      if (homelist && isArrayWithLength(homelist.row)) {
        switch (homelist.displayType) {
          case 'video':
            newList = this.makeVideoList(homelist, callAsset)
            break
          case 'button':
            newList = this.makeSeriesList(homelist, callAsset)
            break
          case '2button':
            newList = this.makeHouseAndSenateList(homelist, callAsset)
            break
          default:
            Log.warn(
              'HomeContent.js - renderComponent(), unknown displayType ',
              homelist.displayType
            )
        }

        if (newList) {
          if (position === 'bottom') {
            this._scope.tag('List').addAt(newList, this._scope.tag('List').items.length)
          } else {
            this._scope.tag('List').addAt(newList, 0)
          }
        } else {
          Log.warn('HomeContent.js - renderComponent(), nothing to render')
        }
      }
    }
  }

  makeLiveList(homelist, callAsset) {
    return {
      type: LiveContainer,
      callAsset,
      data: homelist.row,
      signals: { onLiveUpdate: true },
    }
  }

  makeVideoList(homelist, callAsset) {
    return {
      type: VideoList,
      callAsset,
      title: homelist.title,
      data: homelist.row,
    }
  }

  makeSeriesList(homelist, callAsset) {
    return {
      type: SeriesList,
      callAsset,
      useLocalImages: false,
      title: homelist.title,
      data: homelist.row,
    }
  }

  makeHouseAndSenateList(homelist, callAsset) {
    return {
      type: HouseAndSenateList,
      callAsset,
      title: homelist.title,
      data: homelist.row,
    }
  }
}
