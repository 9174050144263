/* eslint-disable no-console */
import { Lifecycle, Device, Advertising, Localization } from '@firebolt-js/sdk'
import { Log } from '@lightningjs/sdk'
import {
  DEVICE,
  ADVERTISING,
  LOCALIZATION,
  DEVICE_PROPS,
  ADVERTISING_PROPS,
  LOCALIZATION_PROPS,
} from '../constants/firebolt'
import './voiceGuide' //Runs voice guide

export const lifeCycleReady = async () => {
  try {
    Lifecycle.ready().then(lcReady => {
      // console.log('Fire Bolt - Lifecycle.ready ', lcReady)
      Log.info('Fire Bolt - Lifecycle.ready ', lcReady)
    })
  } catch (error) {
    // console.log('Fire Bolt Lifecycle ready error : ', error)
    console.error('Fire Bolt Lifecycle ready error : ', error)
  }
}

export const lifeCycleClose = () => {
  try {
    Lifecycle.close('userExit').then(lcClose => {
      Log.info('Fire Bolt - Lifecycle.close ', lcClose)
    })
  } catch (error) {
    console.error('Fire Bolt Lifecycle close error :', error)
  }
}

/* The below method returns the list of firebolt properties
   Parameters:
   methodName - which firebolt method to be called E.g. 'Device'
   properties - Array of properties to be fetched E.g. ['make','model']
   Returns - requested properties as an object E.g {make:"Arris",model:'xi6'}
*/
export const getFireBoltDetails = async (methodName, properties) => {
  const promises = []
  let promiseResult = []
  for (let property of properties) {
    promises.push(await getFireBoltProperty(methodName, property))
  }
  await Promise.all(promises).then(values => {
    promiseResult = values
  })
  return properties.reduce((dataObj, property, index) => {
    dataObj[property] = promiseResult[index]
    return dataObj
  }, {})
}

export const getFireBoltProperty = async (methodName, property) => {
  try {
    if (methodName === DEVICE) {
      return await Device[property]().then(response => response)
    }
    if (methodName === ADVERTISING) {
      return await Advertising[property]().then(response => response)
    }
    if (methodName === LOCALIZATION) {
      return await Localization[property]().then(response => response)
    }
  } catch (error) {
    console.error(`FireBolt Error ${methodName} ${property} : ${error}`)
    return ''
  }
}

// Singleton class to get firebolt information
export class FireBolt {
  constructor() {
    if (!FireBolt._instance) {
      FireBolt._instance = this
      this.initializeFB()
    }
    return FireBolt._instance
  }

  initializeFB() {
    this.getLocalizationDetails()
    this.getDeviceDetails()
    this.getAdvertisingDetails()
  }

  static instance() {
    return this._instance
  }

  get deviceDetails() {
    return this._deviceInfo
  }

  get advertisingDetails() {
    return this._advertisingInfo
  }

  get localizationDetails() {
    return this._localizationInfo
  }

  async getDeviceDetails() {
    this._deviceInfo = await getFireBoltDetails(DEVICE, [
      DEVICE_PROPS.UID,
      DEVICE_PROPS.NAME,
      DEVICE_PROPS.MAKE,
      DEVICE_PROPS.MODEL,
      DEVICE_PROPS.PLATFORM,
      DEVICE_PROPS.RESOLUTION,
    ])
    return this._deviceInfo
  }

  async getAdvertisingDetails() {
    // eslint-disable-next-line prettier/prettier
		this._advertisingInfo = await getFireBoltDetails(ADVERTISING, [
      ADVERTISING_PROPS.ADVERTISING_ID,
    ])
    return this._advertisingInfo
  }

  async getLocalizationDetails() {
    this._localizationInfo = await getFireBoltDetails(LOCALIZATION, [
      LOCALIZATION_PROPS.COUNTRY_CODE,
      LOCALIZATION_PROPS.LANGUAGE,
      LOCALIZATION_PROPS.LATLON,
      LOCALIZATION_PROPS.LOCALE,
      LOCALIZATION_PROPS.LOCALITY,
      LOCALIZATION_PROPS.POSTAL_CODE,
    ])
    return this._localizationInfo
  }
}

export const fbInstance = new FireBolt()
