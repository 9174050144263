import { Colors, Lightning } from '@lightningjs/sdk'
import { COLORS } from '../constants/colors'
import { getColorCodeFromString } from '../utils/cspan'

export default class CCTextTexture extends Lightning.Component {
  static _template() {
    return {
      BG: {
        x: -10,
      },
      CCText: {
        text: {
          wordWrapWidth: 1700,
          fontSize: 40,
          fontFace: 'PoppinsRegular',
          textAlign: 'center',
          textColor: Colors(COLORS.WHITE).get(),
          lineHeight: 50,
        },
      },
    }
  }
  get CCText() {
    return this.tag('CCText')
  }
  get BG() {
    return this.tag('BG')
  }

  set fontSize(fontSize) {
    this.CCText.text.fontSize = fontSize
    this.CCText.text.lineHeight = fontSize + 10
  }

  set fontFace(fontFace) {
    this.CCText.text.fontFace = fontFace
  }

  set text(text) {
    this.CCText.text.text = text
  }

  set backgroundColor(bg) {
    this._bgColor = getColorCodeFromString(bg)
  }

  set textColor(textColor) {
    this.CCText.text.textColor = Colors(getColorCodeFromString(textColor)).get()
  }

  _init() {
    this._padding = 20
    this.CCText.on('txLoaded', () => {
      this.h = this.CCText.finalH
      let w = this.CCText.finalW + this._padding
      this.x = (this.w - w) / 2
      this.patch({
        BG: {
          texture: Lightning.Tools.getRoundRect(
            w,
            this.CCText.renderHeight - 20,
            0,
            0,
            0,
            true,
            Colors(this._bgColor).get()
          ),
        },
      })
    })
  }
}
