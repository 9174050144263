import { Lightning, Colors } from '@lightningjs/sdk'
import { COLORS } from '@/constants/colors'
import { FONTS, FONTSTYLE } from '@/constants/fonts'

export default class Details extends Lightning.Component {
  static _template() {
    return {
      flex: { direction: 'column' },
      Title: {
        flexItem: { marginBottom: 40 },
        text: {
          advancedRenderer: true,
          text: 'Terms and Coniditons',
          ...FONTSTYLE.H2,
          textColor: Colors(COLORS.LIGHTGREY).get(),
          verticalAlign: 'middle',
          wordWrapWidth: 690,
        },
      },
      Instruction: {
        flexItem: { marginBottom: 0 },
        text: {
          advancedRenderer: true,
          text: 'To read our Terms and Conditions,visit:',
          fontSize: 28,
          fontFace: FONTS.POPPINS.REGULAR,
          textColor: Colors(COLORS.DARKGREY_40).get(),
          verticalAlign: 'middle',
          lineHeight: 42,
          wordWrapWidth: 690,
        },
      },
      Url: {
        flexItem: { marginTop: 0 },
        text: {
          text: 'www.c-span.org/about/TermsandConditions/',
          fontSize: 28,
          fontFace: FONTS.POPPINS.SEMIBOLD,
          textColor: Colors(COLORS.DARKGREY_40).get(),
          verticalAlign: 'middle',
          lineHeight: 50,
          wordWrapWidth: 690,
        },
      },
    }
  }

  get Title() {
    return this.tag('Title')
  }

  get Instruction() {
    return this.tag('Instruction')
  }

  get Url() {
    return this.tag('Url')
  }

  updateText(data) {
    //update text here
    this.Title.text = data.title
    this.Instruction.text = data.text
    if (data && data.url) {
      this.Url.text = data.url.replace(/(^\w+:|^)\/\//, '')
    }
  }

  hideUrl() {
    this.Url.setSmooth('alpha', 0)
  }
}
