export const ROUTE = {
  SPLASH: 'splash',
  HOME: 'home',
  CATEGORIES: 'categories',
  VIDEO: 'video',
  SEARCH: 'search',
  SCHEDULE: 'schedule',
  SERIES: 'series',
  RANKSET: 'rankset',
  SEASONS: 'seasons',
  SETTINGS: 'settings',
  AUTHENTICATION: 'authentication',
  TRENDINGTOPICS: 'trendingtopics',
  TVNET: 'tvnets',
  TVNETLOGIN: 'tvnetlogin',
}
