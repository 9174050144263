import { Lightning } from '@lightningjs/sdk'
import CSPANImage from '../CSPANImage'
import { Colors } from '@lightningjs/sdk'
import { COLORS } from '../../constants/colors'
import { voiceGuide } from '../../services/voiceGuide'

const TITLE_FONT_SIZE = 24
const TITLE_LINE_HEIGHT = 32
export default class BookItem extends Lightning.Component {
  static _template() {
    return {
      Wrapper: {
        y: -3,
        Image: {
          w: 252,
          h: 378,
          type: CSPANImage,
          preset: 'book',
          strokeFocused: 5,
          strokeUnfocused: 0,
          radius: 10,
        },
        Details: {
          x: 292,
          h: 378,
          Title: {
            color: Colors(COLORS.LIGHTGREY).get(),
            text: {
              advancedRenderer: true,
              text: '',
              fontSize: TITLE_FONT_SIZE,
              fontFace: 'PoppinsSemiBold',
              lineHeight: TITLE_LINE_HEIGHT,
              wordWrapWidth: 252,
              maxLines: 3,
            },
          },
          Description: {
            x: 1,
            color: Colors(COLORS.DARKGREY_40).get(),
            text: {
              advancedRenderer: true,
              text: '',
              fontSize: 22,
              fontFace: 'PoppinsRegular',
              lineHeight: 32,
              wordWrapWidth: 247,
              maxLines: 8,
            },
          },
        },
      },
    }
  }

  static get width() {
    return 464
  }

  static get height() {
    return 378
  }

  get Image() {
    return this.tag('Image')
  }

  get Title() {
    return this.tag('Title')
  }

  get Description() {
    return this.tag('Description')
  }

  set item(item) {
    this._item = item
    this.patch({
      Wrapper: {
        Image: { image: item.bookImagePath || item.imagePath },
        Details: {
          Title: {
            text: {
              text: item.title,
            },
          },
          Description: {
            text: {
              text: item.description,
            },
          },
        },
      },
    })
  }

  get item() {
    return this._item ? this._item : undefined
  }

  _init() {
    this.Title.on('txLoaded', titleInfo => {
      const renderTitleH =
        titleInfo.source.renderInfo.lines.length * TITLE_LINE_HEIGHT + TITLE_FONT_SIZE / 2 - 6
      this.Description.y = this.Title.y + renderTitleH
      //This will be reverted once we have lightning core #2.9.0 support on the store
      // this.tag('Description').y = this.tag('Title').y + this.tag('Title').renderHeight - 6
    })
  }

  _focus() {
    const title = (this.item && this.item.title) || ''
    voiceGuide.utterThis(title)
    this.Image.patch({ smooth: { y: -10 } })
    this.Image.highlight = true
  }

  _unfocus() {
    this.Image.patch({ smooth: { y: 0 } })
    this.Image.highlight = false
  }
}
