import { Lightning, Colors, Utils } from '@lightningjs/sdk'
import { COLORS } from '@/constants/colors'
import { voiceGuide } from '../../../services/voiceGuide'

export default class ImageKey extends Lightning.Component {
  static _template() {
    return {
      w: w => w,
      h: h => h,
      BG: {},
      Image: {
        x: w => w / 2,
        y: h => h / 2,
        mount: 0.5,
        src: '',
      },
    }
  }

  set radius(v) {
    this._radius = v
  }

  set image(v) {
    if (this._image !== v) {
      this._image = v
      this._setImage()
    }
  }

  set focusBackGroundColor(focusBackGroundColor) {
    this._focusBackGroundColor = focusBackGroundColor
  }

  set unfocusBackgroundColor(unfocusBackgroundColor) {
    this._unfocusBackgroundColor = unfocusBackgroundColor
  }

  set autoWidth(v) {
    this._autoWidth = v
  }

  set padding(v) {
    this._paddingLeft = v
    this._paddingRight = v
  }

  set paddingLeft(v) {
    this._paddingLeft = v
  }

  set paddingRight(v) {
    this._paddingRight = v
  }

  set letterSpacing(v) {
    this._letterSpacing = v
  }

  _construct() {
    this._radius = 0
    this._focusBackGroundColor = Colors(COLORS.WHITE).get()
    this._unfocusBackgroundColor = Colors(COLORS.BLACK).get()
    this._image = ''
    this._autoWidth = false
    this._paddingLeft = 30
    this._paddingRight = 30
    this._letterSpacing = 0
  }

  _init() {
    if (this._autoWidth) {
      this.tag('Image').on('txLoaded', () => {
        if (this._autoWidth) {
          this._imageRenderWidth = this.tag('Image').renderWidth
          this._calculateButtonWidth()
        }
      })
    } else {
      this._updateButton()
    }
  }

  _firstActive() {
    this._setImage()
  }

  _focus() {
    voiceGuide.utterThis('Backspace')
    this._isFocus = true
    this._updateButton()
    this.image = Utils.asset('images/search/backspace-focus.png')
  }

  _unfocus() {
    this._isFocus = false
    this._updateButton()
    this.image = Utils.asset('images/search/backspace-unfocus.png')
  }

  _setImage() {
    this.patch({
      Image: {
        src: this._image,
      },
    })
  }

  _calculateButtonWidth() {
    this.w = this._imageRenderWidth + this._paddingLeft + this._paddingRight
    this._updateButton()
  }

  _updateButton() {
    // eslint-disable-next-line prettier/prettier
		let bgColor = this._isFocus
			? this._focusBackGroundColor
			: this._unfocusBackgroundColor
    this.patch({
      /* Shadow: {
														alpha: 0,
													  },*/
      BG: {
        // eslint-disable-next-line prettier/prettier
				texture: Lightning.Tools.getRoundRect(
					this.w,
					this.h,
					this._radius,
					0,
					0,
					true,
					bgColor
				)
      },
      Image: {},
    })
  }
}
