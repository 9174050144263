import { Colors, Lightning, Router, Utils } from '@lightningjs/sdk'
import PlayerButton from './PlayerButton'
import ProgressBar from './ProgressBar'
import { COLORS } from '../../constants/colors'
import TextButton from '../TextButton'
import { isoStringToDate } from '../../utils/time'
import { sendMetrics } from '../../services/metrics'
import { BUTTON, GA4, PAGENAME } from '../../constants/analytics'
import { appInstance } from '../../utils/tools'
import { ROUTE } from '../../constants/routes'
import { voiceGuide } from '../../services/voiceGuide'

export default class PlayerControls extends Lightning.Component {
  static _template() {
    return {
      GradientOverlay: {
        alpha: 1,
        x: 0,
        y: 0,
        h: 1080,
        w: 1920,
        color: Colors(COLORS.PLAYER_BG_GRADIENT)
          .alpha(0.9)
          .get(),
        rect: true,
      },
      ContentGradient: {
        alpha: 0,
        Top: {
          zIndex: 2,
          w: 1920,
          h: 120,
          y: 0,
          mountY: 0,
          rect: true,
          colorTop: Colors(COLORS.PLAYER_BG_GRADIENT)
            .alpha(0.9)
            .get(),
          colorBottom: Colors(COLORS.PLAYER_BG_GRADIENT)
            .alpha(0.001)
            .get(),
        },
        Bottom: {
          zIndex: 2,
          w: 1920,
          h: 120,
          y: 1080,
          mountY: 1,
          rect: true,
          colorBottom: Colors(COLORS.PLAYER_BG_GRADIENT)
            .alpha(0.9)
            .get(),
          colorTop: Colors(COLORS.PLAYER_BG_GRADIENT)
            .alpha(0.001)
            .get(),
        },
      },
      ContentOverlay: {
        x: 104,
        y: 100,
        flex: { direction: 'column' },
        LiveLabel: {
          flexItem: {},
          text: {
            advancedRenderer: true,
            textColor: Colors(COLORS.DARKGREY_60).get(),
            fontFace: 'PoppinsRegular',
            fontSize: 28,
            fontWeight: 400,
            lineHeight: 42,
            textAlign: 'center',
            alpha: 0,
          },
        },
        Title: {
          flexItem: { marginTop: 8 },
          text: {
            advancedRenderer: true,
            fontSize: 40,
            fontWeight: 700,
            lineHeight: 60,
            fontFace: 'PoppinsBold',
            textColor: Colors(COLORS.LIGHTGREY).get(),
            wordWrap: true,
            wordWrapWidth: 1200,
            maxLines: 3,
            paddingRight: 400,
          },
        },
        VideoDetails: {
          flexItem: { marginTop: 30 },
          w: 800,
          h: 604,
          DescriptionWrapper: {
            w: 800,
            h: 590,
            Description: {
              alpha: 1,
              text: {
                //advancedRenderer: true,
                fontSize: 28,
                lineHeight: 42,
                fontWeight: 400,
                fontFace: 'PoppinsRegular',
                textColor: Colors(COLORS.DARKGREY_40).get(),
                wordWrap: true,
                wordWrapWidth: 800,
                paddingRight: 10,
                //maxLines: 10,
                //textOverflow: '...',
              },
            },
          },
          KnowMore: {
            alpha: 0,
            y: 330,
            w: 180,
            h: 61,
            type: TextButton,
            radius: 15,
            fontSize: 22,
            fontColor: Colors(COLORS.DARKGREY_60).get(),
            bgColor: Colors(COLORS.MIDNIGHT).get(),
            focusBackGroundColor: Colors(COLORS.LIGHTGREY).get(),
            unfocusBackgroundColor: Colors(COLORS.MIDNIGHT).get(),
            focusFontColor: Colors(COLORS.MIDNIGHT).get(),
            unfocusFontColor: Colors(COLORS.DARKGREY_60).get(),
            shadow: false,
            label: 'Read More',
          },
          Notice: {
            alpha: 1,
            text: {
              advancedRenderer: true,
              fontSize: 20,
              lineHeight: 30,
              fontWeight: 400,
              fontFace: 'PoppinsRegular',
              textColor: Colors(COLORS.DARKGREY_60).get(),
              wordWrap: true,
              wordWrapWidth: 600,
              maxLines: 4,
              textOverflow: '...',
            },
          },
        },
      },

      VideoControl: {
        x: 104,
        y: 900,
        Play: {
          type: PlayerButton,
        },
        Progress: { x: 146, y: 45, type: ProgressBar, signals: { changeFocus: true } },
        CCDetails: {
          alpha: 1,
          x: 1618,
          y: 10,
          flex: {
            direction: 'column',
            alignItems: 'center',
          },
          ClosedCaption: {
            flexItem: {},
            w: 94,
            h: 61,
            type: TextButton,
            radius: 15,
            fontSize: 22,
            fontColor: Colors(COLORS.DARKGREY_60).get(),
            bgColor: Colors(COLORS.MIDNIGHT).get(),
            focusBackGroundColor: Colors(COLORS.LIGHTGREY).get(),
            unfocusBackgroundColor: Colors(COLORS.MIDNIGHT).get(),
            focusFontColor: Colors(COLORS.MIDNIGHT).get(),
            unfocusFontColor: Colors(COLORS.DARKGREY_60).get(),
            shadow: false,
            label: 'CC',
          },
          CaptionIndicator: {
            alpha: 0,
            flexItem: {},
            mountY: 0.5,
            color: Colors(COLORS.COBALT).get(),
            texture: Lightning.Tools.getRoundRect(34, 8, 5),
          },
        },
      },
    }
  }

  set playOrPause(v) {
    this._playButtonIsStop = v
    let imageX = v ? '40' : '43'
    this.Play.width = v ? '26' : '29'
    this.Play.height = v ? this._playHeight : '34'
    this.Play.imageX = imageX
    let icon = v ? this._pauseIcon : 'play.png'
    this.Play.image = Utils.asset('images/player/' + icon)
  }

  get DescHeight() {
    return this._descHeight
  }

  get Progress() {
    return this.tag('Progress')
  }

  get Play() {
    return this.tag('Play')
  }

  get ContentGradient() {
    return this.tag('ContentGradient')
  }

  get Title() {
    return this.tag('Title')
  }

  get Notice() {
    return this.tag('Notice')
  }

  get VideoDetails() {
    return this.tag('VideoDetails')
  }

  get ClosedCaption() {
    return this.tag('ClosedCaption')
  }

  get CCDetails() {
    return this.tag('CCDetails')
  }

  get ContentOverlay() {
    return this.tag('ContentOverlay')
  }

  get CaptionIndicator() {
    return this.tag('CaptionIndicator')
  }

  get GradientOverlay() {
    return this.tag('GradientOverlay')
  }

  get Description() {
    return this.tag('Description')
  }

  get DescriptionLength() {
    return this.tag('Description').text.text.length
  }

  get KnowMore() {
    return this.tag('KnowMore')
  }

  get playerCurrentTime() {
    return this.tag('Progress').currentTime
  }

  get VideoControl() {
    return this.tag('VideoControl')
  }

  get videoTitle() {
    return this.tag('Title').text.text
  }

  get videoTitleLength() {
    return this.tag('Title').text.text.length
  }

  get videoDescription() {
    return this.tag('Description').text.text
  }

  _init() {
    this._playButtonIsStop = true
    this._videoDuration = 0
    this._liveState = false
    this._CCkey = false
    this._knowMore = true
    this._pauseIcon = 'pause.png'
    this._playHeight = '34'
    this.updateCCButton(false)
    this.playOrPause = false
    this._setState('Play')
    // this.Description.on('txLoaded', () => {
    //   this._descHeight = this.Description.renderHeight
    //   this.tag('Notice').y = this._knowMore ? '430' : this.Description.renderHeight
    // })
  }

  _handleBack() {
    appInstance.endHlsPlayer()
    const historyLength = Router.getHistory() ? Router.getHistory().length : 0
    if (historyLength > 0) {
      Router.back()
    } else {
      if (Router.getActiveRoute() === ROUTE.HOME) {
        Router.back()
      } else {
        Router.navigate(ROUTE.HOME)
      }
    }
  }

  title(title) {
    this.tag('Title').text.text = title
    this.descPosition()
  }

  notice(text) {
    this.tag('Notice').text.text = text
  }

  descPosition() {
    this.ContentGradient.setSmooth('alpha', 0)
    this.tag('ContentOverlay').y = 100
  }

  LiveLabel(text, isLive) {
    let videoDate = isoStringToDate(text)
    this.tag('LiveLabel').text.text = isLive
      ? text
      : `${videoDate.monthName} ${videoDate.day}, ${videoDate.year}  `
  }

  description(desc) {
    this.tag('Description').text.text = desc
    //this.updateReadMoreButton()
  }

  updateReadMoreButton() {
    this.KnowMore.alpha = this.DescriptionLength > 355 ? '1' : '0'
    this._knowMore = this.DescriptionLength > 355
    this._noticePos = this.DescHeight
  }

  toggleCCButton(CCText) {
    this.CaptionIndicator.setSmooth('alpha', CCText === 'hidden' ? 0 : 1)
  }

  cleanHtml(desc) {
    let tempDivElement = document.createElement('div')
    tempDivElement.innerHTML = desc
    return tempDivElement.textContent
  }

  updateProgress(currentTime, videoDuration) {
    this._videoDuration = videoDuration
    this.Progress.setProgress(currentTime, videoDuration)
  }

  changeFocus(state) {
    this._setState(state)
  }

  sendControlAction(action) {
    this.application.emit('playerControlAction', action)
  }

  switchControl(isLive) {
    this._pauseIcon = isLive ? 'livePause.png' : 'pause.png'
    this.VideoControl.x = isLive ? '863' : '104'
    this.VideoControl.y = isLive ? '850' : '870'
    this.CCDetails.x = isLive ? '100' : '1618'
    this.playOrPause = false
    this._liveState = isLive
    this.Progress.alpha = isLive ? 0 : 1
    this.Play.height = isLive ? '26' : '34'
    this._playHeight = isLive ? '26' : '34'
  }

  updateCCButton(ccKey) {
    this.CCDetails.alpha = ccKey ? 1 : 0
    this._CCkey = ccKey
  }

  progressHandler() {
    this.Progress._setProgressState('Active')
  }

  showDesc(v) {
    this.KnowMore.setSmooth('alpha', v ? 0 : 1)
    this.Notice.setSmooth('alpha', v ? 0 : 1)
    this.VideoControl.setSmooth('alpha', v ? 0 : 1)
    this.Description.text.maxLines = v ? 20 : 7
  }

  static _states() {
    return [
      class Play extends this {
        _getFocused() {
          return this.Play
        }

        $enter() {
          voiceGuide.utterThis(this._playButtonIsStop ? 'Pause' : 'Play')
          this.Play.color = Colors(COLORS.LIGHTGREY).get()
        }

        $exit() {
          this.Play.color = Colors(COLORS.MIDNIGHT).get()
        }

        _handleEnter() {
          this.fireAncestors(this._liveState ? '$pressLivePlay' : '$pressPlay')
        }

        _handleUp() {
          if (this._knowMore) {
            this._setState('KnowMore')
          } else {
            this.fireAncestors('$hideOverlay')
          }
        }

        _handleRight() {
          if (this._liveState) {
            if (this._CCkey) this._setState('ClosedCaption')
          } else {
            this._setState('Progress')
          }
        }

        _handleLeft() {
          this.fireAncestors('$hideOverlay')
        }

        _handleDown() {
          this.fireAncestors('$hideOverlay')
        }
      },
      class Progress extends this {
        $enter() {
          voiceGuide.utterThis('Progress-bar')
          this.Progress._setProgressState('Inactive')
        }

        $exit() {
          this.Progress._setProgressState('Idle')
        }

        _getFocused() {
          return this.Progress
        }

        _handleLeft() {
          this._setState('Play')
        }

        _handleRight() {
          if (this._CCkey) {
            this._setState('ClosedCaption')
          } else {
            this.fireAncestors('$hideOverlay')
          }
        }

        _handleUp() {
          if (this._knowMore) {
            this._setState('KnowMore')
          } else {
            this.fireAncestors('$hideOverlay')
          }
        }

        _handleEnter() {
          this.Progress._setProgress()
        }
      },

      class ClosedCaption extends this {
        $enter() {
          voiceGuide.utterThis('Closed Captions')
          this.ClosedCaption.bgColor = Colors(COLORS.LIGHTGREY).get()
        }

        $exit() {
          this.ClosedCaption.bgColor = Colors(COLORS.MIDNIGHT).get()
        }

        _getFocused() {
          return this.ClosedCaption
        }

        _handleEnter() {
          sendMetrics(GA4.CLICK, { button: BUTTON.CC, page_title: PAGENAME.VIDEO })
          this.fireAncestors('$closedCaption')
        }

        _handleUp() {
          if (this._knowMore) {
            this._setState('KnowMore')
          } else {
            this.fireAncestors('$hideOverlay')
          }
        }

        _handleRight() {
          this.fireAncestors('$hideOverlay')
        }

        _handleLeft() {
          this._setState(this._liveState ? 'Play' : 'Progress')
        }
      },
      class KnowMore extends this {
        $enter() {
          voiceGuide.utterThis('Read more')
          this.KnowMore.bgColor = Colors(COLORS.LIGHTGREY).get()
        }

        $exit() {
          this.KnowMore.bgColor = Colors(COLORS.MIDNIGHT).get()
        }

        _getFocused() {
          return this.KnowMore
        }

        _handleEnter() {
          sendMetrics(GA4.CLICK, { button: BUTTON.READ, page_title: PAGENAME.VIDEO })
          this.fireAncestors('$showDesc')
        }

        _handleDown() {
          this._setState('Play')
        }

        _handleUp() {
          this.fireAncestors('$hideOverlay')
        }
      },
    ]
  }
}
