import {isStringWithLength} from '../../utils/checks';
import {isoStringToTime, getNumberOfDaysFromNow, isoStringToDate} from '../../utils/time';
import {Language} from '@lightningjs/sdk';
import {adjustToItalic} from '../../utils/cspan';

export default class ScheduleAsset {
	constructor(schedule) {
		this._liveBeginTime = isStringWithLength(schedule.liveBeginTime)
			? schedule.liveBeginTime
			: undefined;
		this._imagePath = isStringWithLength(schedule.imagePath) ? schedule.imagePath : undefined;
		this._title = isStringWithLength(schedule.title) ? adjustToItalic(schedule.title) : '';
	}

	get imagePath() {
		return this._imagePath;
	}

	get liveBeginTime() {
		return this._liveBeginTime;
	}

	get title() {
		return this._title;
	}

	scheduleDate(scheduleTime) {
		const timerObj = isoStringToTime(scheduleTime, true);
		const hours =
			timerObj.hours > 12
				? timerObj.hours - 12 > 9
					? timerObj.hours - 12
					: `0${timerObj.hours - 12}`
				: timerObj.hours;

		const isAmorPM = timerObj.hours >= 12 ? 'PM' : 'AM';
		const time = `${hours > 9 ? hours : hours}:${timerObj.minutes} ${isAmorPM}`;
		const days = getNumberOfDaysFromNow(scheduleTime);
		if (days > 0) {
			const dateObj = isoStringToDate(scheduleTime, true);
			return `${dateObj.monthName} ${dateObj.day}, ${dateObj.year} | ${time}`;
		} else return Language.translate('today', time);
	}
}
