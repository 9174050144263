import { Lightning, Router } from '@lightningjs/sdk'
import { appInstance, getWidget } from '../utils/tools'
import PlayerControls from '../components/player/PlayerControls'
import { BUTTON, GA4, PAGENAME } from '../constants/analytics'
import { sendMetrics } from '../services/metrics'
import { isStringWithLength } from '@/utils/checks'
import { ROUTE } from '../constants/routes'

export default class Video extends Lightning.Component {
  static _template() {
    return {
      Controls: {
        alpha: 0,
        type: PlayerControls,
        signals: { pressPlay: true, pressLivePlay: true },
      },
    }
  }

  set data(videoAsset) {
    this._data = videoAsset
    this._videoDate = videoAsset.date
    this._videoTitle = videoAsset.title
    this._videoDuration = videoAsset.videoDuration
    this._videoId = videoAsset.id
    this._liveNetwork = videoAsset.liveNetwork
    this._live =
      videoAsset.isliveStream !== undefined
        ? videoAsset.isliveStream
        : this._liveNetwork > 0
        ? true
        : false
    this.isLive(this._live)
    this.titleText(videoAsset.title)
    this.descText(videoAsset.description !== undefined ? videoAsset.description : '')
    this.noticeText(videoAsset.notice !== undefined ? videoAsset.notice : '')
    this.updateSeekTime(videoAsset.videoDuration !== undefined ? videoAsset.videoDuration : '')
  }

  get Controls() {
    return this.tag('Controls')
  }

  _init() {
    this.watchPercentages = [25, 50, 75]
    this.prevWatchPercentage = 0
    this._seeking = false
    this._index = 0
    this._isPaused = false
    this._playStream = ''
    this.videoParams = {}
    this._initializeEvents()
    this._isLive = false
    this.scrollpx = 0
  }

  _active() {
    // eslint-disable-next-line prettier/prettier
		let isVideo = this._data && this._data.videoFile && isStringWithLength(this._data.videoFile)
    let isVideoHd =
      this._data && this._data.videoFileHD && isStringWithLength(this._data.videoFileHD)
    const videoFile =
      isVideoHd !== undefined
        ? this._data.videoFileHD
        : isVideo !== undefined
        ? this._data.videoFile
        : undefined
    this.url = videoFile
    this.playerStart(videoFile)
  }

  _inactive() {
    appInstance.endHlsPlayer()
  }

  _handlePlay() {
    this._setState('Controls')
    this.Controls.changeFocus('Play')
    appInstance.play()
    sendMetrics(GA4.CLICK, { button: BUTTON.PLAY, page_title: PAGENAME.VIDEO })
  }

  _handlePause() {
    this._setState('Controls')
    this.Controls.changeFocus('Play')
    appInstance.pause()
    sendMetrics(GA4.CLICK, { button: BUTTON.PLAY, page_title: PAGENAME.VIDEO })
  }

  _handlePlayPause() {
    this._setState('Controls')
    this.Controls.changeFocus('Play')
    appInstance.pressPlay()
  }

  _handleReplay() {
    if (this._live) return true
    this._setState('Controls')
    this.Controls.changeFocus('Replay')
    this.replay()
  }

  _handleRewind(e) {
    if (this._live) return true
    this._setState('Controls')
    this.Controls.changeFocus('Progress')
    this.Controls.progressHandler()
    this.eventStopper(e)
    sendMetrics(GA4.CLICK, {
      button: BUTTON.REWIND,
      page_title: PAGENAME.VIDEO,
    })
  }

  _handleForward(e) {
    if (this._live) return true
    this._setState('Controls')
    this.Controls.changeFocus('Progress')
    this.Controls.progressHandler()
    this.eventStopper(e)
    sendMetrics(GA4.CLICK, {
      button: BUTTON.FORWARD,
      page_title: PAGENAME.VIDEO,
    })
  }

  _updateTimeOffset(offset) {
    if (this._live) return true
    this._seekTarget = this.Controls.playerCurrentTime
    this._seeking = true
    if (this._seekTimeout) clearTimeout(this._seekTimeout)
    appInstance.pause()
    const duration = Math.floor(appInstance.getVideoDuration())
    const skipValue = this._SeekValue ? 30 : 10
    const step = skipValue * offset
    if (offset === 1) {
      this._seekTarget = Math.min(duration - 2, this._seekTarget + step)
    } else {
      this._seekTarget = Math.max(0, this._seekTarget + step)
    }
    if (this._seekTarget <= 0) {
      this._seekTarget = 1
    } else if (this._seekTarget > duration - 1) {
      this._seekTarget = duration - 1
    }
    this.Controls.updateProgress(this._seekTarget, duration)

    this._seekTimeout = setTimeout(() => {
      appInstance.seek(this._seekTarget)
      clearTimeout(this._seekTimeout)
    }, 500)
  }

  _captureBack() {
    sendMetrics(GA4.CLICK, { button: BUTTON.BACK, page_title: PAGENAME.VIDEO })
    appInstance.endHlsPlayer()
    if (this._timeout) clearTimeout(this._timeout)
    const historyLength = Router.getHistory() ? Router.getHistory().length : 0
    if (historyLength > 0) {
      Router.back()
    } else {
      if (Router.getActiveRoute() === ROUTE.HOME) {
        Router.back()
      } else {
        Router.navigate(ROUTE.HOME)
      }
    }
  }

  _initializeEvents() {
    this._clearEventListeners()
    this.application.off('global_playerEvent_video')
    this.application.on('global_playerEvent_video', event => {
      this._playerEvent(event)
    })
    //liveNetwork 1, 2, 3 are caption enabled and other items are having empty captions
    this.application.on('caption_Key', key => {
      let liveCC = this._liveNetwork > 0 ? (this._liveNetwork < 4 ? true : false) : key
      this.Controls.updateCCButton(liveCC)
    })
    this.application.off('stillWatching_videopage')
    this.application.on('stillWatching_videopage', ({ open, buttonPressed }) => {
      this._stillWatchingOpen = open
      if (buttonPressed !== undefined) {
        const historyLength = Router.getHistory() ? Router.getHistory().length : 0
        switch (buttonPressed) {
          case 'yes':
            appInstance.continue()
            break
          case 'no':
            appInstance.stop()
            if (historyLength > 0) {
              Router.back()
            } else {
              if (Router.getActiveRoute() === ROUTE.HOME) {
                Router.back()
              } else {
                Router.navigate(ROUTE.HOME)
              }
            }
            break
        }
      }
    })
  }

  _detach() {
    super._detach()
    this._clearEventListeners()
  }

  _clearEventListeners() {
    this.application.off('global_playerEvent_video')
    this.application.off('caption_Key')
    this.application.off('stillWatching')
  }

  _playerEvent(event) {
    switch (event) {
      case 'LoadedMetadata':
        this.sendAnalytics()
        this._setState('Controls')
        break
      case 'CanPlay':
        appInstance.play()
        break
      case 'Play':
        this._setState('Controls')
        this.Controls.playOrPause = true
        break
      case 'Pause':
        this._isPaused = true
        this.Controls.playOrPause = false
        break
      case 'Stop':
        Router.back()
        break
      case 'Forward':
        if (this._live) return true
        break
      case 'Rewind':
        if (this._live) return true
        break
      case 'Error':
        Router.navigate('error')
        break
      case 'Ended':
        this._seeking = false
        this._isPaused = false
        if (this._timeout) {
          clearTimeout(this._timeout)
        }
        Router.back()
        appInstance.endHlsPlayer()
        break
      case 'Progress':
        break
      case 'Stalled':
        //Video stopped
        break
      case 'Clear':
        this.updateVideoAnalytics()
        break
      case 'TimeUpdate':
        // eslint-disable-next-line no-case-declarations
        const currentTime = Math.floor(appInstance.getCurrentTime())
        this._currentTime = currentTime
        if (!this._seeking) {
          const watchPercentage = Number(
            ((this._currentTime * 100) / this._videoDuration).toFixed()
          )
          if (
            this.watchPercentages.includes(watchPercentage) &&
            this.prevWatchPercentage != watchPercentage
          ) {
            this.prevWatchPercentage = watchPercentage
            this.updateVideoAnalytics()
          }
          this.Controls.updateProgress(currentTime, appInstance.getVideoDuration())
        }
        break
      case 'Seeking':
        this._isPaused = false
        this._seekedTimeOut = setTimeout(() => {
          appInstance.play()
        }, 200)
        break
      case 'Seeked':
        if (this._seekedTimeOut) clearTimeout(this._seekedTimeOut)
        appInstance.play()
        this._seeking = false
        this._isPaused = false
        break
      default:
        break
    }
  }

  $showDesc() {
    this._setState('Details')
  }

  $pressPlay() {
    appInstance.pressPlay()
  }

  $pressLivePlay() {
    appInstance.isPlaying ? appInstance.pause() : this.playerStart(this.url)
  }

  $hideOverlay() {
    this._setState('Hide')
  }

  $closedCaption() {
    const CCStatus = appInstance.getCCStatus() === 'hidden' ? 'showing' : 'hidden'
    appInstance.updateCCTrackListener(CCStatus)
    this.Controls.toggleCCButton(CCStatus)
  }

  $rewind() {
    if (this._live) return true
    sendMetrics(GA4.CLICK, {
      button: BUTTON.REWIND,
      page_title: PAGENAME.VIDEO,
    })
    this._updateTimeOffset(-1)
  }

  $forward() {
    if (this._live) return true
    sendMetrics(GA4.CLICK, {
      button: BUTTON.FORWARD,
      page_title: PAGENAME.VIDEO,
    })
    this._updateTimeOffset(1)
  }

  $liveReplay() {
    appInstance.pause()
    let replayDuration = appInstance.getVideoDuration() - 120
    appInstance.seek('-' + replayDuration)
  }

  $watchLive() {
    this.Controls.updateProgress(appInstance.getCurrentTime(), appInstance.getVideoDuration())
    this.playerStart(this.url)
  }

  titleText(title) {
    this.Controls.title(title)
  }

  noticeText(notice) {
    this.Controls.notice(notice)
  }

  updateSeekTime(videoDuration) {
    this.Controls.Progress.forwardTime(videoDuration)
    this._SeekValue = Math.floor(videoDuration / 60) >= '5'
  }

  descText(desc) {
    this.Controls.description(desc)
  }

  isLive(Live) {
    this._isLive = Live
    this.Controls.LiveLabel(Live ? 'Happening Now' : this._videoDate, Live)
    this.Controls.switchControl(Live)
  }

  sendAnalytics() {
    this.videoParams.page_title = PAGENAME.VIDEO
    this.videoParams.video_title = this._videoTitle
    this.videoParams.video_id = this._videoId
    this.videoParams.video_duration = this._videoDuration
    sendMetrics(GA4.SCREENVIEW, { ...this.videoParams })
  }

  updateVideoAnalytics() {
    const watchPercentage = ((this._currentTime * 100) / this._videoDuration).toFixed()
    Object.assign(this.videoParams, {
      played_time: this._currentTime,
      video_percent: `${watchPercentage}`,
    })
    this.sendAnalytics()
  }

  playerStart(url) {
    appInstance.firstTimePlay = true
    if (!this._live) this.Controls.updateProgress(0, 0)
    if (url !== undefined) {
      this._playStream = url
      appInstance.playerOpen({
        url,
        islive: this._live,
        isHomePlayer: false,
        scope: undefined,
      })
    } else {
      this.tryAgain()
    }
  }

  tryAgain() {
    getWidget('Prompt').content = {
      message: 'Video is not available. Please try again later',
      handleBack: true,
      buttons: [
        {
          label: 'Ok',
          action: () => {
            appInstance.stop()
            Router.back()
          },
        },
      ],
    }
    setTimeout(() => {
      Router.focusWidget('Prompt')
    }, 800)
  }

  eventStopper(e) {
    if (e) {
      e.preventDefault()
      e.stopPropagation()
    }
  }

  replay() {
    this.Controls.updateProgress(0, appInstance.getVideoDuration())
    this.playerStart(this.url)
  }

  static _states() {
    return [
      class Loading extends this {},
      class Video extends this {
        _captureKey() {
          this._setState('Controls')
        }
      },
      class Controls extends this {
        $enter() {
          this._setInterfaceTimeout()
          this.Controls.setSmooth('alpha', 1)
        }

        $exit() {
          if (this._timeout) clearTimeout(this._timeout)
          this._timeout = 0
        }

        _setInterfaceTimeout() {
          if (this._timeout) {
            clearTimeout(this._timeout)
          }
          this._timeout = setTimeout(() => {
            this._setState('Hide')
          }, 5000)
        }

        _handleDown() {
          if (this._timeout) clearTimeout(this._timeout)
          this._setState('Hide')
        }

        _captureKey() {
          this._setInterfaceTimeout()
          return false
        }

        _getFocused() {
          return this.Controls
        }
      },
      class Hide extends this {
        $enter() {
          this.Controls.setSmooth('alpha', 0)
        }

        $exit() {
          this.Controls.setSmooth('alpha', 1)
        }

        _captureKey() {
          this._setState('Controls')
          return false
        }

        _captureBack() {
          this._setState('Controls')
          return true
        }

        _captureEnter() {
          this._setState('Controls')
          return true
        }

        _captureLeft() {
          this._setState('Controls')
          if (this._live) return true
          this.Controls.changeFocus('Progress')
          this.Controls.progressHandler()
        }

        _captureRight() {
          this._setState('Controls')
          if (this._live) return true
          this.Controls.changeFocus('Progress')
          this.Controls.progressHandler()
        }

        _captureDown() {
          this._setState('Controls')
          return true
        }

        _captureUp() {
          this._setState('Controls')
          return true
        }
      },
      class Details extends this {
        $enter() {
          if (this.Controls.DescriptionLength > 800) {
            this.Controls.ContentGradient.setSmooth('alpha', 1)
          }
          this.Controls.showDesc(true)
        }

        $exit() {
          this.Controls.showDesc(false)
          this.Controls.descPosition()
        }

        _handleDownRelease() {
          if (this.Controls.DescriptionLength > 800 && this.Controls.DescHeight > 760) {
            let k = Math.ceil(this.Controls.DescHeight - 600)
            if (this.Controls.ContentOverlay.y > '-' + k) {
              this.Controls.ContentOverlay.setSmooth('y', this.scrollpx - 100)
            }
          }
        }

        _handleUp() {
          if (this.Controls.DescriptionLength > 800) {
            if (this.Controls.ContentOverlay.y < 0) {
              this.Controls.ContentOverlay.setSmooth('y', 100)
            }
          }
        }

        _handleEnter() {
          return true
        }

        _handlePlay() {
          return true
        }

        _handlePause() {
          return true
        }

        _handlePlayPause() {
          return true
        }

        _handleForward() {
          return true
        }

        _handleRewind() {
          return true
        }

        _captureBack() {
          this.Controls.descPosition()
          this._setState('Controls')
        }
      },
    ]
  }
}
