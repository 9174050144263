import { isStringWithLength, isNumber } from '../../utils/checks'
import { adjustToItalic } from '../../utils/cspan'
import { ROUTE } from '../../constants/routes'

export default class SeriesListAsset {
  constructor(seriesList) {
    this._description = isStringWithLength(seriesList.description)
      ? adjustToItalic(seriesList.description)
      : ''
    this._logo = isStringWithLength(seriesList.logo) ? seriesList.logo : undefined
    this._id = seriesList.id ? seriesList.id : undefined
    this._title = isStringWithLength(seriesList.title) ? adjustToItalic(seriesList.title) : ''
    this._type = isStringWithLength(seriesList.type) ? seriesList.type : undefined
  }

  get displayType() {
    return this._displayType
  }

  get title() {
    return this._title
  }

  get logo() {
    return this._logo
  }

  get type() {
    let seriesType
    switch (this._type) {
      case 'season':
        seriesType = ROUTE.SEASONS
        break
      case 'rankset':
        seriesType = ROUTE.RANKSET
        break
      default:
        seriesType = ROUTE.SERIES
    }
    return seriesType
  }

  get id() {
    return this._id
  }

  get description() {
    return this._description
  }
}
