import { Colors, Lightning, Log, Router } from '@lightningjs/sdk'
import { isBoolean } from '../../utils/checks'
import CSPANImage from '../CSPANImage'
import { ROUTE } from '../../constants/routes'
import { COLORS } from '../../constants/colors'
import { isCurrentTimeBetweenStartEnd } from '../../utils/cspan'
import { voiceGuide } from '../../services/voiceGuide'

export default class LiveCardBig extends Lightning.Component {
  static _template() {
    return {
      w: 544,
      h: 380,
      Image: {
        w: 544,
        h: 306,
        type: CSPANImage,
        preset: 'large',
        radius: 10,
        strokeFocused: 5,
        strokeUnfocused: 0,
      },
      Title: {
        y: 333,
        text: {
          advancedRenderer: true,
          text: '',
          fontFace: 'PoppinsSemiBold',
          fontSize: 28,
          lineHeight: 42,
          maxLines: 2,
          wordWrapWidth: 544,
        },
      },
    }
  }

  //Card total width
  static get width() {
    return 544
  }

  //Card total height
  static get height() {
    return 380
  }

  set isliveStream(isliveStream) {
    this._isliveStream = isBoolean(isliveStream) ? isliveStream : false
  }

  get isliveStream() {
    return isBoolean(this._isliveStream) ? this._isliveStream : false
  }

  set listIsActive(listIsActive) {
    this._listIsActive = listIsActive
    this.tag('Image').activate = listIsActive
    this.tag('Title').color = listIsActive
      ? Colors(COLORS.LIGHTGREY).get()
      : Colors(COLORS.LIGHTGREY)
          .alpha(0.5)
          .get()
  }

  get listIsActive() {
    return typeof this._listIsActive === 'boolean' ? this._listIsActive : false
  }

  set item(item) {
    this._item = item
    const title = item.title
    this.isliveStream = item.isliveStream
    if (this.isliveStream) {
      this.tag('Image').liveBadge = item
    }
    this.tag('Image').image = item.imagePath
    this.patch({
      Title: { text: { text: title } },
    })
  }

  get item() {
    return this._item ? this._item : undefined
  }

  _handleEnter() {
    const itemType = this._item.itemType
    const id = this._item.id

    if (itemType && id) {
      if (this.isliveStream) {
        const liveBeginTime = new Date(this._item.liveBeginTime)
        const liveEndTime = new Date(this._item.liveEndTime)
        if (isCurrentTimeBetweenStartEnd(liveBeginTime, liveEndTime)) {
          Router.navigate(`${ROUTE.VIDEO}/${this._item.itemType}/${this._item.id}`)
        } else {
          Log.warn('Stream is not live yet')
        }
      }
    } else {
      Log.warn('missing params ', { itemType, id })
    }
  }

  _focus() {
    const title = (this._item && this._item.title) || ''
    voiceGuide.utterThis(title)
    this.tag('Image').patch({ smooth: { y: -10 } })
    this.tag('Image').highlight = true
  }

  _unfocus() {
    this.tag('Image').patch({ smooth: { y: 0 } })
    this.tag('Image').highlight = false
  }

  updateImageAndBadge() {
    this.tag('Image').image = this.item.imagePath
    if (this.isliveStream) {
      this.tag('Image').liveBadge = this.item
    }
  }
}
