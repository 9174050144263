/**
 * @param { string } isoStr - 2022-08-20T09:22:04.00Z or 2022-08-20T09:22:04.00
 * @param { boolean } useTimeZone - true or false
 * @returns { object } - { hours: 09, minutes: 22, seconds: 04 } without timeZone, or { hours: 11, minutes: 22, seconds: 04 } with timeZone, depending on your timeZone
 * */

export const isoStringToTime = (isoStr, useTimeZone = true) => {
  let date
  if (useTimeZone) {
    //If ending with "Z" returns different date depending on browser locale
    date = new Date(isoStr)
  } else {
    //If ending with "Z" Zulu remove "Z" to avoid different return time
    date = endsWithZ(isoStr) ? new Date(isoStr.slice(0, -1)) : new Date(isoStr)
  }
  const hours = formatToTwoDigit(date.getHours())
  const minutes = formatToTwoDigit(date.getMinutes())
  const seconds = formatToTwoDigit(date.getSeconds())

  const amPm = hours >= 12 ? 'pm' : 'am'
  let amPmHours = hours % 12
  amPmHours = amPmHours > 0 ? amPmHours : 12
  amPmHours = formatToTwoDigit(amPmHours)

  function formatToTwoDigit(t) {
    return t < 10 ? `0${t}` : `${t}`
  }

  function endsWithZ(str) {
    const endsWidthZ = /.*Z$/gm
    const regex = new RegExp(endsWidthZ)
    return !!str.match(regex)
  }

  return { hours, minutes, seconds, amPm, amPmHours }
}

/**
 * @param { string } isoStr - 2022-08-20T09:22:04.00Z or 2022-08-20T09:22:04.00
 * @param { boolean } useTimeZone - true or false
 * @returns { object } - { dayName: 'Saturday', monthName: 'August', day: 20, month: 08, year 2022 } if using timeZone this may vary
 * */
export const isoStringToDate = (isoStr, useTimeZone = true) => {
  if (!(isoStr && typeof isoStr === 'string')) {
    return { dayName: '', monthName: '', day: '', month: '', year: '' }
  }
  const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]
  let date
  if (useTimeZone) {
    //If ending with "Z" returns different date depending on browser locale
    date = new Date(isoStr)
  } else {
    //If ending with "Z" Zulu remove "Z" to avoid different return time
    date = endsWithZ(isoStr) ? new Date(isoStr.slice(0, -1)) : new Date(isoStr)
  }

  const dayName = days[date.getDay()]
  const day = formatToTwoDigit(date.getDate())
  const month = formatToTwoDigit(date.getMonth() < 12 ? date.getMonth() + 1 : 1)
  const monthName = months[date.getMonth()]
  const year = `${date.getFullYear()}`

  function formatToTwoDigit(t) {
    return t < 10 ? `0${t}` : `${t}`
  }

  function endsWithZ(str) {
    const endsWidthZ = /.*Z$/gm
    const regex = new RegExp(endsWidthZ)
    return !!str.match(regex)
  }

  return { dayName, monthName, day, month, year }
}

export const isDateToday = date => {
  let currentDate = new Date()
  let inputDate = new Date(date)
  return inputDate.setHours(0, 0, 0, 0) === currentDate.setHours(0, 0, 0, 0)
}

export const isDateTomorrow = date => {
  let currentDate = new Date()
  let inputDate = new Date(date)
  const tomorrow = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate() + 1
  )
  return inputDate.setHours(0, 0, 0, 0) === tomorrow.setHours(0, 0, 0, 0)
}

export const isDateTodayOrTomorrow = date => {
  return isDateToday(date) || isDateTomorrow(date)
}

// Milliseconds to Seconds
export const msToSec = ms => {
  return ms / 1000
}

// Milliseconds to Minutes
export const msToMin = ms => {
  return ms / 60000
}

//Milliseconds to Hours
export const msToHrs = ms => {
  return ms / 3600000
}

// Seconds to Milliseconds
export const secToMs = sec => {
  return sec * 1000
}

// Seconds to Minutes
export const secToMin = sec => {
  return sec / 60
}

// Seconds to Hours
export const secToHrs = sec => {
  return sec / 3600
}

// Minutes to Milliseconds
export const minToMs = min => {
  return min * 60000
}

// Minutes to Seconds
export const minToSec = min => {
  return min * 60
}

// Hours to Milliseconds
export const hrsToMs = hrs => {
  return hrs * 3600000
}

// Hours to Seconds
export const hrsToSec = hrs => {
  return hrs * 3600
}

// Hours to Minutes
export const hrsToMin = hrs => {
  return hrs * 60
}
export const getNumberOfDaysFromNow = scheduleDateString => {
  let currentDate = new Date().setHours(0, 0, 0, 0)
  const diffTime = Math.abs(currentDate - Date.parse(scheduleDateString))
  return Math.floor(diffTime / (1000 * 60 * 60 * 24))
}

export const secToHrsMinSec = sec => {
  let hoursDoubleDigit = formatToTwoDigit(Math.floor(sec / 3600))
  let hours = Math.floor(sec / 3600)
  sec %= 3600
  let minutesDoubleDigit = formatToTwoDigit(Math.floor(sec / 60))
  let minutes = Math.floor(sec / 60)
  let secondsDoubleDigit = formatToTwoDigit(sec % 60)
  let seconds = sec % 60

  function formatToTwoDigit(t) {
    return t < 10 ? `0${t}` : `${t}`
  }

  return {
    hours,
    hoursDoubleDigit,
    minutes,
    minutesDoubleDigit,
    seconds,
    secondsDoubleDigit,
  }
}
