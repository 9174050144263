export const STATES = {
  MENU: 'Menu',
  CONTENT: 'Content',
  ACTIVATION: 'Activation',
  DETAILS: 'Details',
  LOGGEDIN: 'LoggedIn',
  LOADER: 'Loader',
  EMPTY_STATE: 'EmptyState',
}
export const FEEDBACK = {
  TITLE: 'Feedback',
  APP_VERSION: 'APP_VERSION',
}
export const PROVIDER = 'Linked to provider'
export const COPRYRIGHTS = '© 2023 National Cable Satellite Corporation'
