import { Colors, Language, Lightning, Router } from '@lightningjs/sdk'
import ScheduleList from '../components/Schedule/ScheduleList'
import Loader from '../components/Loader'
import { COLORS } from '../constants/colors'
import { sortVideoByDate } from '../utils/cspan'
import { getWidget } from '../utils/tools'
import { sendMetrics } from '../services/metrics'
import { BUTTON, GA4, PAGENAME } from '../constants/analytics'
import { ROUTE } from '../constants/routes'
import { scheduleRoute } from '../lib/routeCalls'

export default class Schedule extends Lightning.Component {
  static _template() {
    return {
      Loader: { type: Loader, alpha: 0.0001 },
      NoData: {
        x: 930,
        y: 540,
        mount: 0.5,
        alpha: 0,
        text: {
          text: Language.translate('no_data'),
          fontSize: 24,
          lineHeight: 32,
          maxLines: 1,
          fontFace: 'PoppinsRegular',
          wordWrapWidth: 1000,
          textColor: Colors(COLORS.LIGHTGREY).get(),
        },
      },
      ScheduleList: {
        alpha: 0,
        x: 300,
        y: 130,
        w: 1920,
        h: 950,
        clipping: true,
        type: ScheduleList,
        signals: { initialized: true },
      },
    }
  }

  get ScheduleList() {
    return this.tag('ScheduleList')
  }

  get Loader() {
    return this.tag('Loader')
  }

  get NoData() {
    return this.tag('NoData')
  }

  set data(data) {
    this._data = data
  }

  _active() {
    this._renderData()
  }

  _init() {
    this.runMinuteClock(true)
  }

  _disable() {
    this.runMinuteClock(false)
  }

  _handleBack() {
    sendMetrics(GA4.CLICK, { button: BUTTON.BACK, page_title: PAGENAME.SCHEDULE })
    const historyLength = Router.getHistory() ? Router.getHistory().length : 0
    if (historyLength > 0) {
      Router.back()
    } else {
      if (Router.getActiveRoute() === ROUTE.HOME) {
        Router.back()
      } else {
        Router.navigate(ROUTE.HOME)
      }
    }
  }

  _renderData() {
    this._setState('Loader')
    this.ScheduleList.clear()

    setTimeout(() => {
      //Wait for menu to build before focussing
      this._setFocusToWidget()
    }, 200)

    getWidget('Background').startPosition()
    if (this._data.length) {
      this.ScheduleList.alpha = 0.1
      this.ScheduleList.items = sortVideoByDate(this._data, 'liveBeginTime')
    } else {
      this._setState('NoData')
    }
  }

  runMinuteClock(run = true) {
    let now = new Date()
    let nextClockMinute = (60 - now.getSeconds()) * 1000 - now.getMilliseconds()
    this.clearMinuteClock()
    if (!run) return
    this._minuteClock = setTimeout(() => {
      if (run) {
        scheduleRoute(this).then(() => {
          this._renderData()
          this.runMinuteClock(true)
        })
      }
    }, nextClockMinute)
  }

  clearMinuteClock() {
    if (this._minuteClock) clearTimeout(this._minuteClock)
  }

  _setFocusToWidget() {
    Router.focusWidget('Header')
  }

  static _states() {
    return [
      class Loader extends this {
        $enter() {
          this.Loader.setSmooth('alpha', 1, { duration: 0.3 })
        }

        $exit() {
          this.Loader.setSmooth('alpha', 0, { duration: 0.3 })
        }

        initialized(isScrollbarAllowed) {
          this._setState(isScrollbarAllowed ? 'ScheduleList' : 'SchedulesWithoutScroll')
        }
      },
      class ScheduleList extends this {
        $enter() {
          this.ScheduleList.setSmooth('alpha', 1, { duration: 0.3 })
        }

        _getFocused() {
          return this.ScheduleList
        }
      },
      class SchedulesWithoutScroll extends this {
        $enter() {
          this.ScheduleList.setSmooth('alpha', 1, { duration: 0.3 })
        }

        _focus() {
          if (!Router.isNavigating()) this._setFocusToWidget()
        }

        _getFocused() {
          return this.ScheduleList
        }
      },
      class NoData extends this {
        $enter() {
          this.NoData.alpha = 1
        }

        $exit() {
          this.NoData.alpha = 0
        }

        _focus() {
          if (!Router.isNavigating()) this._setFocusToWidget()
        }
      },
    ]
  }
}
