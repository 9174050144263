import {
  getHome,
  getVideoLive,
  getVideoVodClip,
  getVideoVodProgram,
  getScheduleInfo,
  getSeriesList,
  getSeries,
  getSeriesinfo,
  getSeasonInfo,
  getCategories,
  getTrendingTopics,
  getSeasons,
  getCategoryTitle,
  getSettingsData,
  getTVNetData,
  getRankset,
  getTVNetInfo,
  getTVNetToken,
} from '../api/Api'
import ScheduleAsset from '../api/assets/ScheduleAsset'
import SeriesListAsset from '../api/assets/SeriesListAsset'
import { isArrayWithLength, isObjectWithLength } from '../utils/checks'
import SeriesInfoAsset from '../api/assets/SeriesInfoAsset'
import TrendingItemAsset from '../api/assets/TrendingItemAsset'
import { getVideoLiveState } from '../utils/cspan'
import { isDateTodayOrTomorrow } from '../utils/time'
import RanksetAsset from '../api/assets/RanksetAsset'
import { Parameters } from '@firebolt-js/sdk'
import { ROUTE } from '../constants/routes'

export const rootRoute = () => {
  return Parameters.initialization().then(args => {
    console.log("cspanParameters",args)
    const availableSections = ['schedule', 'trendingtopics', 'settings', 'tvnets', 'series']
    const availableEntities = ['categories', 'rankset', 'seasons', 'series']
    const availableVideoTypes = ['program', 'clip', 'event']

    const navObj = (args && args.discovery && args.discovery.navigateTo) || undefined
    const action = (navObj && navObj.action) || 'home'
    const data = (navObj && navObj.data) || {}

    switch (true) {
      case action === 'section' && availableSections.includes(data.sectionName):
        return data.sectionName

      case action === 'entity' && availableEntities.includes(data.entityId):
        return `${data.entityId}/${data.assetId}`

      case action === 'playback' && availableVideoTypes.includes(data.entityId):
        return `${ROUTE.VIDEO}/${data.entityId}/${data.assetId}`

      case action === 'search':
        return data.query ? `${ROUTE.SEARCH}/${data.query}` : ROUTE.SEARCH

      default:
        return ROUTE.HOME
    }
  }).catch(initializationError => {
    console.log("initializationError", initializationError)
    return ROUTE.HOME
  })
}

export const homeRoute = page => {
  return getHome().then(data => {
    page.data = data
  })
}

export const videoRoute = (page, { itemType, id }) => {
  switch (itemType) {
    case 'program':
      return getVideoVodProgram(id).then(data => {
        page.data = data
      })
    case 'clip':
      return getVideoVodClip(id).then(data => {
        page.data = data
      })
    case 'event':
      return getVideoLive(id).then(data => {
        page.data = data
      })
    default:
      return getVideoVodProgram(id).then(data => {
        page.data = data
      })
  }
}
export const scheduleRoute = page => {
  let scheduleData = []
  let videoStatus = ['upcoming']
  return getScheduleInfo()
    .then(items => {
      if (isArrayWithLength(items)) {
        scheduleData = items
          .filter(
            item =>
              videoStatus.indexOf(getVideoLiveState(item.liveBeginTime, item.liveEndTime)) !== -1 &&
              isDateTodayOrTomorrow(item.date)
          )
          .map(item => new ScheduleAsset(item))
      }
    })
    .finally(() => (page.data = scheduleData))
}

export const seriesListRoute = page => {
  let seriesListData = []
  return getSeriesList()
    .then(items => {
      if (isArrayWithLength(items)) {
        seriesListData = items.map(item => new SeriesListAsset(item))
      }
    })
    .finally(() => (page.data = seriesListData))
}

export const ranksetRoute = (page, id) => {
  let ranksetData = []
  return getRankset(id)
    .then(data => {
      ranksetData = data.map(item => new RanksetAsset(item))
    })
    .finally(() => (page.data = { data: ranksetData, title: undefined }))
}
export const seriesRoute = (page, id) => {
  let seriesData = []
  let seriesInfo = ''
  let params = {}

  return getSeriesinfo(id)
    .then(data => {
      seriesInfo = isObjectWithLength(data) ? data : { parameters: {} }
      // eslint-disable-next-line prettier/prettier
      params = isObjectWithLength(seriesInfo.parameters)
        ? seriesInfo.parameters
        : {}
      return getSeries(id, params).then(items => {
        seriesData = new SeriesInfoAsset(seriesInfo, items)
      })
    })
    .finally(() => (page.data = seriesData))
}

export const seasonsRoute = (page, id) => {
  let seriesData = []
  let seriesInfo = ''
  let params = {}

  return getSeasonInfo(id)
    .then(data => {
      seriesInfo = isObjectWithLength(data) ? data : { parameters: {} }
      // eslint-disable-next-line prettier/prettier
      params = isObjectWithLength(seriesInfo.parameters)
        ? seriesInfo.parameters
        : {}
      return getSeasons(id, params).then(items => {
        seriesData = new SeriesInfoAsset(seriesInfo, items)
      })
    })
    .finally(() => (page.data = seriesData))
}

export const trendingTopicsRoute = page => {
  return getTrendingTopics().then(items => {
    page.data = items
  })
}
export const tvnettopic = page => {
  return getTVNetData().then(items => {
    page.data = items
  })
}
export const tvnetinfo = page => {
  let CSpanData = []
  let videoToken = ''
  let tokenKey = ''

  return getTVNetInfo()
    .then(items => {
      CSpanData = items
      tokenKey = items.tokenKey
      return getTVNetToken(tokenKey).then(item => {
        videoToken = item
      })
    })
    .finally(() => (page.data = { data: CSpanData, token: videoToken }))
}
export const categoryRoute = (page, id) => {
  let categoriesData = []
  let title = ''
  return getCategoryTitle(id)
    .then(item => {
      title = item[0].title
      return getCategories(id).then(items => {
        categoriesData = items.map(item => new TrendingItemAsset(item))
      })
    })
    .finally(() => (page.data = { data: categoriesData, title: title }))
}
export const settingsRoute = page => {
  let settingsdata = []
  let Activationdata = []
  return getSettingsData()
    .then(items => {
      settingsdata = items
      return getTVNetData().then(items => {
        Activationdata = items
      })
    })
    .finally(() => (page.data = { settingsData: settingsdata, activationData: Activationdata }))
}
