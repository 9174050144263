import { Colors, Lightning, Router, Utils } from '@lightningjs/sdk'
import { COLORS } from '../constants/colors'
import { isArrayWithLength, isStringWithLength } from '../utils/checks'
import PromptButton from '../widgets/prompt/PromptButton'
import { ROUTE } from '../constants/routes'
import { Metrics } from '@firebolt-js/sdk'

export default class PageNotFound extends Lightning.Component {
  static _template() {
    return {
      w: 1920,
      h: 1080,
      flex: {
        alignContent: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        wrap: false,
      },
      Logo: {
        flex: false,
        flexItem: false,
        x: 96,
        y: 68,
        src: Utils.asset('images/logo.png'),
      },
      Background: {
        flex: false,
        flexItem: false,
        w: 1920,
        h: 1080,
        rect: true,
        color: Colors(COLORS.PLAYER_BG_GRADIENT)
          .alpha(0.9)
          .get(),
      },
      Prompt: {
        w: 960,
        flex: {
          direction: 'column',
          justifyContent: 'flex-start',
          alignItems: 'center',
          wrap: false,
        },
        flexItem: { minHeight: 360, maxHeight: 520 },
        rect: true,
        color: Colors(COLORS.MIDNIGHT).get(),
        shader: {
          type: Lightning.shaders.RoundedRectangle,
          radius: 20,
        },
      },
    }
  }

  _init() {
    this._index = 0
    this.content = {
      message: 'Page not found',
      buttons: [
        {
          label: 'Close App',
          action: () => {
            this.application.closeApp()
          },
        },
        {
          label: 'Go to Homepage',
          action: () => {
            Router.navigate(ROUTE.HOME)
          },
        },
      ],
    }
  }

  set index(index) {
    this._index = index
  }

  get index() {
    return this._index
  }

  set content({ message = '', buttons = [] }) {
    Metrics.error(Metrics.ErrorType.OTHER, 'PAGE_NOT_FOUND', 'Page not found', true)
    let generatedButtons = undefined
    if (isArrayWithLength(buttons)) {
      generatedButtons = buttons.map((button, idx) => {
        return {
          type: PromptButton,
          flexItem: { marginRight: idx === buttons.length - 1 ? 0 : 20 },
          label: isStringWithLength(button.label) ? button.label : '',
          action: typeof button.action === 'function' ? button.action : () => {},
        }
      })
    }

    this.patch({
      Prompt: {
        Label: {
          flexItem: {
            marginTop: 80,
            marginLeft: 100,
            marginRight: 100,
          },
          color: Colors(COLORS.WHITE).get(),
          text: {
            advancedRenderer: true,
            fontFace: 'PoppinsRegular',
            textAlign: 'center',
            fontSize: 40,
            wordWrapWidth: 760,
            lineHeight: 80,
            text: isStringWithLength(message) ? message : '',
            maxLines: 3,
          },
        },
        Buttons: {
          width: 960,
          height: 70,
          flex: {
            direction: 'row',
            justifyContent: 'center',
            alignContent: 'flex-start',
            alignItems: 'flex-start',
            wrap: true,
          },
          flexItem: {
            marginBottom: 80,
            minWidth: 760,
            maxWidth: 760,
          },
          children: generatedButtons,
        },
      },
    })
  }

  _handleLeft() {
    if (this._index > 0) {
      this._index--
    } else {
      this._index = this.tag('Buttons').children.length - 1
    }
    return true
  }

  _handleRight() {
    if (this._index < this.tag('Buttons').children.length - 1) {
      this._index++
    } else {
      this._index = 0
    }
    return true
  }

  _handleUp() {
    return true
  }

  _handleDown() {
    return true
  }

  _handleEnter() {
    this.tag('Buttons').children[this._index].action()
  }

  _handleBack() {
    return true
  }

  _getFocused() {
    return this.tag('Buttons').children[this._index]
  }
}
