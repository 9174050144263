import Error from '../pages/Error'
import Splash from '../pages/Splash'
import Home from '../pages/Home'
import Video from '../pages/Video'
import Schedule from '../pages/Schedule'
import Categories from '../pages/Categories'
import TrendingTopics from '../pages/TrendingTopics'
import SeriesList from '../pages/SeriesList'
import Series from '../pages/Series'
import Authentication from '../pages/Authentication'
import { ROUTE } from '../constants/routes'
// eslint-disable-next-line prettier/prettier
import {
  videoRoute,
  homeRoute,
  scheduleRoute,
  seriesListRoute,
  seriesRoute,
  trendingTopicsRoute,
  categoryRoute,
  seasonsRoute,
  settingsRoute,
  tvnettopic,
  ranksetRoute,
  tvnetinfo,
  rootRoute,
} from './routeCalls'
import Search from '../pages/Search'
import PageNotFound from '../pages/PageNotFound'
import Settings from '../pages/Settings'
import Activation from '../pages/Authentication'
import { lifeCycleReady } from '../services/firebolt'
import { embedGtagPromise, externalGtagPromise, sendMetrics } from '../services/metrics'
import { GA4, PAGENAME } from '../constants/analytics'
import TVnet from '../pages/Tvnet'
import { appInstance } from '../utils/tools'
import { Router } from '@lightningjs/sdk'

export const routerConfiguration = {
  root: () => {
    return new Promise(resolve => {
      return rootRoute().then(routeTo => {
        resolve(routeTo)
      })
    })
  },
  boot: () => {
    /* eslint-disable no-console*/
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async resolve => {
      await lifeCycleReady()
      await externalGtagPromise()
      await embedGtagPromise()
      // eslint-disable-next-line no-undef
      sendMetrics('app_open')
      resolve()
    })
  },
  routes: [
    {
      path: '$', //Splash screen
      component: Splash,
      widgets: ['Background'],
    },
    {
      path: '*', //Page not found
      component: PageNotFound,
      widgets: ['Background'],
    },
    {
      path: '!', //Error page
      component: Error,
      options: { preventStorage: true },
      widgets: ['Background'],
      on: (page, { request }) => {
        sendMetrics(GA4.SCREENVIEW, { page_title: PAGENAME.ERROR })
        return new Promise(resolve => {
          page.errorData = request.error
          resolve()
        })
      },
    },
    {
      path: ROUTE.HOME,
      widgets: ['Prompt', 'Header', 'Background'],
      component: Home,
      on: page => {
        sendMetrics(GA4.SCREENVIEW, { page_title: PAGENAME.HOME })
        return homeRoute(page, {})
      },
    },
    {
      path: ROUTE.AUTHENTICATION,
      widgets: ['Prompt'],
      component: Authentication,
    },
    {
      path: `${ROUTE.VIDEO}/:itemType/:id`,
      widgets: ['Prompt'],
      component: Video,
      on: (page, { itemType, id }) => {
        sendMetrics(GA4.SCREENVIEW, { page_title: PAGENAME.VIDEO, item_type: itemType, id: id })
        return videoRoute(page, { itemType, id })
      },
    },
    {
      path: `${ROUTE.CATEGORIES}/:id`,
      widgets: ['Prompt', 'Background'],
      component: Categories,
      on: (page, { id }) => {
        sendMetrics(GA4.SCREENVIEW, { page_title: PAGENAME.CATEGORIES, id: id })
        return categoryRoute(page, id)
      },
    },
    {
      path: ROUTE.SCHEDULE,
      widgets: ['Prompt', 'Header', 'Background'],
      component: Schedule,
      on: page => {
        //sendMetrics(GA4.SCREENVIEW, { page_title: PAGENAME.UPCOMING })
        return scheduleRoute(page)
      },
    },
    {
      path: ROUTE.TRENDINGTOPICS,
      widgets: ['Prompt', 'Header', 'Background'],
      component: TrendingTopics,
      on: page => {
        sendMetrics(GA4.SCREENVIEW, { page_title: PAGENAME.TRENDINGTOPICS })
        return trendingTopicsRoute(page)
      },
    },
    {
      path: ROUTE.TVNET,
      widgets: ['Background'],
      component: Activation,
      on: page => {
        return tvnettopic(page)
      },
    },
    {
      path: ROUTE.SERIES,
      widgets: ['Prompt', 'Header', 'Background'],
      component: SeriesList,
      on: page => {
        sendMetrics(GA4.SCREENVIEW, { page_title: PAGENAME.SERIES })
        return seriesListRoute(page)
      },
    },
    {
      path: `${ROUTE.SERIES}/:id`,
      widgets: ['Prompt', 'Background'],
      component: Series,
      on: (page, { id }) => {
        sendMetrics(GA4.SCREENVIEW, { page_title: PAGENAME.SERIES, id: id })
        return seriesRoute(page, id)
      },
    },
    {
      path: `${ROUTE.RANKSET}/:id`,
      widgets: ['Prompt', 'Background'],
      component: Categories,
      on: (page, { id }) => {
        sendMetrics(GA4.SCREENVIEW, { page_title: PAGENAME.RANKSET, id: id })
        return ranksetRoute(page, id)
      },
    },
    {
      path: `${ROUTE.SEASONS}/:id`,
      widgets: ['Prompt', 'Background'],
      component: Series,
      on: (page, { id }) => {
        sendMetrics(GA4.SCREENVIEW, { page_title: PAGENAME.SEASONS, id: id })
        return seasonsRoute(page, id)
      },
    },
    {
      path: ROUTE.SEARCH,
      widgets: ['Prompt', 'Header', 'Background'],
      component: Search,
      on: () => {
        sendMetrics(GA4.SCREENVIEW, { page_title: PAGENAME.SEARCH })
        //Enabling 'on' since the focus is not coming to page

        return Promise.resolve()
      },
    },
    {
      path: `${ROUTE.SEARCH}/:query`,
      widgets: ['Prompt', 'Header', 'Background'],
      component: Search,
      on: (page, { query }) => {
        sendMetrics(GA4.SCREENVIEW, { page_title: PAGENAME.SEARCH })
        page._searchKeyword = query
        page.getData()
        return Promise.resolve()
      },
    },
    {
      path: ROUTE.SETTINGS,
      widgets: ['Prompt', 'Header', 'Background'],
      component: Settings,
      on: page => {
        sendMetrics(GA4.SCREENVIEW, { page_title: PAGENAME.SETTINGS })
        return settingsRoute(page)
      },
    },
    {
      path: ROUTE.TVNETLOGIN,
      component: TVnet,
      on: page => {
        return tvnetinfo(page)
      },
    },
  ],
  afterEachRoute: ({ hash }) => {
    if (hash && hash === 'home') {
      Router.setHistory([])
    } else {
      let history = Router.getHistory()
      let first = 'home'
      history.sort(function(x, y) {
        return x.hash == first ? -1 : y.hash == first ? 1 : 0
      })
      Router.setHistory(history)
    }

    //Ensure the page receives focus after each route
    Router.focusPage()

    return new Promise(resolve => {
      resolve()
    })
  },
  beforeEachRoute: from => {
    //Stop and reset video player before each route
    return new Promise(resolve => {
      if (appInstance && from !== '$') {
        appInstance.stop()
        appInstance.isHomePlayer(false, undefined)
      }
      resolve(true)
    })
  },
}
