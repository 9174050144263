//Info: mark functions and variables that are not ment to call directly with an _ and do not export them.
//Depending on how big the api gets, we might move parts to other files to keep things readable.

import { Log } from '@lightningjs/sdk'
import { API } from '../constants/api'
import { extractParameters } from '../utils/tools'
// eslint-disable-next-line prettier/prettier
import {
  isArrayWithLength,
  isStringWithLength,
  isObjectWithLength
} from '../utils/checks'
import Api_HomeAsset from './assets/Api_HomeAsset'
import TrendingInfoAsset from './assets/TrendingInfoAsset'
import VideoAsset from './assets/VideoAsset'
import TvNetInfoAsset from './assets/TvNetInfoAsset'
import { fbInstance } from '../services/firebolt'
import { getVideoLiveState } from '../utils/cspan'

const _initiateRequest = (url, requestOptions) => {
  return fetch(url, requestOptions)
    .then(handleErrors)
    .then(response => response.json())
    .catch(error => {
      Log.error('Error at _initiateRequest ', error, url)
      return Promise.reject(error)
    })
}

const pageReject = err => {
  if (typeof err === 'object') {
    let { ok, redirected, status, statusText, type, url } = err
    return Promise.reject({
      ok,
      redirected,
      status,
      statusText,
      type,
      url,
    })
  } else {
    return Promise.reject({
      ok: false,
      redirected: false,
      status: err && typeof err.message === 'string' ? err.message : undefined,
      statusText: err && typeof err.message === 'string' ? err.message : undefined,
      type: undefined,
      url: undefined,
    })
  }
}

const getUrlWithStrictCC = (url, parameters) => {
  return isStringWithLength(parameters)
    ? parameters.includes('strictCC=true')
      ? `${url}?${parameters}`
      : `${url}?${parameters}&strictCC=true`
    : `${url}?strictCC=true`
}

export const getHome = () => {
  return fetch(`${API.BASEURL.APP}${API.ENDPOINT.HOME}`, API.REQUESTOPTIONS)
    .then(handleErrors)
    .then(response => response.json())
    .then(result => {
      const obj = JSON.parse(result.data)
      return new Api_HomeAsset(obj)
    })
    .catch(err => {
      return pageReject(err)
    })
}
export const getTrendingTopics = () => {
  return fetch(`${API.BASEURL.APP}${API.ENDPOINT.HOME}`, API.REQUESTOPTIONS)
    .then(handleErrors)
    .then(response => response.json())
    .then(async result => {
      const trendingObj = JSON.parse(result.data)
      const promises = trendingObj.carousels
        .filter(item => item.type === 'Tag')
        .map(item => getTendingItemInfo(item).then(data => data))

      // eslint-disable-next-line prettier/prettier
      return Promise.all(promises).then(trendingList =>
        trendingList.filter(item => !!item)
      )
    })
}
export const getTendingItemInfo = item => {
  let url = `${API.BASEURL.APP}${API.ENDPOINT.TAGS}/${item.id}/programs`
  let parameters = extractParameters(item.params)

  //Add parameters when they exist
  url = getUrlWithStrictCC(url, parameters)

  return _initiateRequest(`${url}`, API.REQUESTOPTIONS).then(result => {
    // eslint-disable-next-line prettier/prettier
    return isArrayWithLength(result.array)
      ? new TrendingInfoAsset(item, result.array)
      : undefined
  })
}
export const getCategoryTitle = id => {
  return _initiateRequest(
    `${API.BASEURL.APP}${API.ENDPOINT.RANKSETS}/${API.CALLID.CATEGORIES}/listitems`,
    API.REQUESTOPTIONS
  ).then(result => {
    return isArrayWithLength(result.listItems)
      ? result.listItems.filter(item => item.id == id)
      : undefined
  })
}

export const getRankset = (id, params) => {
  let url = `${API.BASEURL.APP}${API.ENDPOINT.RANKSETS}/${id}/listitems`
  let parameters = extractParameters(params)

  //Add parameters when they exist
  const isClassroom = id.toLowerCase().includes('classroom')
  url = isStringWithLength(parameters)
    ? isClassroom && !parameters.includes('strictCC=true')
      ? `${url}?strictCC=true`
      : `${url}?${parameters}`
    : isClassroom
    ? `${url}?strictCC=true`
    : url

  return _initiateRequest(`${url}`, API.REQUESTOPTIONS).then(result => {
    return isArrayWithLength(result.listItems) ? result.listItems : undefined
  })
}

export const getTags = (id, params) => {
  let url = `${API.BASEURL.APP}${API.ENDPOINT.TAGS}/${id}/programs`
  let parameters = extractParameters(params)

  //Add parameters when they exist
  url = getUrlWithStrictCC(url, parameters)

  return _initiateRequest(`${url}`, API.REQUESTOPTIONS).then(result => {
    return isArrayWithLength(result.array) ? result.array : undefined
  })
}

export const getVideoLive = (id, params) => {
  let url = `${API.BASEURL.APP}${API.ENDPOINT.VIDEOLIVE}/${id}`
  let parameters = extractParameters(params)

  //Add parameters when they exist
  if (isStringWithLength(parameters)) {
    url = `${url}?${parameters}`
  }

  return _initiateRequest(`${url}`, API.REQUESTOPTIONS).then(videoObject => {
    return new VideoAsset(videoObject, true)
  })
}

export const getVideoVodProgram = (id, params) => {
  let url = `${API.BASEURL.APP}${API.ENDPOINT.VIDEOVODPROGRAM}/${id}`
  let parameters = extractParameters(params)

  //Add parameters when they exist
  if (isStringWithLength(parameters)) {
    url = `${url}?${parameters}`
  }

  return _initiateRequest(`${url}`, API.REQUESTOPTIONS).then(videoObject => {
    return new VideoAsset(videoObject, false)
  })
}

export const getVideoVodClip = (id, params) => {
  let url = `${API.BASEURL.APP}${API.ENDPOINT.VIDEOVODCLIP}/${id}`
  let parameters = extractParameters(params)

  //Add parameters when they exist
  if (isStringWithLength(parameters)) {
    url = `${url}?${parameters}`
  }

  return _initiateRequest(`${url}`, API.REQUESTOPTIONS).then(videoObject => {
    return new VideoAsset(videoObject, false)
  })
}
export const getTVNetData = () => {
  let ReqId = Math.floor(new Date().getTime() / 1000)
    .toString()
    .replace(/[0-9]$/, 5)

  const uniqueId =
    (fbInstance && fbInstance.deviceDetails && fbInstance.deviceDetails.uid) ||
    'ee6723b8-7ab3-462c-8d93-dbf61227998e'

  let url = `${API.BASEURL.APP}${API.ENDPOINT.TVNET}?deviceid=${uniqueId}&requestid=${ReqId}`
  return fetch(`${url}`, API.REQUESTOPTIONS)
    .then(response => response.json())
    .then(result => {
      return result
    })
}

export const getTVNetInfo = () => {
  let url = `${API.BASEURL.APP}${API.ENDPOINT.TVNETINFO}`
  return _initiateRequest(`${url}`, API.REQUESTOPTIONS).then(result => {
    return JSON.parse(result.data)
  })
}
export const getTVProviderData = data => {
  let url = `${API.BASEURL.APP}${API.ENDPOINT.TVNETPROVIDER}${data}`
  return _initiateRequest(`${url}`, API.REQUESTOPTIONS).then(result => {
    return result
  })
}
export const getNetworkInfo = () => {
  let videoStatus = ['live']
  let url = `${API.BASEURL.APP}${API.ENDPOINT.NETWORKINFO}`
  return _initiateRequest(`${url}`, API.REQUESTOPTIONS).then(items => {
    if (isArrayWithLength(items.scheduleItems)) {
      return items.scheduleItems
        .filter(item => videoStatus.indexOf(getVideoLiveState(item.beginTime, item.endTime)) !== -1)
        .map(item => new TvNetInfoAsset(item))
    }
  })
}
export const getTVNetToken = token => {
  let url = `${API.BASEURL.APP}${API.ENDPOINT.TVNETTOKEN}${token}`
  return _initiateRequest(`${url}`, API.REQUESTOPTIONS).then(result => {
    return result
  })
}

export const unLinkTVNetData = () => {
  let ReqId = Math.floor(new Date().getTime() / 1000)
    .toString()
    .replace(/[0-9]$/, 5)

  const uniqueId =
    (fbInstance && fbInstance.deviceDetails && fbInstance.deviceDetails.uid) ||
    'ee6723b8-7ab3-462c-8d93-dbf61227998e'

  let url = `${API.BASEURL.APP}${API.ENDPOINT.TVNET}?deviceid=${uniqueId}&requestid=${ReqId}`
  return fetch(`${url}`, API.UNLINKOPTIONS).then(result => {
    return result
  })
}

export const getScheduleInfo = () => {
  let url = `${API.BASEURL.APP}${API.ENDPOINT.RANKSETS}/${API.CALLID.LIVESTREAM}/listitems/?daysForward=2`
  return _initiateRequest(`${url}`, API.REQUESTOPTIONS).then(result => {
    return isArrayWithLength(result.listItems) ? result.listItems : undefined
  })
}

export const getSeriesList = () => {
  let url = `${API.BASEURL.APP}${API.ENDPOINT.SERIESLIST}`
  return _initiateRequest(`${url}`, API.REQUESTOPTIONS).then(result => {
    return JSON.parse(result.data)
  })
}

export const getSeriesinfo = id => {
  let url = `${API.BASEURL.APP}${API.ENDPOINT.SERIESINFO}/${id}`
  return _initiateRequest(`${url}`, API.REQUESTOPTIONS).then(result => {
    return result
  })
}

export const getSeasonInfo = id => {
  let url = `${API.BASEURL.APP}${API.ENDPOINT.SEASONSINFO}/${id}`
  return _initiateRequest(`${url}`, API.REQUESTOPTIONS).then(result => {
    return result
  })
}

export const getCategories = id => {
  let url = `${API.BASEURL.APP}${API.ENDPOINT.TAGS}/${id}/programs?limit=20&strictCC=true`
  return _initiateRequest(`${url}`, API.REQUESTOPTIONS).then(result => {
    return isArrayWithLength(result.array) ? result.array : undefined
  })
}
export const getSeries = (id, params) => {
  let url = `${API.BASEURL.APP}${API.ENDPOINT.SERIESINFO}/${id}/programs`
  let parameters = extractParameters(params)

  //Add parameters when they exist
  url = getUrlWithStrictCC(url, parameters)

  return _initiateRequest(`${url}`, API.REQUESTOPTIONS).then(result => {
    return isArrayWithLength(result.array) ? result.array : undefined
  })
}

export const getSeasons = (id, params) => {
  let url = `${API.BASEURL.APP}${API.ENDPOINT.SEASONSINFO}/${id}/programs`
  let parameters = extractParameters(params)

  //Add parameters when they exist
  url = getUrlWithStrictCC(url, parameters)

  return _initiateRequest(`${url}`, API.REQUESTOPTIONS).then(result => {
    return isArrayWithLength(result.array) ? result.array : undefined
  })
}

export const getImage = (key = '', edits = {}) => {
  if (!isStringWithLength(key)) return Promise.resolve('')
  const paramsString = JSON.stringify({
    bucket: API.BASEURL.IMAGE_BUCKET,
    key,
    edits,
  })
  const encodedString = btoa(paramsString)

  return fetch(`${API.BASEURL.IMAGE}${encodedString}`, API.REQUESTOPTIONS_IMAGE)
    .then(handleErrors)
    .then(result => {
      return result.url
    })
    .catch(error => {
      Log.error('Error while fetching the image data', error, key, edits)
      return ''
    })
}
export const getSearchResult = searchParam => {
  if (isStringWithLength(searchParam)) {
    const url = `${API.BASEURL.APP}${API.ENDPOINT.SEARCH}?query=${searchParam}`
    return _initiateRequest(`${url}`, API.REQUESTOPTIONS).then(result => {
      return isArrayWithLength(result.items) ? result.items : undefined
    })
  } else {
    Log.info('no search parameter given')
  }
}
export const getSettingsData = () => {
  const url = `${API.BASEURL.APP}${API.ENDPOINT.SETTINGS}`
  return _initiateRequest(`${url}`, API.REQUESTOPTIONS)
    .then(result => {
      const data = JSON.parse(result.data)
      return isObjectWithLength(data.settings) ? data.settings : undefined
    })
    .catch(() => {
      return undefined
    })
}

const handleErrors = response => {
  if (!response.ok) return Promise.reject(response)
  return Promise.resolve(response)
}
