import { Lightning } from '@lightningjs/sdk'

export default class HomeList extends Lightning.Component {
  static _template() {
    return {}
  }

  set index(index) {
    this.previousIndex = this._index
    this._index = index
    this.signal('onIndexChanged', { index: this.index, previousIndex: this.previousIndex })
  }

  get index() {
    return typeof this._index === 'number' ? this._index : 0
  }

  set previousIndex(previousIndex) {
    this._previousIndex = previousIndex
  }

  get previousIndex() {
    return typeof this._previousIndex === 'number' ? this._previousIndex : 0
  }

  set liveContainerVisible(liveContainerVisible) {
    this._liveContainerVisible = liveContainerVisible
  }

  get liveContainerVisible() {
    return typeof this._liveContainerVisible === 'boolean' ? this._liveContainerVisible : false
  }

  get listLength() {
    return this._listLength - 1
  }

  get items() {
    return this.childList && this.childList._items && this.childList._items.length
      ? this.childList._items
      : []
  }

  _init() {
    this._listLength = 0
  }

  _handleUp() {
    const conditionUp = this.liveContainerVisible ? this.index > 0 : this.index > 1
    if (conditionUp) {
      this.index--
      this.moveToIndex(this.index)
    } else {
      return false
    }
  }

  _handleDown() {
    if (this.index < this.listLength) {
      this.index++
      this.moveToIndex(this.index)
    } else {
      return false
    }
  }

  _getFocused() {
    console.log("HomeList")
    return this.childList._items[this.index]
  }

  addAt(item, index) {
    this.childList.addAt(item, index)
    this._listLength = this.childList.length
    this.repositionItems()
    this.childList._items[this.index]._refocus()
  }

  removeAt(index) {
    this.childList.removeAt(index)
    this._listLength = this.childList.length
    this.repositionItems()
    this.childList._items[this.index]._refocus()
  }

  clear() {
    this.childList.clear()
    this._listLength = this.childList.length
  }

  first() {
    this.index = 0
    this.moveToIndex(this.index)
  }

  setIndex(index) {
    if (!(index >= 0 && index <= this.listLength)) return
    this.index = index
    this.moveToIndex(this.index)
    this.childList._items[this.index]._refocus()
  }

  moveToIndex(index) {
    const travelDistance = this.getDistanceToZero(index)
    if (!travelDistance) return
    this._activeIndex = index
    this.childList.forEach(item => {
      let newPos = 0
      newPos += item.startPos - travelDistance
      item.setSmooth('y', newPos, { duration: 0.3 })
      item.startPos = newPos
    })
  }

  nudgeByPixels(pixels) {
    let newPos = 0
    this.childList.forEach(item => {
      newPos = item.startPos - pixels
      item.setSmooth('y', newPos, { duration: 0.3 })
    })
  }

  getDistanceToZero(index) {
    return this.childList._items[index].startPos
  }

  repositionItems() {
    const wrapper = this
    if (!wrapper && wrapper.childList.length) {
      return true
    }

    let position = 0
    wrapper.childList.forEach(item => {
      const mainPos = position || 0
      position += item.h
      item.startPos = mainPos
      item.patch({
        y: mainPos,
      })
    })
  }
}
