import { Language, Lightning, Colors, Router } from '@lightningjs/sdk'
import { COLORS } from '../constants/colors'
import { getTVNetData } from '../api/Api'
import { ROUTE } from '../constants/routes'
import { sendMetrics } from '../services/metrics'
import { BUTTON, GA4, PAGENAME } from '../constants/analytics'

export default class Activation extends Lightning.Component {
  static _template() {
    return {
      x: 104,
      y: 110,
      Title: {
        text: {
          fontSize: 60,
          fontFace: 'PoppinsRegular',
          textColor: Colors(COLORS.LIGHTGREY).get(),
          lineHeight: 80,
          verticalAlign: 'middle',
          maxLines: 2,
          wordWrapWidth: 1377,
        },
      },
      Content: {
        y: 277,
        CodeContent: {
          Line1: {
            color: Colors(COLORS.DARKGREY_40).get(),
            flex: { direction: 'row' },
          },
          Line2: {
            y: 60,
            text: {
              fontSize: 32,
              fontFace: 'PoppinsRegular',
              textColor: Colors(COLORS.LIGHTGREY).get(),
              verticalAlign: 'middle',
              text: Language.translate('activation_line2'),
            },
          },
          CodeBackground: {
            y: 159,
            w: 836,
            h: 180,
            texture: Lightning.Tools.getRoundRect(836, 180, 20),
            color: Colors(COLORS.BLACK)
              .alpha(0.4)
              .get(),
            Code: {
              mount: 0.5,
              x: w => w / 2 + 12,
              y: h => h / 2 + 12,
              paddingTop: 20,
              text: {
                fontSize: 100,
                lineHeight: 150,
                letterSpacing: 25,
                fontFace: 'PoppinsRegular',
                textColor: Colors(COLORS.WHITE).get(),
                verticalAlign: 'middle',
              },
            },
          },
        },
        DividerContent: {
          x: 995,
          w: 58,
          h: 520,
          Divider1: {
            x: w => w / 2,
            y: -12,
            mountX: 0.5,
            w: 2,
            h: 220,
            rect: true,
            color: Colors(COLORS.LIGHTGREY).get(),
          },
          Or: {
            y: 217,
            w: 58,
            text: {
              fontSize: 40,
              fontFace: 'PoppinsRegular',
              lineHeight: 60,
              textColor: Colors(COLORS.DARKGREY_40).get(),
              verticalAlign: 'middle',
              textAlign: 'center',
              text: Language.translate('or'),
            },
          },
          Divider2: {
            x: w => w / 2,
            y: 288,
            mountX: 0.5,
            w: 2,
            h: 220,
            rect: true,
            color: Colors(COLORS.LIGHTGREY).get(),
          },
        },
        QRContent: {
          x: 1212,
          QRText: {
            text: {
              fontSize: 32,
              fontFace: 'PoppinsRegular',
              textColor: Colors(COLORS.LIGHTGREY).get(),
              verticalAlign: 'middle',
              maxLines: 1,
              textAlign: 'center',
              text: Language.translate('scan_qr'),
            },
          },

          QRCode: {
            y: 68,
            w: 398,
            h: 398,
          },
          UsePhone: {
            y: 482,
            text: {
              fontSize: 24,
              fontFace: 'PoppinsRegular',
              lineHeight: 36,
              textColor: Colors(COLORS.DARKGREY_40).get(),
              verticalAlign: 'middle',
              maxLines: 1,
              textAlign: 'center',
              text: Language.translate('use_phone_to_scan'),
            },
          },
        },
      },
    }
  }

  _active() {
    this._renderData()
  }

  set data(data) {
    this._data = data
  }

  _renderData() {
    this._activationCode =
      this._data && this._data.activationCode ? this._data.activationCode : undefined
    this._url = this._data && this._data.activationUrl ? this._data.activationUrl : undefined
    this._activationUrl = this._url.replace(/^https?:\/\//, '')
    this._loggedIn = this._data.loggedIn
    if (!this._loggedIn) {
      this.updateAuthPage()
      this.startPollTimer()
    } else {
      Router.navigate(ROUTE.TVNETLOGIN)
    }
  }

  get Title() {
    return this.tag('Title')
  }

  get Line1() {
    return this.tag('Line1')
  }

  get Line2() {
    return this.tag('Line2')
  }

  get Code() {
    return this.tag('Code')
  }

  get QRCode() {
    return this.tag('QRCode')
  }

  _inactive() {
    this.stopPollTimer()
  }

  _handleBack() {
    sendMetrics(GA4.CLICK, { button: BUTTON.BACK, page_title: PAGENAME.TVNETWORK })
    this.stopPollTimer()
    // Router.back()
    const historyLength = Router.getHistory() ? Router.getHistory().length : 0
    if (historyLength > 0) {
      Router.back()
    } else {
      if (Router.getActiveRoute() === ROUTE.HOME) {
        Router.back()
      } else {
        Router.navigate(ROUTE.HOME)
      }
    }
  }

  updateAuthPage() {
    this.Line1.children = this.createFlexText([
      {
        text: Language.translate('activation_line1'),
        fontSize: 32,
        fontFace: 'PoppinsRegular',
        verticalAlign: 'middle',
        textColor: Colors(COLORS.DARKGREY_40).get(),
        lineHeight: 48,
      },
      {
        text: this._activationUrl,
        fontFace: 'PoppinsBold',
        fontSize: 32,
        verticalAlign: 'middle',
        textColor: Colors(COLORS.DARKGREY_40).get(),
        lineHeight: 48,
      },
    ])

    this.Title.text = Language.translate('tvNet_link_message')
    this.Code.text = this._activationCode
    this.QRCode.src = `https://cdn.metrological.com/qr?q=${encodeURIComponent(this._url)}`
  }

  startPollTimer() {
    this.stopPollTimer()
    this._pollTimer = setTimeout(() => {
      getTVNetData()
        .then(response => {
          let logIn = response.loggedIn
          if (!logIn) {
            this._activationCode =
              response && response.activationCode ? response.activationCode : undefined
            this._url = response && response.activationUrl ? response.activationUrl : undefined
            this._activationUrl = this._url.replace(/^https?:\/\//, '')
            this.updateAuthPage()
            this.startPollTimer()
          } else {
            this.stopPollTimer()
            Router.navigate(ROUTE.TVNETLOGIN)
          }
        })
        .catch(() => {
          // Catch needs to be here
        })
    }, 5000)
  }

  stopPollTimer() {
    if (this._pollTimer) {
      clearTimeout(this._pollTimer)
      this._pollTimer = undefined
    }
  }

  createFlexText(text) {
    return text.map(t => {
      return {
        w: t.w || 0,
        flexItem: { marginRight: 10 },
        text: {
          text: t.text || t,
          fontFace: t.fontFace ? t.fontFace : 'PoppinsRegular',
          fontSize: t.fontSize ? t.fontSize : 40,
        },
      }
    })
  }
}
