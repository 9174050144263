import {Colors} from '@lightningjs/sdk'
import {COLORS} from '../../constants/colors'
import VideoCard from '../Home/VideoCard'
import {isStringWithLength} from '../../utils/checks'

const TITLE_FONT_SIZE = 24
const TITLE_LINE_HEIGHT = 32
export default class VideoItem extends VideoCard {
  static _template() {
    return {
      ...super._template(),
      Title: {
        y: 242,
        color: Colors(COLORS.LIGHTGREY).get(),
        text: {
          advancedRenderer: true,
          text: '',
          fontSize: TITLE_FONT_SIZE,
          fontFace: 'PoppinsSemiBold',
          lineHeight: TITLE_LINE_HEIGHT,
          maxLines: 2,
          wordWrapWidth: 398,
        },
      },
      Description: {
        y: 292,
        color: Colors(COLORS.DARKGREY_40).get(),
        text: {
          advancedRenderer: true,
          text: '',
          fontSize: 22,
          fontFace: 'PoppinsRegular',
          lineHeight: 32,
          wordWrapWidth: 398,
          maxLines: 5,
        },
      },
    }
  }

  static get height() {
    return 500
  }
  get Description() {
    return this.tag('Description')
  }
  get Title() {
    return this.tag('Title')
  }
  get Image() {
    return this.tag('Image')
  }

  set item(item) {
    this._item = item
    let title = ''
    let description = ''

    if (isStringWithLength(item.title)) {
      title = item.title
    }
    if (isStringWithLength(item.description)) {
      description = item.description
    }

    this.Image.image = item.imagePath
    this.patch({
      Title: { text: { text: title } },
      Description: { text: { text: description } },
    })
  }

  get item() {
    return this._item ? this._item : undefined
  }

  _init() {
    this.Title.on('txLoaded', titleInfo => {
      const renderTitleH =
        titleInfo.source.renderInfo.lines.length * TITLE_LINE_HEIGHT + TITLE_FONT_SIZE / 2 - 7
      this.Description.y = this.Title.y + renderTitleH
      //This will be reverted once we have lightning core #2.9.0 support on the store
      // this.Description.y = this.tag('Title').y + this.tag('Title').renderHeight - 7
    })
  }

  _handleEnter() {
    return false
  }
}
