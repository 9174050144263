import { Colors, Lightning, Log, Router } from '@lightningjs/sdk'
import { isBoolean, isStringWithLength } from '../../utils/checks'
import { COLORS } from '../../constants/colors'
import { ROUTE } from '../../constants/routes'
import { voiceGuide } from '../../services/voiceGuide'

export default class HouseAndSenateCard extends Lightning.Component {
  static _template() {
    return {
      w: 690,
      h: 150,
      DropShadow: {
        zIndex: 10,
        alpha: 0.2,
        x: -4,
        y: 10,
        w: 690,
        h: 150,
        rect: true,
        color: Colors(COLORS.BLACK).get(),
        texture: Lightning.Tools.getShadowRect(333, 150, 10, 8, 2),
      },
      Highlight: {
        zIndex: 15,
        x: -20,
        y: -20,
        w: 710,
        h: 170,
        rect: true,
        alpha: 0.0001,
        shader: {
          type: Lightning.shaders.RoundedRectangle,
          radius: 20,
          stroke: 5,
          fillColor: Colors(COLORS.WHITE)
            .alpha(0)
            .get(),
          strokeColor: Colors(COLORS.LIGHTGREY).get(),
        },
      },
      Card: {
        zIndex: 15,
        x: -10,
        y: -10,
        w: 690,
        h: 150,
        src: '',
        shader: {
          type: Lightning.shaders.RoundedRectangle,
          radius: 10,
        },
      },
      Title: {
        x: 162,
        y: 75,
        zIndex: 20,
        mountY: 0.4,
        mountX: 0.5,
        color: Colors(COLORS.DARKGREY).get(),
        text: {
          advancedRenderer: true,
          text: '',
          fontSize: 32,
          lineHeight: 46,
          maxLines: 1,
          textAlign: 'center',
          verticalAlign: 'middle',
          fontFace: 'PoppinsRegular',
          wordWrapWidth: 290,
        },
      },
    }
  }

  //Card total width
  static get width() {
    return 690
  }

  //Card total height
  static get height() {
    return 150
  }

  set isliveStream(isliveStream) {
    this._isliveStream = isBoolean(isliveStream) ? isliveStream : false
  }

  get isliveStream() {
    return isBoolean(this._isliveStream) ? this._isliveStream : false
  }

  set highlight(highlight) {
    this._highlight = highlight
    this.patch({
      Highlight: {
        alpha: highlight ? 1 : 0.0001,
      },
    })
  }

  get highlight() {
    return isBoolean(this._highlight) ? this._highlight : false
  }

  set item(item) {
    this._item = item
    if (isStringWithLength(item.image)) {
      this.tag('Card').src = item.image
    } else {
      this.noImage = true
      this.noImageFallback(item.title)
    }
    this.tag('Card').on('txError', () => {
      this.noImage = true //Only set on txError, txLoaded will be called even when an error occurs
      this.noImageFallback(item.title)
    })
  }

  set noImage(noImage) {
    this._noImage = noImage
  }

  get noImage() {
    return typeof this._noImage === 'boolean' ? this._noImage : false
  }

  get item() {
    return this._item ? this._item : undefined
  }

  set listIsActive(listIsActive) {
    this._listIsActive = listIsActive
    if (this.noImage) {
      this.tag('Card').patch({
        colorTop: listIsActive
          ? Colors(COLORS.BACKGROUND_SERIES_TOP_ACTIVE).get()
          : Colors(COLORS.BACKGROUND_SERIES_TOP_INACTIVE).get(),
        colorBottom: listIsActive
          ? Colors(COLORS.BACKGROUND_SERIES_BOTTOM_ACTIVE).get()
          : Colors(COLORS.BACKGROUND_SERIES_BOTTOM_INACTIVE).get(),
      })
      this.tag('Title').color = listIsActive
        ? Colors(COLORS.LIGHTGREY).get()
        : Colors(COLORS.DARKGREY).get()
    } else {
      this.tag('Card').setSmooth(
        'color',
        listIsActive ? Colors(COLORS.WHITE).get() : Colors(COLORS.DARKGREY_60).get()
      )
    }
  }

  get listIsActive() {
    return typeof this._listIsActive === 'boolean' ? this._listIsActive : false
  }

  _handleEnter() {
    const itemType = this._item.itemType
    const id = this._item.id

    if (itemType && id) {
      if (itemType === 'Series') {
        Router.navigate(`${ROUTE.SERIES}/${id}`)
      }
    } else {
      Log.warn('missing params ', { itemType, id })
    }
  }

  _focus() {
    const title = (this._item && this._item.title) || ''
    voiceGuide.utterThis(title)
    this.highlight = true
    this.patch({ smooth: { y: -10 } })
  }

  _unfocus() {
    this.highlight = false
    this.patch({ smooth: { y: 0 } })
  }

  noImageFallback(title) {
    this.tag('Title').text.text = title || ''
    this.tag('Card').patch({
      src: undefined,
      rect: true,
      colorTop: Colors(COLORS.BACKGROUND_SERIES_TOP_INACTIVE).get(),
      colorBottom: Colors(COLORS.BACKGROUND_SERIES_BOTTOM_INACTIVE).get(),
    })
  }
}
