import { Metadata } from '@lightningjs/sdk'
import { fbInstance } from './firebolt'
import { GA4 } from '../constants/analytics'

export const externalGtagPromise = () => {
  return new Promise((resolve, reject) => {
    const externalGtagScript = document.createElement('script')
    /* eslint-disable */
		externalGtagScript.src =
			`https://www.googletagmanager.com/gtag/js?id=${GA4.MEASUREMENTID}`;
		externalGtagScript.addEventListener('load', () => resolve(true));
		externalGtagScript.addEventListener('error', () => reject(false));
		document.head.appendChild(externalGtagScript);
	});
};
export const embedGtagPromise = () => {
	return new Promise(resolve => {
		const embedGtagScript = document.createElement('script');
		embedGtagScript.text = `window.dataLayer = window.dataLayer ||[];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', "${GA4.MEASUREMENTID}",{ 'debug_mode': true });`;
		document.head.appendChild(embedGtagScript);
		resolve();
	});
};
export const getCSPANParams = () => {
	return {
		device_id: (fbInstance.deviceDetails && fbInstance.deviceDetails.uid) ? fbInstance.deviceDetails.uid : undefined,
		device_model: (fbInstance.deviceDetails && fbInstance.deviceDetails.model) ? fbInstance.deviceDetails.model : undefined,
		app_version: Metadata.get('version', 'unknown'),
		app_name: Metadata.get('name', 'unknown')
	};
};
export const getParams = async (customParams) => {
	return {
		...getCSPANParams,
		...customParams
	};
};

export const sendMetrics = (eventName, customParams) => {
  getParams(customParams).then((params) => {
    gtag('event', eventName, {...getCSPANParams(), ...params})
  })
}
