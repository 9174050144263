import { Colors, Lightning, Registry, Utils } from '@lightningjs/sdk'
import { COLORS } from '../../constants/colors'
import { PROGRESS_ACTION } from '../../constants/videoapp'

export default class ProgressBar extends Lightning.Component {
  static _template() {
    return {
      Progress: {
        forceZIndexContext: true,
        Total: {
          texture: Lightning.Tools.getRoundRect(1420, 10, 5),
          color: Colors(COLORS.LIGHTGREY).get(),
          alpha: 0.2,
        },
        Inactive: {
          x: -5,
          alpha: 0,
          mountY: 0.3,
          texture: Lightning.Tools.getRoundRect(
            20,
            20,
            10,
            0,
            0,
            true,
            Colors(COLORS.COBALT).get()
          ),
        },

        Active: {
          x: -1,
          y: -1,
          color: Colors(COLORS.COBALT).get(),
          Pointer: {
            UnSelect: {
              alpha: 0,
              mountY: 0.3,
              texture: Lightning.Tools.getRoundRect(
                30,
                30,
                15,
                0,
                0,
                true,
                Colors(COLORS.LIGHTGREY).get()
              ),
            },
            Select: {
              alpha: 0,
              mountY: 0.3,
              x: -25,
              w: 90,
              h: 30,
              flex: {
                direction: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
              },
              Rewind: {
                flexItem: {},
                w: 20,
                h: 26,
                rotation: Math.PI,
                src: Utils.asset('images/fwd.png'),
              },
              Indicator: {
                flexItem: {},
                texture: Lightning.Tools.getRoundRect(
                  30,
                  30,
                  15,
                  4,
                  Colors(COLORS.LIGHTGREY).get(),
                  true,
                  Colors(COLORS.COBALT).get()
                ),
              },
              Forward: {
                flexItem: {},
                w: 20,
                h: 26,
                src: Utils.asset('images/fwd.png'),
              },
            },
          },
          DirectionTime: {
            x: -10,
            y: 27,
            alpha: 0,
            text: {
              textColor: Colors(COLORS.WHITE).get(),
              fontFace: 'PoppinsRegular',
              fontSize: 20,
              fontWeight: 600,
              lineHeight: 30,
              textAlign: 'center',
            },
          },
        },
        Timer: {
          y: -45,
          flex: {
            direction: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
          },
          CurrentTime: {
            flexItem: {},
            text: {
              fontSize: 22,
              lineHeight: 33,
              fontFace: 'PoppinsRegular',
              maxLines: 1,
              text: '00:00',
            },
          },
          Separator: {
            flexItem: { marginLeft: 10 },
            text: {
              fontSize: 22,
              lineHeight: 33,
              fontFace: 'PoppinsRegular',
              maxLines: 1,
              text: '/ ',
            },
          },
          Duration: {
            flexItem: {},
            text: {
              textColor: Colors(COLORS.DARKGREY_60).get(),
              fontSize: 22,
              lineHeight: 33,
              fontFace: 'PoppinsRegular',
              maxLines: 1,
              text: '00:00',
            },
          },
        },
      },
    }
  }

  set _progress(v) {
    const now = Date.now()
    let estimation = 0
    if (!this._last || this._last < now - 1000) {
      estimation = 500
    } else {
      estimation = now - this._last
    }
    this._last = now
    const x = v * 1420

    estimation *= 0.001
    this.tag('Total').setSmooth('x', x, { timingFunction: 'linear', duration: estimation })
    this.tag('Total').setSmooth('texture.x', x, {
      timingFunction: 'linear',
      duration: estimation,
    })
    this.tag('Active').setSmooth('texture.w', Math.max(x, 0.0001) /* force clipping */, {
      timingFunction: 'linear',
      duration: estimation,
    })
    this.tag('Inactive').setSmooth('x', this.tag('Active').getSmooth('texture.w') - 5, {
      timingFunction: 'linear',
      duration: estimation,
    })
    this.tag('Pointer').setSmooth('x', this.tag('Active').getSmooth('texture.w') - 5, {
      timingFunction: 'linear',
      duration: estimation,
    })
    this.tag('DirectionTime').setSmooth('x', this.tag('Active').getSmooth('texture.w') - 10, {
      timingFunction: 'linear',
      duration: estimation,
    })
  }

  get CurrentTimeValue() {
    return this.tag('CurrentTime').text.text
  }

  get DurationTimeValue() {
    return this.tag('Duration').text
  }

  get Pointerx() {
    return this.tag('Pointer').x
  }

  get currentTime() {
    return this._currentTime
  }

  _init() {
    this._currentTime = 0
    this.tag('Active').texture = {
      type: Lightning.textures.SourceTexture,
      textureSource: this.tag('Total').texture.source,
    }
    this._setState('Idle')
  }

  _formatTime(seconds) {
    const hours = Math.floor(seconds / 3600)
    seconds -= hours * 3600
    const minutes = Math.floor(seconds / 60)
    seconds -= minutes * 60
    seconds = Math.floor(seconds)
    const parts = []
    if (hours) parts.push(hours)
    parts.push(minutes)
    parts.push(seconds)
    return parts.map(number => (number < 10 ? '0' + number : '' + number)).join(':')
  }

  _setProgress() {
    this._setState('Active')
  }

  _setProgressState(state) {
    this._setState(state)
  }

  _focus() {
    this._setState('Inactive')
  }

  _unfocus() {
    this._setState('Idle')
  }

  setProgress(currentTime, duration) {
    this._progress = currentTime / Math.max(duration, 1)
    this._currentTime = currentTime
    this.tag('CurrentTime').text = this._formatTime(currentTime)
    if (Number.isFinite(duration)) {
      this.tag('Duration').text = this._formatTime(duration)
    } else {
      this.tag('Duration').text = '00:00'
    }
  }

  updateseek(time) {
    Registry.clearTimeout(this._timeout)
    this.tag('DirectionTime').text.text = time
    this.tag('DirectionTime').setSmooth('alpha', 1)
    this._setInterfaceTimeout()
  }

  forwardTime(time) {
    this.clickcount = Math.floor(time / 60) >= '5' ? '3' : '1'
    this._seekCountTimer = this.clickcount * 10 + 's'
  }

  static _states() {
    return [
      class Active extends this {
        $enter() {
          this.tag('Select').alpha = 1
          this.tag('UnSelect').alpha = 0
        }

        $exit() {
          this.tag('Select').alpha = 0
          this.tag('UnSelect').alpha = 1
          this.tag('DirectionTime').setSmooth('alpha', 0)
        }
        _handleLeftRelease() {
          this.fireAncestors(PROGRESS_ACTION.REWIND)
        }
        _handleForward() {
          this.updateseek('+' + this._seekCountTimer)
        }
        _handleForwardRelease() {
          this.fireAncestors(PROGRESS_ACTION.FORWARD)
        }
        _handleRewind() {
          this.updateseek('-' + this._seekCountTimer)
        }
        _handleRewindRelease() {
          this.fireAncestors(PROGRESS_ACTION.REWIND)
        }

        _handleLeft() {
          this.updateseek('-' + this._seekCountTimer)
        }
        _handleRight() {
          this.updateseek('+' + this._seekCountTimer)
        }
        _handleRightRelease() {
          this.fireAncestors(PROGRESS_ACTION.FORWARD)
        }

        _handleEnter() {
          this.signal('changeFocus', 'Progress')
          this._setState('Inactive')
        }

        _setInterfaceTimeout() {
          if (this._timeout) {
            Registry.clearTimeout(this._timeout)
          }
          this._timeout = Registry.setTimeout(() => {
            this.tag('DirectionTime').setSmooth('alpha', 0)
          }, 1000)
        }
      },
      class Inactive extends this {
        $enter() {
          this.tag('Select').alpha = 0
          this.tag('UnSelect').alpha = 1
        }

        $exit() {
          this.tag('Select').alpha = 1
          this.tag('UnSelect').alpha = 0
        }
      },
      class Idle extends this {
        $enter() {
          this.tag('Select').alpha = 0
          this.tag('UnSelect').alpha = 0
          this.tag('Inactive').alpha = 1
        }
        $exit() {
          this.tag('Inactive').alpha = 0
        }
      },
    ]
  }
}
