import { Colors, Lightning } from '@lightningjs/sdk'
import { List } from '@lightningjs/ui'
import SeriesCard from './SeriesCard'
import { COLORS } from '../../constants/colors'

export default class SeriesList extends Lightning.Component {
  static _template() {
    return {
      w: 1845,
      h: 250,
      x: 105,
      Title: {
        x: 0,
        y: 50,
        pivot: 0,
        color: Colors(COLORS.DARKGREY).get(),
        text: {
          advancedRenderer: true,
          text: '',
          fontSize: 40,
          lineHeight: 46,
          textAlign: 'left',
          maxLines: 2,
          fontFace: 'PoppinsSemiBold',
          wordWrapWidth: 248,
        },
      },
      ListWrapper: {
        w: 1845,
        h: 250,
        x: 269,
        clipping: true,
        SeriesList: {
          w: 1486,
          h: 250,
          y: 50,
          x: 20,
          type: List,
          direction: 'row',
          spacing: 41,
          signals: { onIndexChanged: true },
          scrollTransition: { duration: 0.3, timingFunction: 'cubic-bezier(.33, 1, .68, 1)' },
        },
      },
    }
  }

  static get width() {
    return 1845
  }

  static get height() {
    return 250
  }

  set index(index) {
    this._index = index
    this.tag('SeriesList').setIndex(index)
  }

  get index() {
    return this._index ? this._index : 0
  }

  set title(title) {
    this._title = title
    this.tag('Title').text.text = title || ''

    let labelWidth = 248
    this.tag('Title').on('txLoaded', () => {
      if (this.tag('Title').renderWidth > labelWidth) {
        this.tag('Title').scale = labelWidth / this.tag('Title').renderWidth
      } else {
        this.tag('Title').scale = 1
      }
    })
  }

  get title() {
    return this._title ? this._title : ''
  }

  set callAsset(callAsset) {
    this._callAsset = callAsset
  }

  get callAsset() {
    return this._callAsset
  }

  set useLocalImages(useLocalImages) {
    this._useLocalImages = useLocalImages
  }

  get useLocalImages() {
    return typeof this._useLocalImages === 'boolean' ? this._useLocalImages : false
  }

  set data(items) {
    if (Array.isArray(items)) {
      this._data = items
      items.forEach(item => {
        this.tag('SeriesList').add({
          type: SeriesCard,
          useLocalImages: this.useLocalImages,
          item,
          listIsActive: false,
        })
      })
    }
  }

  get data() {
    return this._data && Array.isArray(this._data) ? this._data : undefined
  }

  _focus() {
    this._activateList(true)
    this.tag('Title').color = Colors(COLORS.LIGHTGREY).get()
  }

  _unfocus() {
    this._activateList(false)
    this.tag('Title').color = Colors(COLORS.DARKGREY_60).get()
  }

  _handleLeft() {
    //Jump to last item when list is at start
    this.tag('SeriesList').last()
  }

  _handleRight() {
    //Jump to first item when list is at end
    this.tag('SeriesList').first()
  }

  _getFocused() {
    return this.tag('SeriesList')
  }

  onIndexChanged({ index }) {
    this._index = index
    this.fireAncestors('$cellIndexChanged', index)
  }

  _activateList(activate) {
    this.fireAncestors('$cellIndexChanged', this.index)
    this.tag('SeriesList').items.forEach(listItem => {
      listItem.listIsActive = activate
    })
  }

  clear() {
    this.tag('SeriesList').clear()
  }
}
