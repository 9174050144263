import { Lightning, Colors } from '@lightningjs/sdk'
import { COLORS } from '../../constants/colors'
import CSPANImage from '../CSPANImage'

export default class ScheduleItem extends Lightning.Component {
  static _template() {
    return {
      h: 129,
      w: 1400,
      Wrapper: {
        Image: {
          w: 160,
          h: 90,
          y: -10,
          type: CSPANImage,
          preset: 'upcoming',
          radius: 5,
          shadowX: 0,
          shadowY: 4,
          shadowBlur: 4,
          shadowW: 0,
        },
        Details: {
          x: 200,
          Date: {
            text: {
              fontFace: 'PoppinsRegular',
              text: 'date',
              fontSize: 22,
              maxLines: 1,
              textColor: Colors(COLORS.DARKGREY_60).get(),
              wordWrapWidth: 480,
            },
          },
          Title: {
            y: 32,
            text: {
              advancedRenderer: true,
              fontFace: 'PoppinsSemiBold',
              fontSize: 28,
              textColor: Colors(COLORS.LIGHTGREY).get(),
              maxLines: 2,
              lineHeight: 34,
              wordWrapWidth: 968,
            },
          },
          Bar: {
            y: 96,
            w: 250,
            h: 2,
            rect: true,
            color: Colors(COLORS.DARKGREY).get(),
          },
        },
      },
    }
  }

  static get width() {
    return 1400
  }

  static get height() {
    return 129
  }

  get Bar() {
    return this.tag('Bar')
  }

  get Title() {
    return this.tag('Title')
  }

  get Image() {
    return this.tag('Image')
  }

  set item(item) {
    this._item = item
    this.patch({
      Wrapper: {
        Image: { image: item.imagePath },
        Details: {
          Date: {
            text: {
              text: item.scheduleDate(item.liveBeginTime),
            },
          },
          Title: {
            text: {
              text: item.title,
            },
          },
        },
      },
    })
  }

  get item() {
    return this._item
  }

  set lastItem(isLastItem) {
    this.Bar.alpha = isLastItem ? 0 : 1
  }

  _init() {
    this.h = 129
    this.Title.on('txLoaded', () => {
      const numberOfLines = this.Title.texture.source.renderInfo.lines.length
      if (numberOfLines > 1) {
        this.h = 129 + (numberOfLines - 1) * 34
        this.Bar.y = 93 + (numberOfLines - 1) * 34
      }
    })
  }
}
