export const TRACK_MODE = {
  HIDDEN: 'hidden',
  SHOWING: 'showing',
  DISABLED: 'disabled',
}
export const CAPTION_TYPE = {
  CAPTION: 'captions',
  SUBTITLE: 'subtitles',
}
export const PROGRESS_ACTION = {
  FORWARD: '$forward',
  REWIND: '$rewind',
  FORWARD_TIME: '+10S',
  REWIND_TIME: '-10S',
}
