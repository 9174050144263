import { Lightning, Colors, Language } from '@lightningjs/sdk'
import { COLORS } from '@/constants/colors'
import { voiceGuide } from '../../../services/voiceGuide'

export default class Spacekey extends Lightning.Component {
  static _template() {
    return {
      y: y => y - 2,
      BG: {},
      Label: {
        x: 85,
        y: 19,
        text: {
          text: Language.translate('page_search').keySpace,
          fontSize: 22,
          fontFace: 'PoppinsSemiBold',
          lineHeight: 33,
        },
      },
    }
  }

  _init() {
    this.defaultButton()
  }

  _focus() {
    /* istanbul ignore next */
    voiceGuide.utterThis('Space')
    const bgColor = Colors(COLORS['LIGHTGREY']).get()
    const fontColor = Colors(COLORS['MIDNIGHT']).get()
    this.updateButton(bgColor, fontColor)
  }

  _unfocus() {
    this.defaultButton()
  }

  updateButton(bgColor, lblColor) {
    this.patch({
      BG: {
        texture: Lightning.Tools.getRoundRect(240, 60, 10, 3, 0, true, bgColor),
      },
      Label: {
        text: {
          textColor: lblColor,
        },
      },
    })
  }

  defaultButton() {
    const bgColor = Colors(COLORS['BLACK'])
      .alpha(0.4)
      .get()
    const fontColor = Colors(COLORS['DARKGREY_60']).get()
    this.updateButton(bgColor, fontColor)
  }
}
