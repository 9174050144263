import { Colors, Lightning, Log } from '@lightningjs/sdk'
import { ContentRenderer } from './ContentRenderer'
import { ContentData } from './ContentData'
import { API } from '../../../constants/api'
import { isNumber, isObjectWithLength } from '../../../utils/checks'
import { getWidget } from '../../../utils/tools'
import BackToTop from '../BackToTop'
import { COLORS } from '../../../constants/colors'
import HomeList from './HomeList'

export default class HomeContent extends Lightning.Component {
  static _template() {
    return {
      w: 1920,
      h: 1080,
      List: {
        y: 217,
        w: 1920,
        h: 1080,
        type: HomeList,
        autoResize: true,
        direction: 'column',
        scroll: {
          after: 2,
          jump: 1,
          forward: 0.9,
          backward: 0.1,
        },
        signals: { onIndexChanged: true },
        scrollTransition: { duration: 0.3, timingFunction: 'cubic-bezier(.33, 1, .68, 1)' },
      },
      BackToTop: { type: BackToTop },
      Fade: {
        y: 0,
        x: 0,
        w: 1920,
        h: 1080,
        rect: true,
        color: Colors(COLORS.BACKGROUND_GRADIENT_TOP).get(),
        zIndex: 9999,
        alpha: 1,
        Gradient: {
          w: 1920,
          h: 270,
          x: 0,
          y: 810,
          rect: true,
          colorTop: Colors(COLORS.BACKGROUND_GRADIENT_TOP).get(),
          colorBottom: Colors(COLORS.BACKGROUND_GRADIENT_BOTTOM).get(),
        },
        transitions: { alpha: { duration: 1 } },
      },
    }
  }

  set indexOnReturn(indexOnReturn) {
    this._indexOnReturn = indexOnReturn
  }

  get indexOnReturn() {
    return typeof this._indexOnReturn === 'object' ? this._indexOnReturn : undefined
  }

  set liveContainerVisible(liveContainerVisible) {
    this._liveContainerVisible = liveContainerVisible
    this.tag('List').liveContainerVisible = liveContainerVisible
  }

  get liveContainerVisible() {
    return typeof this._liveContainerVisible === 'boolean' ? this._liveContainerVisible : false
  }

  //Changes state to Loading, to stop user interaction
  set isLoading(isLoading) {
    this._setState(isLoading ? 'Loading' : 'List')
    this._isLoading = isLoading
  }

  get isLoading() {
    return this._isLoading
  }

  //Changes state to UpdatingLive, to stop user interaction
  set isUpdatingLive(isUpdatingLive) {
    this._setState(isUpdatingLive ? 'UpdatingLive' : 'List')
    this._isUpdatingLive = isUpdatingLive
  }

  get isUpdatingLive() {
    return this._isUpdatingLive
  }

  set data(data) {
    this.tag('List').clear()
    if (data.hasData) {
      this._data = data
      if (this._data.callInfo.length) {
        this.isLoading = true
        this._contentData.loadHomeData().then(containsLive => {
          this.signal('onDataFetch')
          this.updateListYPosition(containsLive)
          this.isLoading = false
        })
      }
    } else {
      this.signal('onDataFetch')
      Log.warn('HomeContent.js - items, no data ', data)
    }
    // Reset menu position
    getWidget('Header').trackHeader(undefined)
  }

  get data() {
    return this._data ? this._data : undefined
  }

  set index({ list = undefined, cell = undefined }) {
    if (isNumber(list)) this._index.list = list
    if (isNumber(cell)) this._index.cell = cell
  }

  get index() {
    return this._index
  }

  $getHomeIndex() {
    return this._index
  }

  _init() {
    this._index = { list: 0, cell: 0 }
    this._contentRenderer = new ContentRenderer(this)
    this._contentData = new ContentData(this)
  }

  $onLiveContainerUpdate({ status, template }) {
    if (status === 'start') {
      //block user interaction
      this.isUpdatingLive = true
    } else if (status === 'end') {
      //release user interaction
      if (template === 'empty') {
        this.updateListYPosition(false)
        this.liveContainerVisible = false
        if (this.indexOnReturn !== undefined) {
          this.setListFocusIndex(this.indexOnReturn)
        } else {
          this.tag('List').setIndex(1) //Focus on 1
        }
        this.isUpdatingLive = false
      } else if (template === 'featured' || template === 'list' || template === 'featuredAndList') {
        this.updateListYPosition(true)
        this.liveContainerVisible = true
        if (this.indexOnReturn !== undefined) {
          this.setListFocusIndex(this.indexOnReturn)
        } else {
          this.tag('List').setIndex(0) //Focus on 0
        }
        this.isUpdatingLive = false
      }
    }
  }

  $scrollList(pixels) {
    if (this.tag('List').listLength > 0) {
      getWidget('Header').trackHeader(this.tag('List').childList._items[0], this._listStartPosition)
    }
    this.tag('List').nudgeByPixels(pixels)
  }

  $cellIndexChanged(cellIndex) {
    if (isNumber(cellIndex)) {
      this.index = { cell: cellIndex }
      this.signal('homeContentIndexChanged', this._index, this.liveContainerVisible)
    }
  }

  _getLiveCallAsset(callInfo) {
    if (callInfo !== undefined) {
      return callInfo.find(e => {
        return e.id === API.CALLID.LIVESTREAM
      })
    } else {
      return false
    }
  }

  fadeOut(fadeOut) {
    if (fadeOut !== true) {
      this.tag('Fade').patch({
        alpha: 1,
      })
    } else {
      this.tag('Fade').patch({
        smooth: { alpha: 0.0001 },
      })
    }
  }

  getLiveContainer() {
    const renderedContent = this._contentRenderer.getRenderedComponents()
    const liveComponent = renderedContent.find(list => list.callAsset.id === API.CALLID.LIVESTREAM)
    return liveComponent ? liveComponent : undefined
  }

  setListFocusIndex({ list, cell }) {
    if (!this.liveContainerVisible && list === 0) {
      list = 1
    }
    this.tag('List').setIndex(list)
    this.tag('List').items[list].index = cell
  }

  // When index changes, render and unload rows
  onIndexChanged({ index, previousIndex }) {
    this.index = { list: index }
    this.indexOnReturn = undefined //Reset return index once index is being changed

    const liveContainer = this.getLiveContainer()
    if (liveContainer) {
      liveContainer.homeContentIndex = index
    }

    if (this.liveContainerVisible) {
      if (index === 1 && previousIndex < index) {
        this.tag('BackToTop').show = true
      } else if (index === 0) {
        this.tag('BackToTop').show = false
      }
    } else {
      if (index === 2 && previousIndex < index) {
        this.tag('BackToTop').show = true
      } else if (index === 1) {
        this.tag('BackToTop').show = false
      }
    }

    if (index !== previousIndex) {
      if (index > previousIndex) {
        if (index > 0) {
          if (this.tag('List').listLength > 0) {
            getWidget('Header').trackHeader(
              this.tag('List').childList._items[0],
              this._listStartPosition
            )
          }
        } else {
          getWidget('Header').trackHeader(undefined)
        }
      } else {
        if (index <= 0) {
          if (this.tag('List').listLength > 0) {
            getWidget('Header').trackHeader(
              this.tag('List').childList._items[0],
              this._listStartPosition
            )
          }
        } else {
          getWidget('Header').trackHeader(undefined)
        }
      }
    }

    if (isObjectWithLength(this.index)) {
      this.signal('homeContentIndexChanged', this.index, this.liveContainerVisible)
    }
  }

  updateListYPosition(hasLive) {
    this._listStartPosition = hasLive ? 220 : 217
    this.tag('List').y = this._listStartPosition
  }

  static _states() {
    return [
      class Loading extends this {
        _handleLeft() {
          //Block interaction
          return true
        }

        _handleRight() {
          //Block interaction
          return true
        }

        _handleUp() {
          //Block interaction
          return true
        }

        _handleDown() {
          //Block interaction
          return true
        }

        _handleEnter() {
          //Block interaction
          return true
        }

        _handleBack() {
          //Block interaction
          return true
        }

        $enter() {
          this.fadeOut(false)
        }

        $exit() {
          this.fadeOut(true)
        }
      },
      class UpdatingLive extends this {
        _handleLeft() {
          //Block interaction
          return true
        }

        _handleRight() {
          //Block interaction
          return true
        }

        _handleUp() {
          //Block interaction
          return true
        }

        _handleDown() {
          //Block interaction
          return true
        }

        _handleEnter() {
          //Block interaction
          return true
        }

        _handleBack() {
          //Block interaction
          return true
        }
      },
      class List extends this {
        _handleBack() {
          if (this.liveContainerVisible) {
            if (this.index.list > 0) {
              this.tag('List').first()
              if (
                this.tag('List').childList._items[0] &&
                this.tag('List').childList._items[0].liveTemplate === 'featuredAndList'
              )
                this.tag('List').childList._items[0].refocusToFeature()
            } else {
              return false
            }
          } else {
            if (this.index.list > 1) {
              this.tag('List').setIndex(1)
            } else {
              return false
            }
          }
        }

        _getFocused() {
          return this.tag('List')
        }
      },
    ]
  }
}
