import { Lightning, Router, Language, Colors } from '@lightningjs/sdk'
import { List } from '@lightningjs/ui'
import { getWidget } from '../utils/tools'
import VideoList from '../components/Home/VideoList'
import BackToTop from '../components/Home/BackToTop'
import { sendMetrics } from '../services/metrics'
import { BUTTON, GA4, PAGENAME } from '../constants/analytics'
import { COLORS } from '../constants/colors'
import { ROUTE } from '../constants/routes'

export default class TrendingTopics extends Lightning.Component {
  static _template() {
    return {
      x: 0,
      w: 1920,
      h: 1080,
      NoData: {
        x: 930,
        y: 540,
        mount: 0.5,
        alpha: 0,
        text: {
          text: Language.translate('no_data'),
          fontSize: 24,
          lineHeight: 32,
          maxLines: 1,
          fontFace: 'PoppinsRegular',
          wordWrapWidth: 1000,
          textColor: Colors(COLORS.LIGHTGREY).get(),
        },
      },
      List: {
        w: 1920,
        h: 440,
        y: 225,
        type: List,
        direction: 'column',
        signals: { onIndexChanged: true },
        scrollTransition: { duration: 0.3 },
      },
      BackToTop: { type: BackToTop },
    }
  }

  get List() {
    return this.tag('List')
  }

  get BackToTop() {
    return this.tag('BackToTop')
  }

  get NoData() {
    return this.tag('NoData')
  }

  set data(data) {
    this._data = data
  }

  _init() {
    this._index = { list: 0, cell: 0 }
  }

  _active() {
    this._renderData()
  }

  _handleBack() {
    sendMetrics(GA4.CLICK, { button: BUTTON.BACK, page_title: PAGENAME.TRENDINGTOPICS })
    getWidget('Header').trackHeader(undefined, 0)
    // this._index.list > 0 ? this.List.first() : Router.back()

    if (this._index.list > 0) {
      this.List.first()
    } else {
      const historyLength = Router.getHistory() ? Router.getHistory().length : 0
      if (historyLength > 0) {
        Router.back()
      } else {
        if (Router.getActiveRoute() === ROUTE.HOME) {
          Router.back()
        } else {
          Router.navigate(ROUTE.HOME)
        }
      }
    }
  }

  historyState(params) {
    if (!params) {
      return {
        focusIndex: this._index,
      }
    } else {
      this._index = params.focusIndex
      setTimeout(() => {
        this._setListFocusIndex(this._index)
      }, 1000)
    }
  }

  _renderData() {
    if (this._data.length) {
      this.tag('List').items = this._data.map(item => {
        return {
          type: VideoList,
          title: item.title,
          data: item.items,
        }
      })
      this.List.setIndex(this._index.list)
      this.List.items[this._index.list].index = this._index.cell
      //Move header and backgroung based on list cornerPoints
      let cornerPoints = this.List.wrapper.core.getCornerPoints()
      this._listStartPosition = cornerPoints[1]
      if (this._index.list === 0) {
        getWidget('Header').trackHeader(undefined, 0)
        getWidget('Background').startPosition()
      } else {
        this._showBackToTop(this._index.list > 0)
        getWidget('Header').trackHeader(this.List.wrapper, this._listStartPosition)
      }
      this._setState('List')
    } else {
      this._setState('NoData')
    }
  }

  _setListFocusIndex({ list, cell }) {
    this.List.setIndex(list)
    this.List.items[this._index.list].index = cell
  }

  _showBackToTop(value) {
    this.BackToTop.show = value
  }

  $cellIndexChanged(cell) {
    this._index.cell = cell
  }

  onIndexChanged({ index, previousIndex }) {
    if (index !== previousIndex) {
      this._index.list = index
      getWidget('Background').moveBackground(index)
      if (index > 0) {
        this._showBackToTop(true)
        getWidget('Header').trackHeader(this.List.wrapper, this._listStartPosition)
      } else {
        this._showBackToTop(false)
        getWidget('Header').trackHeader(undefined)
      }
    }
  }

  static _states() {
    return [
      class List extends this {
        _getFocused() {
          return this.List
        }

        _handleUp() {
          this._setState('Inactive')
        }
      },
      class NoData extends this {
        $enter() {
          this.NoData.alpha = 1
        }

        _focus() {
          Router.focusWidget('Header')
        }

        $exit() {
          this.NoData.alpha = 0
        }
      },
      class Inactive extends this {
        $enter() {
          Router.focusWidget('Header')
        }

        _focus() {
          if (!Router.isNavigating()) {
            this._setState('List')
          }
        }
      },
    ]
  }
}
