import { Metrics } from '@firebolt-js/sdk'

const originalFetch = window.fetch

export const fireboltMetricsStart = () => {
  window.addEventListener('error', errorHandler)
  window.fetch = async (...args) => {
    let response = undefined
    try {
      response = await originalFetch(...args)
    } catch (e) {
      response = { status: 999, statusText: 'offline' }
    }

    if (response && response.status && 400 <= response.status) {
      Metrics.error(
        Metrics.ErrorType.NETWORK,
        response.status.toString(),
        response.statusText,
        false,
        {
          url: response.url,
        }
      )
    }
    return response
  }
}

const errorHandler = error => {
  Metrics.error(Metrics.ErrorType.OTHER, 'runtime', error.message, false, {
    message: error.message,
    line: error.lineno,
    column: error.colno,
    stack: (error.error && error.error.stack) || error.error,
  })
}

export const fireboltMetricsStop = () => {
  window.fetch = originalFetch
  window.removeEventListener('error', errorHandler)
}
