import { Router, VideoPlayer } from '@lightningjs/sdk'
import { Accessibility, Metrics } from '@firebolt-js/sdk'
import Hls from 'hls.js'
import { playerPolymorph } from '../utils/player'
import { CAPTION_TYPE, TRACK_MODE } from '../constants/videoapp'
import { sendMetrics } from '../services/metrics'
import { BUTTON, GA4 } from '../constants/analytics'
import CCTextTexture from './CCTextTexture'
import { getColorCodeFromString } from '../utils/cspan'
import { fireboltVideoMetrics } from '../services/firebolt_video_metrics'

const CUE_LINE_SMALL = 45
const CUE_LINE_MEDIUM = 25
const CUE_LINE_LARGE = 15
const CUE_LINE_EXTRALARGE = 10

const FONTSIZE_SMALL = 11
const FONTSIZE_MEDIUM = 20
const FONTSIZE_LARGE = 29
const FONTSIZE_EXTRALARGE = 42

const DEFAULT_FONTSIZE = 20
const DEFAULT_BG = '#000000'
const DEFAULT_FONT_COLOR = '#ffffff'

const DEFAULT_FONT_STYLES = {
  fontSize: DEFAULT_FONTSIZE,
  backgroundColor: DEFAULT_BG,
  fontColor: DEFAULT_FONT_COLOR,
}

export default class VideoApp extends Router.App {
  static _template() {
    return {
      ...super._template(),
      ClosedCaptions: {
        flex: {
          direction: 'column',
        },
        zIndex: -1,
        w: 1920,
        h: 1080,
        y: 800,
      },
    }
  }

  get ClosedCaptions() {
    return this.tag('ClosedCaptions')
  }

  set isLiveStream(isLiveStream) {
    this._isLiveStream = isLiveStream
  }

  get isLiveStream() {
    return this._isLiveStream
  }

  get isPlaying() {
    this._playerTag = document.getElementById('video-player')
    let isPlaying = false
    if (this._playerTag) {
      isPlaying = !!(
        this._playerTag.currentTime > 0 &&
        !this._playerTag.paused &&
        !this._playerTag.ended &&
        this._playerTag.readyState > 2
      )
    }
    return isPlaying
  }

  _init() {
    this._prevCCMode = false
    this._ccCheckCounter = 0
    this._displayTracks = false
    this._renderNatively = false
    this._css = undefined
    this._cues = []
    this._firstTimePlay = false
    this._videoPlayer = null
    this._captiontype = ''
    this._ccStatus = TRACK_MODE.HIDDEN
    VideoPlayer.consumer(this)
    this.playHls()
  }

  set firstTimePlay(value) {
    this._firstTimePlay = value
  }
  get firstTimePlay() {
    return this._firstTimePlay
  }

  _hls(url) {
    return new URL(url).pathname.endsWith('.m3u8')
  }

  _handleBack(e) {
    this._displayTracks = false
    this._renderNatively = false
    this._captiontype = ''
    this.endHlsPlayer()
    e.preventDefault()
    e.stopPropagation()
  }

  $videoPlayerEvent(event) {
    if (event === 'TimeUpdate' && this._ccStatus === TRACK_MODE.SHOWING) {
      if (this._cues.length) this._updateSubtitles()
      else if (this._styles && this._renderNatively) this._updateCC()
    }
    //Emit needs to be unique for each listener
    this.application.emit('global_playerEvent_video', event)
    this.application.emit('global_playerEvent_stillWatching', event)
    this.application.emit('global_playerEvent_liveFeaturedCard', event)
  }

  _updateCC() {
    this._videoElement = document.getElementById('video-player')
    for (let i = 0; i < this._videoElement.textTracks.length; i++) {
      if (this._videoElement.textTracks[i].kind !== 'metadata') {
        const fontSize = this._styles.fontSize

        const cueLine =
          fontSize <= FONTSIZE_SMALL
            ? CUE_LINE_SMALL
            : fontSize <= FONTSIZE_MEDIUM
            ? CUE_LINE_MEDIUM
            : fontSize <= FONTSIZE_LARGE
            ? CUE_LINE_LARGE
            : CUE_LINE_EXTRALARGE
        this._changeTextPosition(cueLine, this._videoElement.textTracks[i].cues)
      }
    }
  }

  _changeTextPosition(line, cues) {
    if (cues && cues.length) {
      for (let j = 0; j < cues.length; j++) {
        cues[j].line = line
      }
    }
  }

  _updateSubtitles() {
    this.stage.gc()
    let captionInfo = this._getCaptionByTimeIndex(this._cues, this.getCurrentTime())
    if (captionInfo) {
      this._updateCCTexture(captionInfo.payLoad || '')
    }
  }

  _getCaptionByTimeIndex(captionList, timeIndex) {
    let currentCaptionPayload = ''
    if (captionList && captionList.length) {
      if (Number(timeIndex) <= Number(captionList[captionList.length - 1].startTime)) {
        for (let i = 0; i < captionList.length; i++) {
          if (
            Number(captionList[i].startTime) <= Number(timeIndex) &&
            Number(captionList[i].endTime) > Number(timeIndex)
          ) {
            let payload = captionList[i].text
            currentCaptionPayload += '\n' + payload
          } else if (Number(captionList[i].startTime) > Number(timeIndex)) {
            break
          }
        }
        return {
          payLoad: currentCaptionPayload,
        }
      }
    }
  }

  _initCCAccessibility() {
    Accessibility.closedCaptionsSettings().then(ccSettings => {
      this._styles = ccSettings.enabled ? ccSettings.styles : DEFAULT_FONT_STYLES
      if (this._styles) this._changeSubTitleFS()
      this._closedCaptions()
    })
    Accessibility.listen('closedCaptionsSettingsChanged', ccSettings => {
      this._styles = ccSettings.enabled ? ccSettings : DEFAULT_FONT_STYLES
      if (this._styles) {
        if (typeof this._styles.fontSize === 'string') {
          const fontSize = this._styles.fontSize
          this._styles.fontSize =
            fontSize === 'SMALL'
              ? FONTSIZE_SMALL
              : fontSize === 'MEDIUM'
              ? FONTSIZE_MEDIUM
              : fontSize === 'LARGE'
              ? FONTSIZE_LARGE
              : FONTSIZE_EXTRALARGE
        }
        this._changeSubTitleFS()
      }
      this._closedCaptions()
    }).then(number => {
      this._listenerID = number
    })
  }

  _changeSubTitleFS() {
    this._subtitleFontSize =
      this._styles.fontSize +
      (this._styles.fontSize > 25 ? (this._styles.fontSize > 35 ? 20 : 17) : 10)
  }

  _clearAccessibility() {
    Accessibility.clear(this._listenerID)
  }

  _updateCCTexture(ccData) {
    ccData = ccData.split('\n').filter(data => data !== '')
    this.ClosedCaptions.childList.clear()
    this.ClosedCaptions.children = ccData.map(item => {
      return {
        w: 1920,
        flexItem: {},
        type: CCTextTexture,
        text: item,
        fontSize: this._styles ? this._subtitleFontSize : DEFAULT_FONTSIZE,
        backgroundColor: this._styles ? this._styles.backgroundColor : DEFAULT_BG,
        textColor: this._styles ? this._styles.fontColor : DEFAULT_FONT_COLOR,
      }
    })
  }

  _closedCaptions() {
    if (this._styles) {
      if (this._styles.fontSize < 10) this._styles.fontSize = 20
      this._styles.backgroundColor = getColorCodeFromString(this._styles.backgroundColor)
      this._styles.fontColor = getColorCodeFromString(this._styles.fontColor)

      if (!this._css) {
        this._css = document.createElement('style')
        this._css.type = 'text/css'
        this._css.innerHTML = `::cue { font-size: ${this._styles.fontSize}px;background-color:${this._styles.backgroundColor};color:${this._styles.fontColor} }`
        document.body.appendChild(this._css)
      } else {
        this._css.innerHTML = `::cue { font-size: ${this._styles.fontSize}px;background-color:${this._styles.backgroundColor};color:${this._styles.fontColor}  }`
      }
    } else {
      if (this._css)
        this._css.innerHTML = `::cue { font-size: ${DEFAULT_FONTSIZE}px;background-color:${DEFAULT_BG};color:${DEFAULT_FONT_COLOR} }`
    }
  }

  endHlsPlayer() {
    if (this._updateOnSubtitles) clearInterval(this._updateOnSubtitles)
    this._ccCheckCounter = 0
    this._cues = []
    this.ClosedCaptions.childList.clear()
    this._captiontype = ''
    this._isPaused = false
    VideoPlayer.close()
    Metrics.stopContent(fireboltVideoMetrics.getEntityId())
    if (this._videoPlayer) this.closeHls()
  }

  closeHls() {
    this.updateCCTrackListener(TRACK_MODE.HIDDEN)
    if (this._videoPlayer) {
      this._videoPlayer.destroy()
    }
    this._videoPlayer = null
  }

  replay() {
    if (this._videoUrl) {
      this.playerOpen({ url: this._videoUrl })
    }
  }

  playerOpen({
    url = '',
    islive = false,
    isHomePlayer = false,
    scope = undefined,
    resetFlagValues = true,
  }) {
    if (resetFlagValues) this.resetFlags()
    fireboltVideoMetrics.setEntityId(url) //Update url as id in firebolt_video_metrics
    Metrics.startContent(fireboltVideoMetrics.getEntityId())
    this._ccCheckCounter = 0
    this._cues = []
    this._styles = undefined
    this._initCCAccessibility()
    this._scope = scope
    this.isHomePlayer(isHomePlayer, scope)
    this._videoUrl = url
    this.isLiveStream = islive
    VideoPlayer.clear()
    this.updateCCTrackListener(TRACK_MODE.HIDDEN)
    this._closedCaptions()

    VideoPlayer.open(url)
  }

  isHomePlayer(isHomePlayer = false, scope = undefined) {
    this._isHomePlayer = isHomePlayer
    const precision = this.stage.getRenderPrecision()
    playerPolymorph.updatePrecision(precision)
    playerPolymorph.updateTag()
    if (isHomePlayer) {
      playerPolymorph.onTop(true)
      playerPolymorph.size(690, 388)
      playerPolymorph.roundedCorners({ radius: 15 })
      if (scope) playerPolymorph.trackPosition(scope)
    } else {
      playerPolymorph.reset()
    }
  }

  stopToContinue() {
    this._continueTime = VideoPlayer.currentTime
    setTimeout(() => {
      VideoPlayer.close()
    }, 1000)
  }

  continue() {
    if (this.isLiveStream) {
      this.replay()
    } else {
      this.replay()
      setTimeout(() => {
        if (this._continueTime) {
          VideoPlayer.seek(this._continueTime)
        }
      }, 1500)
    }
  }

  getCurrentTime() {
    return VideoPlayer.currentTime
  }

  getVideoDuration() {
    return VideoPlayer.duration
  }

  stop() {
    clearInterval(this._updateOnSubtitles)
    VideoPlayer.close()
  }

  seek(time) {
    VideoPlayer.seek(time)
  }

  skip(time) {
    VideoPlayer.skip(time)
  }

  pause() {
    VideoPlayer.pause()
  }

  play() {
    VideoPlayer.play()
  }

  pressPlay() {
    sendMetrics(GA4.CLICK, { button: BUTTON.PLAY })
    VideoPlayer.playPause()
  }

  clear() {
    VideoPlayer.clear()
  }
  eventStopper(e) {
    if (e) {
      e.preventDefault()
      e.stopPropagation()
    }
  }

  emitCaptionInformation(CCKey) {
    //Emit needs to be unique for each listener
    this.application.emit('caption_Key', CCKey)
    this.application.emit('caption_Key_tvNet', CCKey)
  }

  getCCStatus() {
    return this._ccStatus
  }
  /*
  If ccMode is showing, then display either a subtitle or caption based on the this._captiontype
  */
  updateCCTrackListener(ccMode) {
    this.ClosedCaptions.childList.clear()
    if (ccMode === TRACK_MODE.SHOWING) this._closedCaptions()
    this._videoElement = document.getElementById('video-player')
    for (let i = 0; i < this._videoElement.textTracks.length; i++) {
      const track = this._videoElement.textTracks[i]
      track.mode =
        ccMode === TRACK_MODE.HIDDEN
          ? ccMode
          : this._captiontype === track.kind
          ? ccMode
          : TRACK_MODE.HIDDEN
    }
    this._ccStatus = ccMode
  }
  resetFlags() {
    this._prevCCMode = false
    this._renderNatively = false
    this._displayTracks = false
    this.firstTimePlay = true
  }
  showTracks(ccMode) {
    this._videoElement = document.getElementById('video-player')
    for (let i = 0; i < this._videoElement.textTracks.length; i++) {
      if (this._videoElement.textTracks[i].kind === 'subtitles') {
        const track = this._videoElement.textTracks[i]
        track.mode = ccMode
      }
    }
  }

  playHls() {
    VideoPlayer.loader((url, videoEl) => {
      return new Promise(resolve => {
        if (
          this._videoPlayer &&
          this._videoPlayer.destroy &&
          typeof this._videoPlayer.destroy === 'function'
        ) {
          this._videoPlayer.destroy()
        }

        if (this._hls(url)) {
          this._videoPlayer = new Hls({
            // backBufferLength: 90, //Original
            debug: false,
            autoStartLoad: true,
            maxBufferHole: 10,
            highBufferWatchdogPeriod: 10,
            maxBufferSize: 20 * 1000 * 1000,
            maxBufferLength: 60,
            stretchShortVideoTrack: true,
            nudgeOffset: 0.6, // default: 0.2
            nudgeMaxRetry: 8, // fix buffer stalled error
            enableWorker: false,
            enableWebVTT: this._renderNatively,
            renderTextTracksNatively: this._renderNatively,
          })

          this._videoPlayer.autoLevelCapping = 4
          fetch(url).then(() => this._videoPlayer.loadSource(url))
          this._videoPlayer.attachMedia(videoEl)
          this._videoPlayer.on(Hls.Events.MANIFEST_PARSED, () => {
            resolve()
          })

          this._videoPlayer.on(Hls.Events.MANIFEST_LOADED, (event, data) => {
            if (
              (data.captions && data.captions.length > 0) ||
              (data.subtitles && data.subtitles.length > 0)
            ) {
              if (data.captions && data.captions.length > 0) {
                this._captiontype = CAPTION_TYPE.CAPTION
              } else if (data.subtitles && data.subtitles.length > 0) {
                this._captiontype = CAPTION_TYPE.SUBTITLE
              } else {
                this._captiontype = ''
              }
              this.emitCaptionInformation(true)
            }
          })
          this._videoPlayer.on(Hls.Events.FRAG_LOADED, () => {
            if (
              this._captiontype === CAPTION_TYPE.SUBTITLE &&
              this._cues.length === 0 &&
              this._displayTracks
            ) {
              /*
                After video Reloaded, cues will take some time to load
              */
              setTimeout(() => {
                this.updateCCTrackListener(this._prevCCMode ? TRACK_MODE.SHOWING : this._ccStatus)
              }, 2000)
              this.showTracks(TRACK_MODE.SHOWING)
              this._displayTracks = false
            }
            if (
              this._captiontype === CAPTION_TYPE.SUBTITLE &&
              this._cues.length === 0 &&
              this.firstTimePlay
            ) {
              /*
                renderTextTracksNatively:false, If cues are not available then reload the video.
                Before reloading video, check if CC button is enabled or not.
                If CC button is enabled, then update this._prevCCMode value to true(TRACK_MODE.SHOWING)
              */
              if (!this._prevCCMode && this._ccStatus === TRACK_MODE.SHOWING)
                this._prevCCMode = true

              this._renderNatively = true
              this.endHlsPlayer()
              this._captiontype = CAPTION_TYPE.SUBTITLE
              this.playerOpen({
                url: this._videoUrl,
                islive: this.isLiveStream,
                isHomePlayer: this._isHomePlayer,
                scope: this._scope,
                resetFlagValues: false,
              })
              this._displayTracks = true
              this.firstTimePlay = false
            }
          })
          this._videoPlayer.on(Hls.Events.CUES_PARSED, (event, data) => {
            if (data.cues) {
              data.cues.forEach(cue => {
                this._cues.push(cue)
                this._cues = this._cues.sort((cue1, cue2) => {
                  const cue1Data = new Date(cue1.startTime)
                  const cue2Data = new Date(cue2.startTime)
                  return cue1Data - cue2Data
                })
              })
            }
          })

          this._videoPlayer.on(Hls.Events.ERROR, (event, data) => {
            if (data.fatal) {
              switch (data.type) {
                case Hls.ErrorTypes.MEDIA_ERROR:
                  Metrics.error(Metrics.ErrorType.MEDIA, 'MEDIA-STALLED', 'playback stalled', false)
                  this._videoPlayer.recoverMediaError()
                  break
                case Hls.ErrorTypes.NETWORK_ERROR:
                  this._videoPlayer.startLoad()
                  break

                default:
                  this._videoPlayer.destroy()
                  break
              }
            }
          })
        } else {
          videoEl.setAttribute('src', url)
          videoEl.load()
          resolve()
        }
      })
    })

    VideoPlayer.unloader(videoEl => {
      return new Promise(resolve => {
        if (
          this._videoPlayer &&
          this._videoPlayer.destroy &&
          typeof this._videoPlayer.destroy === 'function'
        ) {
          this._videoPlayer.destroy()
        }
        videoEl.removeAttribute('src')
        videoEl.load()

        resolve()
      })
    })
  }
}
