import { isNumber, isStringWithLength } from '../../utils/checks'
import { adjustToItalic } from '../../utils/cspan'

export default class TrendingItemAsset {
  constructor(trendingItem) {
    this._bookImagePath = isStringWithLength(trendingItem.bookImagePath)
      ? trendingItem.bookImagePath
      : undefined
    this._id = isNumber(trendingItem.id) ? trendingItem.id : 0
    this._publicId = isStringWithLength(trendingItem.publicId) ? trendingItem.publicId : undefined
    this._title = isStringWithLength(trendingItem.title) ? adjustToItalic(trendingItem.title) : ''
    this._description = isStringWithLength(trendingItem.description)
      ? adjustToItalic(trendingItem.description)
      : ''
    this._imagePath = isStringWithLength(trendingItem.imagePath)
      ? trendingItem.imagePath
      : undefined
    this._date = isStringWithLength(trendingItem.date) ? trendingItem.date : undefined
    this._videoLink = isStringWithLength(trendingItem.videoLink)
      ? trendingItem.videoLink
      : undefined
    this._videoDuration = trendingItem.videoDuration
    this._itemType = isStringWithLength(trendingItem.itemType) ? trendingItem.itemType : ' '
  }

  get itemType() {
    return this._itemType
  }

  get bookImagePath() {
    return this._bookImagePath
  }

  get videoDuration() {
    return this._videoDuration
  }

  get videoLink() {
    return this._videoLink
  }

  get date() {
    return this._date
  }

  get imagePath() {
    return this._imagePath
  }

  get description() {
    return this._description
  }

  get title() {
    return this._title
  }

  get id() {
    return this._id
  }

  get publicId() {
    return this._publicId
  }
}
