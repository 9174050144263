import { Lightning, Colors, Language } from '@lightningjs/sdk'
import { COLORS } from '@/constants/colors'
import { FONTS } from '@/constants/fonts'
import { isStringWithLength } from '../../utils/checks'
import { FONTSTYLE } from '../../constants/fonts'

export default class Qrcode extends Lightning.Component {
  static _template() {
    return {
      DividerContent: {
        Divider1: {
          x: w => w / 2,
          mountX: 0.5,
          w: 2,
          h: 256,
          rect: true,
          color: Colors(COLORS.LIGHTGREY).get(),
        },
        Or: {
          y: 262,
          w: 58,
          h: 60,
          mountX: 0.5,
          text: {
            fontSize: 40,
            fontFace: FONTS.POPPINS.SEMIBOLD,
            lineHeight: 60,
            textColor: Colors(COLORS.DARKGREY_40).get(),
            verticalAlign: 'middle',
            textAlign: 'center',
            text: Language.translate('or'),
          },
        },
        Divider2: {
          x: w => w / 2,
          y: 340,
          mountX: 0.5,
          w: 2,
          h: 282,
          rect: true,
          color: Colors(COLORS.LIGHTGREY).get(),
        },
      },
      QRContent: {
        y: 8,
        x: 109,
        QRText: {
          text: {
            ...FONTSTYLE.H3,
            textColor: Colors(COLORS.WHITE).get(),
            verticalAlign: 'middle',
            maxLines: 1,
            textAlign: 'center',
            text: Language.translate('scan_qr'),
          },
        },

        QRCode: {
          y: 79,
          w: 396,
          h: 394,
        },
        UsePhone: {
          y: 505,
          w: 256,
          text: {
            fontSize: 24,
            fontFace: FONTS.POPPINS.REGULAR,
            lineHeight: 36,
            textColor: Colors(COLORS.DARKGREY_40).get(),
            verticalAlign: 'middle',
            maxLines: 2,
            textAlign: 'center',
            text: Language.translate('use_phone_to_scan'),
            wordWrapWidth: 256,
          },
        },
      },
    }
  }

  get QRCode() {
    return this.tag('QRCode')
  }

  updateQR(data) {
    if (!(data && isStringWithLength(data))) return
    // eslint-disable-next-line prettier/prettier
		this.QRCode.src = `https://cdn.metrological.com/qr?q=${encodeURIComponent(
			data
		)}`
  }
}
