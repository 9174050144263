export const isNumber = nr => {
  return typeof nr === 'number' && !isNaN(nr)
}

export const isStringWithLength = text => {
  return !!(text && typeof text === 'string' && text.length > 0)
}

export const isArrayWithLength = arr => {
  return !!(arr && Array.isArray(arr) && arr.length > 0)
}

export const isObjectWithLength = obj => {
  return !!(typeof obj === 'object' && Object.keys(obj).length > 0)
}

export const isBoolean = bool => {
  return typeof bool === 'boolean'
}

export const stringHasSpacesStart = string => {
  let spaceAtEnd = /^\s+/gm
  return spaceAtEnd.test(string)
}

export const stringHasSpacesEnd = string => {
  let spaceAtEnd = /\s+$/gm
  return spaceAtEnd.test(string)
}

export const stringHasSpacesStartEnd = string => {
  let spaceAtEnd = /^\s+|\s+$/gm
  return spaceAtEnd.test(string)
}

export const isInIframe = () => {
  return window.self !== window.top
}

export const isObjectEqual = (object1, object2) => {
  if (typeof object1 === 'object' && Object.keys(object1).length > 0) {
    return (
      Object.keys(object1).length === Object.keys(object2).length &&
      Object.keys(object1).every(i => isObjectEqual(object1[i], object2[i]))
    )
  } else {
    return object1 === object2
  }
}

// Returns file extension from given link, example: '.mp4' or '.jpg' etc.
export const getFileExtension = (fileName = '') => {
  if (fileName && typeof fileName === 'string') {
    const regex = /\.([0-9a-z]+)(?=[?#])|(\.)(?:[\w]+)$/gim
    const extension = fileName.match(regex)

    if (extension && extension[0] && typeof extension[0] === 'string') {
      return extension[0]
    } else {
      return undefined
    }
  } else {
    return undefined
  }
}

export const removeDuplicateObjectsFromArray = arr => {
  return arr.filter((value, index, self) => {
    return index === self.findIndex(obj => isObjectEqual(obj, value))
  })
}

// returns value if value exist else returns fallback
export const tryGetValue = (fn, fallback = false) => {
  let value
  try {
    value = fn()
  } catch (e) {
    value = undefined
  }
  return value !== undefined ? value : fallback
}

export const generateReqId = () => {
  return Math.floor(new Date().getTime() / 1000)
    .toString()
    .replace(/[0-9]$/, 5)
}
