import { Lightning, Colors, Utils } from '@lightningjs/sdk'
// eslint-disable-next-line prettier/prettier
import {
	TYPE_LABEL,
	TYPE_ICON,
	SPACE_UNDERLINE
} from '@/constants/menu'
import { FONTS } from '@/constants/fonts'
import { COLORS } from '@/constants/colors'
import { voiceGuide } from '../../services/voiceGuide'

export default class MenuItem extends Lightning.Component {
  static _template() {
    return {
      Menu: {
        h: h => h,
        UnderLine: {
          y: h => h + SPACE_UNDERLINE,
          h: 4,
          color: Colors(COLORS.BACKGROUND_SERIES_BOTTOM_ACTIVE).get(),
          alpha: 0.0001,
        },
      },
    }
  }

  static getAssetUrl(basePath, name) {
    return `${basePath}${name}.png`
  }

  set label(str) {
    this._label = str
  }

  get label() {
    return this._label
  }

  set icon(icon) {
    this._icon = icon
  }

  get icon() {
    return this._icon
  }

  set pageIndicator(isSelected) {
    this._isActivePageIndicator = isSelected
    this.toggled = isSelected
    isSelected ? this.showHideUnderline(1, 1) : this.showHideUnderline(0)
  }

  get Menu() {
    return this.tag('Menu')
  }

  get UnderLine() {
    return this.tag('Menu.UnderLine')
  }

  set speechText(speechText) {
    this._speechText = speechText
  }

  get speechText() {
    return this._speechText || ''
  }

  _init() {
    this._isActivePageIndicator = this.toggled
    if (this._isActivePageIndicator) {
      this.showHideUnderline(1, 1)
    }
    this.createTemplates()
  }

  _focus() {
    if (this.menuType === TYPE_LABEL) {
      this.changeMenuColor(COLORS.LIGHTGREY, FONTS.POPPINS.BOLD)
    }
    if (this.menuType === TYPE_ICON) {
      this.updateIconToActive(true)
    }
    this.updateUnderline(COLORS.LIGHTGREY)
    if (this._isActivePageIndicator) {
      this.showHideUnderline(1, 1)
    }

    voiceGuide.utterThis(this.speechText || '')
  }

  _unfocus() {
    if (this.menuType === TYPE_LABEL) {
      this.changeMenuColor(COLORS.DARKGREY_60, FONTS.POPPINS.SEMIBOLD)
    }
    if (this.menuType === TYPE_ICON) {
      this.updateIconToActive(false)
    }
    this.updateUnderline(COLORS.BACKGROUND_SERIES_BOTTOM_ACTIVE)
    if (this._isActivePageIndicator) {
      this.showHideUnderline(1, 1)
    }
  }

  createTemplates() {
    if (this.menuType === TYPE_LABEL) {
      this.patch({
        Menu: {
          text: {
            fontSize: 28,
            fontFace: 'PoppinsSemiBold',
            textAlign: 'center',
            text: this.label,
          },
        },
      })
      this.changeMenuColor(COLORS.DARKGREY_60, FONTS.POPPINS.SEMIBOLD)
    } else if (this.menuType === TYPE_ICON) {
      this.patch({
        Menu: {
          w: w => w,
          src: Utils.asset(`images/icons/menu/${this._icon}-active.png`),
        },
      })
      this.updateIconToActive(false)
      this.updateUnderLineYPos()
    }

    this.updateWidth()
  }

  updateWidth() {
    //Use once, menu is created only once
    this.Menu.once('txLoaded', () => {
      let wid, xpos, itemX, mountX

      if (this.menuType === TYPE_LABEL) {
        wid = this.Menu.finalW - 20
        xpos = 10
        mountX = 0.5
        itemX = this.Menu.finalX + this.Menu.finalW / 2
      } else {
        wid = this.w
        xpos = 0
        mountX = 0
        itemX = this.Menu.finalX
      }

      this.patch({
        Menu: {
          x: itemX,
          mountX: mountX,
          UnderLine: {
            w: wid,
            x: xpos,
            rect: true,
            shader: { type: Lightning.shaders.RoundedRectangle, radius: 2 },
          },
        },
      })
    })
  }

  changeMenuColor(color, fontName) {
    this.patch({
      Menu: {
        color: Colors(color).get(),
        text: {
          fontSize: 28,
          fontFace: fontName,
          textAlign: 'center',
        },
      },
    })
  }

  updateIconToActive(active) {
    this.patch({
      Menu: {
        color: active ? Colors(COLORS.LIGHTGREY).get() : Colors(COLORS.DARKGREY_60).get(),
      },
    })
  }

  updateUnderLineYPos() {
    this.patch({
      Menu: {
        UnderLine: {
          w: w => w,
          y: y => y + 15,
        },
      },
    })
  }

  updateUnderline(color) {
    this.patch({
      Menu: {
        UnderLine: {
          color: Colors(color).get(),
        },
      },
    })
  }

  showHideUnderline(alphaValue, duration = 0) {
    if (alphaValue === 0) alphaValue = 0.0001
    this.UnderLine.setSmooth('alpha', alphaValue, { duration: duration })
  }
}
