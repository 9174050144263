import { isBoolean, isNumber, isStringWithLength } from '../../utils/checks'
import { adjustToItalic } from '../../utils/cspan'

export default class ProgramAsset {
  constructor(programItem) {
    this._date = isStringWithLength(programItem.date) ? programItem.date : undefined
    this._description = isStringWithLength(programItem.description)
      ? adjustToItalic(programItem.description)
      : undefined
    this._featured = isBoolean(programItem.featured) ? programItem.featured : false
    this._id =
      isNumber(programItem.id) || isStringWithLength(programItem.id) ? programItem.id : undefined
    this._image = isStringWithLength(programItem.image) ? programItem.image : undefined
    this._imagePath = isStringWithLength(programItem.imagePath) ? programItem.imagePath : undefined
    this._itemType = isStringWithLength(programItem.type)
      ? programItem.type
      : isStringWithLength(programItem.itemType)
      ? programItem.itemType
      : undefined
    this._liveBeginTime = isStringWithLength(programItem.liveBeginTime)
      ? programItem.liveBeginTime
      : undefined
    this._liveEndTime = isStringWithLength(programItem.liveEndTime)
      ? programItem.liveEndTime
      : undefined
    this._liveNetwork = isNumber(programItem.liveNetwork) ? programItem.liveNetwork : undefined
    this._title = isStringWithLength(programItem.title)
      ? adjustToItalic(programItem.title)
      : undefined
    this._videoDuration = isNumber(programItem.videoDuration)
      ? programItem.videoDuration
      : undefined
    this._videoLink = isStringWithLength(programItem.videoLink) ? programItem.videoLink : undefined
    this._videoFile = isStringWithLength(programItem.videoFile) ? programItem.videoFile : undefined
    this._isliveStream = !!(
      isStringWithLength(programItem.itemType) && programItem.itemType === 'event'
    )

    // Series
    this._publicId = isStringWithLength(programItem.publicId) ? programItem.publicId : undefined
  }

  get date() {
    return this._date
  }

  get description() {
    return this._description
  }

  get featured() {
    return this._featured
  }

  get id() {
    return this._id
  }

  get image() {
    return this._image
  }

  get imagePath() {
    return this._imagePath
  }

  get itemType() {
    return this._itemType
  }

  get liveBeginTime() {
    return this._liveBeginTime
  }

  get liveEndTime() {
    return this._liveEndTime
  }

  get liveNetwork() {
    return this._liveNetwork
  }

  get title() {
    return this._title
  }

  get videoDuration() {
    return this._videoDuration
  }

  get videoLink() {
    return this._videoLink
  }

  get videoFile() {
    return this._videoFile
  }

  get isliveStream() {
    return this._isliveStream
  }

  get publicId() {
    return this._publicId
  }
}
