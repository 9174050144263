const HEADERS = {
  DEFAULT: {
    Accept: 'application/json',
    'X-Api-Key': 'jKHkzrH9Ls93zYnb9JkQC5YQYBZsko7R2LYxDeXW',
  },
  IMAGE: {
    Accept: 'application/json',
    Authorization: 'Basic Y3NwYW5Ob3c6ZnVwdXhxcmY=',
  },
}

// Keep variable private
const _BASEURL = {
  DEFAULT: 'https://api.c-spanarchives.org/3.0/',
  DEFAULT_STAGE: 'https://api.c-spanarchives.org/app-stage/',
}

export const API = {
  BASEURL: {
    APP: _BASEURL.DEFAULT_STAGE, // Add or remove _STAGE to swap between live and dev environment
    IMAGE: 'https://ximage.c-spanvideo.org/',
    IMAGE_BUCKET: 'pictures.c-spanvideo.org', //alternatives: stills.c-spanarchves.org and stills.mw.c-spanvideo.org
  },
  ENDPOINT: {
    HOME: 'external/?requestKey=lightningHome.v2', //'external/?requestKey=lightningHome.test', //'external/?requestKey=lightningHome.v2',  //'external/?requestKey=lightningHome.v1',
    RANKSETS: 'ranksets',
    TAGS: 'tags',
    SERIES: 'series',
    SERIESLIST: 'external/?requestKey=lightning.series.v1',
    SERIESINFO: 'series',
    SEASONSINFO: 'series/seasons',
    VIDEOLIVE: 'events/protected',
    VIDEOVODCLIP: 'clips/protected',
    VIDEOVODPROGRAM: 'programs/protected',
    UPCOMMINGLIVE: 'schedule',
    SEARCH: 'lightning/search',
    SETTINGS: 'external/?requestKey=lightning.settings.v1',
    // TVNET: 'authorization/tve/?deviceid=ee6723b8-7ab3-462c-8d93-dbf61227998e&requestid=1674163760',
    TVNET: 'authorization/tve/',
    TVNETINFO: 'external/?requestKey=lightning.streams.test', //'external/?requestKey=lightning.streams.v1'
    TVNETTOKEN: 'external/?requestKey=',
    TVNETPROVIDER: 'mvpds/',
    NETWORKINFO: 'schedule/',
  },
  HEADERS: {
    DEFAULT: HEADERS.DEFAULT,
    IMAGE: HEADERS.IMAGE,
  },
  REQUESTOPTIONS: {
    method: 'GET',
    headers: HEADERS.DEFAULT,
    redirect: 'follow',
  },
  REQUESTOPTIONS_IMAGE: {
    method: 'GET',
    headers: HEADERS.IMAGE,
    redirect: 'follow',
  },
  CALLID: {
    LIVESTREAM: 'App WIV Live 2',
    FEATUREDCLIPS: 'App Featured Clips',
    LATESTPROGRAMS: 'App WIV',
    DOCUMENTARIES: 'App Documentaries',
    CATEGORIES: 'App Tags',
  },

  UNLINKOPTIONS: {
    method: 'DELETE',
    headers: HEADERS.DEFAULT,
    redirect: 'follow',
  },
}
