// eslint-disable-next-line prettier/prettier
import {
  isArrayWithLength,
  isObjectWithLength,
  isStringWithLength
} from './checks'
import { Log } from '@lightningjs/sdk'

//Extracts parameters from either Array or Object
export const extractParameters = parameters => {
  if (isStringWithLength(parameters)) {
    return parameters
  } else if (isArrayWithLength(parameters)) {
    return extractParamsFromArray(parameters)
  } else if (isObjectWithLength(parameters)) {
    return extractParamsFromObject(parameters)
  } else {
    Log.warn('extractParameters() - invalid parameters ', parameters)
    return ''
  }
}

// Input example [{a: 1},{b:2, c: 3}]
// Output example 'a=1&b=2&c=3'
export const extractParamsFromArray = (parameterArray = []) => {
  if (isArrayWithLength(parameterArray)) {
    let url = ''
    parameterArray.forEach(parameterObject => {
      if (parameterObject !== undefined) {
        Object.keys(parameterObject).forEach(key => {
          key = key.trim()
          let value = parameterObject[key]
          url = url + `&${key}=${value}`
        })
      }
    })
    return isStringWithLength(url) ? url.substring(1) : ''
  } else {
    return ''
  }
}

export const insertIntoArray = (array, insertIndex, itemToAdd) => {
  return array.reduce((previousValue, currentValue, index) => {
    if (index === insertIndex) {
      if (Array.isArray(itemToAdd)) {
        previousValue.push(...itemToAdd, currentValue)
      } else {
        previousValue.push(itemToAdd, currentValue)
      }
    } else {
      previousValue.push(currentValue)
    }
    return previousValue
  }, [])
}

// Input example {a: 1, b:2, c: 3}
// Output example 'a=1&b=2&c=3'
export const extractParamsFromObject = (parameterObject = {}) => {
  if (isObjectWithLength(parameterObject)) {
    let url = ''
    Object.keys(parameterObject).forEach(key => {
      if (key !== undefined) {
        key = key.trim()
        let value = parameterObject[key]
        url = url + `&${key}=${value}`
      }
    })
    return isStringWithLength(url) ? url.substring(1) : ''
  } else {
    return ''
  }
}

export const upperCaseFirst = text => {
  if (text && typeof text === 'string' && text.length > 0) {
    return text[0].toUpperCase() + text.slice(1)
  } else {
    return text
  }
}

export const generateUID = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, char => {
    let random = (Math.random() * 16) | 0
    let v = char === 'x' ? random : (random & 0x3) | 0x8
    return v.toString(16)
  })
}

// Removes undefined from array [22, 'hello world', undefined, {}] > [22, 'hello world', {}]
export const removeUndefinedFromArray = array => {
  return array.filter(item => item !== undefined)
}

export let appInstance = undefined

export const initAppInstance = scope => {
  appInstance = scope
}

export const getWidget = name => {
  if (
    appInstance &&
    appInstance.widgets &&
    appInstance.widgets.children &&
    isArrayWithLength(appInstance.widgets.children)
  ) {
    // eslint-disable-next-line prettier/prettier
    return (
      appInstance.widgets.children.find(
        e => e.ref.toLowerCase() === name.toLowerCase()
      ) || {})
  } else {
    return {}
  }
}

//Capitalize first letter of every words in a sentence
export const trimAndCapitalize = str => {
  //trim extra white spaces
  str = str.replace(/\s+/g, ' ').trim()
  return str
    .split(' ')
    .map(element => capitalizeFirstLetter(element))
    .join(' ')
}
export const capitalizeFirstLetter = str => {
  return str.charAt(0).toUpperCase() + str.slice(1)
}
