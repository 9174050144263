import { Lightning } from '@lightningjs/sdk'
import { Grid } from '@lightningjs/ui'

export default class SeriesGrid extends Lightning.Component {
  static _template() {
    return {
      SeriesGrid: {
        type: Grid,
        direction: 'column',
        scrollTransition: { duration: 0.3 },
        signals: {
          onIndexChanged: 'onIndexChanged',
        },
      },
    }
  }

  get SeriesGrid() {
    return this.tag('SeriesGrid')
  }

  get itemCount() {
    return this.SeriesGrid.length
  }

  set items({ items, focusIndex }) {
    if (Array.isArray(items)) {
      this._data = items
      this.SeriesGrid.items = items.map((item, index) => {
        return {
          type: this.itemType,
          alpha: index < this._columns ? 1 : this._alpha,
          useLocalImages: this._useLocalImages,
          item,
          index,
        }
      })
      focusIndex === 0
        ? this.setIndex(0)
        : setTimeout(() => {
            this.setIndex(focusIndex)
          }, 100)
      let cornerPoints = this.SeriesGrid.wrapper.core.getCornerPoints()
      this._listStartPosition = cornerPoints[1]
      //Scroll Header(Title or image) based on  list start position
      this.signal('scrollHeader', {
        lightningComponent: this.SeriesGrid.wrapper,
        startPos: this._listStartPosition,
      })
    }
  }

  set options(options) {
    this.itemType = options.itemType
    this._alpha = options.alpha
    this._columns = options.columns
    this._useLocalImages = options.useLocalImages ? options.useLocalImages : false
    this.SeriesGrid.patch({
      w: options.width,
      h: options.height,
      x: options.xVal,
      columns: options.columns,
      crossSpacing: options.crossSpacing,
      mainSpacing: options.mainSpacing,
    })
  }

  _init() {
    this._mainIndex = 0
  }

  _handleEnter() {
    this.signal('onItemSelection', {
      item: this.SeriesGrid.currentItem.item,
      focusIndex: this.SeriesGrid.index,
      mainIndex: this._mainIndex,
      previousMainIndex: this._previousMainIndex,
    })
  }

  _handleDown() {
    if (this._mainIndex < this._lines - 1) this.SeriesGrid.setIndex(this._data.length - 1)
  }

  _getFocused() {
    return this.SeriesGrid
  }

  _itemAt(index) {
    return this.SeriesGrid.items[index]
  }

  setIndex(index) {
    this.SeriesGrid.setIndex(typeof index === 'number' ? index : 0)
  }

  /**
   * Callback method which will be invoked whenever we scroll over the grid view
   * @param mainIndex row index of the current focused item
   * @param previousMainIndex row index of the previous focused item
   * @param lines number of rows available in the grid
   * @param index index of the current item
   */
  onIndexChanged({ mainIndex, previousMainIndex, lines, index }) {
    this._lines = lines
    if (mainIndex !== previousMainIndex) {
      this.signal('scrollHeader', {
        lightningComponent: this.SeriesGrid.wrapper,
        startPos: this._listStartPosition,
        mainIndex,
      })
      //set alpha value of mainIndex(focused row ) to 1  and other items to 0.6
      for (let count = 0; count < this._columns; count++) {
        const previousRowItem = this._itemAt(previousMainIndex * this._columns + count)
        if (previousRowItem) previousRowItem.alpha = this._alpha

        const currentRowItem = this._itemAt(mainIndex * this._columns + count)
        if (currentRowItem) currentRowItem.alpha = 1
      }
      if (this._mainIndex != mainIndex) {
        this._mainIndex = mainIndex
        this._previousMainIndex = previousMainIndex
        this.signal('onMainIndexChanged', {
          mainIndex: this._mainIndex,
          previousMainIndex: this._previousMainIndex,
          index,
        })
      }
    }
  }

  setFocusToFirstItem() {
    this.SeriesGrid.first()
  }
}
