import { Colors, Lightning, Router } from '@lightningjs/sdk'
import { COLORS } from '../../constants/colors'
import PromptButton from './PromptButton'
import { isArrayWithLength, isNumber, isStringWithLength } from '../../utils/checks'
import { appInstance } from '../../utils/tools'
import { ROUTE } from '../../constants/routes'
import { voiceGuide } from '../../services/voiceGuide'

export default class Prompt extends Lightning.Component {
  static _template() {
    return {
      w: 1920,
      h: 1080,
      alpha: 0.0001,
      flex: {
        direction: 'column',
        alignContent: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: 50,
        wrap: false,
      },
      zIndex: 999999,
      rect: true,
      color: Colors(COLORS.PLAYER_BG_GRADIENT)
        .alpha(0.9)
        .get(),
      Prompt: {
        w: 960,
        flex: {
          direction: 'column',
          justifyContent: 'flex-start',
          alignItems: 'center',
          wrap: false,
        },
        flexItem: { minHeight: 360, maxHeight: 520 },
        rect: true,
        color: Colors(COLORS.MIDNIGHT).get(),
        shader: {
          type: Lightning.shaders.RoundedRectangle,
          radius: 20,
        },
      },
      CountDownLabel: {
        w: 1920,
        flexItem: {
          marginTop: 40,
        },
        color: Colors(COLORS.DARKGREY_60).get(),
        text: {
          fontFace: 'PoppinsRegular',
          textAlign: 'center',
          fontSize: 28,
          wordWrapWidth: 760,
          lineHeight: 80,
          text: '',
          maxLines: 4,
        },
      },
    }
  }

  _init() {
    this._index = 0
  }

  set index(index) {
    this._index = index
  }

  get index() {
    return this._index
  }

  set onScreen(onScreen) {
    this._onScreen = onScreen
  }

  get onScreen() {
    return typeof this._onScreen === 'boolean' ? this._onScreen : false
  }

  set countDownAction(countDownAction) {
    this._countDownAction = countDownAction
  }

  get countDownAction() {
    return typeof this._countDownAction === 'function' ? this._countDownAction : () => {}
  }

  set content({
    message = '',
    buttons = [],
    videoCountdown = false,
    countDownAction = () => {},
    handleBack = false,
  }) {
    this.countDownAction = countDownAction
    this.handleBack = handleBack
    let generatedButtons = undefined
    if (isArrayWithLength(buttons)) {
      generatedButtons = buttons.map((button, idx) => {
        return {
          type: PromptButton,
          flexItem: { marginRight: idx === buttons.length - 1 ? 0 : 20 },
          label: isStringWithLength(button.label) ? button.label : '',
          action: typeof button.action === 'function' ? button.action : () => {},
        }
      })
    }

    this.videoCountdown = videoCountdown

    this.tag('Prompt').patch({
      Label: {
        flexItem: {
          marginTop: 80,
          marginLeft: 100,
          marginRight: 100,
          marginBottom: 55,
        },
        color: Colors(COLORS.WHITE).get(),
        text: {
          advancedRenderer: true,
          fontFace: 'PoppinsSemiBold',
          textAlign: 'center',
          fontSize: 40,
          wordWrapWidth: 760,
          lineHeight: 50,
          text: isStringWithLength(message) ? message : '',
          maxLines: 3,
        },
      },
      Buttons: {
        width: 960,
        height: 70,
        flex: {
          direction: 'row',
          justifyContent: 'center',
          alignContent: 'flex-start',
          alignItems: 'flex-start',
          wrap: true,
        },
        flexItem: {
          marginBottom: 60, //80,
          minWidth: 760,
          maxWidth: 760,
        },
        children: generatedButtons,
      },
    })

    voiceGuide.cancel()
    voiceGuide.utterThis(message || '', true)
    this._open()
  }

  set videoCountdown(seconds) {
    if (seconds && isNumber(seconds)) {
      let countDownTime = seconds
      this.tag('CountDownLabel').text.text = `stopping video in ${countDownTime} seconds`
      if (this._videoCountdown) clearInterval(this._videoCountdown)
      this._videoCountdown = setInterval(() => {
        if (countDownTime > 0) {
          countDownTime--
          this.tag('CountDownLabel').text.text = `stopping video in ${countDownTime} seconds`
        } else {
          this.tag('CountDownLabel').text.text = `stopping video in ${countDownTime} seconds`
          if (this._videoCountdown && countDownTime <= 0) clearInterval(this._videoCountdown)
          this.stopVideo()
          this.countDownAction()
          this.close()
        }
      }, 1000)
    } else {
      this.tag('CountDownLabel').text.text = ''
    }
  }

  stopVideo() {
    appInstance.stopToContinue()
  }

  close() {
    this._isOpen = false
    this.alpha = 0.0001
    if (this._videoCountdown) clearInterval(this._videoCountdown)
    Router.focusPage()
  }

  _open() {
    this._isOpen = true
    this.alpha = 1
    Router.focusWidget('Prompt')
  }

  get isOpen() {
    return typeof this._isOpen === 'boolean' ? this._isOpen : false
  }

  _handleKey() {
    // Avoid focus loss to pages
    if (this.isOpen) {
      Router.focusWidget('Prompt')
      return true
    }
  }

  _handleLeft() {
    if (this._index > 0) {
      this._index--
    } else {
      this._index = this.tag('Buttons').children.length - 1
    }
    return true
  }

  _handleRight() {
    if (this._index < this.tag('Buttons').children.length - 1) {
      this._index++
    } else {
      this._index = 0
    }
    return true
  }

  _handleUp() {
    return true
  }

  _handleDown() {
    return true
  }

  _handleEnter() {
    this.tag('Buttons').children[this._index].action()
    this.close()
  }

  _handleBack() {
    this.close()
    if (this.handleBack) {
      // Router.back()
      const historyLength = Router.getHistory() ? Router.getHistory().length : 0
      if (historyLength > 0) {
        Router.back()
      } else {
        if (Router.getActiveRoute() === ROUTE.HOME) {
          Router.back()
        } else {
          Router.navigate(ROUTE.HOME)
        }
      }
    }
    return true
  }

  _getFocused() {
    return this.tag('Buttons').children[this._index]
  }
}
