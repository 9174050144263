import { Colors, Lightning } from '@lightningjs/sdk'
import { COLORS } from '../../constants/colors'

export default class CSpanButton extends Lightning.Component {
  static _template() {
    return {
      Button: {
        color: Colors(COLORS.LIGHTGREY).get(),
        Highlight: {
          zIndex: 15,
          w: 250,
          h: 80,
          rect: true,
          alpha: 0,
          shader: {
            type: Lightning.shaders.RoundedRectangle,
            radius: 40,
            stroke: 5,
            fillColor: Colors(COLORS.WHITE)
              .alpha(0)
              .get(),
            strokeColor: Colors(COLORS.LIGHTGREY).get(),
          },
        },
        texture: Lightning.Tools.getRoundRect(250, 80, 45, 0, 0, true),
        Image: {
          w: 122,
          h: 25,
          mount: 0.5,
          x: w => w / 2,
          y: h => h / 2,
          color: Colors(COLORS.DARKGREY_60).get(),
        },
      },
    }
  }

  set color(color) {
    this._color = color
  }

  set padding({ left, top }) {
    this.tag('Image').patch({
      x: left,
      y: top,
    })
  }

  set imageX(left) {
    this.tag('Image').patch({
      x: left,
    })
  }

  set width(width) {
    this.tag('Image').patch({
      w: width,
    })
  }

  set height(height) {
    this.tag('Image').patch({
      h: height,
    })
  }

  set squarePos({ x, y }) {
    this.tag('CSpanImage').patch({
      x: x,
      y: y,
    })
  }

  set image(src) {
    this._src = src
    this.tag('Button').patch({
      color: this._color,
      Image: {
        src: this._src,
      },
    })
  }

  _construct() {
    this._color = Colors(COLORS.LIGHTGREY).get()
  }

  _focus() {
    this.tag('Button').patch({
      Highlight: { alpha: 1 },
    })
  }

  _unfocus() {
    this.tag('Button').patch({
      Highlight: { alpha: 0 },
    })
  }
}
