class playerPolymorphClass {
  constructor() {
    this._playerTag = undefined
    this._playerStyle = undefined
    this._lightningComponent = undefined
    this._precision = 1
  }

  _playerTrackComponentPositionAndSize(lightningComponent, { position = false, size = false }) {
    lightningComponent.core.onAfterUpdate = () => {
      if (position === true) {
        let newCornerPoints = lightningComponent.core.getCornerPoints()
        let componentPosition = { x: newCornerPoints[1], y: newCornerPoints[0] }
        this.position(componentPosition.x, componentPosition.y)
      }
      if (size === true) {
        let componentSize = { w: lightningComponent.w, h: lightningComponent.h }
        this.size(componentSize.w, componentSize.h)
      }
    }
  }

  updateTag() {
    this._playerTag = document.getElementById('video-player')
    if (this._playerTag) {
      this._playerStyle = this._playerTag.style
    }
  }

  withPrecision(val) {
    return `${Math.round(this._precision * val)}px`
  }

  size(width = 1920, height = 1080) {
    this._playerStyle.width = this.withPrecision(width)
    this._playerStyle.height = this.withPrecision(height)
    this._playerTag.width = this.withPrecision(width)
    this._playerTag.height = this.withPrecision(width)
  }

  position(x = 0, y = 0) {
    this._playerStyle.left = this.withPrecision(x)
    this._playerStyle.top = this.withPrecision(y)
  }

  trackPosition(lightningComponent) {
    this._lightningComponent = lightningComponent
    this._lightningComponent.core.onAfterUpdate = () => {
      let newCornerPoints = lightningComponent.core.getCornerPoints()
      this.position(newCornerPoints[0], newCornerPoints[1])
    }
  }

  onTop(onTop) {
    this._playerStyle.zIndex = onTop === true ? '9999999' : '0'
  }

  roundedCorners({ radius = 15 }) {
    this._playerStyle.borderRadius = `${radius}px`
    this._playerStyle.overflow = 'hidden'
  }

  reset() {
    this.onTop(false)
    this.position(0, 0)
    this.size(1920, 1080)
    if (this._lightningComponent) {
      this._lightningComponent.core.onAfterUpdate = undefined
      this._lightningComponent = undefined
    }
    this._playerStyle.borderRadius = null
    this._playerStyle.border = null
    this._playerStyle.overflow = null
  }

  updatePrecision(prec) {
    this._precision = prec
  }
}

export const playerPolymorph = new playerPolymorphClass()
