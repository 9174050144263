import LiveFeaturedCard from './LiveFeaturedCard'
import { Colors } from '@lightningjs/sdk'
import { COLORS } from '../../constants/colors'
import CardCounter from './CardCounter'
import { List } from '@lightningjs/ui'
import LiveBadge from './LiveBadge'

export const liveEmptyTemplate = {
  w: 1920,
  h: 0,
  background: {
    h: 0,
  },
  liveContent: {
    FeaturedCard: undefined,
    LiveBadge: undefined,
    CardCounter: undefined,
    FeaturedTitle: undefined,
    FeaturedDescription: undefined,
    ListTitle: undefined,
    List: undefined,
    transitions: { alpha: { duration: 0.3 } },
  },
}

// Template: Live FeaturedCard Only
export const liveFeaturedTemplate = {
  w: 1920,
  h: 598,
  background: {
    h: 548,
  },
  liveContent: {
    FeaturedCard: { x: 104, y: 60, type: LiveFeaturedCard },
    LiveBadge: undefined,
    CardCounter: undefined,
    FeaturedTitle: {
      x: 884,
      y: 80,
      color: Colors(COLORS.LIGHTGREY).get(),
      text: {
        advancedRenderer: true,
        text: '',
        fontSize: 36,
        fontFace: 'PoppinsSemiBold',
        lineHeight: 54,
        maxLines: 2,
        wordWrapWidth: 932,
      },
    },
    FeaturedDescription: {
      x: 884,
      y: 152,
      color: Colors(COLORS.DARKGREY_40).get(),
      text: {
        advancedRenderer: true,
        text: '',
        fontSize: 28,
        fontFace: 'PoppinsRegular',
        lineHeight: 42,
        maxLines: 8,
        wordWrapWidth: 932,
      },
    },
    ListTitle: undefined,
    List: undefined,
    transitions: { alpha: { duration: 0.3 } },
  },
}

// Template: Live List Only
export const liveListTemplate = {
  w: 1920,
  h: 610,
  background: {
    h: 560,
  },
  liveContent: {
    FeaturedCard: undefined,
    LiveBadge: undefined,
    CardCounter: { x: 1816, y: 48, type: CardCounter },
    FeaturedTitle: undefined,
    FeaturedDescription: undefined,
    ListTitle: {
      x: 104,
      y: 36,
      color: Colors(COLORS.LIGHTGREY).get(),
      text: {
        text: 'Live', //Language.translate does not work here
        fontSize: 40,
        fontFace: 'PoppinsSemiBold',
        lineHeight: 60,
        maxLines: 1,
        wordWrapWidth: 800,
      },
    },
    List: {
      x: 104,
      y: 120,
      w: 1716,
      type: List,
      forceLoad: true, //Keep items alive offscreen
      direction: 'row',
      spacing: 43,
      marginLeft: 72,
      marginRight: 74,
      marginTop: 221,
      marginBottom: 52,
      signals: { onIndexChanged: true },
      scrollTransition: { duration: 0.3, timingFunction: 'cubic-bezier(.33, 1, .68, 1)' },
    },
    transitions: { alpha: { duration: 0.3 } },
  },
}

// Template: Live FeaturedCard And List
export const liveFeaturedAndListTemplate = {
  w: 1920,
  h: 970,
  background: {
    h: 920,
  },
  liveContent: {
    FeaturedCard: { x: 104, y: 60, type: LiveFeaturedCard },
    LiveBadge: { x: 124, y: 80, type: LiveBadge },
    FeaturedTitle: {
      x: 884,
      y: 80,
      color: Colors(COLORS.LIGHTGREY).get(),
      text: {
        advancedRenderer: true,
        text: '',
        fontSize: 36,
        fontFace: 'PoppinsSemiBold',
        lineHeight: 54,
        maxLines: 2,
        wordWrapWidth: 932,
      },
    },
    FeaturedDescription: {
      x: 884,
      y: 152,
      color: Colors(COLORS.DARKGREY_40).get(),
      text: {
        advancedRenderer: true,
        text: '',
        fontSize: 28,
        fontFace: 'PoppinsRegular',
        lineHeight: 42,
        maxLines: 8,
        wordWrapWidth: 932,
      },
    },
    CardCounter: { x: 1816, y: 501, type: CardCounter },
    ListTitle: {
      x: 104,
      y: 486,
      color: Colors(COLORS.DARKGREY_60).get(),
      text: {
        text: 'Live', //Language.translate does not work here
        fontSize: 40,
        fontFace: 'PoppinsSemiBold',
        lineHeight: 60,
        maxLines: 1,
        wordWrapWidth: 800,
      },
    },
    List: {
      x: 104,
      y: 572,
      w: 1716,
      type: List,
      forceLoad: true, //Keep items alive offscreen
      direction: 'row',
      spacing: 40,
      marginLeft: 72,
      marginRight: 74,
      marginTop: 221,
      marginBottom: 52,
      signals: { onIndexChanged: true },
      scrollTransition: { duration: 0.3, timingFunction: 'cubic-bezier(.33, 1, .68, 1)' },
    },
    transitions: { alpha: { duration: 0.3 } },
  },
}
