import { Colors, Lightning } from '@lightningjs/sdk'
import { getRankset } from '../../api/Api'
import {
  liveEmptyTemplate,
  liveFeaturedAndListTemplate,
  liveFeaturedTemplate,
  liveListTemplate,
} from './LiveContainerTemplates'
import {
  findEndedAndUpcomingInRow,
  isLiveCardEndedOrUpcoming,
  removeIndexesFromData,
  sortLiveVideoByDate,
} from '../../utils/cspan'
import HomeListAsset from '../../api/assets/HomeListAsset'
import LiveCardBig from './LiveCardBig'
import LiveCardSmall from './LiveCardSmall'
import { isArrayWithLength, isStringWithLength } from '../../utils/checks'
import { COLORS } from '../../constants/colors'
import { getWidget, upperCaseFirst } from '../../utils/tools'

export default class LiveContainer extends Lightning.Component {
  static _template() {
    return {
      w: 1920,
      h: 0,
      clipping: true,
      Background: {
        w: 1920,
        h: 0,
        rect: true,
        color: 0x4d000000,
        transitions: { h: { duration: 2 } },
      },
      LoadingText: {
        y: 50,
        x: 960,
        color: Colors(COLORS.DARKGREY_60).get(),
        mountX: 0.5,
        alpha: 0.0001,
        text: {
          text: 'Updating live streams',
          fontFace: 'PoppinsRegular',
          maxLines: 1,
          textAlign: 'center',
          verticalAlign: 'middle',
          fontSize: 28,
          lineHeight: 28,
        },
      },
      LiveContent: {},
      transitions: { h: { duration: 2 } },
    }
  }

  _init() {
    this._loading = false
    this.transition('h').off('progress')
    this.transition('h').on('progress', () => {
      this.onHeightChange(this.h)
    })
    this.transition('h').off('finish')
    this.transition('h').on('finish', () => {
      this.onHeightChangeFinish(this.getSmooth('h'))
    })

    this.runMinuteClock(true)
  }

  runMinuteClock(run = true) {
    let now = new Date()
    let nextClockMinute = (60 - now.getSeconds()) * 1000 - now.getMilliseconds()
    this.clearMinuteClock()
    this._minuteClock = setTimeout(() => {
      this.clearMinuteClock()
      if (typeof run === 'boolean' && run === true) {
        this.runMinuteClock(true)
        this.runUpdate()
      }
    }, nextClockMinute)
  }

  clearMinuteClock() {
    if (this._minuteClock) clearTimeout(this._minuteClock)
  }

  runUpdate() {
    const prompt = getWidget('Prompt')
    const isPromptOpen = prompt ? prompt.isOpen : false

    //When prompt is overlaying page, stall update
    if (isPromptOpen) {
      this.updatePending = true
      return
    }

    this._homeContentIndex = this.fireAncestors('$getHomeIndex').list

    if (
      //Verify if focus is on top of home content before updating
      (this.homeContentIndex === 0 && this.liveTemplate !== 'empty') ||
      (this.homeContentIndex === 1 && this.liveTemplate === 'empty')
    ) {
      if (!isPromptOpen) {
        this.updatePending = false

        this.getUpdate().then(data => {
          let newData = (data && data._row) || []
          newData = this._cleanUpData(newData) //Remove ended items or items past soon, in new dataset
          let oldData = this._cleanUpData(this.data) //Remove ended items or items past soon in old dataset

          //Remove ended items or items past soon in rendered data
          this._cleanUpComponents().then(cleanUpfeedback => {
            //Compare old and new data based on length, id and start / end times
            if (this._isDataTheSame(oldData, newData)) {
              if (oldData.length <= 0 && newData.length <= 0) {
                this.data = [] //No data hide live container
              } else {
                // cleanUpfeedback
                // clean: Rendered items are cleaned but still present
                // empty: Rendered items are cleaned but there is nothing left
                if (cleanUpfeedback === 'clean') {
                  this._updateLocalImagesAndBadges()
                } else {
                  this.data = newData
                }
              }
            } else {
              this.data = newData // Data has changed override existing dataset
            }
          })
        })
      } else {
        this.updatePending = true
      }
    } else {
      this.updatePending = true
    }
  }

  //update images
  _updateLocalImagesAndBadges() {
    switch (this.liveTemplate) {
      case 'featured':
        if (this.comp.featuredCard) {
          this.comp.featuredCard.updateImageAndBadge()
        }
        break
      case 'list':
        if (this.comp.list && this.comp.list.items && this.comp.list.items.length > 0) {
          for (let i = 0; i < this.comp.list.items.length; i++) {
            if (this.comp.list && this.comp.list.items && this.comp.list.items[i]) {
              this.comp.list.items[i].updateImageAndBadge()
            }
          }
        }
        break
      case 'featuredAndList':
        if (this.comp.featuredCard) {
          this.comp.featuredCard.updateImageAndBadge()
        }
        if (this.comp.list && this.comp.list.items && this.comp.list.items.length > 0) {
          for (let i = 0; i < this.comp.list.items.length; i++) {
            if (this.comp.list && this.comp.list.items && this.comp.list.items[i]) {
              this.comp.list.items[i].updateImageAndBadge()
            }
          }
        }
        break
      case 'empty':
        break
    }
  }

  _cleanUpData(data) {
    //Remove ended items and items past soon from data
    let markedForRemoval = findEndedAndUpcomingInRow(data)
    data = removeIndexesFromData(markedForRemoval, data)
    //Sort leftover data by date
    data = sortLiveVideoByDate(data)
    //When only one item in data, mark it as featured
    if (data && data.length === 1) {
      data[0]._featured = true
    }
    return data
  }

  _cleanUpComponents() {
    return new Promise(resolve => {
      // Cleanup local data, then cleanup data in components
      // Nessesary for update equallity check
      if (this.liveTemplate === 'empty') resolve('empty')
      this._data = this._cleanUpData(this._data)

      switch (this.liveTemplate) {
        case 'featured':
          if (isLiveCardEndedOrUpcoming(this.comp.featuredCard.data)) {
            resolve('empty')
          } else {
            resolve('clean')
          }
          break
        case 'list':
          this._cleanUpList().then(feedback => {
            if (this.comp.list.length === 1) {
              resolve('empty') // Mark as empty, only one in list means update to featured
            } else {
              resolve(feedback)
            }
          })
          break
        case 'featuredAndList':
          if (isLiveCardEndedOrUpcoming(this.comp.featuredCard.data)) {
            resolve('empty')
          } else {
            this._cleanUpList().then(feedback => {
              resolve(feedback)
            })
          }
          break
        case 'empty':
          resolve('empty')
          break
      }
    })
  }

  _cleanUpList() {
    return new Promise(resolve => {
      const list = (this && this.comp && this.comp.list && this.comp.list.items) || []
      const items = list.map(item => {
        return item.item
      })

      const markedForRemoval = findEndedAndUpcomingInRow(items)
      const renderedListLength =
        typeof this.comp.list.length === 'number' ? this.comp.list.length : 0
      if (markedForRemoval.length >= renderedListLength) {
        resolve('empty')
      } else {
        let itemsToRemove = []
        markedForRemoval.forEach(removalIndex => {
          itemsToRemove.push(this.comp.list.items[removalIndex])
        })
        itemsToRemove.forEach(itemToRemove => {
          for (let i = 0; i < this.comp.list.items.length; i++) {
            if (this.comp.list.items[i].assignedID === itemToRemove.assignedID) {
              this.comp.list.removeAt(i)
            }
          }
        })
        this.comp.cardCounter.index = this.comp.list.index + 1
        this.comp.cardCounter.total = this.comp.list.items.length
        this.comp.list.first()
        this._refocusLastComponent()
        resolve('clean')
      }
    })
  }

  set lastFocusComponent(lastFocusComponent) {
    this._lastFocusComponent = lastFocusComponent
  }

  get lastFocusComponent() {
    return typeof this._lastFocusComponent === 'string' ? this._lastFocusComponent : false
  }

  set homeContentIndex(homeContentIndex) {
    this._homeContentIndex = homeContentIndex
    if (
      (this.homeContentIndex === 0 && this.liveTemplate !== 'empty') ||
      (this.homeContentIndex === 1 && this.liveTemplate === 'empty')
    ) {
      if (this.updatePending) {
        this.runUpdate()
      }
    }
  }

  get homeContentIndex() {
    return typeof this._homeContentIndex === 'number' ? this._homeContentIndex : 1
  }

  set updatePending(updatePending) {
    this._updatePending = updatePending
  }

  get updatePending() {
    return typeof this._updatePending === 'boolean' ? this._updatePending : false
  }

  set onHeightChange(fn) {
    this._onHeightChange = typeof fn === 'function' ? fn : () => {}
  }

  get onHeightChange() {
    return typeof this._onHeightChange === 'function' ? this._onHeightChange : () => {}
  }

  set onHeightChangeFinish(fn) {
    this._onHeightChangeFinish = typeof fn === 'function' ? fn : () => {}
  }

  get onHeightChangeFinish() {
    return typeof this._onHeightChangeFinish === 'function' ? this._onHeightChangeFinish : () => {}
  }

  //Needs to be set when first created
  set callAsset(callAsset) {
    this._callAsset = callAsset
  }

  get callAsset() {
    return this._callAsset ? this._callAsset : {}
  }

  set liveTemplate(name) {
    const availableOptions = ['featured', 'list', 'featuredAndList', 'empty']
    if (availableOptions.includes(name)) {
      this._liveTemplate = name
    } else {
      console.warn('set liveTemplate - invalid template name ', name)
    }
  }

  get liveTemplate() {
    return typeof this._liveTemplate === 'string' ? this._liveTemplate : 'empty'
  }

  set data(data) {
    this.fireAncestors('$scrollList', 0)
    this.fireAncestors('$onLiveContainerUpdate', { status: 'start', template: 'pending' })
    this._setState('Loading')
    this.showLoadingText(true)
    this._data = this._cleanUpData(data)
    this.liveTemplate = this.indentifyTemplateType(this._data)

    this.patchLiveTemplate(this._data, this.liveTemplate).then(() => {
      this.showLoadingText(false)
      this.fireAncestors('$onLiveContainerUpdate', { status: 'end', template: this.liveTemplate })
      const newState = upperCaseFirst(this.liveTemplate)
      this._setState('')
      this._setState(`${newState}`)
    })
  }

  get data() {
    return this._data ? this._data : undefined
  }

  get comp() {
    return {
      background: this.tag('Background') ? this.tag('Background') : undefined,
      loadingText: this.tag('LoadingText') ? this.tag('LoadingText') : undefined,
      liveContent: this.tag('LiveContent') ? this.tag('LiveContent') : undefined,
      featuredCard: this.tag('FeaturedCard') ? this.tag('FeaturedCard') : undefined,
      liveBadge: this.tag('LiveBadge') ? this.tag('LiveBadge') : undefined,
      cardCounter: this.tag('CardCounter') ? this.tag('CardCounter') : undefined,
      featuredTitle: this.tag('FeaturedTitle') ? this.tag('FeaturedTitle') : undefined,
      featuredDescription: this.tag('FeaturedDescription')
        ? this.tag('FeaturedDescription')
        : undefined,
      listTitle: this.tag('ListTitle') ? this.tag('ListTitle') : undefined,
      list: this.tag('List') ? this.tag('List') : undefined,
    }
  }

  _focus() {
    this._refocus()
    if (this._getState() === 'FeaturedAndList.List') {
      this.fireAncestors('$scrollList', 500)
    }
  }

  _disable() {
    this.runMinuteClock(false)
  }

  _refocusLastComponent() {
    if (this.lastFocusComponent) {
      this._setState(this.lastFocusComponent)
    }
  }

  refocusToFeature() {
    this._setState('FeaturedAndList.FeaturedCard')
  }

  // Checks if length is the same,
  // then if ids are the same,
  // then if start / end times are the same
  _isDataTheSame(currentData, newData) {
    if (currentData.length === newData.length) {
      if (this._areIdsTheSame(currentData, newData)) {
        return this._areStartEndTimesTheSame(currentData, newData)
      } else {
        return false
      }
    } else {
      return false
    }
  }

  _areIdsTheSame(currentData, newData) {
    let uniqueToCurrentData = _findUniqueIdInFirstArray(currentData, newData)
    let uniqueToNewData = _findUniqueIdInFirstArray(newData, currentData)
    let uniqueData = uniqueToCurrentData.concat(uniqueToNewData) //Merge arrays

    function _findUniqueIdInFirstArray(arrayA = [], arrayB = []) {
      return arrayA.filter(currentElementA => {
        return (
          arrayB.filter(currentElementB => {
            return currentElementB.id === currentElementA.id
          }).length === 0
        )
      })
    }

    return uniqueData.length <= 0 //If uniqueData is 0, data is the same
  }

  _areStartEndTimesTheSame(currentData, newData) {
    let uniqueToCurrentData = _findUniqueTimeInFirstArray(currentData, newData)
    let uniqueToNewData = _findUniqueTimeInFirstArray(newData, currentData)
    let uniqueData = uniqueToCurrentData.concat(uniqueToNewData) //Merge arrays

    function _findUniqueTimeInFirstArray(arrayA = [], arrayB = []) {
      return arrayA.filter(currentElementA => {
        return (
          arrayB.filter(currentElementB => {
            return (
              currentElementB._liveBeginTime === currentElementA._liveBeginTime &&
              currentElementB._liveEndTime === currentElementA._liveEndTime
            )
          }).length === 0
        )
      })
    }

    return uniqueData.length <= 0 //If uniqueData is 0, data is the same
  }

  _getHomeListAsset(row, callAsset) {
    if (isArrayWithLength(row)) {
      return new HomeListAsset(row, callAsset)
    } else {
      callAsset.failed = true
      return undefined
    }
  }

  //Returns first item marked featured
  _getFirstFeaturedItem(items) {
    for (let i = 0; i < items.length; i++) {
      if (items[i] && items[i].featured === true) {
        return items[i]
      }
    }
    return undefined
  }

  showLoadingText(show) {
    this.comp.loadingText.alpha = show ? 1 : 0.0001
  }

  //Depending of current template fill component data
  fillComponents(data, templateName) {
    const featuredCard = this._getFirstFeaturedItem(data)
    let featuredCardIndex = -1
    if (featuredCard) {
      featuredCardIndex = data.findIndex(item => {
        return item.id === featuredCard.id
      })
    }

    let dataMinusFeatureCard = data
    if (featuredCardIndex !== -1) {
      dataMinusFeatureCard = removeIndexesFromData([featuredCardIndex], data)
    }

    switch (templateName) {
      case 'featured':
        this.fillFeaturedInformation(featuredCard)
        break
      case 'list':
        this.fillListInformation(data)
        break
      case 'featuredAndList':
        this.fillFeaturedInformation(featuredCard)
        this.fillListInformation(dataMinusFeatureCard)
        break
    }
  }

  //Sets FeaturedCard data, title and description
  fillFeaturedInformation(featuredCard) {
    this.comp.featuredTitle.text.text = isStringWithLength(featuredCard.title)
      ? featuredCard.title
      : ''

    // Update description position once title is loaded
    // Cannot listen to txLoaded here, would cause an endless loop. Using timeout instead.
    setTimeout(() => {
      if (this.comp.featuredTitle.renderHeight > 72) {
        this.comp.featuredDescription.y = 206
        this.comp.featuredDescription.text.maxLines = 6
      } else {
        this.comp.featuredDescription.y = 152
        this.comp.featuredDescription.text.maxLines = 8
      }

      this.comp.featuredDescription.text.text = isStringWithLength(featuredCard.description)
        ? featuredCard.description
        : ''
    }, 200)

    this.comp.featuredCard.data = featuredCard
  }

  //Sets List data, and updates CardCounter
  fillListInformation(data) {
    const useBigCard = this.liveTemplate === 'list'
    this.comp.list.clear()
    data.forEach(item => {
      this.comp.list.add({ type: useBigCard ? LiveCardBig : LiveCardSmall, item })
    })
    this.comp.cardCounter.index = this.comp.list.index + 1
    this.comp.cardCounter.total = this.comp.list.items.length
  }

  getUpdate() {
    return getRankset(this.callAsset.id, this.callAsset.params)
      .then(row => {
        return this._getHomeListAsset(row, this.callAsset)
      })
      .catch(err => {
        // Catch is required here
        return undefined
      })
  }

  indentifyTemplateType(data) {
    const includesFeatured = !!this._getFirstFeaturedItem(data)
    if (data && data.length <= 0) return 'empty'
    if (includesFeatured) {
      if (data && data.length > 1) {
        return 'featuredAndList'
      } else {
        return 'featured'
      }
    } else {
      if (data && data.length <= 1) {
        return 'featured'
      } else {
        return 'list'
      }
    }
  }

  patchLiveTemplate(data, name) {
    return new Promise(resolve => {
      if (!this._initialPatchDone) {
        // Initial patch won't work without delay
        // this.comp.liveContent is not initially ready
        setTimeout(() => {
          this.comp.liveContent.setSmooth('alpha', 0.0001)
        }, 1000)
        this._initialPatchDone = true
      } else {
        this.comp.liveContent.setSmooth('alpha', 0.0001)
      }

      this.comp.liveContent.transition('alpha').once('finish', () => {
        let newTemplate = undefined
        switch (name) {
          case 'featured':
            newTemplate = liveFeaturedTemplate
            break
          case 'list':
            newTemplate = liveListTemplate
            break
          case 'featuredAndList':
            newTemplate = liveFeaturedAndListTemplate
            break
          case 'empty':
            newTemplate = liveEmptyTemplate
            break
          default:
            console.warn('LiveContainer.js - patchLiveTemplate, invalid name ', name)
        }

        if (newTemplate) {
          this.comp.liveContent.patch(newTemplate.liveContent)
          this.fillComponents(data, name)
          this.w = newTemplate.w
          this.setSmooth('h', newTemplate.h)
          this.comp.background.setSmooth('h', newTemplate.background.h)
          this.transition('h').once('finish', () => {
            this.comp.liveContent.setSmooth('alpha', 1)
            resolve('done')
          })
        } else {
          resolve('done')
        }
      })
    })
  }

  static _states() {
    return [
      class Loading extends this {
        $enter() {
          this._loading = true
        }

        $exit() {
          this._loading = true
        }

        _handleLeft() {
          //Block interaction
          return true
        }

        _handleRight() {
          //Block interaction
          return true
        }

        _handleUp() {
          //Block interaction
          return true
        }

        _handleDown() {
          //Block interaction
          return true
        }

        _handleEnter() {
          //Block interaction
          return true
        }

        _handleBack() {
          //Block interaction
          return true
        }
      },
      //States for Featured only layout
      class Featured extends this {
        $enter() {
          //Redirect to substate
          this._setState('Featured.FeaturedCard')
        }

        static _states() {
          return [
            class FeaturedCard extends this {
              $enter() {
                this.lastFocusComponent = 'Featured.FeaturedCard'
              }

              _getFocused() {
                return this.comp.featuredCard
              }
            },
          ]
        }
      },
      //States for List only layout
      class List extends this {
        $enter() {
          //Redirect to substate
          this._setState('List.List')
        }

        static _states() {
          return [
            class List extends this {
              $enter() {
                this.lastFocusComponent = 'List.List'
              }

              onIndexChanged({ index }) {
                this.comp.cardCounter.index = index + 1
                this.comp.cardCounter.total = this.comp.list.items.length
              }

              _handleLeft() {
                //Jump to last item when list is at start
                this.comp.list.last()
              }

              _handleRight() {
                //Jump to first item when list is at end
                this.comp.list.first()
              }
              _getFocused() {
                return this.comp.list
              }
            },
          ]
        }
      },
      //States for Featured and List layout
      class FeaturedAndList extends this {
        $enter() {
          //Redirect to substate
          this._setState('FeaturedAndList.FeaturedCard')
        }

        static _states() {
          return [
            class FeaturedCard extends this {
              $enter() {
                this.lastFocusComponent = 'FeaturedAndList.FeaturedCard'
                this.fireAncestors('$scrollList', 0)
              }

              _handleDown() {
                this._setState('FeaturedAndList.List')
              }

              _getFocused() {
                return this.comp.featuredCard
              }
            },
            class List extends this {
              $enter() {
                this.lastFocusComponent = 'FeaturedAndList.List'
                this.fireAncestors('$scrollList', 500)
              }

              onIndexChanged({ index }) {
                this.comp.cardCounter.index = index + 1
                this.comp.cardCounter.total = this.comp.list.items.length
              }

              _handleLeft() {
                //Jump to last item when list is at start
                this.comp.list.last()
              }

              _handleRight() {
                //Jump to first item when list is at end
                this.comp.list.first()
              }
              _handleUp() {
                this._setState('FeaturedAndList.FeaturedCard')
              }

              _getFocused() {
                return this.comp.list
              }
            },
          ]
        }
      },
    ]
  }
}
