import { isBoolean, isNumber, isStringWithLength } from '../../utils/checks'
import { adjustToItalic } from '../../utils/cspan'

export default class TvNetInfoAsset {
  constructor(video) {
    this._description = isStringWithLength(video && video.description)
      ? adjustToItalic(video.description)
      : ''
    this._liveBeginTime =
      isStringWithLength(video && video.beginTime) && video.endTime !== '1970-01-01T00:00:00Z'
        ? video.beginTime
        : undefined
    this._liveEndTime =
      isStringWithLength(video && video.beginTime) && video.endTime !== '1970-01-01T00:00:00Z'
        ? video.endTime
        : undefined
    this._network = isNumber(video && video.network) ? video.network : undefined
    this._title = isStringWithLength(video && video.title) ? adjustToItalic(video.title) : ''
    this._live = isStringWithLength(video.live) ? video.live : undefined
  }
  get network() {
    return this._network
  }
  get title() {
    return this._title
  }
  get live() {
    return this._live
  }
  get description() {
    return this._description
  }
  get liveBeginTime() {
    return this._liveBeginTime
  }
  get liveEndTime() {
    return this._liveEndTime
  }
}
