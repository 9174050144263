import { isStringWithLength, isObjectWithLength, isArrayWithLength } from '../../utils/checks'
import TrendingItemAsset from './TrendingItemAsset'
import { adjustToItalic } from '../../utils/cspan'

export default class TrendingInfoAsset {
  constructor(trendingInfo, trendingItems) {
    this._id = isStringWithLength(trendingInfo && trendingInfo.id) ? trendingInfo.id : undefined
    this._displayType = isStringWithLength(trendingInfo.displayType)
      ? trendingInfo.displayType
      : undefined
    this._params = isObjectWithLength(trendingInfo && trendingInfo.params)
      ? trendingInfo.params
      : undefined
    this._title = isStringWithLength(trendingInfo && trendingInfo.title)
      ? adjustToItalic(trendingInfo.title)
      : undefined
    this._type = isStringWithLength(trendingInfo && trendingInfo.type)
      ? trendingInfo.type
      : undefined
    this._items = isArrayWithLength(trendingItems) ? this._makeCallAssets(trendingItems) : undefined
  }

  get title() {
    return this._title
  }

  get displayType() {
    return this._displayType
  }

  get type() {
    return this._type
  }

  get params() {
    return this._params
  }

  get id() {
    return this._id
  }

  get items() {
    return this._items
  }

  _makeCallAssets(items) {
    return items.map(item => {
      return new TrendingItemAsset(item)
    })
  }
}
