import { Lightning, Router } from '@lightningjs/sdk'
import HomeContent from '../components/Home/Content/HomeContent'
import { isNumber } from '../utils/checks'
import { getWidget } from '../utils/tools'
import Loader from '../components/Loader'

export default class Home extends Lightning.Component {
  static _template() {
    return {
      y: 0,
      x: 0,
      Loader: { type: Loader, alpha: 0.0001 },
      HomeContent: {
        y: 0,
        x: 0,
        alpha: 0,
        type: HomeContent,
        signals: { homeContentIndexChanged: true, onDataFetch: true },
      },
    }
  }

  get Loader() {
    return this.tag('Loader')
  }

  set data(data) {
    if (this.index.list === 0) getWidget('Background').startPosition()
    this.tag('HomeContent').data = data
  }

  set index(index) {
    this._index = index
  }

  get index() {
    return this._index
  }

  _init() {
    this._setState('Loader')
    this.index = { list: 0, cell: 0 }
  }

  historyState(params) {
    if (params) {
      // called because entry was loaded from history
      this.index = params.index
      this.tag('HomeContent').indexOnReturn = params.index
    } else {
      // called because page was added to history
      return {
        index: this.index,
      }
    }
  }

  syncIndexWithContent({ list, cell }) {
    this.index = { list, cell }
  }

  onDataFetch() {
    this._setState('HomeContent')
  }

  homeContentIndexChanged({ list, cell }, liveContainerVisible = false) {
    if (isNumber(list) && isNumber(cell)) {
      if (liveContainerVisible) {
        getWidget('Background').moveBackground(list)
      } else {
        const newIndex = list - 1 >= 0 ? list - 1 : 0
        getWidget('Background').moveBackground(newIndex)
      }
      this.syncIndexWithContent({ list, cell })
    }
  }

  moveBackground(index) {
    const positions = [0, 360, 720, 1080]
    if (index <= 0) {
      this.tag('Background').patch({ smooth: { y: positions[0] } })
    } else if (index >= positions.length) {
      this.tag('Background').patch({ smooth: { y: -positions[3] } })
    } else {
      this.tag('Background').patch({ smooth: { y: -positions[index] } })
    }
  }

  static _states() {
    return [
      class Loader extends this {
        $enter() {
          this.Loader.setSmooth('alpha', 1, { duration: 0.3 })
        }

        $exit() {
          this.Loader.setSmooth('alpha', 0, { duration: 0.3 })
        }
      },
      class HomeContent extends this {
        $enter() {
          this.tag('HomeContent').setSmooth('alpha', 1, { duration: 0.3 })
        }

        _getFocused() {
          return this.tag('HomeContent')
        }

        _handleUp() {
          this._setState('Inactive')
        }
      },
      class Inactive extends this {
        $enter() {
          Router.focusWidget('Header')
        }

        _focus() {
          if (!Router.isNavigating()) {
            this._setState('HomeContent')
          }
        }
      },
    ]
  }
}
