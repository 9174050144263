import { isStringWithLength, isNumber, isArrayWithLength } from '../../utils/checks'
import SeriesAsset from './SeriesAsset'
import { adjustToItalic } from '../../utils/cspan'

export default class SeriesInfoAsset {
  constructor(seriesInfo, items) {
    this._banner = isStringWithLength(seriesInfo.banner) ? seriesInfo.banner : undefined
    this._description = isStringWithLength(seriesInfo.description)
      ? adjustToItalic(seriesInfo.description)
      : ''
    this._id = isNumber(seriesInfo.id) ? seriesInfo.id : undefined
    this._parameters = seriesInfo.parameters
    this._logo = isStringWithLength(seriesInfo.logo) ? seriesInfo.logo : undefined
    this._title = isStringWithLength(seriesInfo.title) ? adjustToItalic(seriesInfo.title) : ''
    this._template = isStringWithLength(seriesInfo.template) ? seriesInfo.template : undefined
    this._items = isArrayWithLength(items) ? this._makeCallAssets(items) : undefined
  }

  get banner() {
    return this._banner
  }

  get template() {
    return this._template
  }

  get title() {
    return this._title
  }

  get logo() {
    return this._logo
  }

  get parameters() {
    return this._parameters
  }

  get id() {
    return this._id
  }

  get description() {
    return this._description
  }

  get items() {
    return this._items
  }

  _makeCallAssets(items) {
    return items.map(item => {
      return new SeriesAsset(item)
    })
  }
}
