import { Colors, Language, Lightning } from '@lightningjs/sdk'
import { COLORS } from '../../constants/colors'

export default class BackToTop extends Lightning.Component {
  static _template() {
    return {
      x: 1930,
      y: 1030,
      w: 686,
      h: 70,
      alpha: 0.0001,
      mount: 1,
      zIndex: 980,
      Background: {
        w: w => w,
        h: h => h,
        rect: true,
        color: Colors(COLORS.DARKGREY_20).get(),
        shader: {
          type: Lightning.shaders.RoundedRectangle,
          radius: 10,
        },
      },
      Label: {
        w: 686,
        h: 50,
        x: 30,
        y: 35,
        flex: { direction: 'row', alignContent: 'center', wrap: false },
        Press: {
          flexItem: { marginRight: 10 },
          color: Colors(COLORS.MIDNIGHT).get(),
          mountY: 0.5,
          text: {
            fontFace: 'PoppinsRegular',
            text: Language.translate('backToTopPress'),
            maxLines: 1,
            textAlign: 'left',
            verticalAlign: 'middle',
            fontSize: 20,
          },
        },
        Back: {
          flexItem: { marginRight: 10 },
          w: 100,
          h: 50,
          mountY: 0.5,
          Shadow: {
            w: 100,
            h: 50,
            y: 2,
            texture: Lightning.Tools.getShadowRect(100, 50, 10, 4, 0),
            color: Colors(COLORS.BLACK)
              .alpha(0.5)
              .get(),
          },
          BackBackground: {
            w: 100,
            h: 50,
            y: -3,
            rect: true,
            colorTop: Colors(COLORS.BACKGROUND_BACKTOTOP_TOP).get(),
            colorBottom: Colors(COLORS.BACKGROUND_BACKTOTOP_BOTTOM).get(),
            shader: {
              type: Lightning.shaders.RoundedRectangle,
              radius: 10,
            },
          },
          BackLabel: {
            x: 50,
            y: 22,
            mountX: 0.5,
            mountY: 0.4,
            color: Colors(COLORS.LIGHTGREY).get(),
            text: {
              fontFace: 'PoppinsRegular',
              text: Language.translate('backToTopBack'),
              maxLines: 1,
              textAlign: 'center',
              verticalAlign: 'middle',
              fontSize: 22,
            },
          },
        },
        OnRemote: {
          flexItem: {},
          mountY: 0.5,
          color: Colors(COLORS.MIDNIGHT).get(),
          text: {
            fontFace: 'PoppinsRegular',
            text: Language.translate('backToTopOnRemote'),
            maxLines: 1,
            textAlign: 'left',
            verticalAlign: 'middle',
            fontSize: 20,
          },
        },
      },
      transitions: { x: { duration: 0.5 }, alpha: { duration: 0.2, delay: 0.3 } },
    }
  }

  get hiddenPosY() {
    return 1470
  }

  get visiblePosY() {
    return 1030
  }

  get hiddenPosX() {
    return 2000
  }

  get visiblePosX() {
    return 1930
  }

  set show(show) {
    this._show = show
    if (show) {
      this.startShowTimeout()
    } else {
      this.stopShowTimeout()
    }
  }

  get show() {
    return this._show
  }

  _init() {
    this._show = false
    this._showTimeout = 0
    this._showTimeoutCancelled = false
    this._hideTime = 4000
  }

  startShowTimeout() {
    this.stopShowTimeout()
    this.makeBannerVisible(true)
    this._showTimeoutCancelled = false
    this._showTimeout = setTimeout(() => {
      if (this._showTimeoutCancelled === false) {
        this.stopShowTimeout()
      }
    }, this._hideTime)
  }

  stopShowTimeout() {
    this._showTimeoutCancelled = true
    this.makeBannerVisible(false)
    clearTimeout(this._showTimeout)
  }

  makeBannerVisible(makeVisible) {
    let transitions = undefined
    if (makeVisible) {
      transitions = {
        x: { duration: 0.3 },
        alpha: { duration: 0.1, delay: 0, timingFunction: 'linear' },
      }
    } else {
      transitions = {
        x: { duration: 0.3 },
        alpha: { duration: 0.1, delay: 0.2, timingFunction: 'linear' },
      }
    }
    this.transitions = transitions
    this.setSmooth('x', makeVisible ? this.visiblePosX : this.hiddenPosX)
    this.setSmooth('alpha', makeVisible ? 1 : 0.0001)
  }
}
