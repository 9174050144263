import { Colors, Language, Lightning, Log, Router } from '@lightningjs/sdk'
import { isBoolean, isNumber, isStringWithLength } from '../../utils/checks'
import {
  isDateToday,
  isDateTomorrow,
  isoStringToDate,
  isoStringToTime,
  secToHrsMinSec,
  secToMin,
} from '../../utils/time'
import CSPANImage from '../CSPANImage'
import { ROUTE } from '../../constants/routes'
import { COLORS } from '../../constants/colors'
import { voiceGuide } from '../../services/voiceGuide'

export default class VideoCard extends Lightning.Component {
  static _template() {
    return {
      w: 398,
      h: 359,
      Image: {
        w: 398,
        h: 226,
        type: CSPANImage,
        preset: 'normal',
        radius: 10,
        strokeFocused: 5,
        strokeUnfocused: 0,
      },
      Date: {
        y: 244,
        color: Colors(COLORS.DARKGREY_60).get(),
        text: {
          text: '',
          fontSize: 20,
          fontFace: 'PoppinsRegular',
          fontStyle: '400',
          lineHeight: 30,
          maxLines: 1,
          wordWrapWidth: 398,
        },
      },
      Title: {
        y: 275,
        color: Colors(COLORS.LIGHTGREY).get(),
        text: {
          advancedRenderer: true,
          text: '',
          fontSize: 24,
          fontFace: 'PoppinsSemiBold',
          lineHeight: 32,
          maxLines: 3,
          wordWrapWidth: 398,
        },
      },
    }
  }

  //Card total width
  static get width() {
    return 398
  }

  //Card total height
  static get height() {
    return 359
  }

  set isliveStream(isliveStream) {
    this._isliveStream = isBoolean(isliveStream) ? isliveStream : false
  }

  get isliveStream() {
    return isBoolean(this._isliveStream) ? this._isliveStream : false
  }

  set item(item) {
    this._item = item
    this.isliveStream = item.isliveStream
    let title = isStringWithLength(item.title) ? item.title : ''
    let dateText = undefined
    if (this.isliveStream === true) {
      this.tag('Image').liveBadge = item
      dateText = this._styleTimeStampLive(item.date, item.liveBeginTime)
    } else {
      dateText = this._styleTimeStamp(item.date, item.videoDuration)
    }

    this.tag('Image').image = item.imagePath
    this.patch({
      Date: { text: { text: dateText } },
      Title: { text: { text: title } },
    })
  }

  get item() {
    return this._item ? this._item : undefined
  }

  set listIsActive(listIsActive) {
    this._listIsActive = listIsActive
    this.tag('Image').activate = listIsActive
    this.tag('Title').color = listIsActive
      ? Colors(COLORS.LIGHTGREY).get()
      : Colors(COLORS.LIGHTGREY)
          .alpha(0.5)
          .get()
  }

  get listIsActive() {
    return typeof this._listIsActive === 'boolean' ? this._listIsActive : false
  }

  _handleEnter() {
    const itemType = this._item.itemType
    const id = this._item.id

    if (itemType && id) {
      Router.navigate(`${ROUTE.VIDEO}/${this._item.itemType}/${this._item.id}`)
    } else {
      Log.warn('missing params ', { itemType, id })
    }
  }

  _focus() {
    this.tag('Image').patch({ smooth: { y: -10 } })
    this.tag('Image').highlight = true
    const title = (this._item && this._item.title) || ''
    voiceGuide.utterThis(title)
  }

  _unfocus() {
    this.tag('Image').patch({ smooth: { y: 0 } })
    this.tag('Image').highlight = false
  }

  _styleTimeStamp(date, videoDuration) {
    const textDate = isStringWithLength(date) ? isoStringToDate(date) : undefined
    let dateText = textDate ? `${textDate.monthName} ${textDate.day}, ${textDate.year}` : ''
    if (isNumber(videoDuration)) {
      let minutes = Math.floor(secToMin(videoDuration))
      let time = secToHrsMinSec(videoDuration)
      if (minutes > 0) {
        if (minutes <= 60) {
          dateText = `${dateText} | ${minutes} ${Language.translate('minutes_min')}`
        } else {
          dateText = `${dateText} | ${time.hours} ${Language.translate('hours_h')} ${
            time.minutes
          } ${Language.translate('minutes_m')}`
        }
      } else {
        if (videoDuration < 60) {
          dateText = `${dateText} | ${videoDuration} ${Language.translate('seconds_secs')}`
        } else {
          dateText = `${dateText}`
        }
      }
    }
    return dateText
  }

  _styleTimeStampLive(date, liveBeginTime) {
    const textDate = isStringWithLength(date) ? isoStringToDate(date) : undefined
    const textTime = isStringWithLength(liveBeginTime) ? isoStringToTime(liveBeginTime) : undefined

    let dateText = textDate ? `${textDate.monthName} ${textDate.day}, ${textDate.year}` : ''
    let timeText = textTime
      ? `${textTime.amPmHours}:${textTime.minutes} ${textTime.amPm.toUpperCase()}`
      : ''

    if (isDateToday(date)) {
      //Today | 16:00PM
      dateText = `Today | ${timeText}`
    } else if (isDateTomorrow(date)) {
      //Tomorrow | 16:00PM
      dateText = `${Language.translate('date_tomorrow')} | ${timeText}`
    } else {
      //November 28, 2022 | 16:00PM
      dateText = `${dateText} | ${timeText}`
    }

    return dateText
  }
}
