import { Log } from '@lightningjs/sdk'
import { API } from '../../../constants/api'
import HomeListAsset from '../../../api/assets/HomeListAsset'
import { getRankset, getSeasons, getSeries, getTags } from '../../../api/Api'
import { isArrayWithLength, isStringWithLength } from '../../../utils/checks'
import { findEndedAndUpcomingInRow, removeIndexesFromData } from '../../../utils/cspan'
import { removeUndefinedFromArray } from '../../../utils/tools'

export class ContentData {
  constructor(homeContent) {
    this._homeContent = homeContent
  }

  _tryLoadInline(callAsset) {
    return new Promise(resolve => {
      const homeListAsset = this._getHomeListAsset(callAsset.contents, callAsset)
      resolve(homeListAsset)
    })
  }

  _tryLoadRankset(callAsset) {
    return getRankset(callAsset.id, callAsset.params)
      .then(row => {
        return this._getHomeListAsset(row, callAsset)
      })
      .catch(() => {
        // Catch is required here
      })
  }

  _tryLoadTags(callAsset) {
    return getTags(callAsset.id, callAsset.params)
      .then(row => {
        if (isArrayWithLength(row)) {
          row.forEach(item => {
            if (!isStringWithLength(item.itemType)) {
              item.itemType = 'tag'
            }
          })
        }
        return this._getHomeListAsset(row, callAsset)
      })
      .catch(() => {
        // Catch is required here
      })
  }

  _tryLoadSeries(callAsset) {
    return getSeries(callAsset.id, callAsset.params)
      .then(row => {
        if (isArrayWithLength(row)) {
          row.forEach(item => {
            if (!isStringWithLength(item.itemType)) {
              item.itemType = 'series'
            }
          })
        }
        return this._getHomeListAsset(row, callAsset)
      })
      .catch(() => {
        // Catch is required here
      })
  }

  _tryLoadSeason(callAsset) {
    return getSeasons(callAsset.id, callAsset.params)
      .then(row => {
        if (isArrayWithLength(row)) {
          row.forEach(item => {
            if (!isStringWithLength(item.itemType)) {
              item.itemType = 'season'
            }
          })
        }
        return this._getHomeListAsset(row, callAsset)
      })
      .catch(() => {
        // Catch is required here
      })
  }

  _tryLoadHouseAndSenate(callAsset) {
    return new Promise(resolve => {
      const homeListAsset = this._getHomeListAsset(callAsset.contents, callAsset)
      resolve(homeListAsset)
    })
  }

  _getHomeListAsset(row, callAsset) {
    if (isArrayWithLength(row)) {
      return new HomeListAsset(row, callAsset)
    } else {
      callAsset.failed = true
      return undefined
    }
  }

  // Make api call based on type, id and params
  tryLoad(callAsset) {
    return new Promise(resolve => {
      // Check if not already rendered, and did not fail before
      if (
        callAsset &&
        !this._homeContent._contentRenderer.isCallAssetRendered(callAsset) &&
        callAsset.failed === false
      ) {
        switch (callAsset.type) {
          case 'Rankset':
            return this._tryLoadRankset(callAsset).then(result => {
              resolve(result)
            })
          case 'Tag':
            return this._tryLoadTags(callAsset).then(result => {
              resolve(result)
            })
          case 'Series':
            return this._tryLoadSeries(callAsset).then(result => {
              resolve(result)
            })
          case 'Season':
            return this._tryLoadSeason(callAsset).then(result => {
              resolve(result)
            })
          case 'inline':
            return this._tryLoadInline(callAsset).then(result => {
              resolve(result)
            })
          default:
            Log.warn('HomeContent.js > loadmore() - invalid type ', callAsset.type)
            callAsset.failed = true
            resolve(undefined)
        }
      } else {
        resolve(undefined)
      }
    })
  }

  loadHomeData() {
    const self = this
    let containsLive = false

    let calls = []
    this._homeContent._data.callInfo.forEach(async callAsset => {
      calls.push(this.tryLoad(callAsset))
    })

    function sortArrayByWeight(callAssetArray) {
      return callAssetArray.sort((a, b) => a.weight - b.weight)
    }

    return Promise.all(calls)
      .then(listData => {
        listData = sortArrayByWeight(listData)
        listData = removeUndefinedFromArray(listData)

        listData.forEach((homelist, index) => {
          let callAsset = listData[index]
          if (homelist.id === API.CALLID.LIVESTREAM) {
            // Find and remove expired items from row
            let listDataEndedIndexes = findEndedAndUpcomingInRow(homelist._row)
            homelist._row = removeIndexesFromData(listDataEndedIndexes, homelist._row)

            //Fire initial $onLiveContainerUpdate from creation, after creation will be fired from LiveContainer.js
            self._homeContent.$onLiveContainerUpdate({ status: 'start', template: 'pending' })
            containsLive = true
            self._homeContent._contentRenderer.renderComponent(homelist, 'bottom', callAsset)
          } else {
            self._homeContent._contentRenderer.renderComponent(homelist, 'bottom', callAsset)
          }
        })
      })
      .then(() => {
        const liveComponent = self._homeContent.tag('List').childList._items[0]
        liveComponent.onHeightChange = () => {
          self._homeContent.tag('List').repositionItems()
        }

        liveComponent.onHeightChangeFinish = () => {
          self._homeContent.tag('List').repositionItems()
        }
        return Promise.resolve(containsLive)
      })
  }
}
