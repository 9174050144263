import { Lightning, Router, Utils, Log } from '@lightningjs/sdk'
import Menu from '@/components/Menu/Menu'
import { HOME_MENU, MENU_NAMES } from '@/constants/menu'

export default class Header extends Lightning.Component {
  static _template() {
    return {
      w: 1920,
      h: 146,
      y: 0,
      Logo: {
        x: 94,
        y: 65,
        src: Utils.asset('images/logo.png'),
      },
      Menu: {
        x: 643,
        y: 100,
        type: Menu,
        menuListName: MENU_NAMES.HEADER,
        content: HOME_MENU,
        spacing: 40,
        dir: 'row',
      },
    }
  }

  get defaultY() {
    return 146
  }

  get defaultHeight() {
    return 146
  }

  get Menu() {
    return this.tag('Menu')
  }

  get Logo() {
    return this.tag('Logo')
  }

  _getFocused() {
    return this.Menu
  }

  _onActivated(page) {
    const routeLabel = page[Router.symbols.route]
    this.Menu.activatePageIndicator(routeLabel)
    this.showhideLogo(1)
  }

  _handleRight() {
    this.Menu.headerHandleRight()
  }

  _handleLeft() {
    this.Menu.headerHandleLeft()
  }

  _handleUp() {
    //Do nothing
  }

  _handleDown() {
    Router.focusPage()
  }

  showhideLogo(alphaVal) {
    this.Logo.setSmooth('alpha', alphaVal)
  }

  rightAlignMenu() {
    const rightXpos = 1815
    const newXpos = rightXpos - this.Menu.MenuList.w
    this.patch({
      Menu: {
        x: newXpos,
      },
    })
  }

  trackHeader(lightningComponent, startPos = 217) {
    //304
    Log.info(' Track Header Called')
    if (lightningComponent !== undefined) {
      lightningComponent.core.onAfterUpdate = () => {
        if (lightningComponent) {
          lightningComponent.core.onAfterUpdate = () => {
            let newCornerPoints = lightningComponent.core.getCornerPoints()
            this.patch({
              y: newCornerPoints[1] - startPos,
            })
          }
        }
      }
    } else {
      this.patch({
        y: 0,
      })
    }
  }
}
