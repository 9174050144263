import { Lightning } from '@lightningjs/sdk'
import { List } from '@lightningjs/ui'
import { isNumber } from '../../utils/checks'
import HouseAndSenateCard from './HouseAndSenateCard'

export default class HouseAndSenateList extends Lightning.Component {
  static _template() {
    return {
      w: 1920,
      h: 300,
      List: {
        y: 100,
        x: 960,
        w: 1420,
        mountX: 0.5,
        type: List,
        direction: 'row',
        spacing: 40,
        signals: { onIndexChanged: true },
        scrollTransition: { duration: 0.8 },
      },
    }
  }

  static get width() {
    return 1128
  }

  static get height() {
    return 300
  }

  set index(index) {
    this._index = index
    this.tag('List').setIndex(index)
  }

  get index() {
    return isNumber(this._index) ? this._index : 0
  }

  set title(title) {
    this._title = title
  }

  get title() {
    return this._title ? this._title : ''
  }

  set callAsset(callAsset) {
    this._callAsset = callAsset
  }

  get callAsset() {
    return this._callAsset
  }

  set data(items) {
    if (Array.isArray(items)) {
      this._data = items
      items.forEach(item => {
        this.tag('List').add({ type: HouseAndSenateCard, item, listIsActive: false })
      })
    }
  }

  get data() {
    return this._data && Array.isArray(this._data) ? this._data : undefined
  }

  _activateList(activate) {
    this.fireAncestors('$cellIndexChanged', this.index)
    this.tag('List').items.forEach(listItem => {
      listItem.listIsActive = activate
    })
  }

  _focus() {
    this._setState('List')
  }

  _unfocus() {
    this._setState('ExitState')
  }

  onIndexChanged({ index }) {
    this._index = index
    this.fireAncestors('$cellIndexChanged', index)
  }

  clear() {
    this.tag('List').clear()
  }

  static _states() {
    return [
      class List extends this {
        $enter() {
          this._activateList(true)
        }

        $exit() {
          this._activateList(false)
        }

        _handleLeft() {
          //Jump to last item when list is at start
          this.tag('List').last()
        }

        _handleRight() {
          //Jump to first item when list is at end
          this.tag('List').first()
        }

        _getFocused() {
          return this.tag('List')
        }
      },
      class ExitState extends this {},
    ]
  }
}
