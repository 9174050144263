import { Log, Utils } from '@lightningjs/sdk'
import { isStringWithLength } from './checks'

export const isFiveMinutesBeforeStart = startTime => {
  const currentTime = new Date()
  let difference = (startTime.getTime() - currentTime.getTime()) / 1000
  difference /= 60
  const differenceInMinutes = Math.abs(Math.round(difference))
  return differenceInMinutes <= 5
}

export const isXMinutesBeforeStart = (startTime, minutesBeforeStart) => {
  const currentTime = new Date()
  const timeStart = new Date(startTime)
  let difference = (timeStart.getTime() - currentTime.getTime()) / 1000
  difference /= 60
  const differenceInMinutes = Math.abs(Math.round(difference))
  return differenceInMinutes <= minutesBeforeStart
}

export const isCurrentTimeBetweenStartEnd = (startTime, endTime) => {
  const currentTime = new Date()
  const timeStart = new Date(startTime)
  const timeEnd = new Date(endTime)
  return currentTime <= timeEnd && currentTime >= timeStart
}
export const sortVideoByDate = (videoItems, sortByName, order = 'Ascending') => {
  return videoItems.sort((videoItemA, videoItemB) => {
    let videoDateA = new Date(videoItemA[sortByName])
    let videoDateB = new Date(videoItemB[sortByName])

    return order === 'Ascending' ? videoDateA - videoDateB : videoDateB - videoDateA
  })
}
export const hasYetToStart = startTime => {
  const currentTime = new Date()
  const timeStart = new Date(startTime)
  return currentTime < timeStart
}

export const hasEnded = endTime => {
  const currentTime = new Date()
  const timeEnd = new Date(endTime)
  return currentTime > timeEnd
}

export const areDatesTheSame = (objA, objB) => {
  const beginTimeA = objA && objA.liveBeginTime ? Date(objA.liveBeginTime) : false
  const endTimeA = objA && objA.liveEndTime ? Date(objA.liveEndTime) : false
  const beginTimeB = objB && objB.liveBeginTime ? Date(objB.liveBeginTime) : false
  const endTimeB = objB && objB.liveEndTime ? Date(objB.liveEndTime) : false

  if (beginTimeA && beginTimeB && endTimeA && endTimeB) {
    return beginTimeA === beginTimeB && endTimeA === endTimeB
  } else {
    return false
  }
}

export const getVideoLiveState = (startTime, endTime) => {
  if (startTime === undefined || endTime === undefined) return 'unknown'
  let status = ''

  if (hasYetToStart(startTime)) {
    if (isXMinutesBeforeStart(startTime, 15)) {
      status = 'soon' // 15 mins before live
    } else {
      status = 'upcoming' // later than 15 mins before live
    }
  } else if (isCurrentTimeBetweenStartEnd(startTime, endTime)) {
    status = 'live'
  } else if (hasEnded(endTime)) {
    status = 'ended'
  } else {
    Log.warn('getVideoLiveState() - status unknown ', { startTime, endTime })
    status = 'unknown'
  }
  return status
}

export const sortLiveVideoByDate = liveVideos => {
  return liveVideos.sort((liveVideoA, liveVideoB) => {
    let liveVideoDateA = new Date(liveVideoA.liveBeginTime)
    let liveVideoDateB = new Date(liveVideoB.liveBeginTime)
    return liveVideoDateA - liveVideoDateB
  })
}
export const adjustToItalic = desc => {
  if (!(desc && typeof desc === 'string')) return desc
  const regExpForItalicText = /(\[[^\]]+\])/gi
  const regExpForSquareBrackets = /[[\]]+/gi
  return desc.replace(regExpForItalicText, '<i>$&</i>').replace(regExpForSquareBrackets, '')
}
export const findEndedInRow = row => {
  let endedItems = []
  row.forEach((liveCard, index) => {
    let status = getVideoLiveState(liveCard.liveBeginTime, liveCard.liveEndTime)
    if (status === 'ended' || status === 'unknown') {
      endedItems.push(index)
    }
  })
  return endedItems
}

export const findEndedAndUpcomingInRow = row => {
  let endedItems = []
  if (!row) return endedItems
  if (Array.isArray(row) && row.length <= 0) return endedItems
  row.forEach((liveCard, index) => {
    let status = getVideoLiveState(liveCard.liveBeginTime, liveCard.liveEndTime)
    if (status === 'ended' || status === 'unknown' || status === 'upcoming') {
      endedItems.push(index)
    }
  })
  return endedItems
}

export const isLiveCardEndedOrUpcoming = liveCard => {
  if (liveCard === undefined) return 'unknown'
  let status = getVideoLiveState(liveCard.liveBeginTime, liveCard.liveEndTime)
  return status === 'ended' || status === 'unknown' || status === 'upcoming'
}

export const getColorCodeFromString = color => {
  return typeof color === 'string' && color.length > 7 ? '#' + color.slice(4) : color
}

//Remove array of indexes from given dataset and return
export const removeIndexesFromData = (arrayOfIndexes, dataArray) => {
  if (!dataArray) return []
  return dataArray.filter((liveCard, index) => {
    return !arrayOfIndexes.includes(index)
  })
}
// 398x226
// 345x170

//--- getLocalImage - image index -----
//series 5: Book TV - Undefined
//series 6: Booknotes
//series 13: Washington Journal
//series 30: Q&A
//series 32: The Capitol - 345x170
//series 55: Lectures in History
//series 63: In Depth
//series 64: After Words
//series 68: The Civil War
//series 69: The Presidency
//series 70: American History TV - Undefined
//series 90: Landmark Cases
//series 94: U.S. House
//series 96: U.S. Senate

//season 27: Campain 2024

//program 240374: The Supreme Court
//program 517933: The Senate
//program 245135: The White House
//program 165708: The Capitol
//-------------------------------------
export const getLocalImage = (type, id, sizeFormat = 'medium') => {
  if (isStringWithLength(type)) type = type.toLowerCase()
  if (id) id = id.toString()
  const availableTypes = ['series', 'season', 'program']
  const availableSizeFormats = ['small', 'medium']
  sizeFormat = availableSizeFormats.includes(sizeFormat) ? sizeFormat : 'medium'
  const resolution = sizeFormat === 'medium' ? '398x226' : '345x170'
  const availableIds = {
    series: ['5', '6', '13', '30', '32', '55', '63', '64', '68', '69', '70', '90', '94', '96'],
    season: ['27'],
    program: ['240374', '517933', '245135', '165708'],
  }

  if (availableTypes.includes(type)) {
    switch (type) {
      case 'series':
        return getSeriesImage(id, resolution)
      case 'season':
        return getSeasonImage(id, resolution)
      case 'program':
        return getProgramImage(id, resolution)
    }
  } else {
    return undefined
  }

  function getSeriesImage(id, resolution) {
    if (availableIds.series.includes(id)) {
      const imageUrl = Utils.asset(`images/cards/${resolution}/series_${id}.jpg`)
      if (imageUrl) {
        return imageUrl
      } else {
        return undefined
      }
    } else {
      return undefined
    }
  }

  function getSeasonImage(id, resolution) {
    if (availableIds.season.includes(id)) {
      const imageUrl = Utils.asset(`images/cards/${resolution}/season_${id}.jpg`)
      if (imageUrl) {
        return imageUrl
      } else {
        return undefined
      }
    } else {
      return undefined
    }
  }

  function getProgramImage(id, resolution) {
    if (availableIds.program.includes(id)) {
      const imageUrl = Utils.asset(`images/cards/${resolution}/program_${id}.jpg`)
      if (imageUrl) {
        return imageUrl
      } else {
        return undefined
      }
    } else {
      return undefined
    }
  }
}
