import KeyboardButton from '@/components/Search/Keyboard/KeyboardButton.js'
import { KeyboardTemplate, INPUT_TYPE } from '@/constants/search.js'
import { Lightning, Log } from '@lightningjs/sdk'

export default class Keyboard extends Lightning.Component {
  static _template() {
    return {}
  }
  set value(v) {
    if (v.length <= this.maxCharacters) {
      this._value = v
    }
    this.fireAncestors('$keyboardValueChanged', { value: this._value })
  }

  get value() {
    return this._value
  }

  set layout(layout) {
    this._layout = layout

    this._update()
  }

  get layout() {
    return this._layout
  }

  get keyboardTemplate() {
    return KeyboardTemplate
  }

  get keyboardButton() {
    return KeyboardButton
  }

  get maxCharacters() {
    return 15
  }

  get rows() {
    return this.children
  }

  get rowLength() {
    return this.rows[this.rowIndex].children.length
  }

  get currentKey() {
    return this.children[this.rowIndex].children[this.colIndex]
  }

  _init() {
    this.reset()
    this._update()
  }

  _handleRight() {
    if (
      this.layout === 'search' &&
      // eslint-disable-next-line prettier/prettier
      ((this.rowIndex === 0 && this.colIndex === 1) ||
        (this.rowIndex > 0 && this.colIndex >= 5))
    ) {
      this.fireAncestors('$onKeyboardExitRight')
    } else {
      return this._navigate('right', 1)
    }
  }

  _handleLeft() {
    return this._navigate('left', -1)
  }

  _handleUp() {
    return this._navigate('up', -1)
  }

  _handleBack() {
    if (!this.value || !this.value.length) return false
    this.value = this.value.slice(0, -1)
  }

  _handleDown() {
    if (this.layout === 'search' && this.rowIndex >= 6) {
      this.fireAncestors('$onKeyboardExitDown')
    } else if (this.layout === 'numerical' && this.rowIndex >= 1) {
      this.fireAncestors('$onKeyboardExitDown')
      // eslint-disable-next-line prettier/prettier
    } else if (
      this.layout !== 'search' &&
      this.layout !== 'numerical' &&
      this.rowIndex >= 7
    ) {
      this.fireAncestors('$onKeyboardExitDown')
    } else {
      return this._navigate('down', 1)
    }
  }

  _handleEnter() {
    const key = this.currentKey
    switch (key.action) {
      case 'input':
        this.value += key.c
        break
      case 'backspace':
        this.value = this.value.slice(0, -1)
        break
      case 'space':
        if (this.value.length > 0) {
          this.value += ' '
        }
        break
      case 'delete':
        this.value = ''
        break
      case 'search':
        this.signal('onKeyboardInputUpdate', this.value)
        break
      case 'toggleToLayout':
        this.layout = key.toLayout
        break
      default:
        this.signal(key.action)
    }
  }

  _handleKey(keydata) {
    let tempRow = -1,
      tempCol = -1,
      template = this.keyboardTemplate.layouts[this._layout]

    template.rows.forEach((row, rowIndex) => {
      row.keys.forEach((col, colIndex) => {
        let key = template.rows[rowIndex].keys[colIndex]

        if (key.c && key.c.toLowerCase() === keydata.key.toLowerCase()) {
          tempRow = rowIndex
          tempCol = colIndex

          return false
        }
      })
    })

    if (tempRow === -1 || tempCol === -1) {
      return false
    }

    this.colIndex = tempCol
    this.rowIndex = tempRow

    this._handleEnter()
  }

  _getFocused() {
    return this.currentKey
  }

  _navigate(direction, value) {
    // eslint-disable-next-line prettier/prettier
    let dir =
      direction === 'up' || direction === 'down' ? 'vertical' : 'horizontal'

    if (
      dir === 'horizontal' &&
      this.colIndex + value < this.rowLength &&
      this.colIndex + value > -1
    ) {
      this.previous = null

      return (this.colIndex += value)
    } else if (
      dir === 'vertical' &&
      this.rowIndex + value < this.rows.length &&
      this.rowIndex + value > -1
    ) {
      const currentColIndex = this.colIndex,
        targetRow = this.rowIndex + value

      if (this.previous && this.previous.row === targetRow) {
        const tmp = this.previous.col

        this.previous.col = this.colIndex
        this.colIndex = tmp
      } else {
        const targetRow = this.children[this.rowIndex + value],
          targetItems = targetRow.children,
          ck = this.currentKey

        let target = 0

        for (let i = 0; i < targetItems.length; i++) {
          const ckx = this.children[this.rowIndex].x + ck.x,
            tix = targetRow.x + targetItems[i].x

          // eslint-disable-next-line prettier/prettier
          if (
            direction === 'down' &&
            this.rowIndex === 5 &&
            this.colIndex <= 3
          ) {
            target = 0
            // eslint-disable-next-line prettier/prettier
          } else if (
            direction === 'down' &&
            this.rowIndex === 5 &&
            this.colIndex > 3
          ) {
            target = 1
          } else if (
            (ckx >= tix && ckx <= tix + targetItems[i].w) ||
            (tix >= ckx && tix <= ckx + ck.w)
          ) {
            target = i
            break
          }
        }

        this.colIndex = target
      }

      this.previous = { col: currentColIndex, row: this.rowIndex }

      return (this.rowIndex += value)
    }

    if (direction === 'up') {
      this.fireAncestors('$handleUpKeyboard')
    }

    return false
  }

  _update() {
    // eslint-disable-next-line prettier/prettier
    if (
      this._layout &&
      this.keyboardTemplate.layouts[this._layout] === undefined
    ) {
      Log.info(
        `Configured layout "${this.layout}" does not exist. Reverting to "${
          Object.keys(this.keyboardTemplate.layouts)[0]
        }"`
      )
      this._layout = null
    }

    if (!this._layout) {
      this._layout = Object.keys(this.keyboardTemplate.layouts)[0]
    }

    const {
      keyWidth,
      keyHeight,
      horizontalSpacing = 0,
      verticalSpacing = 0,
      layouts,
    } = this.keyboardTemplate

    this.children = layouts[this._layout].rows.map((row, rowIndex) => {
      let keyOffset = 0

      const {
        x = 0,
        // eslint-disable-next-line prettier/prettier
        rowVerticalSpacing = row.keys[0].action === INPUT_TYPE.SEARCH
          ? 20
          : verticalSpacing,
        // eslint-disable-next-line prettier/prettier
        rowHorizontalSpacing = row.keys[0].action === INPUT_TYPE.SPACE
          ? 20
          : horizontalSpacing,
        keys = [],
      } = row
      return {
        y: keyHeight * rowIndex + rowIndex * rowVerticalSpacing,
        x,
        children: keys.map(key => {
          key = Object.assign({ action: 'input' }, key)

          let { w = keyWidth, xPos, h = keyHeight, action, toLayout } = key
          if (action === INPUT_TYPE.SPACE) {
            w = key.w
          }
          xPos = xPos || 0
          let prevOffset = xPos + keyOffset

          keyOffset += xPos + w + rowHorizontalSpacing

          return {
            key,
            action,
            toLayout,
            x: prevOffset,
            w,
            h,
            type: this.keyboardButton,
          }
        }),
      }
    })

    this.colIndex = 0
    this.rowIndex = 0
  }

  reset() {
    this.colIndex = 0
    this.rowIndex = 0
    this._value = ''
    this.previous = null
  }
}
