import { Lightning, Colors } from '@lightningjs/sdk'
import { COLORS } from '../constants/colors'

export default class Background extends Lightning.Component {
  static _template() {
    return {
      y: 0,
      x: 0,
      zIndex: -8,
      Background: {
        y: 0,
        x: 0,
        w: 1920,
        h: 2160,
        BG1: {
          x: 0,
          y: 0,
          w: 1920,
          h: 1080,
          rect: true,
          color: Colors(COLORS.BACKGROUND_GRADIENT_TOP).get(),
        },
        BG2: {
          w: 1920,
          h: 1080,
          x: 0,
          y: 1080,
          rect: true,
          color: Colors(COLORS.BACKGROUND_GRADIENT_BOTTOM).get(),
        },
        Gradient: {
          w: 1920,
          h: 270,
          x: 0,
          y: 810,
          rect: true,
          colorTop: Colors(COLORS.BACKGROUND_GRADIENT_TOP).get(),
          colorBottom: Colors(COLORS.BACKGROUND_GRADIENT_BOTTOM).get(),
        },
        transitions: { y: { duration: 0.3 } },
      },
    }
  }

  startPosition() {
    this.tag('Background').y = 0
  }

  moveBackground(index) {
    const positions = [0, 360, 720, 1080]
    if (index <= 0) {
      this.tag('Background').patch({ smooth: { y: positions[0] } })
    } else if (index >= positions.length) {
      this.tag('Background').patch({ smooth: { y: -positions[3] } })
    } else {
      this.tag('Background').patch({ smooth: { y: -positions[index] } })
    }
  }
}
