import { Lightning, Language, Router } from '@lightningjs/sdk'
import { List } from '@lightningjs/ui'
import MenuItem from './MenuItem'
import { TYPE_LABEL, MENU_NAMES } from '../../constants/menu'
import { ROUTE } from '../../constants/routes'
import { getWidget } from '@/utils/tools'

export default class Menu extends Lightning.Component {
  static _template() {
    return {
      MenuList: {
        type: List,
        autoResize: true,
        spacing: 40,
        direction: 'row',
      },
    }
  }

  set menuListName(menuListName) {
    this._menuListName = menuListName
  }

  get menuListName() {
    return this._menuListName
  }

  set content(content) {
    this._content = content
    this.updateMenuItems()
  }

  get MenuList() {
    return this.tag('MenuList')
  }

  get currentItem() {
    return this.MenuList.currentItem
  }

  get index() {
    return this.MenuList.index
  }

  _init() {
    this._isFirstTimeLoad = false
  }

  _getFocused() {
    return this.MenuList
  }

  _unfocus() {
    if (this.menuListName === MENU_NAMES.HEADER) {
      this.MenuList.index = this.MenuList.UnderlineIndex
    }
  }

  _handleEnter() {
    switch (this.menuListName) {
      case MENU_NAMES.HEADER:
        this.headerMenuHandler()
        break
      case MENU_NAMES.SETTINGS:
        this.settingsMenuHandler()
        break
    }
  }

  _handleBack() {
    // Router.back()
    const historyLength = Router.getHistory() ? Router.getHistory().length : 0
    if (historyLength > 0) {
      Router.back()
    } else {
      if (Router.getActiveRoute() === ROUTE.HOME) {
        Router.back()
      } else {
        if (Router.getActiveRoute() === ROUTE.HOME) {
          Router.back()
        } else {
          Router.navigate(ROUTE.HOME)
        }
      }
    }
  }

  updateMenuItems() {
    this.MenuList.direction = this.dir
    this.MenuList.spacing = this.spacing
    switch (this.menuListName) {
      case MENU_NAMES.HEADER:
        this.updateHeaderMenu()
        break
      case MENU_NAMES.SETTINGS:
        this.updateSettingsMenu()
        break
    }
  }

  updateHeaderMenu() {
    let items = this._content.map(item => ({
      w: item.w,
      h: item.h,
      routePath: ROUTE[item.navLink],
      type: MenuItem,
      [item.menuType]:
        item.menuType === TYPE_LABEL
          ? Language.translate('homeMenu')[item['labelLangKey']]
          : item.icon,
      speechText: item.speechText,
      menuType: item.menuType,
      toggled: false,
    }))

    //Need to use patch, since wigets are early rendered
    this.MenuList.patch({
      type: List,
      autoResize: true,
      spacing: 40,
      direction: 'row',
      items,
    })
  }

  updateSettingsMenu() {
    let items = this._content.map(item => ({
      h: item.h,
      type: MenuItem,
      [item.menuType]: item.label,
      menuType: item.menuType,
      speechText: item.speechText || item.label || '',
    }))

    // Can use items here since template is ready
    this.MenuList.items = items
    this.activatePageIndicator()
  }

  headerHandleLeft() {
    if (this.MenuList.length) this.MenuList.index = this.MenuList.length - 1
  }

  headerHandleRight() {
    this.MenuList.index = 0
  }

  headerMenuHandler() {
    const routePath = this.currentItem.routePath
    if (routePath != '') {
      Router.navigate(routePath)
    }
  }

  settingsMenuHandler() {
    this.signal('onMenuHanldeEnter', this.index)
    this.activatePageIndicator()
  }

  activatePageIndicator(routeLabel = undefined) {
    if (!this._isFirstTimeLoad) {
      const lastElement = this.MenuList.childList.last
      lastElement.core._onAfterUpdate = () => {
        this._isFirstTimeLoad = true

        this.enableMenuUnderline(routeLabel)

        lastElement.core._onAfterUpdate = undefined
        if (this.menuListName === MENU_NAMES.HEADER) {
          getWidget('Header').rightAlignMenu()
        }
      }
    } else {
      this.enableMenuUnderline(routeLabel)
    }
  }

  enableMenuUnderline(routeLabel) {
    this.MenuList.items.forEach((item, index) => {
      if (this.menuListName === MENU_NAMES.HEADER) {
        if (item && item.isAlive) item.pageIndicator = item.routePath === routeLabel
        if (item.routePath === routeLabel) {
          this.MenuList.index = this.MenuList.UnderlineIndex = index
        }
      }
      if (this.menuListName === MENU_NAMES.SETTINGS) {
        item.pageIndicator = this.index === index
      }
    })
  }
}
