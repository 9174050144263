import { Lightning, Colors } from '@lightningjs/sdk'
import { COLORS } from '../constants/colors'

export default class TextButton extends Lightning.Component {
  static _template() {
    return {
      w: w => w,
      h: h => h,
      Shadow: {
        alpha: 0,
        color: Colors(COLORS.BLACK).get(),
        x: -10,
        y: -10,
      },
      BG: {},
      Label: {
        x: w => w / 2,
        y: h => h / 2 + 3,
        mount: 0.5,
        text: {
          advancedRenderer: true,
          textAlign: 'center',
        },
      },
    }
  }

  _construct() {
    this._radius = 0
    this._fontSize = 36
    this._fontFace = 'PoppinsRegular'
    this._focusFontColor = Colors(COLORS.BLACK).get()
    this._unfocusFontColor = Colors(COLORS.WHITE).get()
    this._focusBackGroundColor = Colors(COLORS.WHITE).get()
    this._unfocusBackgroundColor = Colors(COLORS.BLACK).get()
    this._label = ''
    this._autoWidth = false
    this._paddingLeft = 30
    this._paddingRight = 30
    this._letterSpacing = 0
  }

  _init() {
    if (this._autoWidth) {
      this.tag('Label').on('txLoaded', () => {
        if (this._autoWidth) {
          this._labelRenderWidth = this.tag('Label').renderWidth
          this._calculateButtonWidth()
        }
      })
    } else {
      this._updateButton()
    }
  }

  _firstActive() {
    if (this._autoWidth) {
      this._setLabel()
    } else {
      this._setLabel()
    }
  }

  set radius(v) {
    this._radius = v
  }

  set label(v) {
    if (this._label !== v) {
      this._label = v
      this._setLabel()
    }
  }

  set fontSize(v) {
    this._fontSize = v
  }

  set fontFace(v) {
    this._fontFace = v
  }

  set focusFontColor(focusFontColor) {
    this._focusFontColor = focusFontColor
  }

  set unfocusFontColor(unfocusFontColor) {
    this._unfocusFontColor = unfocusFontColor
  }

  set focusBackGroundColor(focusBackGroundColor) {
    this._focusBackGroundColor = focusBackGroundColor
  }

  set unfocusBackgroundColor(unfocusBackgroundColor) {
    this._unfocusBackgroundColor = unfocusBackgroundColor
  }

  set autoWidth(v) {
    this._autoWidth = v
  }

  set shadow(v) {
    this._shadow = v
  }

  set padding(v) {
    this._paddingLeft = v
    this._paddingRight = v
  }

  set paddingLeft(v) {
    this._paddingLeft = v
  }

  set paddingRight(v) {
    this._paddingRight = v
  }

  set letterSpacing(v) {
    this._letterSpacing = v
  }

  _setLabel() {
    this.patch({
      Label: {
        text: {
          fontSize: this._fontSize,
          text: this._label,
          textColor: this._isFocus ? this._focusFontColor : this._unfocusFontColor,
          fontFace: this._fontFace,
          letterSpacing: this._letterSpacing,
        },
      },
    })
  }

  _calculateButtonWidth() {
    this.w = this._labelRenderWidth + this._paddingLeft + this._paddingRight
    this._updateButton()
  }

  _updateButton() {
    let [bgColor, textColor] = this._isFocus
      ? [this._focusBackGroundColor, this._focusFontColor]
      : [this._unfocusBackgroundColor, this._unfocusFontColor]
    this.patch({
      Shadow: {
        alpha: 0,
      },
      BG: {
        texture: Lightning.Tools.getRoundRect(this.w, this.h, this._radius, 0, 0, true, bgColor),
      },
      Label: {
        text: {
          textColor,
        },
      },
    })

    if (this._isFocus && this._shadow) {
      this.tag('Shadow').patch({
        alpha: 1,
        w: this.w + 20,
        h: this.h + 20,
        texture: Lightning.Tools.getShadowRect(this.w + 20, this.h + 20, 10, 40, 30),
      })
    }
  }

  _focus() {
    this._isFocus = true
    this._updateButton()
  }

  _unfocus() {
    this._isFocus = false
    this._updateButton()
  }
}
