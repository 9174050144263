export const KeyboardTemplate = {
  keyWidth: 22,
  keyHeight: 48,
  horizontalSpacing: 42,
  verticalSpacing: 22,
  layouts: {
    abc: {
      rows: [
        {
          // eslint-disable-next-line prettier/prettier
					keys: [
						{c: 'a'},
						{c: 'b'},
						{c: 'c'},
						{c: 'd'},
						{c: 'e'},
						{c: 'f'}
					]
        },
        {
          // eslint-disable-next-line prettier/prettier
					keys: [
						{c: 'g'},
						{c: 'h'},
						{c: 'i'},
						{c: 'j'},
						{c: 'k'},
						{c: 'l'}
					]
        },
        {
          // eslint-disable-next-line prettier/prettier
					keys: [
						{c: 'm'},
						{c: 'n'},
						{c: 'o'},
						{c: 'p'},
						{c: 'q'},
						{c: 'r'}
					]
        },
        {
          // eslint-disable-next-line prettier/prettier
					keys: [
						{c: 's'},
						{c: 't'},
						{c: 'u'},
						{c: 'v'},
						{c: 'w'},
						{c: 'x'}
					]
        },
        {
          // eslint-disable-next-line prettier/prettier
					keys: [
						{c: 'y'},
						{c: 'z'},
						{c: '0'},
						{c: '1'},
						{c: '2'},
						{c: '3'}
					]
        },
        {
          // eslint-disable-next-line prettier/prettier
					keys: [
						{c: '4'},
						{c: '5'},
						{c: '6'},
						{c: '7'},
						{c: '8'},
						{c: '9'}
					]
        },
        {
          keys: [
            {
              action: 'space',

              w: 240,

              // source: 'images/search/space-btn.png',
            },
            {
              action: 'backspace',
              w: 100,
              h: 60,
            },
          ],
        },
        {
          keys: [
            {
              action: 'search',

              w: 360,

              // source: 'images/search/search-btn.png',
            },
          ],
        },
      ],
    },
  },
}
export const FOCUS = 'focus'
export const UNFOCUS = 'unfocus'
export const RESULT_COLUMN = 3

export const NewKeys = {
  liveBegin: 'liveBeginTime',
  liveEnd: 'liveEndTime',
  thumbnail: 'imagePath',
  type: 'itemType',
}
export const STATES = {
  SEARCH_RESULT: 'SearchResult',
  KEYBOARD: 'Keyboard',
  LOADER: 'Loader',
  RESULT: 'Result',
}
export const INPUT_TYPE = {
  SEARCH: 'search',
  SPACE: 'space',
  BACKSPACE: 'backspace',
}
export const BUTTONS = {
  search: {
    w: 360,
    h: 60,
    radius: 10,
    fontSize: 28,
    fontFace: 'PoppinsSemiBold',
    fontColor: 'LIGHTGREY',
    bgColor: 'COBALT',
    focusBackGroundColor: 'LIGHTGREY',
    unfocusBackgroundColor: 'COBALT',
    focusFontColor: 'MIDNIGHT',
    unfocusFontColor: 'LIGHTGREY',
  },
  space: {
    x: 0,
    y: 0,
    w: 240,
    h: 60,
    radius: 10,
    fontSize: 22,
    fontFace: 'PoppinsSemiBold',
    fontColor: 'DARKGREY_60',
    bgColor: 'BLACK',
    focusBackGroundColor: 'LIGHTGREY',
    unfocusBackgroundColor: 'BLACK',
    focusFontColor: 'MIDNIGHT',
    unfocusFontColor: 'DARKGREY_60',
  },
  backspace: {
    x: 0,
    y: 0,
    w: 100,
    h: 60,
    radius: 10,
    bgColor: 'BLACK',
    focusBackGroundColor: 'LIGHTGREY',
    unfocusBackgroundColor: 'BLACK',
  },
}
