import { hrsToMs } from '../utils/time'
import { getWidget } from '../utils/tools'
import { Log } from '@lightningjs/sdk'

export default class StillWatchingHandler {
  constructor(app) {
    this._app = app
    this._waitTime = hrsToMs(4) // 4 Hours
    this._initializeEvents()
  }

  startTimer() {
    this.stopTimer()
    this._timerRunning = true
    this._timer = setTimeout(() => {
      if (this._timerRunning) {
        this.showPrompt()
      }
    }, this._waitTime)
  }

  stopTimer() {
    if (this._timer) clearTimeout(this._timer)
    this._timerRunning = false
  }

  showPrompt() {
    const videoCountdown = 30

    if (getWidget('Prompt').isOpen === true) {
      Log.warn('StillWatchingHandler - Prompt is already open')
      return
    }
    this._app.application.emit('stillWatching_featureCard', {
      open: true,
      buttonPressed: undefined,
    })
    this._app.application.emit('stillWatching_videopage', {
      open: true,
      buttonPressed: undefined,
    })
    getWidget('Prompt').content = {
      message: 'Are you still watching?',
      buttons: [
        {
          label: 'Yes',
          action: () => {
            //Emit needs to be unique for each listener
            this._app.application.emit('stillWatching_featureCard', {
              open: false,
              buttonPressed: 'yes',
            })
            this._app.application.emit('stillWatching_videopage', {
              open: false,
              buttonPressed: 'yes',
            })
          },
        },
        {
          label: 'No',
          action: () => {
            //Emit needs to be unique for each listener
            this._app.application.emit('stillWatching_featureCard', {
              open: false,
              buttonPressed: 'no',
            })
            this._app.application.emit('stillWatching_videopage', {
              open: false,
              buttonPressed: 'no',
            })
          },
        },
      ],
      videoCountdown,
      countDownAction: () => {
        //Send no event to pages
        this._app.application.emit('stillWatching_featureCard', {
          open: false,
          buttonPressed: 'no',
        })
        this._app.application.emit('stillWatching_videopage', {
          open: false,
          buttonPressed: 'no',
        })
      },
    }
  }

  _initializeEvents() {
    this._app.application.off('global_onCaptureKey_stillWatching')
    this._app.application.off('global_playerEvent_stillWatching')

    // Capture any key events fired throughout the app - Fired from App.js
    this._app.application.on('global_onCaptureKey_stillWatching', event => {
      this.onKeyEvent(event)
    })

    // Capture any player events fired throughout the app
    this._app.application.on('global_playerEvent_stillWatching', event => {
      switch (event) {
        case 'LoadStart':
        case 'Play':
          this.startTimer()
          break
        case 'Ended':
        case 'Error':
        case 'Clear':
        case 'Abort':
          this.stopTimer()
          break
      }
    })
  }

  onKeyEvent() {
    const isPlaying =
      this._app && typeof this._app.isPlaying === 'boolean' ? this._app.isPlaying : false
    if (isPlaying) {
      // Restart timer
      this.startTimer()
    } else {
      this.stopTimer()
    }
  }
}
