import { Colors, Lightning } from '@lightningjs/sdk'
import { COLORS } from '../../constants/colors'
import { isStringWithLength } from '../../utils/checks'
import { voiceGuide } from '../../services/voiceGuide'

export default class PromptButton extends Lightning.Component {
  static _template() {
    return {
      h: 70,
      flexItem: {
        minWidth: 130,
        marginRight: 20,
        marginBottom: 20,
        maxWidth: 580,
      },
      flex: {
        alignContent: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        wrap: false,
      },
      rect: true,
      color: Colors(COLORS.BLACK)
        .alpha(0.4)
        .get(),
      shader: {
        type: Lightning.shaders.RoundedRectangle,
        radius: 15,
      },
      Label: {
        flexItem: {
          marginLeft: 40,
          marginRight: 40,
        },
        color: Colors(COLORS.DARKGREY_60).get(),
        y: 4,
        text: {
          fontFace: 'PoppinsSemiBold',
          textAlign: 'center',
          verticalAlign: 'middle',
          fontSize: 28,
          lineHeight: 28,
          wordWrapWidth: 500,
          text: '',
          maxLines: 1,
        },
      },
    }
  }

  set label(label) {
    if (isStringWithLength(label)) {
      this._label = label
      this.tag('Label').text.text = label
    }
  }

  get label() {
    return isStringWithLength(this._label) ? this._label : ''
  }

  set action(action) {
    this._action = action
  }

  get action() {
    return this._action
  }

  _focus() {
    const label = this._label || ''
    voiceGuide.utterThis(label, true)
    this.patch({
      color: Colors(COLORS.LIGHTGREY)
        .alpha(1)
        .get(),
      Label: {
        color: Colors(COLORS.MIDNIGHT).get(),
      },
    })
  }

  _unfocus() {
    this.patch({
      color: Colors(COLORS.BLACK)
        .alpha(0.4)
        .get(),
      Label: {
        color: Colors(COLORS.DARKGREY_60).get(),
      },
    })
  }
}
