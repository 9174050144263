import { Lightning, Router, Colors, Metadata } from '@lightningjs/sdk'
import { MENU_NAMES, SETTINGS_MENU_PROPS } from '@/constants/menu'
import { STATES, FEEDBACK } from '@/constants/settings'
import Menu from '@/components/Menu/Menu'
import Details from '@/components/Settings/Details'
import Qrcode from '@/components/Settings/Qrcode'
import ActivationLoggedin from '@/components/Settings/ActivationLoggedin'
import { FONTS } from '@/constants/fonts'
import { COLORS } from '@/constants/colors'
import { COPRYRIGHTS } from '../constants/settings'

export default class Settings extends Lightning.Component {
  static _template() {
    return {
      Menu: {
        x: 104,
        y: 288,
        type: Menu,
        dir: 'column',
        spacing: 30,
        signals: {
          onMenuHanldeEnter: true,
        },
        menuListName: MENU_NAMES.SETTINGS,
      },
      Activation: {
        alpha: 0,
        x: 542,
        y: 275,
        w: 790,
        type: ActivationLoggedin,
      },
      Details: {
        x: 542,
        y: 275,
        w: 790,
        alpha: 0,
        type: Details,
      },
      QRCode: {
        alpha: 0,
        x: 1332,
        y: 274,
        w: 588,
        type: Qrcode,
      },
      Appversion: {
        x: 104,
        y: 910,
        text: {
          fontSize: 24,
          fontFace: FONTS.POPPINS.REGULAR,
          textColor: Colors(COLORS.DARKGREY_60).get(),
          lineHeight: 36,
          verticalAlign: 'middle',
        },
      },
      AppRight: {
        x: 104,
        y: 952,
        text: {
          text: COPRYRIGHTS,
          fontSize: 20,
          fontFace: FONTS.POPPINS.REGULAR,
          textColor: Colors(COLORS.DARKGREY).get(),
          lineHeight: 30,
          verticalAlign: 'middle',
          wordWrapWidth: 252,
        },
      },
    }
  }

  get Menu() {
    return this.tag('Menu')
  }

  get TVProvider() {
    return this.tag('Pages.TVProvider')
  }

  get QRCode() {
    return this.tag('QRCode')
  }

  get Activation() {
    return this.tag('Activation')
  }

  get Details() {
    return this.tag('Details')
  }

  get Appversion() {
    return this.tag('Appversion')
  }

  _init() {
    this.initializeEvent()
  }

  initializeEvent() {
    this.application.on('loggedIn', response => {
      this.activationData = response
      this._loggedIn = response.loggedIn
      this.showHideQRcode(this._loggedIn ? 0 : 1)
      if (this._loggedIn) {
        this._setState(STATES.ACTIVATION)
      } else {
        this._setState(STATES.MENU)
      }
    })
  }

  set data(data) {
    this._data = data
  }
  _renderData() {
    this._url = this._data && this._data.activationData && this._data.activationData.activationUrl
    this._loggedIn = this._data && this._data.activationData && this._data.activationData.loggedIn
    this.activationData = this._data.activationData
  }
  _active() {
    this._renderData()
    this._activeMenuIndex = 0
    this.Appversion.text = `App version: ${Metadata.appVersion()}`
    this._setState(STATES.ACTIVATION)
    this.updateFeedbackUrl()
    this.getMenuData()
  }

  _handleUp() {
    this._setState('Inactive')
  }

  _handleLeft() {
    this._setState(STATES.MENU)
  }

  _handleRight() {
    if (this._getState() === STATES.MENU && this._activeMenuIndex === 0) {
      if (this._loggedIn) {
        this._setState(STATES.EMPTY_STATE)
        this._setState(STATES.ACTIVATION)
      }
    }
  }

  getMenuData() {
    this.Menu.content = this._data.settingsData.map(item => {
      const menuDetail = { ...SETTINGS_MENU_PROPS }
      menuDetail['label'] = item.title
      return menuDetail
    })
  }

  getPageData() {
    return this._data.settingsData[this._activeMenuIndex]
  }

  getPage(page) {
    return this.tag(`Pages.${page}`)
  }

  onMenuHanldeEnter(index) {
    this._activeMenuIndex = index
    if (this._activeMenuIndex === 0) {
      this._setState(STATES.ACTIVATION)
    } else {
      this.Details.updateText(this.getPageData())
      this.QRCode.updateQR(this.getPageData().url)
      this._setState(STATES.DETAILS)
    }
  }

  showHideQRcode(alphaVal) {
    this.QRCode.setSmooth('alpha', alphaVal)
  }

  updateFeedbackUrl() {
    for (const data of this._data.settingsData) {
      if (data.title === FEEDBACK.TITLE) {
        data.url = data.url.replace(FEEDBACK.APP_VERSION, Metadata.appVersion())
        break
      }
    }
  }

  static _states() {
    return [
      class Menu extends this {
        _getFocused() {
          return this.Menu
        }
      },
      class Details extends this {
        $enter() {
          this.Activation.setSmooth('alpha', 0)
          this.Details.setSmooth('alpha', 1)
          this.showHideQRcode(1)
        }

        _getFocused() {
          return this.Menu
        }
      },
      class Activation extends this {
        $enter() {
          this.Details.setSmooth('alpha', 0)
          this.Activation.activationStatus(this.activationData)
          this.Activation.setSmooth('alpha', 1)
          this.QRCode.updateQR(this._url)
          this.showHideQRcode(this._loggedIn ? 0 : 1)
        }

        _getFocused() {
          return this._loggedIn ? this.Activation : this.Menu
        }
      },
      class EmptyState extends this {},
      class Inactive extends this {
        $enter({ prevState }) {
          this._prevState = prevState
          Router.focusWidget('Header')
        }

        _focus() {
          if (!Router.isNavigating()) {
            this._setState(this._prevState)
          }
        }
      },
    ]
  }
}
