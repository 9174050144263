import { Router, Utils } from '@lightningjs/sdk'
import { COLORS } from './constants/colors'
import { routerConfiguration } from './lib/routes'
import VideoApp from './components/VideoApp'
import { getWidget, initAppInstance } from './utils/tools'
import Header from './widgets/header/Header'
import Prompt from './widgets/prompt/Prompt'
import StillWatchingHandler from './components/StillWatchingHandler'
import Background from './widgets/Background'
import Loader from './components/Loader'
import { ROUTE } from './constants/routes'
import { lifeCycleClose } from './services/firebolt'
import { fireboltMetricsStart, fireboltMetricsStop } from './services/firebolt_metrics'
import { fireboltVideoMetrics } from './services/firebolt_video_metrics'

export default class App extends VideoApp {
  static _template() {
    return {
      ...super._template(),
      Loading: { visible: false, type: Loader },
      Widgets: {
        Header: { type: Header },
        Prompt: { type: Prompt },
        Background: { type: Background },
      },
    }
  }

  static language() {
    return 'en'
  }

  static colors() {
    return COLORS
  }

  static getFonts() {
    return [
      { family: 'PoppinsRegular', url: Utils.asset('fonts/Poppins-Regular.ttf') },
      { family: 'PoppinsSemiBold', url: Utils.asset('fonts/Poppins-SemiBold.ttf') },
      { family: 'PoppinsBold', url: Utils.asset('fonts/Poppins-Bold.ttf') },
    ]
  }

  _construct() {
    fireboltMetricsStart()
    fireboltVideoMetrics.init()
  }

  async _setup() {
    // stillWatchingHandler is self envoking, and must be created in order to listen to app.js
    this._stillWatchingHandler = new StillWatchingHandler(this)
    initAppInstance(this)
    Router.startRouter(routerConfiguration)
  }

  _captureKey(event) {
    //Emit needs to be unique for each listener
    this.application.emit('global_onCaptureKey_stillWatching', event)
    return false
  }

  _handleAppClose() {
    const historyLength = Router.getHistory() ? Router.getHistory().length : 0
    if (historyLength > 0) {
      Router.back()
    } else {
      if (Router.getActiveRoute() === ROUTE.HOME) {
        if (!Router.isNavigating()) {
          this.showAreYouSure()
        }
      } else {
        Router.navigate(ROUTE.HOME)
      }
    }
  }

  _detach() {
    console.log("_detach")
    fireboltMetricsStop()
    lifeCycleClose()
  }

  showAreYouSure() {
    //Stop and reset player tag before exiting the app
    this.stop()
    this.isHomePlayer(false, undefined)
    //Note: Language.translate not available in App.js, message hardcoded
    getWidget('Prompt').content = {
      message: 'Are you sure you want to exit?',
      buttons: [
        {
          label: 'Yes',
          action: () => {
            this.application.closeApp()
          },
        },
        {
          label: 'No',
        },
      ],
    }
  }
}
