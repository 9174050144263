import { Colors } from '@lightningjs/sdk'
import { COLORS } from './colors'

// default parameters can be added like so:
// Label:{ text:{ text: 'helloWorld', ...FONTS.TITLE }}
export const FONTSTYLE = {
  H1: {
    fontFace: 'PoppinsSemiBold',
    fontSize: 60,
    lineHeight: 70,
  },
  H2: {
    fontFace: 'PoppinsSemiBold',
    fontSize: 40,
    lineHeight: 50,
  },
  H3: {
    fontFace: 'PoppinsSemiBold',
    fontSize: 32,
    lineHeight: 40,
  },
  H4: {
    fontFace: 'PoppinsSemiBold',
    fontSize: 28,
    lineHeight: 36,
  },
  H5: {
    fontFace: 'PoppinsSemiBold',
    fontSize: 22,
    lineHeight: 30,
  },
  LABEL: {
    fontFace: 'PoppinsSemiBold',
    fontSize: 20,
    lineHeight: 30,
  },
  METADATA: {
    fontFace: 'PoppinsRegular',
    fontSize: 20,
    lineHeight: 30,
  },
  CONTENT_TITLE: {
    fontFace: 'PoppinsSemiBold',
    fontSize: 24,
    lineHeight: 32,
  },
  CONTENT: {
    fontFace: 'PoppinsRegular',
    fontSize: 28,
    lineHeight: 42,
  },
  SEARCH_BAR: {
    fontFace: 'PoppinsSemiBold',
    fontSize: 32,
    lineHeight: 48,
  },
}

export const FONTS = {
  TITLE: { fontSize: 24, lineHeight: 26, fontColor: Colors(COLORS.WHITE) },
  DESCRIPTION: {
    fontSize: 24,
    lineHeight: 26,
    fontColor: Colors(COLORS.WHITE),
  },
  DATE: { fontSize: 24, lineHeight: 26, fontColor: Colors(COLORS.WHITE) },
  POPPINS: {
    REGULAR: 'PoppinsRegular',
    SEMIBOLD: 'PoppinsSemiBold',
    BOLD: 'PoppinsBold',
  },
  PAGE: {
    SPLASH: {
      TITLE: {},
    },
    HOME: {
      LIVE_LIST: {},
      VIDEO_LIST: {},
      SERIES_LIST: {},
    },
    SEARCH: {
      fontColor: Colors(COLORS.DARKGREY_60).get(),
      focusColor: Colors(COLORS.LIGHTGREY).get(),
      fontSize: 32,
    },
    SCHEDULE: {
      TITLE: {},
      DESCRIPTION: {},
      DATE: {},
    },
  },
}
