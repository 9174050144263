import { Lightning, Router, Log, Colors, Language } from '@lightningjs/sdk'
import SeriesGrid from '../components/Series/SeriesGrid'
import VideoItem from '../components/Series/VideoItem'
import { COLORS } from '../constants/colors'
import { isNumber } from '../utils/checks'
import { getWidget } from '../utils/tools'
import { sendMetrics } from '../services/metrics'
import { BUTTON, GA4, PAGENAME } from '../constants/analytics'
import BackToTop from '../components/Home/BackToTop'
import { ROUTE } from '../constants/routes'

export default class Categories extends Lightning.Component {
  static _template() {
    return {
      w: 1920,
      h: 1080,
      Gradient: {
        Top: {
          y: 0,
          rect: true,
          w: 1920,
          h: 250,
          colorBottom: Colors(COLORS.BLACK)
            .alpha(0)
            .get(),
          colorTop: Colors(COLORS.BLACK)
            .alpha(0.5)
            .get(),
        },
      },
      NoData: {
        x: 930,
        y: 540,
        mount: 0.5,
        alpha: 0,
        text: {
          text: Language.translate('no_data'),
          fontSize: 24,
          lineHeight: 32,
          maxLines: 1,
          fontFace: 'PoppinsRegular',
          wordWrapWidth: 1000,
          textColor: Colors(COLORS.LIGHTGREY).get(),
        },
      },
      Wrapper: {
        y: 92,
        TextWrapper: {
          y: 0,
          Title: {
            x: 104,
            color: Colors(COLORS.LIGHTGREY).get(),
            text: {
              advancedRenderer: true,
              fontFace: 'PoppinsSemiBold',
              fontSize: 60,
              lineHeight: 80,
              maxLines: 1,
              wordWrapWidth: 1600,
            },
          },
          transitions: { y: { duration: 0.3 } },
        },
        CategoryGrid: {
          x: 104,
          y: 168,
          w: 1920,
          h: 900,
          type: SeriesGrid,
          options: {
            width: 1920,
            height: 500,
            xVal: 0,
            columns: 4,
            crossSpacing: 40,
            mainSpacing: 40,
            alpha: 0.6,
            itemType: VideoItem,
          },
          signals: {
            onItemSelection: true,
            scrollHeader: true,
            onMainIndexChanged: true,
          },
        },
      },
      BackToTop: { type: BackToTop },
    }
  }

  get CategoryGrid() {
    return this.tag('CategoryGrid')
  }

  get NoData() {
    return this.tag('NoData')
  }

  get GradientTop() {
    return this.tag('Gradient.Top')
  }

  get Title() {
    return this.tag('Title')
  }

  get BackToTop() {
    return this.tag('BackToTop')
  }

  set data({ data, title }) {
    this._data = data
    this._title = title
  }

  set params(args) {
    if (args[Router.symbols['historyState']])
      this._index = args[Router.symbols['historyState']].index
  }

  _init() {
    this._index = { focusIndex: 0, mainIndex: 0 }
  }

  _active() {
    this._renderData()
  }

  _handleBack() {
    if (this._index.mainIndex > 0) {
      this.CategoryGrid.setFocusToFirstItem()
    } else {
      sendMetrics(GA4.CLICK, { button: BUTTON.BACK, page_title: PAGENAME.CATEGORIES })
      // Router.back()
      const historyLength = Router.getHistory() ? Router.getHistory().length : 0
      if (historyLength > 0) {
        Router.back()
      } else {
        if (Router.getActiveRoute() === ROUTE.HOME) {
          Router.back()
        } else {
          Router.navigate(ROUTE.HOME)
        }
      }
    }
  }

  historyState(params) {
    if (!params) {
      return {
        index: this._index,
      }
    }
  }

  _renderData() {
    this._setState('CategoryGrid')
    //Based on row index(mainIndex) update background and display BackToTop component
    this._index.mainIndex > 0
      ? this.onMainIndexChanged({
          mainIndex: this._index.mainIndex,
          previousMainIndex: this._index.previousMainIndex,
          index: this._index.focusIndex,
        })
      : getWidget('Background').startPosition()
    if (this._data.length) {
      this.Title.patch({
        text: {
          text: this._title ? this._title : '',
        },
      })
      this.CategoryGrid.items = { items: this._data, focusIndex: this._index.focusIndex }
      this._showBackToTop(this._index.mainIndex > 0)
    } else {
      this._setState('NoData')
    }
  }

  _showBackToTop(value) {
    this.BackToTop.show = value
  }

  scrollHeader({ lightningComponent, startPos = 304 }) {
    //Move Title while items are scrolled
    if (lightningComponent !== undefined) {
      lightningComponent.core.onAfterUpdate = () => {
        if (lightningComponent) {
          lightningComponent.core.onAfterUpdate = () => {
            let newCornerPoints = lightningComponent.core.getCornerPoints()
            this.Title.patch({
              y: newCornerPoints[1] - startPos,
            })
            this.GradientTop.patch({
              y: newCornerPoints[1] - startPos,
            })
          }
        }
      }
    } else {
      this.Title.patch({
        y: 0,
      })
      this.GradientTop.patch({
        y: 0,
      })
    }
  }

  onItemSelection({ item, focusIndex, mainIndex }) {
    this._index.focusIndex = focusIndex
    this._index.mainIndex = mainIndex
    const itemType = item.itemType
    const id = item.id
    if (itemType && id) {
      Router.navigate(`video/${itemType}/${id}`)
    } else {
      Log.warn('missing params ', { itemType, id })
    }
  }

  onMainIndexChanged({ mainIndex, previousMainIndex, index }) {
    if (isNumber(mainIndex)) {
      if (mainIndex === 1 && previousMainIndex < mainIndex) {
        this._showBackToTop(true)
      } else if (mainIndex === 0) {
        this._showBackToTop(false)
      }

      this._index.mainIndex = mainIndex
      this._index.previousMainIndex = previousMainIndex
      this._index.focusIndex = index
      getWidget('Background').moveBackground(mainIndex)
    }
  }

  static _states() {
    return [
      class CategoryGrid extends this {
        _getFocused() {
          return this.CategoryGrid
        }
      },
      class NoData extends this {
        $enter() {
          this.NoData.alpha = 1
        }

        $exit() {
          this.NoData.alpha = 0
        }
      },
    ]
  }
}
