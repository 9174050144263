import { Colors, Lightning, Log, Router } from '@lightningjs/sdk'
import { isBoolean, isStringWithLength } from '../../utils/checks'
import { COLORS } from '../../constants/colors'
import { ROUTE } from '../../constants/routes'
import { getImage } from '../../api/Api'
import { voiceGuide } from '../../services/voiceGuide'

export default class SeriesCard extends Lightning.Component {
  static _template() {
    return {
      w: 325,
      h: 150,
      Card: {
        zIndex: 15,
        w: 325,
        h: 150,
        rect: true,
        colorTop: Colors(COLORS.BACKGROUND_SERIES_TOP_INACTIVE).get(),
        colorBottom: Colors(COLORS.BACKGROUND_SERIES_BOTTOM_INACTIVE).get(),
        shader: {
          type: Lightning.shaders.RoundedRectangle,
          radius: 10,
        },
      },
      Highlight: {
        zIndex: 18,
        x: -10,
        y: -10,
        w: 345,
        h: 170,
        rect: true,
        alpha: 0.0001,
        shader: {
          type: Lightning.shaders.RoundedRectangle,
          radius: 20,
          stroke: 5,
          fillColor: Colors(COLORS.WHITE)
            .alpha(0)
            .get(),
          strokeColor: Colors(COLORS.LIGHTGREY).get(),
        },
      },
      Image: {
        zIndex: 20,
        w: 325,
        h: 150,
        alpha: 0.0001,
        shader: {
          type: Lightning.shaders.RoundedRectangle,
          radius: 10,
        },
        transitions: { alpha: { duration: 0.7 } },
      },
      Title: {
        x: 163,
        y: 75,
        zIndex: 20,
        mountX: 0.5,
        pivotY: 0.5,
        alpha: 1,
        color: Colors(COLORS.DARKGREY).get(),
        text: {
          advancedRenderer: true,
          text: '',
          fontSize: 32,
          lineHeight: 50,
          maxLines: 4,
          textAlign: 'center',
          verticalAlign: 'middle',
          fontFace: 'PoppinsSemiBold',
          wordWrapWidth: 310,
        },
      },
    }
  }

  //Card total width
  static get width() {
    return 325
  }

  //Card total height
  static get height() {
    return 150
  }

  set isliveStream(isliveStream) {
    this._isliveStream = isBoolean(isliveStream) ? isliveStream : false
  }

  get isliveStream() {
    return isBoolean(this._isliveStream) ? this._isliveStream : false
  }

  set highlight(highlight) {
    this._highlight = highlight
  }

  get highlight() {
    return typeof this._highlight === 'boolean' ? this._highlight : false
  }

  set useLocalImages(useLocalImages) {
    this._useLocalImages = useLocalImages
  }

  get useLocalImages() {
    return typeof this._useLocalImages === 'boolean' ? this._useLocalImages : false
  }

  set item(item) {
    this._item = item
    this.hasTitle = this._item.title
    if (this.hasTitle) {
      this.title = this._item.title
      this.tag('Title').alpha = 1
    } else {
      this.tag('Title').alpha = 0
    }
    this._tryLoadImage(item)
  }

  get item() {
    return this._item ? this._item : undefined
  }

  set hasTitle(title) {
    this._hasTitle = this._doesStringExist(title)
  }

  get hasTitle() {
    return typeof this._hasTitle === 'boolean' ? this._hasTitle : false
  }

  set listIsActive(listIsActive) {
    this._listIsActive = listIsActive
    this.tag('Card').patch({
      colorTop: listIsActive
        ? Colors(COLORS.BACKGROUND_SERIES_TOP_ACTIVE).get()
        : Colors(COLORS.BACKGROUND_SERIES_TOP_INACTIVE).get(),
      colorBottom: listIsActive
        ? Colors(COLORS.BACKGROUND_SERIES_BOTTOM_ACTIVE).get()
        : Colors(COLORS.BACKGROUND_SERIES_BOTTOM_INACTIVE).get(),
    })
    this.tag('Title').color = listIsActive
      ? Colors(COLORS.LIGHTGREY).get()
      : Colors(COLORS.DARKGREY).get()
  }

  get listIsActive() {
    return typeof this._listIsActive === 'boolean' ? this._listIsActive : false
  }

  set title(title) {
    this._title = title
    this.tag('Title').text.text = title || ''

    const labelHeight = 135 //Labelheight smaller than card for padding
    this.tag('Title').on('txLoaded', () => {
      let mountY = 0.67
      let y = 75
      const renderHeight = this.tag('Title').renderHeight
      if (renderHeight <= 50) {
        // one line
        y = 74
        mountY = 0.6
      } else if (renderHeight <= 100) {
        // two lines
        y = 74
        mountY = 0.5
      } else if (renderHeight <= 150) {
        // three lines
        y = 74
        mountY = 0.54
      } else if (renderHeight <= 200) {
        // four lines
        y = 76
        mountY = 0.53
      } else if (renderHeight > 200) {
        // more than four lines
        y = 76
        mountY = 0.53
      }

      if (renderHeight > labelHeight) {
        this.tag('Title').scale = labelHeight / renderHeight
        this.tag('Title').mountY = mountY
        this.tag('Title').y = y
      } else {
        this.tag('Title').scale = 1
        this.tag('Title').mountY = mountY
        this.tag('Title').y = y
      }
    })
  }

  _init() {
    this.tag('Image').on('txLoaded', () => {
      this._imageLoaded()
    })
    this.tag('Image').on('txError', () => {
      this._imageFailed()
    })
  }

  _handleEnter() {
    const itemType = this._item.itemType
    const id = this._item.id

    if (itemType && id) {
      switch (itemType) {
        case 'series':
          Router.navigate(`${ROUTE.SERIES}/${id}`)
          break
        case 'program':
          Router.navigate(`${ROUTE.VIDEO}/${itemType}/${id}`)
          break
        case 'tag':
          Router.navigate(`${ROUTE.CATEGORIES}/${id}`, { item: this._item })
          break
        case 'Rankset':
          Router.navigate(`${ROUTE.RANKSET}/${id}`)
          break
      }
    } else {
      Log.warn('missing params ', { itemType, id })
    }
  }

  _focus() {
    this.patch({ smooth: { y: -10 } })
    this.tag('Highlight').patch({
      alpha: 1,
    })

    const title = (this._item && this._item.title) || ''
    voiceGuide.utterThis(title)
  }

  _unfocus() {
    this.patch({ smooth: { y: 0 } })
    this.tag('Highlight').patch({
      alpha: 0,
    })
  }

  _tryLoadImage(item) {
    this.tag('Image').alpha = 0.0001
    const hasImagePath = this._doesStringExist(item.imagePath)
    const hasImage = this._doesStringExist(item.image)
    if (hasImagePath) {
      getImage(item.imagePath, {
        resize: { width: 325, height: 150 },
      }).then(imageUrl => {
        this.tag('Image').src = imageUrl
      })
    } else if (hasImage) {
      this.tag('Image').src = item.image
    } else {
      this._imageFailed()
    }
  }

  _imageLoaded() {
    this.tag('Title').setSmooth('alpha', 0)
    this.tag('Image').setSmooth('alpha', 1)
  }

  _imageFailed() {
    this.tag('Title').alpha = 1
    this.tag('Image').alpha = 0.0001
  }

  _doesStringExist(str) {
    return !!(str && isStringWithLength(str))
  }
}
