import { Lightning, Router, Registry } from '@lightningjs/sdk'
import { appInstance, getWidget } from '../utils/tools'
import { BUTTON, GA4, PAGENAME } from '../constants/analytics'
import TvNetworkControls from '../components/TVNet/TvNetworkControls'
import { sendMetrics } from '../services/metrics'
import { getNetworkInfo } from '../api/Api'
import { minToMs } from '../utils/time'

const CSPAN1_NETWORK_ID = 1
const CSPAN2_NETWORK_ID = 2
const CSPAN3_NETWORK_ID = 3
import { ROUTE } from '../constants/routes'

export default class TVnet extends Lightning.Component {
  static _template() {
    return {
      Controls: {
        alpha: 0,
        type: TvNetworkControls,
        signals: { pressPlay: true },
      },
    }
  }

  set data(tvNetData) {
    this._data = tvNetData.data
    this._token = tvNetData.token.data
  }

  set overlayText(item) {
    this._videoItem = item
    this.titleText(item.title)
    this.descText(item.description)
  }

  get Controls() {
    return this.tag('Controls')
  }

  _init() {
    this._networkId = CSPAN1_NETWORK_ID
    this._seeking = false
    this._index = 0
    this._isPaused = false
    this._playStream = ''
    this.videoParams = {}
    this._initializeEvents()
    this.scrollpx = 0
  }

  _firstActive() {
    // Check for the live updates
    this._updateNetworkInfo = setInterval(() => {
      // update badge status every minute
      this._getNetworksInformation()
    }, minToMs(1))
  }

  _active() {
    this._renderData()
    sendMetrics(GA4.SCREENVIEW, { page_title: PAGENAME.TVNETWORK })
    this.playerStart(this._cSpan1)
    this.Controls.updateCspan1()
    this._getNetworksInformation()
  }

  _renderData() {
    // eslint-disable-next-line
		this._cSpan1 = `${this._data.channels["C-SPAN1"].url}?hdnts=${this._token}`;
    // eslint-disable-next-line
		this._cSpan2 = `${this._data.channels["C-SPAN2"].url}?hdnts=${this._token}`;
    // eslint-disable-next-line
		this._cSpan3 = `${this._data.channels["C-SPAN3"].url}?hdnts=${this._token}`;
  }

  _inactive() {
    appInstance.endHlsPlayer()
  }

  _detach() {
    super._detach()
    this._clearEventListeners()
  }

  _handlePlay() {
    this._setState('Controls')
    this.Controls.changeFocus('Play')
    appInstance.play()
  }

  _handlePause() {
    this._setState('Controls')
    this.Controls.changeFocus('Play')
    appInstance.pause()
  }

  _handlePlayPause() {
    this._setState('Controls')
    this.Controls.changeFocus('Play')
    appInstance.pressPlay()
  }

  _handleReplay() {
    this._setState('Controls')
    this.Controls.changeFocus('Replay')
    this.replay()
  }

  _captureBack() {
    sendMetrics(GA4.CLICK, { button: BUTTON.BACK, page_title: PAGENAME.TVNETWORK })
    appInstance.endHlsPlayer()
    Registry.clearTimeout(this._timeout)
    Router.back()
  }

  _initializeEvents() {
    this._clearEventListeners()
    this.application.on('global_playerEvent_video', event => {
      this._playerEvent(event)
    })
    this.application.on('caption_Key_tvNet', key => {
      this.Controls.updateCCButton(key)
    })
    this.application.off('stillWatching')
    this.application.on('stillWatching', ({ event }) => {
      if (event === 'no') {
        appInstance.stop()
        // Router.back()
        this._handleRouterBack()
      }
    })
    this.application.on('channel', key => {
      //Check which channel user has selected.
      this._networkId =
        key === 'C-SPAN1'
          ? CSPAN1_NETWORK_ID
          : key === 'C-SPAN2'
          ? CSPAN2_NETWORK_ID
          : CSPAN3_NETWORK_ID
      this._getNetworksInformation()

      // const cSpanUrl = this._data.channels[key].url
      const cSpanUrl = `${this._data.channels[key].url}?hdnts=${this._token}`
      this.playerStart(cSpanUrl)
    })
  }

  _handleRouterBack() {
    const historyLength = Router.getHistory() ? Router.getHistory().length : 0
    if (historyLength > 0) {
      Router.back()
    } else {
      Router.navigate(ROUTE.HOME)
    }
  }

  _clearEventListeners() {
    this.application.off('global_playerEvent_video')
    this.application.off('caption_Key')
    this.application.off('stillWatching')
  }

  _playerEvent(event) {
    switch (event) {
      case 'LoadedMetadata':
        this.sendAnalytics()
        this._setState('Controls')
        break
      case 'CanPlay':
        appInstance.play()
        break
      case 'Play':
        this.Controls.playOrPause = true
        break
      case 'Pause':
        this._isPaused = true
        this.Controls.playOrPause = false
        break
      case 'Stop':
        appInstance.stop()
        // Router.back()
        this._handleRouterBack()
        break
      case 'Error':
        Router.navigate('error')
        break
      case 'Ended':
        this._seeking = false
        this._isPaused = false
        if (this._timeout) {
          Registry.clearTimeout(this._timeout)
        }
        this._handleRouterBack()
        appInstance.endHlsPlayer()
        break
      case 'Progress':
        break
      case 'Clear':
        Object.assign(this.videoParams, { played_time: appInstance.getCurrentTime() })
        this.sendAnalytics()
        break
      case 'Seeking':
        this._isPaused = false
        this._seekedTimeOut = Registry.setTimeout(() => {
          appInstance.play()
        }, 2000)
        break
      case 'Seeked':
        if (this._seekedTimeOut) Registry.clearTimeout(this._seekedTimeOut)
        appInstance.play()
        this._seeking = false
        this._isPaused = false
        break
      default:
        break
    }
  }

  /**
   * This is used to fetch the TV network information based on the selected network id,
   * also filters and displays the current programme information.
   */
  _getNetworksInformation() {
    getNetworkInfo().then(items => {
      this._liveNetworksInfo = items
      this._displayTitlesInfo()
      let item = this._getVideoInformation(this._liveNetworksInfo, this._networkId)

      if (typeof this._videoItem != 'object') this.overlayText = item
      if (
        typeof this._videoItem === 'object' &&
        this._videoItem.title !== item.title &&
        this._videoItem.description !== item.description
      ) {
        const states = ['Controls', 'Details']
        this.overlayText = item
        if (states.indexOf(this._getState()) !== -1) {
          this.Controls.descPosition()
          this._setState('Controls')
        }
      }
    })
  }

  //Display the title information for all the TV nets
  _displayTitlesInfo() {
    let cspan1Info = this._getVideoInformation(this._liveNetworksInfo, CSPAN1_NETWORK_ID)
    let cspan2Info = this._getVideoInformation(this._liveNetworksInfo, CSPAN2_NETWORK_ID)
    let cspan3Info = this._getVideoInformation(this._liveNetworksInfo, CSPAN3_NETWORK_ID)
    this.Controls.CSpan1Title = cspan1Info ? cspan1Info.title : ''
    this.Controls.CSpan2Title = cspan2Info ? cspan2Info.title : ''
    this.Controls.CSpan3Title = cspan3Info ? cspan3Info.title : ''
  }

  _getVideoInformation(networks, id) {
    return networks.find(item => item.network === id)
  }

  $hideTVNetOverlay() {
    this._setState('Hide')
  }

  $showDesc() {
    this._setState('Details')
  }

  $pressPlay() {
    appInstance.pressPlay()
  }

  $closedCaption() {
    const CCStatus = appInstance.getCCStatus() === 'hidden' ? 'showing' : 'hidden'
    appInstance.updateCCTrackListener(CCStatus)
    this.Controls.toggleCCButton(CCStatus)
  }

  $hideCCUpdate() {
    const resetStatus = 'hidden'
    appInstance.updateCCTrackListener(resetStatus)
    this.Controls.toggleCCButton(resetStatus)
  }

  $showCCUpdate() {
    const resetStatus = 'showing'
    appInstance.updateCCTrackListener(resetStatus)
    this.Controls.toggleCCButton(resetStatus)
  }

  sendAnalytics() {
    this.videoParams.page_title = PAGENAME.VIDEO
    this.videoParams.video_title = this._videoTitle
    this.videoParams.video_id = this._videoId
    this.videoParams.video_duration = this._videoDuration
    sendMetrics(GA4.SCREENVIEW, { ...this.videoParams })
  }

  playerStart(url) {
    if (url !== undefined) {
      this._playStream = url
      appInstance.playerOpen({ url, islive: true, isHomePlayer: false, scope: undefined })
    } else {
      this.tryAgain()
    }
  }

  tryAgain() {
    getWidget('Prompt').content = {
      message: 'Video is not available. Please try again later',
      handleBack: true,
      buttons: [
        {
          label: 'Ok',
          action: () => {
            appInstance.stop()
            this._handleRouterBack()
          },
        },
      ],
    }
    setTimeout(() => {
      Router.focusWidget('Prompt')
    }, 800)
  }

  titleText(title) {
    this.Controls.title(title)
  }

  descText(desc) {
    this.Controls.description(desc)
  }

  eventStopper(e) {
    if (e) {
      e.preventDefault()
      e.stopPropagation()
    }
  }

  replay() {
    this.playerStart(this.url)
  }

  static _states() {
    return [
      class Controls extends this {
        $enter() {
          this._setInterfaceTimeout()
          this.Controls.setSmooth('alpha', 1)
        }

        $exit() {
          Registry.clearTimeout(this._timeout)
          this._timeout = 0
        }

        _setInterfaceTimeout() {
          if (this._timeout) {
            Registry.clearTimeout(this._timeout)
          }
          this._timeout = Registry.setTimeout(() => {
            this._setState('Hide')
          }, 5000)
        }

        _captureKey() {
          this._setInterfaceTimeout()
          return false
        }

        _handleDown() {
          Registry.clearTimeout(this._timeout)
          this._setState('Hide')
        }

        _getFocused() {
          return this.Controls
        }
      },
      class Hide extends this {
        $enter() {
          this.Controls.setSmooth('alpha', 0)
        }

        $exit() {
          this.Controls.setSmooth('alpha', 1)
        }

        _captureKey() {
          this._setState('Controls')
          return false
        }

        _captureBack() {
          this._setState('Controls')
          return true
        }

        _captureEnter() {
          this._setState('Controls')
          return true
        }

        _captureLeft() {
          this._setState('Controls')
          return true
        }

        _captureRight() {
          this._setState('Controls')
          return true
        }

        _captureDown() {
          this._setState('Controls')
          return true
        }

        _captureUp() {
          this._setState('Controls')
          return true
        }
      },
      class Details extends this {
        $enter() {
          this.Controls.setSmooth('alpha', 1)
          if (this.Controls.DescriptionLength > 800) {
            this.Controls.ContentGradient.setSmooth('alpha', 1)
          }
          this.Controls.showDesc(true)
        }

        $exit() {
          this.Controls.showDesc(false)
          this.Controls.descPosition()
        }

        _handleDownRelease() {
          if (this.Controls.DescriptionLength > 800 && this.Controls.DescHeight > 760) {
            let k = Math.ceil(this.Controls.DescHeight - 600)
            if (this.Controls.ContentOverlay.y > '-' + k) {
              this.Controls.ContentOverlay.setSmooth('y', this.scrollpx - 100)
            }
          }
        }

        _handleUp() {
          if (this.Controls.DescriptionLength > 800) {
            if (this.Controls.ContentOverlay.y < 0) {
              this.Controls.ContentOverlay.setSmooth('y', 100)
            }
          }
        }

        _handleEnter() {
          return true
        }

        _handlePlay() {
          return true
        }

        _handlePause() {
          return true
        }

        _handlePlayPause() {
          return true
        }

        _handleForward() {
          return true
        }

        _handleRewind() {
          return true
        }

        _captureBack() {
          this.Controls.Description.setSmooth('y', 0)
          this.Controls.descPosition()
          this._setState('Controls')
        }
      },
    ]
  }
}
