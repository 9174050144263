import { Colors, Language, Lightning, Router } from '@lightningjs/sdk'
import SeriesGrid from '../components/Series/SeriesGrid'
import SeriesListItem from '../components/Series/SeriesListItem'
import { getWidget } from '../utils/tools'
import { COLORS } from '../constants/colors'
import { isNumber } from '../utils/checks'
import BackToTop from '../components/Home/BackToTop'
import { sendMetrics } from '../services/metrics'
import { BUTTON, GA4, PAGENAME } from '../constants/analytics'
import { ROUTE } from '../constants/routes'

export default class SeriesList extends Lightning.Component {
  static _template() {
    return {
      NoData: {
        x: 930,
        y: 540,
        mount: 0.5,
        alpha: 0,
        text: {
          text: Language.translate('no_data'),
          fontSize: 24,
          lineHeight: 32,
          maxLines: 1,
          fontFace: 'PoppinsRegular',
          wordWrapWidth: 1000,
          textColor: Colors(COLORS.LIGHTGREY).get(),
        },
      },
      SeriesGrid: {
        x: 50,
        y: 260,
        w: 1920,
        h: 900,
        type: SeriesGrid,
        transitions: { y: { duration: 0.4 } },
        options: {
          width: 1920,
          height: 322,
          xVal: 54,
          columns: 4,
          crossSpacing: 40,
          mainSpacing: 46,
          itemType: SeriesListItem,
          alpha: 0.6,
          useLocalImages: true,
        },
        signals: {
          onItemSelection: true,
          scrollHeader: true,
          onMainIndexChanged: true,
        },
      },
      BackToTop: { type: BackToTop },
    }
  }

  get SeriesGrid() {
    return this.tag('SeriesGrid')
  }

  get NoData() {
    return this.tag('NoData')
  }

  get BackToTop() {
    return this.tag('BackToTop')
  }

  set data(items) {
    this._items = items
  }

  set params(args) {
    if (args[Router.symbols['historyState']])
      this._index = args[Router.symbols['historyState']].index
  }

  _init() {
    this._index = { focusIndex: 0, mainIndex: 0, previousMainIndex: 0 }
  }

  _active() {
    this._renderData()
  }

  _handleBack() {
    getWidget('Header').trackHeader(undefined, 0)
    if (this._index.mainIndex > 0) {
      this.SeriesGrid.setFocusToFirstItem()
    } else {
      sendMetrics(GA4.CLICK, { button: BUTTON.BACK, page_title: PAGENAME.SERIES })
      // Router.back()
      const historyLength = Router.getHistory() ? Router.getHistory().length : 0
      if (historyLength > 0) {
        Router.back()
      } else {
        if (Router.getActiveRoute() === ROUTE.HOME) {
          Router.back()
        } else {
          Router.navigate(ROUTE.HOME)
        }
      }
    }
  }

  historyState(params) {
    if (!params) {
      return {
        index: this._index,
      }
    }
  }

  _renderData() {
    this._index.mainIndex > 0
      ? this.onMainIndexChanged({ mainIndex: this._index.mainIndex, index: this._index.focusIndex })
      : getWidget('Background').startPosition()
    if (this._items.length) {
      this._showBackToTop(this._index.mainIndex > 0)
      this._setState('SeriesGrid')
      this.SeriesGrid.items = {
        items: this._items,
        focusIndex: this._index.focusIndex,
      }
    } else {
      this._setState('NoData')
    }
  }

  _showBackToTop(value) {
    this.BackToTop.show = value
  }

  setListFocusIndex({ focusIndex }) {
    this.SeriesGrid.setIndex(focusIndex)
  }

  onItemSelection({ item, focusIndex }) {
    this._index.focusIndex = focusIndex
    if (item) {
      Router.navigate(`${item.type}/${item.id}`)
    }
  }

  onMainIndexChanged({ mainIndex, previousMainIndex, index }) {
    if (isNumber(mainIndex)) {
      mainIndex > 0 ? this._showBackToTop(true) : this._showBackToTop(false)

      this._index.mainIndex = mainIndex
      this._index.previousMainIndex = previousMainIndex
      this._index.focusIndex = index
      getWidget('Background').moveBackground(mainIndex)
    }
  }

  scrollHeader({ lightningComponent, startPos = 304, mainIndex }) {
    mainIndex > 0
      ? getWidget('Header').trackHeader(lightningComponent, startPos)
      : getWidget('Header').trackHeader(undefined, 0)
  }

  static _states() {
    return [
      class SeriesGrid extends this {
        _getFocused() {
          return this.SeriesGrid
        }

        _handleUp() {
          this._setState('Inactive')
        }
      },
      class NoData extends this {
        $enter() {
          this.NoData.alpha = 1
        }

        _focus() {
          Router.focusWidget('Header')
        }

        $exit() {
          this.NoData.alpha = 0
        }
      },
      class Inactive extends this {
        $enter() {
          Router.focusWidget('Header')
        }

        _focus() {
          if (!Router.isNavigating()) {
            this._setState('SeriesGrid')
          }
        }
      },
    ]
  }
}
