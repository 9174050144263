const LABEL_HEIGHT = 42
export const TYPE_LABEL = 'label'
export const TYPE_ICON = 'icon'

export const HOME_MENU = [
  {
    labelLangKey: 'home',
    navLink: 'HOME',
    speechText: 'Home',
    w: 86,
    h: LABEL_HEIGHT,
    menuType: TYPE_LABEL,
  },
  {
    labelLangKey: 'upcomingLive',
    navLink: 'SCHEDULE',
    speechText: 'Upcoming Live',
    w: 210,
    h: LABEL_HEIGHT,
    menuType: TYPE_LABEL,
  },
  {
    labelLangKey: 'trendingTopics',
    navLink: 'TRENDINGTOPICS',
    speechText: 'Trending Topics',
    w: 228,
    h: LABEL_HEIGHT,
    menuType: TYPE_LABEL,
  },
  {
    labelLangKey: 'series',
    navLink: 'SERIES',
    speechText: 'Series',
    w: 86,
    h: LABEL_HEIGHT,
    menuType: TYPE_LABEL,
  },
  /* To be enabled later
		{
		  labelLangKey: 'series',
		  navLink: '',
		  w: 115,
		  h: LABEL_HEIGHT,
		  menuType: TYPE_LABEL,
		},*/
  {
    labelLangKey: 'tvnets',
    navLink: 'TVNET',
    speechText: 'TV Networks',
    w: 176,
    h: LABEL_HEIGHT,
    menuType: TYPE_LABEL,
  },
  {
    icon: 'search',
    navLink: 'SEARCH',
    speechText: 'Search',
    w: 32,
    h: 32,
    menuType: TYPE_ICON,
  },
  {
    icon: 'settings',
    navLink: 'SETTINGS',
    speechText: 'Settings',
    w: 32,
    h: 32,
    menuType: TYPE_ICON,
  },
]
// constants for MenuItem.js
export const SPACE_UNDERLINE = 3
export const ASSETS = {
  ICON_BASE_URL: 'images/icons/menu/',
  IMAGE_BASE_URL: 'images/',
}
export const MENU_NAMES = {
  HEADER: 'Header-Menu',
  SETTINGS: 'settings',
}
export const SETTINGS_MENU_PROPS = {
  label: '',
  h: LABEL_HEIGHT,
  menuType: TYPE_LABEL,
}
