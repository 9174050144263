export const GA4 = {
  MEASUREMENTID: 'G-5Z04H7T96W',
  SCREENVIEW: 'screen_view',
  PAGEVIEW: 'page_view',
  CLICK: 'click',
  SCROLL: 'scroll',
}

export const PAGENAME = {
  SPLASH: 'splash',
  HOME: 'home',
  CATEGORIES: 'categories',
  VIDEO: 'video',
  SEARCH: 'search',
  SCHEDULE: 'schedule',
  UPCOMING: 'upcoming live',
  SERIES: 'series',
  RANKSET: 'rankset',
  SEASONS: 'seasons',
  SETTINGS: 'settings',
  AUTHENTICATION: 'authentication',
  TRENDINGTOPICS: 'trendingtopics',
  TVNETWORK: 'tvnetwork',
  ACTIVATION: 'Activation Details',
  LOGGEDIN: 'LoggedIn',
  ERROR: 'error',
}
export const BUTTON = {
  BACK: 'back',
  REWIND: 'rewind',
  FORWARD: 'forward',
  CC: 'closed_caption',
  PLAY: 'play-pause',
  READ: 'read_more',
}
