import { Colors, Lightning } from '@lightningjs/sdk'
import { COLORS } from '../constants/colors'
export default class TvNetTitle extends Lightning.Component {
  static _template() {
    return {
      Title: {
        text: {
          fontSize: 28,
          lineHeight: 42,
          maxLines: 2,
          fontFace: 'PoppinsSemiBold',
          fontWeight: 600,
          wordWrapWidth: 398,
          textColor: Colors(COLORS.DARKGREY_60).get(),
        },
      },
    }
  }
  set title(title) {
    this.patch({
      Title: { text: { text: title } },
    })
  }
  set selection(t) {
    this.patch({
      Title: {
        text: { textColor: t ? Colors(COLORS.LIGHTGREY).get() : Colors(COLORS.DARKGREY_60).get() },
      },
    })
  }
}
