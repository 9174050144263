import VideoCard from '../Home/VideoCard'

export default class SearchCard extends VideoCard {
  //Card total width
  static get width() {
    return 398
  }

  //Card total height
  static get height() {
    return 430
  }
}
