import { Lightning, Utils } from '@lightningjs/sdk'

export default class Loader extends Lightning.Component {
  static _template() {
    return {
      x: 960,
      y: 540,
      mount: 0.5,
      src: Utils.asset('images/loader.png'),
    }
  }

  _setup() {
    this._loader = this.animation({
      duration: 1,
      repeat: -1,
      stopMethod: 'immediate',
      actions: [{ p: 'rotation', v: { 0: { v: 0, sm: 0 }, 1: { v: 2 * Math.PI, sm: 0 } } }],
    })
  }

  _active() {
    this._loader.start()
  }

  _inactive() {
    this._loader.stop()
  }

  _handleBack() {
    return false
  }

  _handleKey() {
    //block all the key events other than BACK
  }
}
