import { Colors, Lightning } from '@lightningjs/sdk'
import { List } from '@lightningjs/ui'
import VideoCard from './VideoCard'
import CardCounter from './CardCounter'
import { COLORS } from '../../constants/colors'
import { isNumber } from '../../utils/checks'

export default class VideoList extends Lightning.Component {
  static _template() {
    return {
      w: 1776,
      h: 500,
      y: 0,
      x: 104,
      Title: {
        y: 14,
        color: Colors(COLORS.DARKGREY_60).get(),
        text: {
          advancedRenderer: true,
          text: '',
          fontSize: 40,
          lineHeight: 60,
          fontFace: 'PoppinsSemiBold',
          maxLines: 1,
          wordWrapWidth: 800,
        },
      },
      VideoList: {
        y: 95,
        w: 1712,
        type: List,
        direction: 'row',
        spacing: 40,
        signals: { onIndexChanged: true },
        scrollTransition: { duration: 0.3, timingFunction: 'cubic-bezier(.33, 1, .68, 1)' },
      },
      CardCounter: { x: 1712, y: 28, type: CardCounter },
    }
  }

  static get width() {
    return 1776
  }

  static get height() {
    return 500
  }

  set index(index) {
    this._index = index
    this.tag('VideoList').setIndex(index)
  }

  get index() {
    return isNumber(this._index) ? this._index : 0
  }

  set title(title) {
    this._title = title
    this.tag('Title').text.text = title
  }

  get title() {
    return this._title ? this._title : ''
  }

  set callAsset(callAsset) {
    this._callAsset = callAsset
  }

  get callAsset() {
    return this._callAsset
  }

  set data(items) {
    if (Array.isArray(items)) {
      this.tag('CardCounter').index = 1
      this.tag('CardCounter').total = items.length
      this._data = items
      items.forEach(item => {
        this.tag('VideoList').add({ type: VideoCard, item, listIsActive: false })
      })
    } else {
      this.tag('CardCounter').index = 0
      this.tag('CardCounter').total = 0
    }
    this.tag('CardCounter').highlight = false
  }

  get data() {
    return this._data && Array.isArray(this._data) ? this._data : undefined
  }

  _focus() {
    this.tag('CardCounter').highlight = true
    this._activateList(true)
    this.tag('Title').color = Colors(COLORS.LIGHTGREY).get()
    //this._setState('VideoList')
  }

  _unfocus() {
    this.tag('CardCounter').highlight = false
    this._activateList(false)
    this.tag('Title').color = Colors(COLORS.DARKGREY_60).get()
    //this._setState('ExitState')
  }

  onIndexChanged({ index }) {
    this._index = index
    this.tag('CardCounter').index = index + 1
    this.fireAncestors('$cellIndexChanged', index)
  }

  _activateList(activate) {

    console.log("updatedactivae",activate)
    this.tag('VideoList').items.forEach(listItem => {
      console.log("listItem",listItem)
      listItem.listIsActive = activate
    })
  }

  clear() {
    this.tag('VideoList').clear()
  }

  _getFocused() {
    return this.tag('VideoList')
  }

  _handleLeft() {
    //Jump to last item when list is at start
    this.tag('VideoList').last()
  }

  _handleRight() {
    //Jump to first item when list is at end
    this.tag('VideoList').first()
  }

  static _states() {
    return [
      class VideoList extends this {
        $enter() {
          this.tag('CardCounter').highlight = true
          this._activateList(false)
          this.tag('Title').color = Colors(COLORS.LIGHTGREY).get()
        }

        $exit() {
          this.tag('CardCounter').highlight = false
          this._activateList(false)
          this.tag('Title').color = Colors(COLORS.DARKGREY_60).get()
        }

       

        _getFocused() {
          return this.tag('VideoList')
        }
      },
      class ExitState extends this {},
    ]
  }
}
