import { Colors, Lightning } from '@lightningjs/sdk'
import { COLORS } from '../../constants/colors'

export default class PlayerButton extends Lightning.Component {
  static _template() {
    return {
      Button: {
        color: Colors(COLORS.LIGHTGREY).get(),
        w: 80,
        h: 80,
        texture: Lightning.Tools.getRoundRect(80, 80, 40, 0, 0, true),
        Image: {
          x: 40,
          y: 40,
          mount: 0.5,
          color: Colors(COLORS.MIDNIGHT).get(),
        },
      },
    }
  }

  set color(color) {
    this._color = color
  }

  set padding({ left, top }) {
    this.tag('Image').patch({
      x: left,
      y: top,
    })
  }

  set imageX(left) {
    this.tag('Image').patch({
      x: left,
    })
  }

  set imageY(top) {
    this.tag('Image').patch({
      y: top,
    })
  }

  set width(width) {
    this.tag('Image').patch({
      w: width,
    })
  }

  set height(height) {
    this.tag('Image').patch({
      h: height,
    })
  }

  set image(src) {
    this._src = src
    this.tag('Button').patch({
      color: this._color,
      Image: {
        src: this._src,
      },
    })
  }

  _construct() {
    this._color = Colors(COLORS.LIGHTGREY).get()
  }

  _focus() {
    this.tag('Button').patch({
      color: Colors(COLORS.LIGHTGREY).get(),
      Image: {
        color: Colors(COLORS.MIDNIGHT)
          .alpha(1)
          .get(),
      },
    })
  }

  _unfocus() {
    this.tag('Button').patch({
      color: Colors(COLORS.MIDNIGHT).get(),
      Image: {
        color: Colors(COLORS.DARKGREY_60)
          .alpha(1)
          .get(),
      },
    })
  }
}
