import { Colors, Lightning } from '@lightningjs/sdk'
import { COLORS } from '../../constants/colors'
import CSPANImage from '../CSPANImage'
import { voiceGuide } from '../../services/voiceGuide'

export default class SeriesListItem extends Lightning.Component {
  static _template() {
    return {
      Image: {
        w: 398,
        h: 226,
        type: CSPANImage,
        preset: 'normal',
        radius: 10,
        strokeFocused: 5,
        strokeUnfocused: 0,
      },
      Title: {
        y: 241,
        color: Colors(COLORS.LIGHTGREY).get(),
        text: {
          advancedRenderer: true,
          fontFace: 'PoppinsSemiBold',
          fontSize: 24,
          maxLines: 3,
          wordWrapWidth: 398,
          lineHeight: 32,
        },
      },
    }
  }

  static get width() {
    return 398
  }

  static get height() {
    return 322
  }

  get Image() {
    return this.tag('Image')
  }

  get Background() {
    return this.tag('Background')
  }

  set useLocalImages(value) {
    this._useLocalImages = value
  }

  get useLocalImages() {
    return this._useLocalImages
  }

  set item(item) {
    this._item = item
    this.Image.useLocalImage = this.useLocalImages
    this.patch({
      Image: {
        image: item.logo,
      },
      Title: {
        text: {
          text: item.description,
        },
      },
    })
  }

  get item() {
    return this._item ? this._item : undefined
  }

  _focus() {
    const title = (this.item && this.item.title) || ''
    voiceGuide.utterThis(title)
    this.Image.setSmooth('y', -10, { duration: 0.3 })
    this.Image.highlight = true
  }

  _unfocus() {
    this.Image.setSmooth('y', 0, { duration: 0.3 })
    this.Image.highlight = false
  }
}
