// Fire Bolt Methods
export const DEVICE = 'Device'
export const ADVERTISING = 'Advertising'
export const LOCALIZATION = 'Localization'

// Fire Bolt device method Properties
export const DEVICE_PROPS = {
  UID: 'uid',
  NAME: 'name',
  MAKE: 'make',
  MODEL: 'model',
  PLATFORM: 'platform',
  RESOLUTION: 'screenResolution',
}

// Fire Bolt advertising method Properties
export const ADVERTISING_PROPS = {
  ADVERTISING_ID: 'advertisingId',
}

// Fire Bolt localization method Properties
export const LOCALIZATION_PROPS = {
  COUNTRY_CODE: 'countryCode',
  LANGUAGE: 'language',
  LATLON: 'latlon',
  LOCALE: 'locale',
  LOCALITY: 'locality',
  POSTAL_CODE: 'postalCode',
}
