import { Colors, Language, Lightning, Router, Utils } from '@lightningjs/sdk'
import { COLORS } from '../constants/colors'
import { SCREEN } from '../constants/screen'
import { GA4, PAGENAME } from '../constants/analytics'
import { sendMetrics } from '../services/metrics'

export default class Splash extends Lightning.Component {
  static _template() {
    return {
      w: SCREEN.WIDTH,
      h: SCREEN.HEIGHT,
      Logo: {
        x: w => w / 2,
        y: h => h / 2,
        w: 738,
        h: 334,
        mount: 0.5,
        CSpan: {
          alpha: 0,
          CSpanText: {
            x: 0,
            y: 0,
            w: 738,
            h: 151,
            src: Utils.asset('images/splash/cspanText.png'),
          },
          CSpanImage: {
            x: 164,
            y: 55,
            w: 42,
            h: 42,
            rect: true,
            color: Colors(COLORS.LOGO_SQUARE).get(),
          },
        },
        CSpanSquare: {
          x: 0,
          y: 181,
          w: 738,
          h: 84,
          flex: {
            direction: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
          },
          Square1: {
            flexItem: {},
            w: 84,
            h: 84,
            alpha: 0,
            rect: true,
            color: Colors(COLORS.LOGO_SQUARE)
              .alpha(0.16)
              .get(),
          },
          Square2: {
            flexItem: {},
            w: 84,
            h: 84,
            alpha: 0,
            rect: true,
            color: Colors(COLORS.LOGO_SQUARE)
              .alpha(0.34)
              .get(),
          },
          Square3: {
            flexItem: {},
            w: 84,
            h: 84,
            alpha: 0,
            rect: true,
            color: Colors(COLORS.LOGO_SQUARE)
              .alpha(0.6)
              .get(),
          },
          Square4: {
            flexItem: {},
            w: 84,
            h: 84,
            alpha: 0,
            rect: true,
            color: Colors(COLORS.LOGO_SQUARE).get(),
          },
          Select: {
            flexItem: {},
            alpha: 0,
            w: 353,
            h: 84,
            src: Utils.asset('images/splash/select.png'),
          },
        },
        CableText: {
          h: 60,
          y: 265,
          alpha: 0,
          text: {
            fontSize: 40,
            fontWeight: 400,
            lineHeight: 60,
            fontFace: 'PoppinsRegular',
            verticalAlign: 'middle',
            textColor: Colors(COLORS.LIGHTGREY).get(),
            text: Language.translate('powered_by_cable'),
          },
        },
      },
    }
  }

  _init() {
    this._csplashAnimation = this.tag('CSpan').animation({
      duration: 0.7,
      repeat: 0,
      stopMethod: 'immediate',
      actions: [
        { p: 'y', v: { 0: -50, 0.2: -20, 0.35: -6.34, 1: 0 } },
        { p: 'alpha', v: { 0: 0, 0.2: 0.6, 0.5: 0.9, 1: 1 } },
      ],
    })
    this._square1Animation = this.tag('Square1').animation({
      duration: 0.7,
      repeat: 0,
      delay: 0.2,
      stopMethod: 'immediate',
      actions: [{ p: 'alpha', v: { 0: 0, 0.15: 0.5, 0.3: 0.75, 0.45: 0.9, 0.6: 0.95, 1: 1 } }],
    })
    this._square2Animation = this.tag('Square2').animation({
      duration: 0.7,
      repeat: 0,
      delay: 0.35,
      stopMethod: 'immediate',
      actions: [{ p: 'alpha', v: { 0: 0, 0.15: 0.5, 0.3: 0.75, 0.45: 0.9, 0.6: 0.95, 1: 1 } }],
    })
    this._square3Animation = this.tag('Square3').animation({
      duration: 0.7,
      repeat: 0,
      delay: 0.5,
      stopMethod: 'immediate',
      actions: [{ p: 'alpha', v: { 0: 0, 0.15: 0.5, 0.3: 0.75, 0.45: 0.9, 0.6: 0.95, 1: 1 } }],
    })
    this._square4Animation = this.tag('Square4').animation({
      duration: 0.7,
      repeat: 0,
      delay: 0.65,
      stopMethod: 'immediate',
      actions: [{ p: 'alpha', v: { 0: 0, 0.15: 0.5, 0.3: 0.75, 0.45: 0.9, 0.6: 0.95, 1: 1 } }],
    })
    this._selectAnimation = this.tag('Select').animation({
      duration: 0.7,
      repeat: 0,
      delay: 0.8,
      stopMethod: 'immediate',
      actions: [{ p: 'alpha', v: { 0: 0, 0.5: 0.5, 1: 1 } }],
    })
    this._cableTextAnimation = this.tag('CableText').animation({
      duration: 1,
      repeat: 0,
      delay: 1.5,
      stopMethod: 'immediate',
      actions: [
        { p: 'y', v: { 0: 245, 1: 265 } },
        { p: 'alpha', v: { 0: 0, 0.5: 0.5, 1: 1 } },
      ],
    })
    this._splashAnimation = this.tag('Logo').animation({
      duration: 0.6,
      repeat: 0,
      delay: 5,
      stopMethod: 'immediate',
      actions: [{ p: 'alpha', v: { 0: 1, 0.5: 0.5, 1: 0 } }],
    })

    this._splashAnimation.on('finish', () => {
      // eslint-disable-next-line no-undef
      sendMetrics(GA4.SCREENVIEW, { page_title: PAGENAME.SPLASH })
      Router.resume()
    })
  }

  _active() {
    this._csplashAnimation.start()
    this._splashAnimation.start()
    this._square1Animation.start()
    this._square2Animation.start()
    this._square3Animation.start()
    this._square4Animation.start()
    this._selectAnimation.start()
    this._cableTextAnimation.start()
  }
}
