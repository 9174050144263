import { isBoolean, isNumber, isStringWithLength } from '../../utils/checks'
import { adjustToItalic } from '../../utils/cspan'

export default class VideoAsset {
  constructor(video = undefined, isliveStream = false) {
    this._date = isStringWithLength(video && video.date) ? video.date : undefined
    this._description = isStringWithLength(video && video.description)
      ? adjustToItalic(video.description)
      : undefined
    this._notice = isStringWithLength(video && video.notice)
      ? adjustToItalic(video.notice)
      : undefined
    this._id = isNumber(video && video.id) ? video.id : undefined
    this._imagePath = isStringWithLength(video && video.imagePath) ? video.imagePath : undefined
    this._liveBeginTime =
      isStringWithLength(video && video.liveBeginTime) &&
      video.liveEndTime !== '1970-01-01T00:00:00Z'
        ? video.liveBeginTime
        : undefined
    this._liveEndTime =
      isStringWithLength(video && video.liveEndTime) && video.liveEndTime !== '1970-01-01T00:00:00Z'
        ? video.liveEndTime
        : undefined
    this._liveNetwork = isNumber(video && video.liveNetwork) ? video.liveNetwork : undefined
    this._publicId = isStringWithLength(video && video.publicId) ? video.publicId : undefined
    this._title = isStringWithLength(video && video.title) ? adjustToItalic(video.title) : undefined
    this._videoDuration = isNumber(video && video.videoDuration) ? video.videoDuration : undefined

    this._videoFile = isStringWithLength(video && video.videoFile) ? video.videoFile : undefined
    this._videoFileHD = isStringWithLength(video && video.videoFileHD)
      ? video.videoFileHD
      : undefined
    this._videoLink = isStringWithLength(video && video.videoLink) ? video.videoLink : undefined
    this._videoMonetizable = isBoolean(video && video.videoMonetizable)
      ? video.videoMonetizable
      : false
    this._isliveStream = isliveStream
  }

  get date() {
    return this._date
  }

  get description() {
    return this._description
  }

  get notice() {
    return this._notice
  }

  get id() {
    return this._id
  }

  get imagePath() {
    return this._imagePath
  }

  get liveBeginTime() {
    return this._liveBeginTime
  }

  get liveEndTime() {
    return this._liveEndTime
  }

  get liveNetwork() {
    return this._liveNetwork
  }

  get publicId() {
    return this._publicId
  }

  get title() {
    return this._title
  }

  get videoDuration() {
    return this._videoDuration
  }

  get videoFile() {
    return this._videoFile
  }

  get videoFileHD() {
    return this._videoFileHD
  }

  get videoLink() {
    return this._videoLink
  }

  get videoMonetizable() {
    return this._videoMonetizable
  }

  get isliveStream() {
    return this._isliveStream
  }
}
