import { Lightning, Language, Colors } from '@lightningjs/sdk'
import { COLORS } from '@/constants/colors'
import { NewKeys } from '@/constants/search'
import { Grid } from '@lightningjs/ui'
import ProgramAsset from '@/api/assets/ProgramAsset'
import SearchCard from '@/components/Search/SearchCard'
import Loader from '@/components/Loader'
import { STATES } from '@/constants/search'
import { FONTSTYLE } from '@/constants/fonts'
import { trimAndCapitalize } from '@/utils/tools'

export default class SearchResult extends Lightning.Component {
  static _template() {
    return {
      Loader: { x: 637, y: 331, type: Loader, alpha: 0.0001 },
      Title: {
        w: 178,
        y: 0,
        alpha: 0,
        Label: {
          w: w => w,
          h: 25,
          y: y => y,
          text: {
            text: Language.translate('page_search').resultTitle,
            fontFace: 'PoppinsRegular',
            fontSize: 18,
            textColor: Colors(COLORS.DARKGREY_60).get(),
          },
        },
        SearchKeyword: {
          y: 25,
          text: {},
        },
      },
      Result: {
        y: 110,
        type: Grid,
        w: w => w,
        columns: 3,
        scroll: {
          after: 2,
          jump: 1,
          forward: 0.9,
          backward: 0.1,
        },
        signals: { onIndexChanged: true },
      },
    }
  }

  static _states() {
    return [
      class Loader extends this {
        $enter() {
          this.Loader.alpha = 1
        }

        $exit() {
          this.Loader.alpha = 0
        }
      },
      class Result extends this {
        $enter() {
          this.Result.alpha = 1
        }

        $exit() {
          this.Result.alpha = 0
        }
      },
    ]
  }

  set data(items) {
    if (Array.isArray(items)) {
      this.showHideTitle(1)
      this.clearGrid()
      items.forEach((item, index) => {
        item = this.renameKeys(item, NewKeys)
        if (item !== undefined) this.Result.add({ type: SearchCard, item, index, marginRight: 40 })
      })
    }
  }

  get Result() {
    return this.tag('Result')
  }

  get SearchKeyWord() {
    return this.tag('Title.SearchKeyword')
  }

  get Title() {
    return this.tag('Title')
  }

  get focusIndex() {
    return this.Result.index
  }

  get Loader() {
    return this.tag('Loader')
  }

  _getFocused() {
    return this.Result
  }

  clearGrid() {
    this._setState(STATES.RESULT)
    this.Result.clear()
  }

  showLoader() {
    this._setState(STATES.LOADER)
  }

  showHideTitle(alphaValue) {
    this.Title.setSmooth('alpha', alphaValue)
  }

  updateTitle(searchItem) {
    this.SearchKeyWord.patch({
      text: {
        text: trimAndCapitalize(searchItem),
        ...FONTSTYLE.H2,
        textColor: Colors(COLORS.LIGHTGREY).get(),
      },
    })
  }

  onIndexChanged({ index, previousIndex }) {
    if (index <= 2) {
      this.showHideTitle(1)
    } else {
      this.showHideTitle(0)
    }

    const listIndex = Math.floor(index / 3)

    if (listIndex === 0) {
      this.tag('Result').y = 110
    } else if (listIndex > 0) {
      this.tag('Result').y = 50
    }

    this.signal('onSearchGridIndexChanged', { index, previousIndex })
  }

  renameKeys(obj, newKeys) {
    const keyValues = Object.keys(obj).map(key => {
      const newKey = newKeys[key] || key
      return { [newKey]: obj[key] }
    })

    return new ProgramAsset(Object.assign({}, ...keyValues))
  }
}
