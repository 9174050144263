export const COLORS = {
  BLACK: '#000000',
  WHITE: '#ffffff',
  LIGHTGREY: '#D7E0E2',
  MIDNIGHT: '#162C47',
  DARKGREY: '#336075',
  DARKGREY_20: '#CED7DC',
  DARKGREY_40: '#A1B3BD',
  DARKGREY_60: '#7994A2',
  DARKGREY_60_FADED: '#2F3D49',
  NAVY: '#153569',
  COBALT: '#0F80C7',
  BACKGROUND_GRADIENT_TOP: '#162C47',
  BACKGROUND_GRADIENT_BOTTOM: '#0B1724',
  BACKGROUND_SERIES_TOP_ACTIVE: '#153569',
  BACKGROUND_SERIES_BOTTOM_ACTIVE: '#0f80c7',
  BACKGROUND_SERIES_TOP_INACTIVE: '#112A4E',
  BACKGROUND_SERIES_BOTTOM_INACTIVE: '#0D5585',
  BACKGROUND_BACKTOTOP_TOP: '#153569',
  BACKGROUND_BACKTOTOP_BOTTOM: '#0f80c7',
  DARKCYAN_BLUE: '#051426',
  GREY_85: '#D9D9D9',
  PLAYER_BG_GRADIENT: '#020B14',
  LOGO_SQUARE: '#3660FF',
  TVNET_SQUARE: '#0077BE',
}
