import { Lightning, Router } from '@lightningjs/sdk'
import { List } from '@lightningjs/ui'
import ScheduleItem from './ScheduleItem'
import ScrollBar from '../ScrollBar'
import { Log } from '@firebolt-js/sdk'

const ITEM_AVERAGE_HEIGHT = 129
const VISIBLE_HEIGHT = 600
export default class ScheduleList extends Lightning.Component {
  static _template() {
    return {
      w: 1920,
      Wrapper: {
        h: 829,
        y: 130,
        ScheduleList: {
          x: 96,
          w: 1920,
          type: List,
          direction: 'column',
          boundsMargin: [0, 500, 520, 0],
          signals: { onIndexChanged: true },
        },
        ScrollBar: {
          alpha: 0.001,
          x: 32,
          w: 16,
          h: 600,
          type: ScrollBar,
          signals: { scrollTo: true },
        },
      },
    }
  }

  get ScheduleList() {
    return this.tag('ScheduleList')
  }

  get ScrollBar() {
    return this.tag('ScrollBar')
  }

  set items(items) {
    this._setState('Loading')
    if (Array.isArray(items)) {
      this._data = items
      const length = items.length
      this.ScheduleList.items = items.map((item, index) => {
        return {
          type: ScheduleItem,
          item,
          lastItem: index === length - 1,
        }
      })
    }
  }

  get itemCount() {
    return this.ScheduleList.length
  }

  get _totalNumberOfItems() {
    return this.ScheduleList.items.length
  }

  _init() {
    this._direction = 'DOWN'
    this._childrenHeight = []
  }

  /**
   * Method used to update the alpha value of visible items
   * @param startIndex index of the current focused item
   * @private
   */
  _setAlphaToVisibleItems(startIndex) {
    /** From the starting index get all the visible items on the screen and calculate its total height.
        1)If the height(displayHeight) is less than the VISIBLE_HEIGHT then update its alpha value to 1
        2)if the height is more than the VISIBLE_HEIGHT then update the last 2 items of alpha as 0.6
     and the remaining item alpha as 0.1 **/
    let displayHeight = 0
    for (let count = startIndex; count < this._totalNumberOfItems; count++) {
      displayHeight = displayHeight + this._childrenHeight[count]
      this._setAlphaToItem(count, 1)
      if (displayHeight > VISIBLE_HEIGHT) {
        //Set last 2 items from visible area  to 0.6 and 0.1
        if (count + 1 < this._totalNumberOfItems) this._setAlphaToItem(count + 1, 0.6)
        if (count + 2 < this._totalNumberOfItems) this._setAlphaToItem(count + 2, 0.1)
        break
      }
    }
  }

  /**
   * Method used to set the alpha to the given item
   * @param index index of the child to which the alpha to be changed
   * @param alphaValue value of alpha that needs to be applied
   * @private
   */
  _setAlphaToItem(index, alphaValue) {
    const currentitem = this.ScheduleList.items[index]
    if (currentitem && typeof currentitem.setSmooth === 'function')
      currentitem.setSmooth('alpha', alphaValue, {
        duration: 0.1,
      })
    else currentitem.alpha = alphaValue
  }

  /**
   * Method used to finish any pending transitions when we scroll quickly
   * @private
   */
  _finishTransitions() {
    this.ScheduleList.fastForward('alpha')
    this.ScheduleList.fastForward('y')
  }

  /**
   * Method used to scroll an item to the given position
   * @param scrollPosition position to which the item should be moved
   * @param previousIndex index of the previous item
   * @param index index of the current item
   */
  scrollTo(scrollPosition, previousIndex, index) {
    if (!this.ScheduleList.length) return
    if (scrollPosition === 0) {
      this.ScheduleList.setIndex(0)
      this.ScheduleList.setSmooth('y', 0, {
        duration: 0.3,
      })
      this._setAlphaToVisibleItems(0)
      return
    }

    this._finishTransitions()

    let heightToMove = 129
    const currentY = this.ScheduleList.getSmooth('y')
    if (scrollPosition > previousIndex) {
      //handleDown
      heightToMove = currentY - this._childrenHeight[previousIndex]
    } else {
      heightToMove = currentY + this._childrenHeight[index]
    }

    try {
      this.ScheduleList.setSmooth('y', heightToMove, {
        duration: 0.3,
      })
      this._setAlphaToItem(index - 1, 0.1)
      this._setAlphaToVisibleItems(index)
    } catch (exception) {
      Log.error('Exception ', exception)
    }
  }

  clear() {
    this.ScheduleList.clear()
    this.scrollTo(0)
    this.ScrollBar.reset()
  }

  /**
   * Callback method which will be invoked whenever we scroll the list view
   * @param index index of the current item
   * @param previousIndex index of the previous item
   * @param dataLength number of items length
   */
  onIndexChanged({ index, previousIndex, dataLength }) {
    if (index > previousIndex && index < dataLength) {
      this._direction = 'DOWN'
      this.ScrollBar._handleDown()
    } else if (index < previousIndex && index >= 0) {
      this._direction = 'UP'
      this.ScrollBar._handleUp()
    }
  }

  static _states() {
    return [
      class Loading extends this {
        $enter() {
          this._initializeList()
        }

        $exit() {
          //If 5 or less  items available ,then focus is given to Header Widget
          this.signal('initialized', this.ScheduleList.items.length > 5)
        }

        _initializeList() {
          setTimeout(() => {
            // Each item in the list is of different size, Reposition the items after adding to the list.
            this.ScheduleList.repositionItems()
            let totalHeight = 0
            this.ScheduleList.items.forEach(item => {
              totalHeight += item.h
              this._childrenHeight.push(item.h)
            })

            this._height = totalHeight
            //If more than 5 items available ,then display the scroll bar
            if (this.ScheduleList.items.length > 5) {
              this.ScrollBar.alpha = 1
              const totalNumberOfItems = this.ScheduleList.items.length
              const visibleItems = totalHeight / totalNumberOfItems > ITEM_AVERAGE_HEIGHT ? 3 : 4
              this.ScrollBar.totalNumOfScrolls = Math.floor(totalNumberOfItems - visibleItems)
              this._setAlphaToVisibleItems(0)
              this._setState('List')
            } else {
              this._setState('DisableScrollBar')
            }
            this.ScheduleList.h = this._height
          }, 200)
        }
      },
      class List extends this {
        _getFocused() {
          return this.ScheduleList
        }

        _focus() {
          if (!Router.isNavigating()) this.ScrollBar.changeColor = true
        }

        _unfocus() {
          this.ScrollBar.changeColor = false
        }

        _handleUp() {
          Router.focusWidget('Header')
        }
      },
      class DisableScrollBar extends this {
        $enter() {
          this.ScrollBar.alpha = 0
        }
      },
    ]
  }
}
