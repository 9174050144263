/* eslint-disable prettier/prettier */
import {Colors, Language, Lightning, Utils} from '@lightningjs/sdk'
import PlayerButton from '../player/PlayerButton'
import {COLORS} from '../../constants/colors'
import TextButton from '../TextButton'
import {isoStringToDate} from '../../utils/time'
import CSpanButton from './CSPANbutton'
import TvNetTitle from '../TvNetTitle'
import {voiceGuide} from '../../services/voiceGuide'

export default class TvNetworkControls extends Lightning.Component {
	static _template() {
		return {
			GradientOverlay: {
				alpha: 1,
				x: 0,
				y: 0,
				h: 1080,
				w: 1920,
				color: Colors(COLORS.PLAYER_BG_GRADIENT).alpha(0.9).get(),
				rect: true
			},
			ContentGradient: {
				alpha: 0,
				Top: {
					zIndex: 2,
					w: 1920,
					h: 120,
					y: 0,
					mountY: 0,
					rect: true,
					colorTop: Colors(COLORS.PLAYER_BG_GRADIENT)
						.alpha(0.9)
						.get(),
					colorBottom: Colors(COLORS.PLAYER_BG_GRADIENT)
						.alpha(0.001)
						.get()
				},
				Bottom: {
					zIndex: 2,
					w: 1920,
					h: 120,
					y: 1080,
					mountY: 1,
					rect: true,
					colorBottom: Colors(COLORS.PLAYER_BG_GRADIENT)
						.alpha(0.9)
						.get(),
					colorTop: Colors(COLORS.PLAYER_BG_GRADIENT)
						.alpha(0.001)
						.get()
				}
			},
			ContentOverlay: {
				x: 104,
				y: 100,
				flex: {direction: 'column'},
				LiveLabel: {
					flexItem: {},
					text: {
						advancedRenderer: true,
						textColor: Colors(COLORS.DARKGREY_60).get(),
						fontFace: 'PoppinsRegular',
						fontSize: 28,
						fontWeight: 400,
						lineHeight: 42,
						textAlign: 'center',
						alpha: 0
					}
				},
				Title: {
					flexItem: {marginTop: 8},
					text: {
						advancedRenderer: true,
						fontSize: 40,
						fontWeight: 700,
						lineHeight: 60,
						fontFace: 'PoppinsBold',
						textColor: Colors(COLORS.LIGHTGREY).get(),
						wordWrap: true,
						wordWrapWidth: 1200,
						maxLines: 3,
						paddingRight: 400
					}
				},
				VideoDetails: {
					flexItem: {marginTop: 30},
					w: 800,
					h: 604,
					DescriptionWrapper: {
						w: 800,
						h: 590,
						clipping: true,
						Description: {
							alpha: 1,
							text: {
								advancedRenderer: true,
								fontSize: 28,
								lineHeight: 42,
								fontWeight: 400,
								fontFace: 'PoppinsRegular',
								textColor: Colors(COLORS.DARKGREY_40).get(),
								wordWrap: true,
								wordWrapWidth: 800,
								maxLines: 7,
								textOverflow: '...'
							}
						}
					},
					KnowMore: {
						alpha: 0,
						y: 330,
						w: 180,
						h: 61,
						type: TextButton,
						radius: 15,
						fontSize: 22,
						fontColor: Colors(COLORS.DARKGREY_60).get(),
						bgColor: Colors(COLORS.MIDNIGHT).get(),
						focusBackGroundColor: Colors(COLORS.LIGHTGREY).get(),
						unfocusBackgroundColor: Colors(COLORS.MIDNIGHT).get(),
						focusFontColor: Colors(COLORS.MIDNIGHT).get(),
						unfocusFontColor: Colors(COLORS.DARKGREY_60).get(),
						shadow: false,
						label: Language.translate('read_more')
					}
				}
			},
			VideoControl: {
				x: 104,
				y: 820,
				Play: {
					type: PlayerButton,
					color: Colors(COLORS.MIDNIGHT).get(),
					height: 26,
					width: 26,
					Image: Utils.asset('images/player/livePause.png')
				},
				CSpanButtons: {
					alpha: 1,
					x: 290,
					CSpan1: {
						type: CSpanButton,
						color: Colors(COLORS.MIDNIGHT).get(),
						h: 80,
						w: 250,
						image: Utils.asset('images/tvnet/CSPAN1.png'),
						height: 24,
						width: 122
					},
					CSpan2: {
						x: 440,
						type: CSpanButton,
						h: 80,
						w: 250,
						color: Colors(COLORS.MIDNIGHT).get(),
						image: Utils.asset('images/tvnet/CSPAN2.png'),
						height: 30,
						width: 140
					},
					CSpan3: {
						x: 875,
						type: CSpanButton,
						color: Colors(COLORS.MIDNIGHT).get(),
						image: Utils.asset('images/tvnet/CSPAN3.png'),
						h: 80,
						w: 250,
						height: 30,
						width: 140
					}
				},
				CSpanTitle: {
					alpha: 1,
					y: 115,
					x: 294,
					CSpan1: {
						w: 398,
						h: 150,
						type: TvNetTitle,
						selection: false
					},
					CSpan2: {
						x: 437,
						w: 398,
						h: 150,
						type: TvNetTitle,
						selection: false
					},
					CSpan3: {
						x: 875,
						w: 398,
						h: 150,
						type: TvNetTitle,
						selection: false
					}
				},
				CCDetails: {
					alpha: 1,
					x: 1615,
					flex: {
						direction: 'column',
						alignItems: 'center'
					},
					ClosedCaption: {
						flexItem: {},
						w: 94,
						h: 61,
						type: TextButton,
						radius: 15,
						fontSize: 22,
						fontColor: Colors(COLORS.DARKGREY_60).get(),
						bgColor: Colors(COLORS.MIDNIGHT).get(),
						focusBackGroundColor: Colors(COLORS.LIGHTGREY).get(),
						unfocusBackgroundColor: Colors(COLORS.MIDNIGHT).get(),
						focusFontColor: Colors(COLORS.MIDNIGHT).get(),
						unfocusFontColor: Colors(COLORS.DARKGREY_60).get(),
						shadow: false,
						label: Language.translate('cc')
					},
					CaptionIndicator: {
						alpha: 0,
						flexItem: {},
						mountY: 0.5,
						color: Colors(COLORS.COBALT).get(),
						texture: Lightning.Tools.getRoundRect(34, 8, 5)
					}
				}
			}
		}
	}

	set playOrPause(v) {
		this._playButtonIsStop = v
		let imageX = v ? '40' : '43'
		this.Play.width = v ? '26' : '29'
		this.Play.height = v ? '26' : '34'
		this.Play.imageX = imageX
		let icon = v ? this._pauseIcon : 'play.png'
		this.Play.image = Utils.asset('images/player/' + icon)
	}

	set CSpan1Title(title) {
		this.CSpan1Title.title = title
	}

	set CSpan2Title(title) {
		this.CSpan2Title.title = title
	}

	set CSpan3Title(title) {
		this.CSpan3Title.title = title
	}

	get DescHeight() {
		return this._descHeight
	}

	get Play() {
		return this.tag('Play')
	}

	get CSpan1() {
		return this.tag('CSpanButtons.CSpan1')
	}

	get CSpan2() {
		return this.tag('CSpanButtons.CSpan2')
	}

	get CSpan3() {
		return this.tag('CSpanButtons.CSpan3')
	}

	get CSpan1Title() {
		return this.tag('CSpanTitle.CSpan1')
	}

	get CSpan2Title() {
		return this.tag('CSpanTitle.CSpan2')
	}

	get CSpan3Title() {
		return this.tag('CSpanTitle.CSpan3')
	}

	get ClosedCaption() {
		return this.tag('ClosedCaption')
	}

	get CCDetails() {
		return this.tag('CCDetails')
	}

	get CaptionIndicator() {
		return this.tag('CaptionIndicator')
	}

	get ContentGradient() {
		return this.tag('ContentGradient')
	}

	get GradientOverlay() {
		return this.tag('GradientOverlay')
	}

	get ContentOverlay() {
		return this.tag('ContentOverlay')
	}

	get Description() {
		return this.tag('Description')
	}

	get DescriptionLength() {
		return this.tag('Description').text.text.length
	}

	get KnowMore() {
		return this.tag('KnowMore')
	}

	get VideoControl() {
		return this.tag('VideoControl')
	}


	get videoTitle() {
		return this.tag('Title').text.text
	}

	get videoTitleLength() {
		return this.tag('Title').text.text.length
	}


	get videoDescription() {
		return this.tag('Description').text.text
	}


	_init() {
		this._playButtonIsStop = true
		// eslint-disable-next-line prettier/prettier
		this._videoDuration = 0,
			// eslint-disable-next-line prettier/prettier
			this._liveState = false,
			// eslint-disable-next-line prettier/prettier
			this._CCkey = false,
			this._knowMore = true,
			this._pauseIcon = 'livePause.png'
		this.playOrPause = false
		this._setState('Play')
		this.updateCCButton(false)
		this.Description.on('txLoaded', () => {
			this._descHeight = this.Description.renderHeight
		})
	}

	_active() {
		this._setState('Play')
	}

	_focusSelectedText(cspan) {
		this.tag('CSpanTitle').children.forEach(item => {
			item.selection = (item.$ref === cspan)

		})
	}

	title(title) {
		this.tag('Title').text.text = title
		this.descPosition()
	}

	LiveLabel(text, isLive) {
		let videoDate = isoStringToDate(text)
		this.tag('LiveLabel').text.text = isLive ? text : `${videoDate.monthName} ${videoDate.day}, ${videoDate.year}  `
	}

	description(desc) {
		this.tag('Description').text.text = desc
		this.updateReadMoreButton()
	}

	cleanHtml(desc) {
		let tempDivElement = document.createElement('div')
		tempDivElement.innerHTML = desc
		return tempDivElement.textContent
	}

	changeFocus(state) {
		this._setState(state)
	}

	sendControlAction(action) {
		this.application.emit('playerControlAction', action)
	}

	selectCSpanChannel(key) {
		this.application.emit('channel', key)
	}

	updateCCState() {
		if (this._CCkey) {
			let captionState = this.CaptionIndicator.alpha == 0 ? 'hide' : 'show'
			this.fireAncestors(captionState == 'hide' ? '$hideCCUpdate' : '$showCCUpdate')
		}

	}

	updateCspan1() {
		this.CSpan1.image = Utils.asset('images/tvnet/S-CSPAN1.png')
	}


	updateCCButton(ccKey) {
		this.CCDetails.alpha = ccKey ? 1 : 0
		this._CCkey = ccKey
		this.updateReadMoreButton()
	}

	descPosition() {
		this.ContentGradient.setSmooth('alpha', 0)
		this.tag('ContentOverlay').y = 100
	}

	updateReadMoreButton() {
		this.KnowMore.alpha = (this.DescriptionLength > 355) ? '1' : '0'
		this._knowMore = this.DescriptionLength > 355
	}

	toggleCCButton(CCText) {
		this.CaptionIndicator.setSmooth('alpha', CCText === 'hidden' ? 0 : 1)
	}

	showDesc(v) {
		this.KnowMore.setSmooth('alpha', v ? 0 : 1)
		this.VideoControl.setSmooth('alpha', v ? 0 : 1)
		this.Description.text.maxLines = v ? 20 : 7
	}

	cSpanReset() {
		this.CSpan1.image = Utils.asset('images/tvnet/CSPAN1.png')
		this.CSpan2.image = Utils.asset('images/tvnet/CSPAN2.png')
		this.CSpan3.image = Utils.asset('images/tvnet/CSPAN3.png')
	}

	static _states() {
		return [
			class Play extends this {
				_getFocused() {
					return this.Play
				}

				$enter() {
					/* istanbul ignore next */
					voiceGuide.utterThis(this._playButtonIsStop ? 'Stop' : 'Play')
					this.Play.color = Colors(COLORS.LIGHTGREY).get()
					this._focusSelectedText('')
				}

				$exit() {
					this.Play.color = Colors(COLORS.MIDNIGHT).get()
				}

				_handleEnter() {
					this.fireAncestors('$pressPlay')
				}

				_handleUp() {
					if (this._knowMore) {
						this._setState('KnowMore')
					} else {
						this.fireAncestors('$hideTVNetOverlay')
					}
				}

				_handleRight() {
					this._setState('CSpan1')
				}

				_handleLeft() {
					this.fireAncestors('$hideTVNetOverlay')
				}

			},
			class CSpan1 extends this {

				_getFocused() {
					return this.CSpan1
				}

				$enter() {
					/* istanbul ignore next */
					voiceGuide.utterThis('C-SPAN 1')
				}

				_handleEnter() {
					this.cSpanReset()
					this.CSpan1.image = Utils.asset('images/tvnet/S-CSPAN1.png')
					this.selectCSpanChannel('C-SPAN1')
					this.updateCCState()
					this._focusSelectedText('CSpan1')

				}

				_handleLeft() {
					this._setState('Play')
				}

				_handleRight() {
					this._setState('CSpan2')
				}

				_handleUp() {
					if (this._knowMore) {
						this._setState('KnowMore')
					} else {
						this.fireAncestors('$hideTVNetOverlay')
					}
				}

			},
			class CSpan2 extends this {

				_getFocused() {
					return this.CSpan2
				}

				$enter() {
					/* istanbul ignore next */
					voiceGuide.utterThis('C-SPAN 2')
				}

				_handleEnter() {
					this.cSpanReset()
					this.CSpan2.image = Utils.asset('images/tvnet/S-CSPAN2.png')
					this.selectCSpanChannel('C-SPAN2')
					this.updateCCState()
					this._focusSelectedText('CSpan2')
				}

				_handleUp() {
					if (this._knowMore) {
						this._setState('KnowMore')
					} else {
						this.fireAncestors('$hideTVNetOverlay')
					}
				}

				_handleLeft() {
					this._setState('CSpan1')
				}

				_handleRight() {
					this._setState('CSpan3')
				}
			},
			class CSpan3 extends this {

				_getFocused() {
					return this.CSpan3
				}

				$enter() {
					/* istanbul ignore next */
					voiceGuide.utterThis('C-SPAN 3')
				}

				_handleEnter() {
					this.cSpanReset()
					this.CSpan3.image = Utils.asset('images/tvnet/S-CSPAN3.png')
					this.selectCSpanChannel('C-SPAN3')
					this.updateCCState()
					this._focusSelectedText('CSpan3')
				}

				_handleUp() {
					if (this._knowMore) {
						this._setState('KnowMore')
					} else {
						this.fireAncestors('$hideTVNetOverlay')
					}
				}

				_handleLeft() {
					this._setState('CSpan2')
				}

				_handleRight() {
					if (this._CCkey) {
						this._setState('ClosedCaption')
					} else {
						this.fireAncestors('$hideTVNetOverlay')
					}
				}
			},

			class ClosedCaption extends this {
				$enter() {
					/* istanbul ignore next */
					voiceGuide.utterThis('Closed Captions')
					this.ClosedCaption.bgColor = Colors(COLORS.LIGHTGREY).get()
				}

				$exit() {
					this.ClosedCaption.bgColor = Colors(COLORS.MIDNIGHT).get()
				}

				_getFocused() {
					return this.ClosedCaption
				}

				_handleEnter() {
					this.fireAncestors('$closedCaption')
				}

				_handleUp() {
					if (this._knowMore) {
						this._setState('KnowMore')
					} else {
						this.fireAncestors('$hideTVNetOverlay')
					}
				}

				_handleRight() {
					this.fireAncestors('$hideTVNetOverlay')
				}

				_handleLeft() {
					this._setState('CSpan3')
				}
			},
			class KnowMore extends this {
				$enter() {
					/* istanbul ignore next */
					voiceGuide.utterThis('Read More')
					this.KnowMore.bgColor = Colors(COLORS.LIGHTGREY).get()
				}

				$exit() {
					this.KnowMore.bgColor = Colors(COLORS.MIDNIGHT).get()
				}

				_getFocused() {
					return this.KnowMore
				}

				_handleEnter() {
					this.fireAncestors('$showDesc')
				}

				_handleUp() {
					this.fireAncestors('$hideTVNetOverlay')
				}

				_handleDown() {
					this._setState('Play')
				}
			}
		]
	}
}
