import { Lightning, Language, Utils, Colors } from '@lightningjs/sdk'
import { FONTS, FONTSTYLE } from '@/constants/fonts'
import { COLORS } from '@/constants/colors'
import { trimAndCapitalize } from '@/utils/tools'

export default class SearchBar extends Lightning.Component {
  static _template() {
    return {
      Icon: {
        w: 31,
        h: 31,
        y: 15,

        src: Utils.asset('images/search/search.png'),
      },
      Label: {
        x: 57,
        y: 0,
        h: 48,
        rect: true,
        text: {
          textColor: FONTS.PAGE.SEARCH.fontColor,
          ...FONTSTYLE.SEARCH_BAR,
          text: Language.translate('page_search').searchPlaceHolderText,
          verticalAlign: 'middle',
          textAlign: 'left',
        },
      },
      Line: {
        w: 360,
        x: 0,
        y: 66,
        h: 4,
        color: Colors(COLORS.DARKGREY).get(),
        rect: true,
        shader: { type: Lightning.shaders.RoundedRectangle, radius: 5 },
      },
    }
  }

  _updateLabel(str, highlight = false) {
    this.patch({
      Label: {
        text: {
          text: trimAndCapitalize(str),
          // eslint-disable-next-line prettier/prettier
					textColor: highlight
						? FONTS.PAGE.SEARCH.focusColor
						: FONTS.PAGE.SEARCH.fontColor
        },
      },
    })
  }
}
