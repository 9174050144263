import { Accessibility } from '@firebolt-js/sdk'

// Instantiated in firebolt.js, see imports
class VoiceGuide {
  constructor() {
    window._synth = window.speechSynthesis
    window._pendingUtterances = [] //Garbage collection bug avoids end state uttarances not triggering, storing globally

    this._voices = []
    this._voice = undefined
    this._pitch = 1
    this._rate = 1
    this._languageCode = 'en-US'
    this._pendingLines = []
    this._voiceGuidanceEnabled = false

    this._populateVoiceList()
    this._getVoiceGuidanceSettings()
    this._listenToVoiceGuidanceChanges()
  }

  get synth() {
    return window._synth
  }

  set voice(voice) {
    this._voice = voice
  }

  get voice() {
    return this._voice
  }

  set voices(voices) {
    this._voices = voices
  }

  get voices() {
    return this._voices
  }

  set pitch(pitch) {
    this._pitch = pitch
  }

  get pitch() {
    return this._pitch
  }

  set rate(rate) {
    this._rate = rate
  }

  get rate() {
    return this._rate
  }

  set languageCode(languageCode) {
    this._languageCode = languageCode
  }

  get languageCode() {
    return this._languageCode
  }

  set lastLineSpoken(lastLineSpoken) {
    this._lastLineSpoken = lastLineSpoken
  }

  get lastLineSpoken() {
    return typeof this._lastLineSpoken === 'string' && this._lastLineSpoken.length > 0
      ? this._lastLineSpoken
      : ''
  }

  set pendingItems(pendingItems) {
    window._pendingUtterances = pendingItems
  }

  get pendingItems() {
    return window._pendingUtterances
  }

  set utterance(utterance) {
    window._utterance = utterance
  }

  get utterance() {
    return window._utterance
  }

  _populateVoiceList() {
    if (this.synth && this.synth.getVoices) {
      this.voices = this.synth.getVoices()
    }
  }

  async _getVoiceGuidanceSettings() {
    const vgSettings = await Accessibility.voiceGuidanceSettings()
    this._voiceGuidanceEnabled =
      typeof vgSettings.enabled === 'boolean' ? vgSettings.enabled : false
    this._rate = typeof vgSettings.speed === 'number' ? vgSettings.speed / 100 : 1
  }

  _listenToVoiceGuidanceChanges() {
    Accessibility.listen('voiceGuidanceSettingsChanged', vgSettings => {
      this._voiceGuidanceEnabled =
        typeof vgSettings.enabled === 'boolean' ? vgSettings.enabled : false
      this._rate = typeof vgSettings.speed === 'number' ? vgSettings.speed / 100 : 1
    })
  }

  cancel() {
    this.synth.cancel()
  }

  utterThis(value = '', keepTalking = false) {
    if (value === this.lastLineSpoken) {
      if (this.synth && this.synth.cancel) this.synth.cancel()
      return
    }
    if (!this._voiceGuidanceEnabled) return
    value = textWithoutHtmlTags(value)

    function textWithoutHtmlTags(text) {
      return text.replace(/<.*?>/gi, '')
    }

    if (!keepTalking) this.synth.cancel()
    const utterance = this._makeUtterance(value)
    this._speakUtterance(utterance)
  }

  _makeUtterance(value) {
    const utterance = new SpeechSynthesisUtterance(value)
    utterance.pitch = this.pitch
    utterance.rate = this.rate
    utterance.lang = this.languageCode
    return utterance
  }

  _speakUtterance(utterance) {
    return new Promise((resolve, reject) => {
      this.synth.speak(utterance)

      utterance.onstart = event => {
        if (
          event &&
          event.utterance &&
          event.utterance.text &&
          typeof event.utterance.text === 'string'
        ) {
          this.lastLineSpoken = event.utterance.text
        } else if (
          event &&
          event.target &&
          event.target.text &&
          typeof event.target.text === 'string'
        ) {
          this.lastLineSpoken = event.target.text
        } else {
          this.lastLineSpoken = ''
        }
      }

      utterance.onend = () => {
        resolve('end')
      }

      utterance.onerror = event => {
        reject(event.error)
      }

      // For debugging purposes
      // utterance.onpause = () => {
      //   // console.log('>>>>>> VOICE GUIDE - PAUSED ', event.elapsedTime)
      //   // resolve('paused')
      // }
      //
      // utterance.onresume = () => {
      //   // console.log('>>>>>> VOICE GUIDE - RESUME ', event.elapsedTime)
      //   // resolve('resumed')
      // }
      //
      // utterance.onboundary = event => {
      //   // console.log('>>>>>> VOICE GUIDE - BOUNDARY ', event.elapsedTime)
      // }
    })
  }
}

export const voiceGuide = new VoiceGuide()
