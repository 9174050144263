import {
  isArrayWithLength,
  isNumber,
  isObjectWithLength,
  isStringWithLength,
} from '../../utils/checks'
import ProgramAsset from './ProgramAsset'
import { adjustToItalic } from '../../utils/cspan'

export default class HomeListAsset {
  constructor(row, callAsset) {
    this._displayType = isStringWithLength(callAsset && callAsset.displayType)
      ? callAsset.displayType
      : undefined
    this._id = isStringWithLength(callAsset && callAsset.id) ? callAsset.id : undefined
    this._params = isObjectWithLength(callAsset && callAsset.params) ? callAsset.params : undefined
    this._paramsAsString = isStringWithLength(callAsset && callAsset.paramsAsString)
      ? callAsset.paramsAsString
      : undefined
    this._title = isStringWithLength(callAsset && callAsset.title)
      ? adjustToItalic(callAsset.title)
      : undefined
    this._type = isStringWithLength(callAsset && callAsset.type) ? callAsset.type : undefined
    this._weight = isNumber(callAsset && callAsset.weight) ? callAsset.weight : undefined
    this._row = isArrayWithLength(row) ? this._createRow(row) : undefined
  }

  get displayType() {
    return this._displayType
  }

  get id() {
    return this._id
  }

  get params() {
    return this._params
  }

  get paramsAsString() {
    return this._paramsAsString
  }

  get title() {
    return this._title
  }

  get type() {
    return this._type
  }

  get weight() {
    return this._weight
  }

  get row() {
    return this._row
  }

  _createRow(row) {
    return row.map(programItem => {
      return new ProgramAsset(programItem)
    })
  }
}
