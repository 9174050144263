import { Utils, Lightning, Colors, Language } from '@lightningjs/sdk'
import { FOCUS, UNFOCUS, BUTTONS, INPUT_TYPE } from '@/constants/search.js'
import { COLORS } from '@/constants/colors'
import TextButton from '@/components/TextButton'
import InputKey from '@/components/Search/Keyboard/InputKey.js'
import ImageKey from './ImageKey'
import Spacekey from '@/components/Search/Keyboard/Spacekey'
import { voiceGuide } from '../../../services/voiceGuide'

export default class KeyboardButton extends Lightning.Component {
  static _template() {
    return {
      Content: {},
    }
  }

  set key(v) {
    this._key = v

    if (this.active) {
      this._update()
    }
  }

  get key() {
    return this._key
  }

  get c() {
    return this.key.c
  }

  _init() {
    this._update()
  }

  _focus() {
    const action = (this._key && this._key.action) || ''
    voiceGuide.utterThis(action)
    this.handleFocus(FOCUS)
  }

  _unfocus() {
    this.handleFocus(UNFOCUS)
  }

  _update() {
    const key = this._key

    let content =
      this._key.action != 'input'
        ? this.updateTextButtons(this.key.action)
        : {
            InputButton: {
              buttonText: key.c,
              type: InputKey,
            },
          }

    let mount = 0
    if (this.key.action != 'input') {
      mount = 0.5
    }
    this.patch({ Content: { x: 0, y: 0, mount: mount, ...content } })
  }

  handleFocus(state) {
    if (this.key.action === 'input') {
      this.focusHandlers(state, 'Content.InputButton')
    } else if (this.key.action === INPUT_TYPE.BACKSPACE) {
      this.focusHandlers(state, 'Content.ImageButton')
    } else if (this.key.action === INPUT_TYPE.SPACE) {
      this.focusHandlers(state, 'Content.Space')
    } else {
      this.focusHandlers(state, 'Content.TextButton')
    }
  }

  focusHandlers(state, templateName) {
    if (state === FOCUS) {
      this.tag(templateName)._focus()
    } else {
      this.tag(templateName)._unfocus()
    }
  }

  updateTextButtons(action) {
    let template = {
      y: this.h / 2,
      src: this._key.source ? Utils.asset(this._key.source) : '',
    }

    if (action === INPUT_TYPE.SEARCH) {
      template = {
        TextButton: {
          ...template,
          ...BUTTONS[action],
          type: TextButton,

          label: this.getLabel(action),
        },
      }
      delete template.src
      this.updateColor(template.TextButton, action)
    } else if (action === INPUT_TYPE.SPACE) {
      template = {
        Space: {
          type: Spacekey,
        },
      }
    } else if (action === INPUT_TYPE.BACKSPACE) {
      template = {
        ImageButton: {
          ...template,
          ...BUTTONS[action],
          type: ImageKey,
          image: Utils.asset('images/search/backspace-unfocus.png'),
        },
      }
      delete template.src
      this.updateColor(template.ImageButton, action)
    }
    return template
  }

  updateColor(template, action) {
    if (template.type.name != 'ImageKey') {
      template.fontColor = Colors(COLORS[template.fontColor]).get()
      template.focusFontColor = Colors(COLORS[template.focusFontColor]).get()
      // eslint-disable-next-line prettier/prettier
			template.unfocusFontColor = Colors(
				COLORS[template.unfocusFontColor]
			).get()
    }

    template.bgColor = Colors(COLORS[template.bgColor]).get()
    // eslint-disable-next-line prettier/prettier
		template.focusBackGroundColor = Colors(
			COLORS[template.focusBackGroundColor]
		).get()
    // eslint-disable-next-line prettier/prettier
		if (action === INPUT_TYPE.SEARCH) {
      template.unfocusBackgroundColor = Colors(COLORS[template.unfocusBackgroundColor]).get()
    } else {
      template.unfocusBackgroundColor = Colors(COLORS[template.unfocusBackgroundColor])
        .alpha(0.4)
        .get()
    }
  }

  getLabel(action) {
    const labels = {
      search: Language.translate('page_search').searchButtonText,
      space: Language.translate('page_search').keySpace,
    }
    return labels[action]
  }
}
