import { Colors, Lightning, Log, Utils } from '@lightningjs/sdk'
import { getImage } from '../api/Api'
import { isObjectWithLength, isStringWithLength } from '../utils/checks'
import { COLORS } from '../constants/colors'
import LiveBadge from './Home/LiveBadge'

export default class CSPANImage extends Lightning.Component {
  static _template() {
    return {
      Background: {
        x: -10,
        y: -10,
        src: '',
      },
      DropShadow: undefined,
      Image: { src: '', alpha: 0.0001, transitions: { alpha: { duration: 0.7 } } },
      LiveBadge: {
        x: 20,
        y: 20,
        zIndex: 30,
        type: LiveBadge,
      },
    }
  }

  _init() {
    this._imageCancelled = false
    this.tag('Image').on('txLoaded', () => {
      if (this.noImage === false) {
        this.tag('Image').setSmooth('alpha', 1)
      }
    })
    this.tag('Image').on('txError', () => {
      if (this.noImage === false) {
        this.patch({
          Image: {
            src: this.presetDefaults,
          },
        })
      }
    })
    this.updateContent()
  }

  get availableTypes() {
    return ['small', 'normal', 'large', 'book', 'upcoming']
  }

  get presetBackgrounds() {
    return {
      small: Utils.asset('images/thumbnails/default/small_bg.png'),
      normal: Utils.asset('images/thumbnails/default/normal_bg.png'),
      large: Utils.asset('images/thumbnails/default/large_bg.png'),
      book: Utils.asset('images/thumbnails/default/book_bg.png'),
      upcoming: Utils.asset('images/thumbnails/default/upcoming_bg.png'),
    }
  }

  get presetDefaults() {
    return Utils.asset(
      this.preset === 'book'
        ? 'images/thumbnails/missing/book.png'
        : 'images/thumbnails/missing/normal.png'
    )
  }

  set preset(presetName) {
    if (this.availableTypes.includes(presetName)) {
      this._preset = presetName
      this.tag('Background').patch({
        x: -10,
        y: -10,
        src: this.preset ? this.presetBackgrounds[presetName] : undefined,
      })
    } else {
      Log.warn('CSPAN Image - invalid preset: ', presetName)
    }
  }

  get preset() {
    return this._preset && isStringWithLength(this._preset) ? this._preset : undefined
  }

  set useLocalImage(useLocalImage) {
    this._useLocalImage = useLocalImage
  }

  get useLocalImage() {
    return typeof this._useLocalImage === 'boolean' ? this._useLocalImage : false
  }

  set highlight(highlight) {
    this._highlight = highlight
    this.patch({
      Highlight: {
        alpha: highlight ? 1 : 0.0001,
      },
    })
  }

  get highlight() {
    return typeof this._highlight === 'boolean' ? this._highlight : false
  }

  get width() {
    return this._width
  }

  get height() {
    return this._height
  }

  set radius(radius) {
    this._radius = radius
  }

  get radius() {
    return typeof this._radius === 'number' ? this._radius : 15
  }

  set strokeFocused(strokeFocused) {
    this._strokeFocused = strokeFocused
  }

  get strokeFocused() {
    return typeof this._strokeFocused === 'number' ? this._strokeFocused : 5
  }

  set strokeUnfocused(strokeUnfocused) {
    this._strokeUnfocused = strokeUnfocused
  }

  get strokeUnfocused() {
    return typeof this._strokeUnfocused === 'number' ? this._strokeUnfocused : 0
  }

  set strokeColor(strokeColor) {
    this._strokeColor = strokeColor
  }

  get strokeColor() {
    return this._strokeColor ? this._strokeColor : 0xffffffff
  }

  get imageUrl() {
    return isStringWithLength(this._imageUrl) ? this._imageUrl : ''
  }

  set shadowX(x) {
    this._shadowX = x
  }

  get shadowX() {
    return typeof this._shadowX === 'number' ? this._shadowX : -4
  }

  set shadowY(y) {
    this._shadowY = y
  }

  get shadowY() {
    return typeof this._shadowY === 'number' ? this._shadowY : 10
  }

  set shadowBlur(blur) {
    this._shadowBlur = blur
  }

  get shadowBlur() {
    return typeof this._shadowBlur === 'number' ? this._shadowBlur : 8
  }

  set shadowW(w) {
    this._shadowW = w
  }

  get shadowW() {
    return typeof this._shadowW === 'number' ? this._shadowW : 8
  }

  set activate(activate) {
    if (this.tag('Image')) {
      this.tag('Image').setSmooth(
        'color',
        activate ? Colors(COLORS.WHITE).get() : Colors(COLORS.DARKGREY_60).get()
      )
    }
    if (this.tag('LiveBadge')) {
      this.tag('LiveBadge').activate = activate
    }
  }

  set liveBadge(liveData) {
    if (isObjectWithLength(liveData)) {
      this.tag('LiveBadge').item = liveData
    } else {
      this.patch({ LiveBadge: undefined })
    }
  }

  set image(imagePath) {
    this._imageUrl = imagePath
  }

  // True means no use of image
  set noImage(noImage) {
    this._noImage = noImage
  }

  get noImage() {
    return typeof this._noImage === 'boolean' ? this._noImage : false
  }

  //Get image when item is active
  _active() {
    if (this.noImage === true) return
    if (this.useLocalImage) {
      this.updateImage(this._imageUrl)
    } else {
      this._imageTimeout = setTimeout(() => {
        if (this._imageCancelled === false) {
          getImage(this._imageUrl, {
            resize: { width: this._width, height: this._height },
          }).then(imageUrl => {
            this.updateImage(imageUrl)
          })
        }
      }, 400)
    }
  }

  _inactive() {
    if (this._imageTimeout) clearTimeout(this._imageTimeout)
    this._imageCancelled = true
  }

  updateImage(imageUrl) {
    this._imageUrl = imageUrl
    this.tag('Image').src = isStringWithLength(imageUrl) ? imageUrl : this.presetDefaults
  }

  updateContent() {
    const presetResolutions = {
      small: { w: 325, h: 150 },
      normal: { w: 398, h: 226 },
      large: { w: 544, h: 306 },
      book: { w: 252, h: 378 },
      upcoming: { w: 160, h: 90 },
    }

    this._width = this.preset ? presetResolutions[this.preset].w : this.w
    this._height = this.preset ? presetResolutions[this.preset].h : this.h
    this._strokeColor = 0xffffffff

    this.patch({
      w: this._width,
      h: this._height,
      Background: {
        src: this.preset ? this.presetBackgrounds[this.preset] : undefined,
      },
      DropShadow: this.preset
        ? undefined
        : {
            zIndex: 10,
            alpha: 0.2,
            x: this.shadowX,
            y: this.shadowY,
            w: this._width + this.shadowW,
            h: this._height,
            rect: true,
            color: Colors(COLORS.BLACK).get(),
            texture: Lightning.Tools.getShadowRect(
              this._width + this.shadowW,
              this._height,
              this.radius,
              this.shadowBlur,
              2
            ),
          },
      Highlight: {
        zIndex: 15,
        x: -10,
        y: -10,
        w: this._width + 20,
        h: this._height + 20,
        rect: true,
        alpha: this.highlight ? 1 : 0.0001,
        shader: {
          type: Lightning.shaders.RoundedRectangle,
          radius: 20,
          stroke: 5,
          fillColor: Colors(COLORS.WHITE)
            .alpha(0)
            .get(),
          strokeColor: Colors(COLORS.LIGHTGREY).get(),
        },
      },
      Image: {
        zIndex: 20,
        w: this._width,
        h: this._height,
        shader: {
          type: Lightning.shaders.RoundedRectangle,
          radius: this.radius,
        },
      },
    })
  }
}
