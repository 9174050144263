import { Lightning, Router, Log, Colors, Language } from '@lightningjs/sdk'
import SeriesGrid from '../components/Series/SeriesGrid'
import BookItem from '../components/Series/BookItem'
import VideoItem from '../components/Series/VideoItem'
import { COLORS } from '../constants/colors'
import { isNumber } from '../utils/checks'
import { getWidget } from '../utils/tools'
import BackToTop from '../components/Home/BackToTop'
import { BUTTON, GA4, PAGENAME } from '../constants/analytics'
import { sendMetrics } from '../services/metrics'
import { ROUTE } from '../constants/routes'

export default class Series extends Lightning.Component {
  static _template() {
    return {
      x: 0,
      y: 0,
      w: 1920,
      h: 1080,
      NoData: {
        x: 930,
        y: 540,
        mount: 0.5,
        alpha: 0,
        text: {
          text: Language.translate('no_data'),
          fontSize: 24,
          lineHeight: 32,
          maxLines: 1,
          fontFace: 'PoppinsRegular',
          wordWrapWidth: 1000,
          textColor: Colors(COLORS.LIGHTGREY).get(),
        },
      },
      Wrapper: {
        Background: {
          Image: { w: 1920, h: 400 },
          Gradient: {
            y: 206,
            rect: true,
            w: 1920,
            h: 194,
            colorTop: Colors(COLORS.BACKGROUND_GRADIENT_TOP)
              .alpha(0)
              .get(),
            colorBottom: Colors(COLORS.BACKGROUND_GRADIENT_TOP).get(),
          },
        },
        Description: {
          y: 264,
          x: 103,
          text: {
            advancedRenderer: true,
            fontSize: 24,
            lineHeight: 32,
            maxLines: 2,
            fontFace: 'PoppinsSemiBold',
            wordWrapWidth: 1712,
            textColor: Colors(COLORS.LIGHTGREY).get(),
          },
        },
      },
      SeriesGrid: {
        x: 90,
        y: 380,
        w: 1920,
        h: 1080,
        type: SeriesGrid,
        transitions: { y: { duration: 0.4 } },
        signals: {
          onItemSelection: true,
          scrollHeader: true,
          onMainIndexChanged: true,
        },
      },
      BackToTop: { type: BackToTop },
    }
  }

  get SeriesGrid() {
    return this.tag('SeriesGrid')
  }

  get NoData() {
    return this.tag('NoData')
  }

  get Wrapper() {
    return this.tag('Wrapper')
  }

  get BackToTop() {
    return this.tag('BackToTop')
  }

  set data(data) {
    this._data = data
  }

  set params(args) {
    if (args[Router.symbols['historyState']])
      this._index = args[Router.symbols['historyState']].index
  }

  _init() {
    this._index = { focusIndex: 0, mainIndex: 0, previousMainIndex: 0 }
  }

  _active() {
    this._renderData()
  }

  historyState(params) {
    if (!params) {
      return {
        index: this._index,
      }
    }
  }

  _handleBack() {
    if (this._index.mainIndex > 0) {
      this.SeriesGrid.setFocusToFirstItem()
    } else {
      sendMetrics(GA4.CLICK, { button: BUTTON.BACK, page_title: PAGENAME.SERIES })
      // Router.back()
      const historyLength = Router.getHistory() ? Router.getHistory().length : 0
      if (historyLength > 0) {
        Router.back()
      } else {
        if (Router.getActiveRoute() === ROUTE.HOME) {
          Router.back()
        } else {
          Router.navigate(ROUTE.HOME)
        }
      }
    }
  }

  _renderData() {
    //Based on row index(mainIndex) update background and display BackToTop component
    this._index.mainIndex > 0
      ? this.onMainIndexChanged({
          mainIndex: this._index.mainIndex,
          previousMainIndex: this._index.previousMainIndex,
          index: this._index.focusIndex,
        })
      : getWidget('Background').startPosition()
    if (this._data.items) {
      this._setState('SeriesGrid')
      this.patch({
        Wrapper: {
          Background: {
            Image: {
              src: this._data.banner,
            },
          },
          Description: {
            text: {
              text: this._data.description,
            },
          },
        },
      })
      this._type = this._data.template ? this._data.template : 'video'
      this.SeriesGrid.options = {
        width: 1920,
        height: this._type === 'video' ? 380 : 260,
        xVal: this._type === 'video' ? 15 : 15,
        columns: this._type === 'video' ? 4 : 3,
        crossSpacing: this._type === 'video' ? 40 : 120,
        mainSpacing: this._type === 'video' ? 40 : 78,
        itemType: this._type === 'video' ? VideoItem : BookItem,
        alpha: 0.6,
      }
      this.SeriesGrid.items = {
        items: this._data.items,
        focusIndex: this._index.focusIndex,
      }
      this._showBackToTop(this._index.mainIndex > 0)
    } else {
      this._setState('NoData')
    }
  }

  _showBackToTop(value) {
    this.BackToTop.show = value
  }

  onItemSelection({ item, focusIndex }) {
    this._index.focusIndex = focusIndex
    const itemType = item.itemType
    const id = item.id
    if (itemType && id) {
      Router.navigate(`video/${itemType}/${id}`)
    } else {
      Log.warn('missing params ', { itemType, id })
    }
  }

  onMainIndexChanged({ mainIndex, previousMainIndex, index }) {
    if (isNumber(mainIndex)) {
      mainIndex > 0 ? this._showBackToTop(true) : this._showBackToTop(false)
      this._index.mainIndex = mainIndex
      this._index.previousMainIndex = previousMainIndex
      this._index.focusIndex = index
      getWidget('Background').moveBackground(mainIndex)
    }
  }

  scrollHeader({ lightningComponent, startPos = 304 }) {
    //Move Title while items are scrolled
    if (lightningComponent !== undefined) {
      lightningComponent.core.onAfterUpdate = () => {
        if (lightningComponent) {
          lightningComponent.core.onAfterUpdate = () => {
            let newCornerPoints = lightningComponent.core.getCornerPoints()
            this.Wrapper.patch({
              y: newCornerPoints[1] - startPos,
            })
          }
        }
      }
    } else {
      this.Wrapper.patch({
        y: 0,
      })
    }
  }

  static _states() {
    return [
      class SeriesGrid extends this {
        _getFocused() {
          return this.SeriesGrid
        }
      },
      class NoData extends this {
        $enter() {
          this.NoData.alpha = 1
        }

        $exit() {
          this.NoData.alpha = 0
        }
      },
    ]
  }
}
