import { Metrics } from '@firebolt-js/sdk'

const metricEvents = [
  'loadedmetadata',
  'play',
  'playing',
  'pause',
  'waiting',
  'progress',
  'seeking',
  'seeked',
  'ratechange',
  'ended',
]

const errorEvents = [
  'abort', // Fired when playback is aborted; for example, if the media is playing and user stops the playback the hard way.
  'error',
]

let observer = undefined
let entityId = undefined

// Extract path to avoid duplicate metrics due to possible signed URLs and diffrent hosts serving the same content.
// eg. /content/entitys/1234.m3u8
const srcToEntityId = src => {
  if (src) {
    return new URL(src).pathname
  } else {
    return ''
  }
}

const calculateProgress = (currentTime, duration) => {
  if (isFinite(duration)) return Number((currentTime / duration).toFixed(3))

  return 0
}

const filter = (nodeList, tag) => {
  tag = tag.toUpperCase()
  return Array.from(nodeList).filter(n => n.nodeName === tag)
}

const errorHandler = event => {
  const video = event.target
  Metrics.error(Metrics.ErrorType.MEDIA, event.type, '', false, {
    src: video.src,
    entityId: entityId,
  })
}

const metricsHandler = event => {
  const video = event.target
  const id = entityId

  switch (event.type) {
    case 'loadedmetadata':
      Metrics.mediaLoadStart(id)
      break

    case 'play':
      Metrics.mediaPlay(id)
      break

    case 'playing':
      Metrics.mediaPlaying(id)
      break

    case 'pause':
      Metrics.mediaPause(id)
      break

    case 'waiting':
      Metrics.mediaWaiting(id)
      break

    case 'progress':
      Metrics.mediaProgress(id, calculateProgress(video.currentTime, video.duration))
      break

    case 'seeking':
      Metrics.mediaSeeking(id, calculateProgress(video.currentTime, video.duration))
      break

    case 'seeked':
      Metrics.mediaSeeked(id, calculateProgress(video.currentTime, video.duration))
      break

    case 'ratechange':
      Metrics.mediaRateChange(id, video.playbackRate)
      break

    case 'ended':
      Metrics.mediaEnded(id)
      break
  }
}

const observe = () => {
  if (observer) {
    observer.disconnect()
  }

  observer = new MutationObserver(mutations => {
    mutations.map(record => {
      const added = record.addedNodes
      const removed = record.removedNodes
      const addedVideos = filter(added, 'VIDEO')
      const removedVideos = filter(removed, 'VIDEO')
      if (removedVideos.length > 0) {
        removedVideos.forEach(element => {
          fireboltVideoMetrics.deregisterVideoListeners(element)
        })
      }
      if (addedVideos.length > 0) {
        addedVideos.forEach(element => {
          fireboltVideoMetrics.registerVideoListeners(element)
        })
      }
    })
  })

  observer.observe(document.body, { childList: true, subtree: true })
}

export const fireboltVideoMetrics = {
  init: () => {
    observe()
  },

  getEntityId: () => {
    return entityId
  },

  setEntityId: id => {
    entityId = srcToEntityId(id)
  },

  registerVideoListeners: videoElement => {
    errorEvents.forEach(event => {
      videoElement.addEventListener(event, errorHandler)
    })
    metricEvents.forEach(event => {
      videoElement.addEventListener(event, metricsHandler)
    })
  },

  deregisterVideoListeners: videoElement => {
    errorEvents.forEach(event => {
      videoElement.removeEventListener(event, errorHandler)
    })
    metricEvents.forEach(event => {
      videoElement.removeEventListener(event, metricsHandler)
    })
  },
}
