import { Colors, Language, Lightning, Utils } from '@lightningjs/sdk'
import { getVideoLiveState } from '../../utils/cspan'
import { COLORS } from '../../constants/colors'
import { minToMs } from '../../utils/time'

export default class LiveBadge extends Lightning.Component {
  static _template() {
    return {
      w: 110,
      h: 40,
      alpha: 0.0001,
      Background: {
        w: 110,
        h: 40,
        rect: true,
        color: Colors(COLORS.WHITE).get(),
        shader: { type: Lightning.shaders.RoundedRectangle, radius: 20 },
      },
      Label: {
        x: 15,
        y: 20,
        mountY: 0.45,
        color: Colors(COLORS.COBALT).get(),
        text: {
          text: '',
          verticalAlign: 'middle',
          fontFace: 'PoppinsRegular',
          fontSize: 20,
          lineHeight: 30,
        },
      },
      StatusIcon: {
        x: 90,
        y: 20,
        w: 30,
        h: 30,
        mount: 0.5,
        src: '',
      },
    }
  }

  set item(item) {
    this._item = item
    this._startTime = item.liveBeginTime
    this._endTime = item.liveEndTime

    if (item.isliveStream === true) {
      this._initUpdateCheck()
    }
  }

  set label(label) {
    this._label = label
    this.tag('Label').text.text = label
  }

  get label() {
    return this._label
  }

  set status(status) {
    switch (status) {
      case 'live':
        this.alpha = 1
        this.label = Language.translate('live')
        this.tag('StatusIcon').src = Utils.asset('images/status_live_now.png')
        break
      case 'soon':
        this.alpha = 1
        this.label = 'Soon'
        this.tag('StatusIcon').src = Utils.asset('images/status_live_soon.png')
        break
      default:
        this.alpha = 0.0001
        this.label = ''
        this.tag('StatusIcon').src = ''
    }
  }

  set activate(activate) {
    if (this.tag('Background')) {
      this.tag('Background').setSmooth(
        'color',
        activate ? Colors(COLORS.WHITE).get() : Colors(COLORS.DARKGREY_60).get()
      )
      this.tag('StatusIcon').setSmooth(
        'color',
        activate ? Colors(COLORS.WHITE).get() : Colors(COLORS.DARKGREY_60).get()
      )
    }
  }

  _active() {
    this._initUpdateCheck()
  }

  _inactive() {
    this._clearUpdateCheck()
  }

  //Call once
  _initUpdateCheck() {
    if (this._item && this._item.isliveStream === true) {
      this.checkAndUpdate()
      this._clearUpdateCheck()
      this._updateCheck = setInterval(() => {
        // update badge status every minute
        this.checkAndUpdate()
      }, minToMs(1))
    }
  }

  _clearUpdateCheck() {
    if (this._item && this._item.isliveStream === true) {
      if (this._updateCheck) clearInterval(this._updateCheck)
    }
  }

  checkAndUpdate() {
    this.status = getVideoLiveState(this._startTime, this._endTime)
  }
}
