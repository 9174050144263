import { API } from '../../constants/api'
import {
  isArrayWithLength,
  isNumber,
  isObjectWithLength,
  isStringWithLength,
} from '../../utils/checks'
import { extractParamsFromObject } from '../../utils/tools'
import ProgramAsset from './ProgramAsset'
import { adjustToItalic } from '../../utils/cspan'

export default class Api_HomeCallAsset {
  constructor(callData, indexWeight = 999999) {
    this._displayType = isStringWithLength(callData && callData.displayType)
      ? callData.displayType
      : undefined
    this._id = isStringWithLength(callData && callData.id) ? callData.id : undefined
    this._params = isObjectWithLength(callData && callData.params) ? callData.params : undefined
    this._paramsAsString = isObjectWithLength(this._params)
      ? extractParamsFromObject(this._params)
      : ''
    this._expandedParams = isObjectWithLength(callData && callData.expandedParams)
      ? callData.expandedParams
      : undefined
    this._expandedParamsAsString = isObjectWithLength(this._expandedParams)
      ? extractParamsFromObject(this._expandedParams)
      : ''
    this._title = isStringWithLength(callData && callData.title)
      ? adjustToItalic(callData.title)
      : undefined

    this._type = isStringWithLength(callData && callData.type) ? callData.type : undefined
    this._weight = isNumber(callData && callData.weight) ? callData.weight : indexWeight
    this._hasLive = !!(this._id && this._id === API.CALLID.LIVESTREAM)
    this._failed = false
    this._contents = isArrayWithLength(callData && callData.contents)
      ? this._createRow(callData.contents)
      : []
  }

  get displayType() {
    return this._displayType
  }

  get id() {
    return this._id
  }

  get params() {
    return this._params
  }

  get paramsAsString() {
    return this._paramsAsString
  }

  get expandedParams() {
    return this._expandedParams
  }

  get expandedParamsAsString() {
    return this._expandedParamsAsString
  }

  get title() {
    return this._title
  }

  get type() {
    return this._type
  }

  get weight() {
    return this._weight
  }

  get hasLive() {
    return this._hasLive
  }

  set failed(failed) {
    this._failed = failed
  }

  get failed() {
    return this._failed
  }

  get contents() {
    return this._contents
  }

  _createRow(row) {
    return row.map(programItem => {
      return new ProgramAsset(programItem)
    })
  }
}
